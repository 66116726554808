import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { UseAutocompleteProps } from '@mui/material/useAutocomplete'
import { useAuthStore } from 'auth/hooks'
import { useChannel } from 'channel/Controller'
import { getAutoCompleteWords } from 'componentData/functions'
import { useSearchLanguage } from 'contexts/SearchLanguageController'
import { useAlertDialog } from 'dialog/hooks'
import { isWordComponent } from 'pathManager/functions'
import { usePathParams } from 'pathManager/hooks'
import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'router'
import { DEFAULT_PATH } from 'router/constants'
import { useActivePlan } from 'subscriptions'
import { ThemeConstants } from 'theme/constants'
import {
  AutoCompleteAPIWordItemType,
  AutoCompleteListItemType,
} from 'types/backend-types'
import { DEFAULT_LANGUAGE, SEARCH_BAR_WIDTH } from 'utils/constants'
import PopperWithTopper from './PopperWithTopper'
import { SearchInput } from './SearchInput'

type AutoCompleteDataType = {
  order: number
  options: AutoCompleteListItemType[]
}

type HandleChange = UseAutocompleteProps<
  string | AutoCompleteAPIWordItemType,
  false,
  undefined,
  true
>['onChange']

const SearchBar = () => {
  const { loggedIn } = useAuthStore()
  const { defaultComponent } = useActivePlan()
  const { channel } = useChannel()
  const { searchLanguage } = useSearchLanguage()
  const { word, component } = usePathParams()
  const wordComponent = isWordComponent(component) ? component : DEFAULT_PATH
  const { openAlert } = useAlertDialog()
  const [searchWord, setSearchWord] = useState<string>('')
  const [autoCompleteData, setAutoCompleteData] =
    useState<AutoCompleteDataType>({ order: 0, options: [] })
  const navigate = useNavigate()
  const { options } = autoCompleteData

  useEffect(() => {
    if (searchWord === '' || !loggedIn) return

    getAutoCompleteWords({
      word: searchWord,
      searchLanguage,
      channel,
    }).then((results) =>
      setAutoCompleteData((acd) =>
        results.order > acd.order
          ? {
              order: results.order,
              options: results?.data ?? [],
            }
          : acd
      )
    )
  }, [searchWord, searchLanguage, loggedIn, channel])

  const noOptionsText =
    searchWord.length < 2
      ? 'Please enter at least 2 characters'
      : searchWord === undefined
      ? 'No results'
      : 'Loading...'

  const handleChange: HandleChange = useCallback(
    (event, newValue) => {
      console.log(newValue, event)
      if (typeof newValue === 'string') {
        getAutoCompleteWords({
          word: newValue,
          searchLanguage,
          channel,
        }).then((results) =>
          results.data.length
            ? navigate(
                `/${wordComponent}/${results.data[0].language}/${results.data[0].word}`
              )
            : openAlert({
                title: 'No Result',
                subtitle: `Could not find anything for "${newValue}"`,
                cancelText: null,
              })
        )
      } else if (newValue?.word && newValue?.language) {
        navigate(`/${defaultComponent}/${newValue.language}/${newValue.word}`)
      } else {
        console.log('Searchbar text change but no word or no language')
      }
    },
    [
      channel,
      defaultComponent,
      navigate,
      openAlert,
      searchLanguage,
      wordComponent,
    ]
  )

  return (
    <SearchBarWrapper elevation={3}>
      <Autocomplete<AutoCompleteListItemType | string, false, undefined, true>
        style={{ zIndex: ThemeConstants.Z_INDEX_AUTOCOMPLETE, flex: 1 }}
        noOptionsText={noOptionsText}
        clearOnEscape
        blurOnSelect
        autoHighlight
        freeSolo
        disableListWrap
        value={word ?? null} // make this uncontrolled
        clearIcon={false}
        onChange={handleChange}
        options={options || []}
        renderInput={(params) => (
          <SearchInput params={params} setSearchWord={setSearchWord} />
        )}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : option?.transliteration ?? option?.word
        }
        renderOption={(props, option) =>
          typeof option === 'string' ? null : (
            <Box
              component="li"
              {...props}
              style={{
                width: '100%',
                flexDirection: 'row',
                display: 'flex',
                flex: '1',
                justifyContent: 'space-between',
              }}
            >
              {`${option.word}${
                option.language === DEFAULT_LANGUAGE
                  ? ''
                  : ' (' + option.language + ')'
              }`}
            </Box>
          )
        }
        isOptionEqualToValue={(option, value) => {
          // console.log(option, value)
          return typeof option === 'string' || typeof value === 'string'
            ? false
            : option?.word === value?.word &&
                option?.language === value?.language &&
                option?.transliteration === value?.transliteration
        }}
        PopperComponent={PopperWithTopper}
      />
    </SearchBarWrapper>
  )
}

export default SearchBar

const SearchBarWrapper = styled(Paper)(() => ({
  width: isMobile ? '100%' : SEARCH_BAR_WIDTH,
  borderRadius: 8,
  pointerEvents: 'all',

  alignItems: 'center',
  boxSizing: 'border-box',
  paddingLeft: '1rem',
  paddingRight: '1rem',
}))
