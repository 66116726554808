// Load modules to the console for development
import { IS_PRODUCTION } from './constants'
import { getParamString } from './functions'

if (!IS_PRODUCTION) {
  import('query-string').then(
    //@ts-ignore
    (queryString) => (window.queryString = queryString)
  )
  import('axios').then(
    //@ts-ignore
    (axios) => (window.axios = axios)
  )
  //@ts-ignore
  window.getParamString = getParamString
}

export {}
