import { Action, State } from './types'

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      }
    default:
      throw new Error('unrecognized user settings action')
  }
}
