import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useAuthStore } from 'auth/hooks'
import { A } from 'components'
import { HTMLProps } from 'react'
import { Path } from 'router/constants'
import { useActivePlan } from 'subscriptions'
import { SidebarListItem } from './SidebarListItem'

const Main = () => {
  const { user, loggedIn } = useAuthStore()
  const { hasPremium, isVanguardPlan } = useActivePlan()

  return (
    <SidebarList>
      <div>
        <ListHeader>Basic</ListHeader>
        <ListItemContainer>
          <SidebarListItem path={Path.TREE} />
          <SidebarListItem path={Path.DETAIL} />
        </ListItemContainer>
        <Divider style={{ marginTop: 10, marginBottom: 30 }} />
        <ListHeader>Premium</ListHeader>
        <ListItemContainer>
          {!hasPremium ? (
            <SidebarListItem path={Path.NODE_CHILD} />
          ) : (
            <>
              <SidebarListItem path={Path.NODE} />
              <SidebarListItem path={Path.NODE_CHILD} />
              <SidebarListItem path={Path.PROGENY_TREE} />
              <SidebarListItem path={Path.PROGENY_TABLE} />
              <SidebarListItem path={Path.CONN} />
            </>
          )}
        </ListItemContainer>
        <Divider style={{ marginTop: 10, marginBottom: 30 }} />
      </div>
      <div>
        <ListItemContainer>
          {!hasPremium && <SidebarListItem path={Path.PLANS} />}
          {isVanguardPlan && <SidebarListItem path={Path.VANGUARD} />}
          <SidebarListItem path={Path.WOTD_LIST} />
          <SidebarListItem path={Path.ACCOUNT} />
          {loggedIn && (
            <SidebarListItem
              path={user?.isAnonymous ? Path.LOGIN : Path.LOGOUT}
            />
          )}
        </ListItemContainer>
        <SidebarFooter />
      </div>
    </SidebarList>
  )
}
export default Main

const SidebarFooter = () => {
  const theme = useTheme()
  return (
    <>
      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 8,
        }}
      >
        <A
          href="https://etymologyexplorer.com/terms_and_conditions.html"
          target="_blank"
          style={{
            marginRight: 15,
            color: theme.palette.text.secondary,
            fontSize: 14,
          }}
          rel="noreferrer"
        >
          Terms
        </A>
        <div>•</div>
        <A
          style={{
            color: theme.palette.text.secondary,
            fontSize: 14,
            marginLeft: 15,
            marginRight: 1,
          }}
          href="https://etymologyexplorer.com/privacy_policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy
        </A>
      </div>
    </>
  )
}

const SidebarList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  justifyContent: 'space-between',
})

const ListItemContainer = styled('div')({})

const ListHeader = ({ children }: HTMLProps<HTMLDivElement>) => (
  <div style={{ paddingLeft: '1rem' }}>
    <Typography
      style={{ textAlign: 'left', fontWeight: '500', fontSize: '1.25rem' }}
    >
      {children}
    </Typography>
  </div>
)
