import { ALL_LANGUAGES_STRING } from './constants'

const all_lang_dict = {
  aav: 'Austroasiatic',
  afa: 'Afro-Asiatic',
  alg: 'Algonquian',
  alv: 'Atlantic-Congo',
  apa: 'Apachean',
  aqa: 'Alacalufan',
  aql: 'Algic',
  ath: 'Athabaskan',
  auf: 'Arauan',
  arw: 'Arawak',
  azc: 'Uto-Aztecan',
  bnd: 'Banda',
  bai: 'Bamileke',
  bat: 'Baltic',
  ber: 'Berber',
  bnt: 'Bantu',
  btk: 'Batak',
  cba: 'Chibchan',
  ccn: 'North Caucasian',
  ccs: 'Kartvelian',
  cdc: 'Chadic',
  cdd: 'Caddoan',
  cel: 'Celtic',
  chi: 'Chimakuan',
  cmc: 'Chamic',
  csu: 'Central Sudanic',
  cus: 'Cushitic',
  day: 'Land Dayak',
  del: 'Lenape',
  dmn: 'Mande',
  dra: 'Dravidian',
  egy: 'Egyptian',
  esx: 'Eskimo-Aleut',
  euq: 'Vasconic',
  gem: 'Germanic',
  gme: 'East Germanic',
  gmq: 'North Germanic',
  gmw: 'West Germanic',
  el: 'Greek',
  hmn: 'Hmong',
  hmx: 'Hmong-Mien',
  hok: 'Hokan',
  hy: 'Armenian',
  iir: 'Indo-Iranian',
  ijo: 'Ijoid',
  inc: 'Indo-Aryan',
  ine: 'Indo-European',
  ira: 'Iranian',
  iro: 'Iroquoian',
  itc: 'Italic',
  ja: 'Japanese',
  kar: 'Karen',
  kro: 'Kru',
  map: 'Austronesian',
  mkh: 'Mon-Khmer',
  mno: 'Manobo',
  unx: 'Munda',
  myn: 'Mayan',
  ngf: 'Trans-New Guinea',
  nic: 'Niger-Congo',
  nub: 'Nubian',
  omq: 'Oto-Manguean',
  omv: 'Omotic',
  oto: 'Otomian',
  phi: 'Philippine',
  poz: 'Malayo-Polynesian',
  pqe: 'Eastern Malayo-Polynesian',
  pra: 'Prakrit',
  qwe: 'Quechuan',
  la: 'Latin',
  sal: 'Salishan',
  sdv: 'Eastern Sudanic',
  sem: 'Semitic',
  sio: 'Siouan',
  sit: 'Sino-Tibetan',
  sla: 'Slavic',
  smi: 'Sami',
  son: 'Songhay',
  sq: 'Albanian',
  ssa: 'Nilo-Saharan',
  syd: 'Samoyedic',
  taw: 'Tai',
  tbq: 'Tibeto-Burman',
  trk: 'Turkic',
  tup: 'Tupian',
  tut: 'Altaic',
  tuw: 'Tungusic',
  urj: 'Uralic',
  wak: 'Wakashan',
  wen: 'Sorbian',
  mn: 'Mongolian',
  xnd: 'Na-Dene',
  esu: "Yup'ik",
  zh: 'Chinese',
  zle: 'East Slavic',
  zls: 'South Slavic',
  zlw: 'West Slavic',
  zne: 'Zande',
  'alv-edo': 'Edoid',
  'alv-kwa': 'Kwa',
  mdd: 'Mbum',
  'alv-sav': 'Savanna',
  'alv-sng': 'Senegambian',
  'alv-von': 'Volta-Niger',
  'ath-nor': 'North Athabaskan',
  'ath-pco': 'Pacific Coast Athabaskan',
  'aus-arn': 'Arnhem',
  'aus-gun': 'Gunwinyguan',
  'aus-bub': 'Bunuban',
  'aus-cww': 'Central New South Wales',
  'aus-dal': 'Daly',
  'aus-dyb': 'Dyirbalic',
  'aus-gar': 'Garawan',
  'aus-kar': 'Karnic',
  'aus-nga': 'Ngayarda',
  'aus-nyu': 'Nyulnyulan',
  'aus-pam': 'Pama-Nyungan',
  'aus-pmn': 'Paman',
  'aus-psw': 'Southwest Pama-Nyungan',
  'aus-rnd': 'Arandic',
  'aus-tnk': 'Tangkic',
  'aus-wdj': 'Iwaidjan',
  'aus-yid': 'Yidinyic',
  'aus-yol': 'Yolngu',
  'aus-yuk': 'Yuin-Kuric',
  'awd-nwk': 'Nawiki',
  'awd-taa': 'Ta-Arawakan',
  'azc-cup': 'Cupan',
  'azc-nah': 'Nahuan',
  'azc-num': 'Numic',
  'azc-tak': 'Takic',
  'azc-trc': 'Taracahitic',
  ngo: 'Ngoni',
  'cau-abz': 'Abkhaz-Abaza',
  'cau-ava': 'Avaro-Andian',
  'cau-cir': 'Circassian',
  dar: 'Dargwa',
  lez: 'Lezgi',
  'cau-nec': 'Northeast Caucasian',
  'ira-csp': 'Caspian',
  'cau-nkh': 'Nakh',
  'cau-nwc': 'Northwest Caucasian',
  'cau-tsz': 'Tsezian',
  'ccs-gzn': 'Georgian-Zan',
  'ccs-zan': 'Zan',
  'cdc-cbm': 'Central Chadic',
  'cdc-est': 'East Chadic',
  'cdc-mas': 'Masa',
  'cdc-wst': 'West Chadic',
  'cel-bry': 'Brythonic',
  'cel-gae': 'Goidelic',
  sre: 'Sara',
  'esx-esk': 'Eskimo',
  'esx-inu': 'Inuit',
  'fiu-fin': 'Finnic',
  'gmw-fri': 'Frisian',
  'hmx-mie': 'Mien',
  'iir-nur': 'Nuristani',
  'inc-dar': 'Dardic',
  'inc-old': 'Old Indo-Aryan',
  'inc-pah': 'Pahari',
  psu: 'Sauraseni Prakrit',
  rom: 'Romani',
  'ine-ana': 'Anatolian',
  'ine-bsl': 'Balto-Slavic',
  'ine-toc': 'Tocharian',
  'ira-cen': 'Central Iranian',
  'ira-glr': 'Gilaki-Rudbari',
  'ira-med': 'Median',
  'ira-mny': 'Munji-Yidgha',
  'ira-msh': 'Mazanderani-Shahmirzadi',
  'ira-nei': 'Northeastern Iranian',
  'ira-nwi': 'Northwestern Iranian',
  'ira-orp': 'Ormuri-Parachi',
  'ira-sak': 'Sakan',
  xsc: 'Scythian',
  'ira-sei': 'Southeastern Iranian',
  sog: 'Sogdian',
  'ira-sgi': 'Sanglechi-Ishkashimi',
  'ira-shy': 'Shughni-Yazgulami',
  smy: 'Semnani',
  'ira-swi': 'Southwestern Iranian',
  'ira-sym': 'Shughni-Yazghulami-Munji',
  'ira-tat': 'Tatic',
  'ira-wes': 'Western Iranian',
  'ira-zzg': 'Zaza-Gorani',
  'jpx-ryu': 'Ryukyuan',
  'khi-kho': 'Khoe',
  'khi-kxa': "Kx'a",
  'khi-tuu': 'Tuu',
  'map-ata': 'Atayalic',
  'mkh-ban': 'Bahnaric',
  'mkh-kat': 'Katuic',
  'mkh-khm': 'Khmuic',
  'mkh-nbn': 'North Bahnaric',
  'mkh-pal': 'Palaungic',
  'mkh-pea': 'Pearic',
  'mkh-vie': 'Vietic',
  'nai-cat': 'Catawban',
  'nai-chu': 'Chumashan',
  'nai-ckn': 'Chinookan',
  'nai-ker': 'Keresan',
  'nai-kta': 'Kiowa-Tanoan',
  'nai-len': 'Lencan',
  'nai-mdu': 'Maiduan',
  'nai-min': 'Misumalpan',
  'nai-miz': 'Mixe-Zoquean',
  'nai-mus': 'Muskogean',
  'nai-pal': 'Palaihnihan',
  'nai-pom': 'Pomoan',
  'nai-sca': 'Siouan-Catawban',
  'nai-shp': 'Sahaptian',
  'nai-shs': 'Shastan',
  'nai-tot': 'Totozoquean',
  'nai-teq': 'Tequistlatec',
  'nai-ttn': 'Totonacan',
  'nai-utn': 'Utian',
  'nai-wtq': 'Wintuan',
  xin: 'Xinca',
  'nai-yok': 'Yokutsan',
  'nai-you': 'Yok-Utian',
  'nai-yuc': 'Yuman-Cochim\u00ed',
  'ngf-fin': 'Finisterre',
  'ngf-mad': 'Madang',
  'ngf-okk': 'Ok',
  'ngf-sbh': "South Bird's Head",
  'nic-bco': 'Benue-Congo',
  'nic-bod': 'Bantoid',
  'nic-grf': 'Grassfields',
  'nic-gur': 'Gur',
  'nic-knj': 'Kainji',
  'nic-ubg': 'Ubangian',
  'nic-vco': 'Volta-Congo',
  'nur-nor': 'Northern Nuristani',
  'nur-sou': 'Southern Nuristani',
  'omq-cha': 'Chatino',
  'omq-chi': 'Chinantecan',
  'omq-maz': 'Mazatecan',
  'omq-mix': 'Mixtecan',
  'omq-pop': 'Popolocan',
  'omq-tri': 'Trique',
  'omq-zap': 'Zapotecan',
  'paa-arf': 'Arafundi',
  iwm: 'Iwam',
  'paa-kag': 'Kainantu-Goroka',
  'paa-lkp': 'Lakes Plain',
  'paa-lsp': 'Lower Sepik',
  zrs: 'Mairasi',
  skv: 'Skou',
  'paa-ram': 'Ramu',
  'paa-spk': 'Sepik',
  'paa-yam': 'Yam',
  'phi-kal': 'Kalamian',
  'poz-aay': 'Admiralty Islands',
  'poz-bnn': 'North Bornean',
  'poz-bop': 'Borneo-Philippines',
  'poz-bre': 'East Barito',
  'poz-brw': 'West Barito',
  'poz-btk': 'Bungku-Tolaki',
  'poz-cet': 'Central-Eastern Malayo-Polynesian',
  'poz-cln': 'New Caledonian',
  'poz-hce': 'Halmahera-Cenderawasih',
  'poz-kal': 'Kaili-Pamona',
  'poz-lgx': 'Lampungic',
  'poz-mcm': 'Malayo-Chamic',
  'poz-mic': 'Micronesian',
  'poz-mly': 'Malayic',
  'poz-msa': 'Malayo-Sumbawan',
  'poz-mun': 'Muna-Buton',
  'poz-nws': 'Northwest Sumatran',
  'poz-occ': 'Central-Eastern Oceanic',
  'poz-oce': 'Oceanic',
  'poz-ocw': 'Western Oceanic',
  'poz-pep': 'Eastern Polynesian',
  'poz-pnp': 'Nuclear Polynesian',
  'poz-pol': 'Polynesian',
  'poz-san': 'Sabahan',
  'poz-sbj': 'Sama-Bajaw',
  'poz-slb': 'Saluan-Banggai',
  'poz-sls': 'Southeast Solomonic',
  'poz-ssw': 'South Sulawesi',
  'poz-sus': 'Sunda-Sulawesi',
  'poz-swa': 'North Sarawakan',
  'poz-tot': 'Tomini-Tolitoli',
  'poz-vnc': 'North-Central Vanuatu',
  'poz-wot': 'Wotu-Wolio',
  'qfa-adm': 'Andamanese',
  'qfa-cka': 'Chukotko-Kamchatkan',
  'qfa-dgn': 'Dogon',
  'qfa-dny': 'Dene-Yeniseian',
  'qfa-hur': 'Hurro-Urartian',
  'qfa-kad': 'Kadu',
  'qfa-kms': 'Kam-Sui',
  ko: 'Korean',
  lic: 'Hlai',
  'qfa-mal': 'Left May',
  'qfa-mch': 'Macro-Chibchan',
  'qfa-sub': 'substrate',
  kzd: 'Kadai',
  'qfa-tap': 'Timor-Alor-Pantar',
  tei: 'Torricelli',
  'qfa-tyn': 'Tyrsenian',
  'qfa-yen': 'Yeniseian',
  'qfa-yuk': 'Yukaghir',
  'roa-eas': 'Eastern Romance',
  'roa-oil': 'O\u00efl',
  'roa-rhe': 'Rhaeto-Romance',
  'sai-ara': 'Araucanian',
  'sai-aym': 'Aymaran',
  'sai-bar': 'Barbacoan',
  'sai-car': 'Cariban',
  'sai-chc': 'Chocoan',
  'sai-cho': 'Chonan',
  'sai-cpc': 'Chapacuran',
  'sai-crn': 'Charruan',
  'sai-ctc': 'Catacaoan',
  'sai-guc': 'Guaicuruan',
  'sai-guh': 'Guahiban',
  'sai-har': 'Har\u00e1kmbut',
  'sai-hkt': 'Har\u00e1kmbut\u2013Katukinan',
  'sai-hrp': 'Huarpean',
  'sai-jee': 'J\u00ea',
  'sai-jir': 'Jirajaran',
  'sai-jiv': 'Jivaroan',
  'sai-ktk': 'Katukinan',
  'sai-mas': 'Mascoian',
  'sai-mgc': 'Mataco-Guaicuru',
  'sai-mje': 'Macro-J\u00ea',
  'sai-mtc': 'Matacoan',
  'sai-mur': 'Muran',
  'sai-nmk': 'Nambikwaran',
  'sai-otm': 'Otomacoan',
  'sai-pan': 'Panoan',
  'sai-pat': 'Pano-Tacanan',
  'sai-tac': 'Tacanan',
  'sai-tuc': 'Tucanoan',
  'sai-tyu': 'Ticuna-Yuri',
  'sai-ucp': 'Uru-Chipaya',
  'sai-wic': 'Wich\u00ed',
  'sai-wit': 'Witotoan',
  'sai-ynm': 'Yanomami',
  'sai-zam': 'Zamucoan',
  zro: 'Z\u00e1paro',
  arc: 'Aramaic',
  ar: 'Arabic',
  'sem-can': 'Canaanite',
  'sem-cen': 'Central Semitic',
  'sem-eas': 'East Semitic',
  'sem-eth': 'Ethiopian Semitic',
  'sem-nwe': 'Northwest Semitic',
  'sem-srb': 'Old South Arabian',
  'sem-sar': 'South Arabian',
  'sem-sou': 'South Semitic',
  'sem-wes': 'West Semitic',
  'sgn-fsl': 'French Sign Languages',
  'sgn-gsl': 'German Sign Languages',
  'sgn-jsl': 'Japanese Sign Languages',
  'sit-qia': 'Qiangic',
  'sit-tan': 'Tani',
  fvr: 'Fur',
  'ssa-sah': 'Saharan',
  'tai-cen': 'Central Tai',
  'tai-cho': 'Chongzuo Tai',
  'tai-nor': 'Northern Tai',
  'tai-sap': 'Sapa-Southwestern Tai',
  'tai-swe': 'Southwestern Tai',
  tyz: 'T\u00e0y',
  'tai-wen': 'Wenma-Southwestern Tai',
  'tbq-bdg': 'Bodo-Garo',
  'tbq-brm': 'Burmish',
  'tbq-kuk': 'Kukish',
  'tbq-lol': 'Loloish',
  'trk-ogr': 'Oghur',
  'trk-ogz': 'Oghuz',
  'tup-gua': 'Tupi-Guarani',
  'urj-mdv': 'Mordvinic',
  'urj-prm': 'Permic',
  aa: 'Afar',
  ab: 'Abkhaz',
  ae: 'Avestan',
  af: 'Afrikaans',
  ak: 'Akan',
  am: 'Amharic',
  an: 'Aragonese',
  as: 'Assamese',
  av: 'Avar',
  ay: 'Aymara',
  az: 'Azerbaijani',
  ba: 'Bashkir',
  be: 'Belarusian',
  bg: 'Bulgarian',
  bh: 'Bihari',
  bi: 'Bislama',
  bm: 'Bambara',
  bn: 'Bengali',
  ylm: 'Limi',
  gol: 'Gola',
  br: 'Breton',
  ca: 'Catalan',
  ce: 'Chechen',
  ch: 'Chamorro',
  co: 'Corsican',
  cr: 'Cree',
  cs: 'Czech',
  cu: 'Old Church Slavonic',
  cv: 'Chuvash',
  cy: 'Welsh',
  da: 'Danish',
  de: 'German',
  dv: 'Dhivehi',
  dz: 'Dzongkha',
  ee: 'Ewe',
  en: 'English',
  eo: 'Esperanto',
  es: 'Spanish',
  et: 'Estonian',
  eu: 'Basque',
  fa: 'Persian',
  ff: 'Fula',
  fi: 'Finnish',
  fj: 'Fijian',
  fo: 'Faroese',
  fr: 'French',
  fy: 'West Frisian',
  ga: 'Irish',
  gd: 'Scottish Gaelic',
  gl: 'Galician',
  gn: 'Guaran\u00ed',
  gu: 'Gujarati',
  gv: 'Manx',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  ho: 'Hiri Motu',
  ht: 'Haitian Creole',
  hu: 'Hungarian',
  hz: 'Herero',
  ia: 'Interlingua',
  id: 'Indonesian',
  ie: 'Interlingue',
  ig: 'Igbo',
  ii: 'Sichuan Yi',
  ik: 'Inupiak',
  io: 'Ido',
  is: 'Icelandic',
  it: 'Italian',
  ike: 'Eastern Canadian Inuktitut',
  ikt: 'Inuvialuktun',
  jv: 'Javanese',
  ka: 'Georgian',
  yom: 'Yombe',
  ki: 'Kikuyu',
  kj: 'Kwanyama',
  kk: 'Kazakh',
  kl: 'Greenlandic',
  km: 'Khmer',
  kn: 'Kannada',
  kr: 'Kanuri',
  ks: 'Kashmiri',
  ku: 'Kurdish',
  kw: 'Cornish',
  ky: 'Kyrgyz',
  lb: 'Luxembourgish',
  lg: 'Luganda',
  li: 'Limburgish',
  nud: 'Ngala',
  lo: 'Lao',
  lt: 'Lithuanian',
  lu: 'Luba-Katanga',
  lv: 'Latvian',
  mg: 'Malagasy',
  mh: 'Marshallese',
  mi: 'Maori',
  mk: 'Macedonian',
  ml: 'Malayalam',
  mr: 'Marathi',
  ms: 'Malay',
  mt: 'Maltese',
  my: 'Burmese',
  na: 'Nauruan',
  nb: 'Norwegian Bokm\u00e5l',
  nd: 'Northern Ndebele',
  ne: 'Nepali',
  ng: 'Ndonga',
  nl: 'Dutch',
  nn: 'Norwegian Nynorsk',
  no: 'Norwegian',
  nr: 'Southern Ndebele',
  nv: 'Navajo',
  ny: 'Chichewa',
  oc: 'Occitan',
  oj: 'Ojibwe',
  om: 'Oromo',
  or: 'Oriya',
  os: 'Ossetian',
  pa: 'Punjabi',
  pi: 'Pali',
  pl: 'Polish',
  ps: 'Pashto',
  pt: 'Portuguese',
  qu: 'Quechua',
  rm: 'Romansch',
  ro: 'Romanian',
  ru: 'Russian',
  rw: 'Rwanda-Rundi',
  sa: 'Sanskrit',
  sc: 'Sardinian',
  sd: 'Sindhi',
  se: 'Northern Sami',
  sg: 'Sango',
  sh: 'Serbo-Croatian',
  si: 'Sinhalese',
  sk: 'Slovak',
  sl: 'Slovene',
  sm: 'Samoan',
  sn: 'Shona',
  so: 'Somali',
  ss: 'Swazi',
  st: 'Sotho',
  su: 'Sundanese',
  sv: 'Swedish',
  sw: 'Swahili',
  ta: 'Tamil',
  te: 'Telugu',
  tg: 'Tajik',
  th: 'Thai',
  ti: 'Tigrinya',
  tk: 'Turkmen',
  tl: 'Tagalog',
  tn: 'Tswana',
  to: 'Tongan',
  tr: 'Turkish',
  ts: 'Tsonga',
  tt: 'Tatar',
  ty: 'Tahitian',
  ug: 'Uyghur',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  ve: 'Venda',
  vi: 'Vietnamese',
  vo: 'Volap\u00fck',
  wa: 'Walloon',
  wo: 'Wolof',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  za: 'Zhuang',
  zu: 'Zulu',
  aaa: 'Ghotuo',
  aab: 'Alumu-Tesu',
  aac: 'Ari',
  aad: 'Amal',
  aaf: 'Aranadan',
  aag: 'Ambrak',
  aah: "Abu' Arapesh",
  aai: 'Arifama-Miniafia',
  aak: 'Ankave',
  aal: 'Afade',
  aan: 'Anamb\u00e9',
  aao: 'Algerian Saharan Arabic',
  aap: 'Par\u00e1 Ar\u00e1ra',
  aaq: 'Penobscot',
  aas: 'Aasax',
  aau: 'Abau',
  aaw: 'Solong',
  aax: 'Mandobo Atas',
  aaz: 'Amarasi',
  aba: 'Ab\u00e9',
  bgl: 'Bo',
  abc: 'Ambala Ayta',
  abd: 'Camarines Norte Agta',
  abe: 'Abenaki',
  abf: 'Abai Sungai',
  abg: 'Abaga',
  abh: 'Tajiki Arabic',
  abi: 'Abidji',
  abj: 'Aka-Bea',
  abl: 'Abung',
  abm: 'Abanyom',
  abn: 'Abua',
  abo: 'Abon',
  abp: 'Abenlen Ayta',
  abq: 'Abaza',
  abr: 'Abron',
  abs: 'Ambonese Malay',
  abt: 'Ambulas',
  abu: 'Abure',
  abv: 'Baharna Arabic',
  abw: 'Pal',
  abx: 'Inabaknon',
  aby: 'Aneme Wake',
  abz: 'Abui',
  'sai-ajg': 'Ajagua',
  acb: '\u00c1nc\u00e1',
  acd: 'Gikyode',
  ace: 'Acehnese',
  ach: 'Acholi',
  kbj: 'Kari',
  ack: 'Aka-Kora',
  acl: 'Akar-Bale',
  acm: 'Iraqi Arabic',
  acn: 'Achang',
  acp: 'Eastern Acipa',
  acq: "Ta'izzi-Adeni Arabic",
  acr: 'Achi',
  acs: 'Acro\u00e1',
  acu: 'Achuar',
  acv: 'Achumawi',
  acw: 'Hijazi Arabic',
  acx: 'Omani Arabic',
  acy: 'Cypriot Arabic',
  acz: 'Acheron',
  ada: 'Adangme',
  adb: 'Adabe',
  add: 'Dzodinka',
  ade: 'Adele',
  adf: 'Dhofari Arabic',
  adg: 'Andegerebinha',
  adh: 'Adhola',
  mrg: 'Mising',
  psq: 'Pasi',
  adj: 'Adioukrou',
  adl: 'Galo',
  adn: 'Adang',
  ado: 'Abu',
  adp: 'Adap',
  adq: 'Adangbe',
  adr: 'Adonara',
  ads: 'Adamorobe Sign Language',
  adt: 'Adnyamathanha',
  adu: 'Aduge',
  adw: 'Amondawa',
  ady: 'Adyghe',
  adz: 'Adzera',
  aea: 'Areba',
  aeb: 'Tunisian Arabic',
  aec: 'Saidi Arabic',
  aed: 'Argentine Sign Language',
  aee: 'Northeast Pashayi',
  aek: 'Haeke',
  ael: 'Ambele',
  aem: 'Arem',
  aen: 'Armenian Sign Language',
  aeq: 'Aer',
  aer: 'Eastern Arrernte',
  aes: 'Alsea',
  ycp: 'Chepya',
  aew: 'Ambakich',
  aey: 'Amele',
  okv: 'Orokaiva',
  afb: 'Gulf Arabic',
  afd: 'Andai',
  afe: 'Putukwam',
  afg: 'Afghan Sign Language',
  afh: 'Afrihili',
  afi: 'Akrukay',
  afk: 'Nanubae',
  afn: 'Defaka',
  afo: 'Eloyi',
  afp: 'Tapei',
  afs: 'Afro-Seminole Creole',
  aft: 'Afitti',
  afu: 'Awutu',
  afz: 'Obokuitai',
  aga: 'Aguano',
  agb: 'Legbo',
  agc: 'Agatu',
  agd: 'Agarabi',
  age: 'Angal',
  agf: 'Arguni',
  agg: 'Angor',
  agh: 'Ngelima',
  agi: 'Agariya',
  agj: 'Argobba',
  agk: 'Isarog Agta',
  agl: 'Fembe',
  agm: 'Angaataha',
  agn: 'Agutaynen',
  ago: 'Tainae',
  agq: 'Aghem',
  agr: 'Aguaruna',
  ags: 'Esimbi',
  agt: 'Central Cagayan Agta',
  agu: 'Aguacateca',
  agv: 'Remontado Agta',
  agw: 'Kahua',
  agx: 'Aghul',
  agy: 'Southern Alta',
  agz: 'Mt. Iriga Agta',
  aha: 'Ahanta',
  ahb: 'Axamb',
  ahg: 'Qimant',
  ahh: 'Aghu',
  ahi: 'Tiagbamrin Aizi',
  ahk: 'Akha',
  ahl: 'Igo',
  ahm: 'Mobumrin Aizi',
  ahn: '\u00c0h\u00e0n',
  aho: 'Ahom',
  ahp: 'Aproumu Aizi',
  khn: 'Khandeshi',
  ahs: 'Ashe',
  aht: 'Ahtna',
  aia: 'Arosi',
  ain: 'Ainu',
  aic: 'Ainbai',
  aid: 'Alngith',
  aie: 'Amara',
  aif: 'Agi',
  aig: 'Antigua and Barbuda Creole English',
  aih: 'Ai-Cham',
  aii: 'Assyrian Neo-Aramaic',
  aij: 'Lishanid Noshan',
  aik: 'Ake',
  ail: 'Aimele',
  aim: 'Aimol',
  aio: 'Aiton',
  aip: 'Burumakok',
  air: 'Airoran',
  ais: 'Nataoran Amis',
  ait: 'Arikem',
  aiw: 'Aari',
  aix: 'Aighon',
  aiy: 'Ali',
  ajg: 'Adja',
  aji: 'Aji\u00eb',
  ajn: 'Andajin',
  ajp: 'South Levantine Arabic',
  ajt: 'Judeo-Tunisian Arabic',
  aju: 'Judeo-Moroccan Arabic',
  ajw: 'Ajawa',
  ajz: 'Amri Karbi',
  akb: 'Angkola Batak',
  akc: 'Mpur',
  akd: 'Ukpet-Ehom',
  ake: 'Akawaio',
  akf: 'Akpa',
  akg: 'Anakalangu',
  akh: 'Angal Heneng',
  aki: 'Aiome',
  akj: 'Jeru',
  akk: 'Akkadian',
  akl: 'Aklanon',
  akm: 'Aka-Bo',
  ako: 'Akurio',
  akp: 'Siwu',
  akq: 'Ak',
  akr: 'Araki',
  aks: 'Akaselem',
  akt: 'Akolet',
  aku: 'Akum',
  akv: 'Akhvakh',
  akw: 'Akwa',
  akx: 'Aka-Kede',
  aky: 'Aka-Kol',
  akz: 'Alabama',
  ala: 'Alago',
  alc: 'Kaw\u00e9sqar',
  ald: 'Alladian',
  ale: 'Aleut',
  alf: 'Alege',
  alh: 'Alawa',
  ali: 'Amaimon',
  alj: 'Alangan',
  alk: 'Alak',
  all: 'Allar',
  alm: 'Amblong',
  alo: 'Larike-Wakasihu',
  alp: 'Alune',
  alq: 'Algonquin',
  alr: 'Alutor',
  alt: 'Southern Altai',
  alu: "'Are'are",
  alw: 'Alaba',
  alx: 'Amol',
  aly: 'Alyawarr',
  alz: 'Alur',
  ama: 'Amanay\u00e9',
  amb: 'Ambo',
  amc: 'Amahuaca',
  ame: "Yanesha'",
  amf: 'Hamer-Banna',
  amg: 'Amurdag',
  ami: 'Amis',
  amj: 'Amdang',
  amk: 'Ambai',
  aml: 'War-Jaintia',
  amm: 'Ama',
  amn: 'Amanab',
  amo: 'Amo',
  amp: 'Alamblak',
  amq: 'Amahai',
  amr: 'Amarakaeri',
  ams: 'Southern Amami-Oshima',
  amt: 'Amto',
  amu: 'Guerrero Amuzgo',
  amv: 'Ambelau',
  amw: 'Western Neo-Aramaic',
  amx: 'Anmatyerre',
  amy: 'Ami',
  amz: 'Atampaya',
  ana: 'Andaqui',
  anb: 'Andoa',
  kzr: 'Karang',
  and: 'Ansus',
  ane: 'X\u00e2r\u00e2c\u00f9\u00f9',
  anf: 'Animere',
  ang: 'Old English',
  anh: 'Nend',
  ani: 'Andi',
  anj: 'Anor',
  ank: 'Goemai',
  anl: 'Anu',
  anm: 'Anal',
  ann: 'Obolo',
  ano: 'Andoque',
  anp: 'Angika',
  anq: 'Jarawa',
  anr: 'Andh',
  ans: 'Anserma',
  ant: 'Antakarinya',
  anu: 'Anuak',
  anv: 'Denya',
  anw: 'Anaang',
  anx: 'Andra-Hus',
  any: 'Anyi',
  anz: 'Anem',
  aoa: 'Angolar',
  aob: 'Abom',
  aoc: 'Pemon',
  aod: 'Andarum',
  aoe: 'Angal Enen',
  aof: 'Bragat',
  aog: 'Angoram',
  aoh: 'Arma',
  aoi: 'Anindilyakwa',
  aoj: 'Mufian',
  aok: 'Arh\u00f6',
  aol: 'Alor',
  aom: '\u00d6mie',
  aon: 'Bumbita Arapesh',
  aor: 'Aore',
  aos: 'Taikat',
  aot: 'Atong (India)',
  aou: "A'ou",
  aox: 'Atorada',
  aoz: 'Uab Meto',
  apb: "Sa'a",
  apc: 'North Levantine Arabic',
  apd: 'Sudanese Arabic',
  ape: 'Bukiyip',
  apf: 'Pahanan Agta',
  apg: 'Ampanang',
  aph: 'Athpare',
  api: 'Apiak\u00e1',
  apj: 'Jicarilla',
  apk: 'Plains Apache',
  apl: 'Lipan',
  apm: 'Chiricahua',
  apn: 'Apinay\u00e9',
  apo: 'Ambul',
  app: 'Apma',
  apq: 'A-Pucikwar',
  apr: 'Arop-Lokep',
  aps: 'Arop-Sissano',
  apt: 'Apatani',
  apu: 'Apurin\u00e3',
  apv: 'Alapmunte',
  apw: 'Western Apache',
  apx: 'Aputai',
  apy: 'Apala\u00ed',
  apz: 'Safeyoka',
  aqc: 'Archi',
  aqd: 'Ampari Dogon',
  aqg: 'Arigidi',
  aqm: 'Atohwaim',
  aqn: 'Northern Alta',
  aqp: 'Atakapa',
  aqr: 'Arh\u00e2',
  aqt: 'Angait\u00e9',
  aqz: 'Akuntsu',
  ard: 'Arabana',
  are: 'Western Arrernte',
  ikk: 'Ika',
  ari: 'Arikara',
  arj: 'Arapaso',
  ark: 'Arikap\u00fa',
  arl: 'Arabela',
  arn: 'Mapudungun',
  aro: 'Araona',
  arp: 'Arapaho',
  arq: 'Algerian Arabic',
  kxh: 'Karo',
  ars: 'Najdi Arabic',
  arx: 'Aru\u00e1',
  arv: 'Arbore',
  ary: 'Moroccan Arabic',
  arz: 'Egyptian Arabic',
  aum: 'Asu',
  asb: 'Assiniboine',
  asc: 'Casuarina Coast Asmat',
  asd: 'Asas',
  ase: 'American Sign Language',
  asf: 'Auslan',
  asg: 'Cishingini',
  ash: 'Abishira',
  asi: 'Buruwai',
  asj: 'Nsari',
  ask: 'Ashkun',
  asl: 'Asilulu',
  asn: 'Xing\u00fa Asurin\u00ed',
  aso: 'Dano',
  asp: 'Algerian Sign Language',
  asq: 'Austrian Sign Language',
  asr: 'Asuri',
  ass: 'Ipulo',
  ast: 'Asturian',
  asu: 'Tocantins Asurini',
  asv: 'Asoa',
  asw: 'Australian Aboriginal Sign Language',
  asx: 'Muratayak',
  asy: 'Yaosakor Asmat',
  asz: 'As',
  atm: 'Ata',
  atb: 'Zaiwa',
  atc: 'Atsahuaca',
  atd: 'Ata Manobo',
  ate: 'Atemble',
  atg: 'Okpela',
  ati: 'Atti\u00e9',
  atj: 'Atikamekw',
  atk: 'Ati',
  atl: 'Mt. Iraya Agta',
  ato: 'Atong (Cameroon)',
  atp: 'Pudtol Atta',
  atq: 'Aralle-Tabulahan',
  atr: 'Waimiri-Atroari',
  ats: 'Gros Ventre',
  att: 'Pamplona Atta',
  atu: 'Reel',
  atv: 'Northern Altai',
  atw: 'Atsugewi',
  atx: 'Arutani',
  aty: 'Aneityum',
  atz: 'Arta',
  aua: 'Asumboa',
  aub: 'Alugu',
  avs: 'Aushiri',
  aud: 'Anuta',
  aug: 'Aguna',
  auh: 'Aushi',
  aui: 'Anuki',
  auj: 'Augila',
  auk: 'Heyo',
  aul: 'Aulua',
  aun: 'Molmo One',
  auo: 'Auyokawa',
  aup: 'Makayam',
  auq: 'Anus',
  aur: 'Aruek',
  aut: 'Austral',
  auu: 'Auye',
  auw: 'Awyi',
  aux: 'Aur\u00e1',
  auy: 'Auyana',
  auz: 'Uzbeki Arabic',
  avb: 'Avau',
  avd: 'Alviri-Vidari',
  avi: 'Avikam',
  avk: 'Kotava',
  avl: 'Eastern Egyptian Bedawi Arabic',
  avm: 'Angkamuthi',
  avn: 'Avatime',
  avo: 'Agavotaguerra',
  avt: 'Au',
  avu: 'Avokaya',
  avv: 'Av\u00e1-Canoeiro',
  awa: 'Awadhi',
  awb: 'Awa (New Guinea)',
  awc: 'Cicipu',
  awe: 'Awet\u00ed',
  awg: 'Anguthimri',
  awh: 'Awbono',
  awi: 'Aekyom',
  awk: 'Awabakal',
  awm: 'Arawum',
  awn: 'Awngi',
  awo: 'Awak',
  awr: 'Awera',
  aws: 'South Awyu',
  awt: 'Arawet\u00e9',
  awu: 'Central Awyu',
  awv: 'Jair Awyu',
  aww: 'Awun',
  awx: 'Awara',
  awy: 'Edera Awyu',
  axb: 'Abipon',
  axe: 'Ayerrerenge',
  axg: 'Mato Grosso Ar\u00e1ra',
  yaf: 'Yaka',
  axl: 'Lower Southern Aranda',
  axm: 'Middle Armenian',
  axx: 'Xaragure',
  aya: 'Awar',
  ayb: 'Ayizo',
  ayd: 'Ayabadhu',
  aye: 'Ayere',
  ayg: 'Nyanga (Togo)',
  ayh: 'Hadrami Arabic',
  ayi: 'Leyigha',
  ayk: 'Akuku',
  ayl: 'Libyan Arabic',
  ayn: 'Sanaani Arabic',
  mor: 'Moro',
  ayp: 'North Mesopotamian Arabic',
  ayq: 'Ayi',
  ays: 'Sorsogon Ayta',
  ayt: 'Bataan Ayta',
  ayu: 'Ayu',
  ayy: 'Tayabas Ayta',
  ayz: 'Maybrat',
  aza: 'Azha',
  azd: 'Eastern Durango Nahuatl',
  azg: 'San Pedro Amuzgos Amuzgo',
  azm: 'Ipalapa Amuzgo',
  azn: 'Western Durango Nahuatl',
  azo: 'Awing',
  azt: 'Faire Atta',
  azz: 'Highland Puebla Nahuatl',
  baa: 'Babatana',
  bab: 'Bainouk-Gunyu\u00f1o',
  bac: 'Badui',
  bae: 'Bar\u00e9',
  baf: 'Nubaca',
  bag: 'Tuki',
  bah: 'Bahamian Creole',
  baj: 'Barakai',
  bal: 'Baluchi',
  ban: 'Balinese',
  bao: 'Waimaha',
  bap: 'Bantawa',
  bar: 'Bavarian',
  bzw: 'Basa',
  bhz: 'Bada',
  bav: 'Babungo',
  baw: 'Bambili-Bambui',
  bax: 'Bamum',
  bay: 'Batuley',
  bba: 'Baatonum',
  bbb: 'Barai',
  bbc: 'Toba Batak',
  bbd: 'Bau',
  bbe: 'Bangba',
  bbf: 'Baibai',
  bbg: 'Barama',
  bbh: 'Bugan',
  lmi: 'Lombi',
  bbj: "Ghomala'",
  bbk: 'Babanki',
  bbl: 'Bats',
  bbm: 'Babango',
  bbn: 'Uneapa',
  bbo: 'Konab\u00e9r\u00e9',
  bbp: 'West Central Banda',
  bbq: 'Bamali',
  bbr: 'Girawa',
  bbs: 'Bakpinka',
  bbt: 'Mburku',
  kle: 'Kulung',
  bbv: 'Karnai',
  bbw: 'Baba',
  bbx: 'Bubia',
  bby: 'Befang',
  bbz: 'Babalia Creole Arabic',
  bca: 'Central Bai',
  bcb: 'Bainouk-Samik',
  bcd: 'North Babar',
  bce: 'Bamenyam',
  bcf: 'Bamu',
  bcg: 'Baga Pokur',
  bch: 'Bariai',
  bci: 'Baoule',
  bcj: 'Bardi',
  bck: 'Bunaba',
  bcl: 'Bikol Central',
  bcm: 'Banoni',
  bcp: 'Bali',
  bco: 'Kaluli',
  bcq: 'Bench',
  bcr: "Babine-Witsuwit'en",
  bcs: 'Kohumono',
  bct: 'Bendi',
  bcu: 'Biliau',
  bcv: 'Shoo-Minda-Nye',
  bcw: 'Bana',
  bcy: 'Bacama',
  bcz: 'Bainouk-Gunyaamolo',
  bda: 'Bayot',
  bdb: 'Basap',
  bdc: 'Ember\u00e1-Baud\u00f3',
  bdd: 'Bunama',
  bde: 'Bade',
  bdf: 'Biage',
  bdg: 'Bonggi',
  bkc: 'Baka',
  bdi: 'Burun',
  bzr: 'Biri',
  bdk: 'Budukh',
  bdl: 'Indonesian Bajau',
  nbn: 'Kuri',
  bdn: 'Baldemu',
  bdo: 'Morom',
  bdp: 'Bende',
  bdq: 'Bahnar',
  bdr: 'West Coast Bajau',
  bds: 'Burunge',
  bdt: 'Bokoto',
  bhp: 'Bima',
  bdv: 'Bodo Parja',
  bdw: 'Baham',
  bdx: 'Budong-Budong',
  bdy: 'Bandjalang',
  bdz: 'Badeshi',
  bea: 'Beaver',
  beb: 'Bebele',
  bec: 'Iceve-Maci',
  bed: 'Bedoanas',
  bee: 'Byangsi',
  bef: 'Benabena',
  beg: 'Belait',
  beh: 'Biali',
  bei: "Bekati'",
  bej: 'Beja',
  bek: 'Bebeli',
  bem: 'Bemba',
  beo: 'Beami',
  bep: 'Besoa',
  bmb: 'Bembe',
  bes: 'Besme',
  bet: 'Guiberoua B\u00e9t\u00e9',
  beu: 'Blagar',
  bev: 'Daloa B\u00e9t\u00e9',
  bew: 'Betawi',
  bex: 'Jur Modo',
  blm: 'Beli',
  yun: 'Bena',
  grg: 'Madi',
  bfb: 'Pauri Bareli',
  bfc: 'Northern Bai',
  bfd: 'Bafut',
  bfe: 'Betaf',
  bff: 'Bofi',
  bfg: 'Busang Kayan',
  bfh: 'Blafe',
  bfi: 'British Sign Language',
  bfj: 'Bafanji',
  bfk: 'Ban Khor Sign Language',
  bfl: 'Banda-Nd\u00e9l\u00e9',
  bfm: 'Mmen',
  bfn: 'Bunak',
  bfo: 'Malba Birifor',
  bfp: 'Beba',
  bfq: 'Badaga',
  bfr: 'Bazigar',
  bfs: 'Southern Bai',
  bft: 'Balti',
  bfu: 'Gahri',
  rem: 'Remo',
  bfx: 'Bantayanon',
  bfy: 'Bagheli',
  bfz: 'Mahasu Pahari',
  bga: 'Gwamhi-Wuri',
  bgb: 'Bobongko',
  bgc: 'Haryanvi',
  bgd: 'Rathwi Bareli',
  bge: 'Bauria',
  bgf: 'Bangandu',
  bgg: 'Bugun',
  bgi: 'Giangan',
  bgj: 'Bangolan',
  bgk: 'Bit',
  bgo: 'Baga Koga',
  bgq: 'Bagri',
  bgr: 'Bawm Chin',
  bgs: 'Tagabawa',
  bgt: 'Bughotu',
  bgu: 'Mbongno',
  bgv: 'Warkay-Bipim',
  bgw: 'Bhatri',
  bgx: 'Balkan Gagauz Turkish',
  bgy: 'Benggoi',
  bgz: 'Banggai',
  bha: 'Bharia',
  bhb: 'Bhili',
  bhc: 'Biga',
  bhd: 'Bhadrawahi',
  bhe: 'Bhaya',
  bhf: 'Odiai',
  bhg: 'Binandere',
  bhh: 'Bukhari',
  bhi: 'Bhilali',
  bhj: 'Bahing',
  bhl: 'Bimin',
  bhm: 'Bathari',
  bhn: 'Bohtan Neo-Aramaic',
  bho: 'Bhojpuri',
  bhq: 'Tukang Besi South',
  bhs: 'Buwal',
  bht: 'Bhattiyali',
  bhu: 'Bhunjia',
  bhv: 'Bahau',
  bhw: 'Biak',
  bhx: 'Bhalay',
  bhy: 'Bhele',
  bia: 'Badimaya',
  bib: 'Bissa',
  bic: 'Bikaru',
  bid: 'Bidiyo',
  bie: 'Bepour',
  bif: 'Biafada',
  big: 'Biangai',
  kvi: 'Kwang',
  bil: 'Bile',
  bim: 'Bimoba',
  bin: 'Edo',
  bio: 'Nai',
  bip: 'Bila',
  biq: 'Bipi',
  bir: 'Bisorio',
  bit: 'Berinomo',
  biu: 'Biete',
  biv: 'Southern Birifor',
  biw: 'Kol (Cameroon)',
  bix: 'Bijori',
  biy: 'Birhor',
  biz: 'Baloi',
  bja: 'Budza',
  bxg: 'Bangala',
  bjc: 'Bariji',
  bje: 'Biao-Jiao Mien',
  bjf: 'Barzani Jewish Neo-Aramaic',
  bjg: 'Bidyogo',
  bjh: 'Bahinemo',
  bji: 'Burji',
  bjj: 'Kannauji',
  bjk: 'Barok',
  bjl: 'Bulu (New Guinea)',
  bjm: 'Bajelani',
  bjn: 'Banjarese',
  bjo: 'Mid-Southern Banda',
  bjp: 'Fanamaket',
  bjr: 'Binumarien',
  bjs: 'Bajan',
  bjt: 'Balanta-Ganja',
  bju: 'Busuu',
  bjv: 'Bedjond',
  bjw: 'Bakw\u00e9',
  bjx: 'Banao Itneg',
  bjy: 'Bayali',
  bjz: 'Baruga',
  bka: 'Kyak',
  bkd: 'Binukid',
  bkf: 'Beeke',
  bkg: 'Buraka',
  bkh: 'Bakoko',
  bki: 'Baki',
  bkj: 'Pande',
  bkk: 'Brokskat',
  bkl: 'Berik',
  bkm: 'Kom (Cameroon)',
  bkn: 'Bukitan',
  bko: "Kwa'",
  bqc: 'Boko',
  bkq: 'Bakair\u00ed',
  bkr: 'Bakumpai',
  bks: 'Masbate Sorsogon',
  bkt: 'Boloki',
  bku: 'Buhid',
  bkv: 'Bekwarra',
  bkw: 'Bekwel',
  bkx: 'Baikeno',
  bky: 'Bokyi',
  bkz: 'Bungku',
  bla: 'Blackfoot',
  blb: 'Bilua',
  blc: 'Bella Coola',
  bld: 'Bolango',
  ble: 'Balanta-Kentohe',
  blf: 'Buol',
  blg: 'Balau',
  blh: 'Kuwaa',
  bli: 'Bolia',
  blj: 'Bolongan',
  blk: "Pa'o Karen",
  bll: 'Biloxi',
  bln: 'Southern Catanduanes Bicolano',
  blo: 'Anii',
  blp: 'Blablanga',
  blq: 'Baluan-Pam',
  blr: 'Blang',
  bls: 'Balaesang',
  blt: 'Tai Dam',
  blv: 'Bolo',
  blw: 'Balangao',
  blx: 'Mag-Indi Ayta',
  bly: 'Notre',
  blz: 'Balantak',
  bma: 'Lame',
  bmc: 'Biem',
  bmd: 'Baga Manduri',
  bme: 'Limassa',
  bmf: 'Bom',
  bmg: 'Bamwe',
  bmh: 'Kein',
  bmi: 'Bagirmi',
  bmj: 'Bote-Majhi',
  bmk: 'Ghayavi',
  bml: 'Bomboli',
  bmn: 'Bina',
  bmo: 'Bambalang',
  bmp: 'Bulgebi',
  bmq: 'Bomu',
  bmr: 'Muinane',
  bmt: 'Biao Mon',
  bmu: 'Somba-Siawari',
  bmv: 'Bum',
  bmw: 'Bomwali',
  bmx: 'Baimak',
  bmz: 'Baramu',
  bna: 'Bonerate',
  bnb: 'Bookan',
  bne: 'Bintauna',
  bnf: 'Masiwang',
  bng: 'Benga',
  bni: 'Bangi',
  bnj: 'Eastern Tawbuid',
  bnk: 'Bierebo',
  bnl: 'Boon',
  bnm: 'Batanga',
  bnn: 'Bunun',
  bno: 'Asi',
  bnp: 'Bola',
  bnq: 'Bantik',
  bnr: 'Butmas-Tur',
  bns: 'Bundeli',
  bnu: 'Bentong',
  bnv: 'Beneraf',
  bnw: 'Bisis',
  bnx: 'Bangubangu',
  bny: 'Bintulu',
  bnz: 'Beezen',
  boa: 'Bora',
  bob: 'Aweer',
  boe: 'Mundabli',
  bof: 'Bolon',
  bog: 'Bamako Sign Language',
  boh: 'Boma',
  boi: 'Barbare\u00f1o',
  boj: 'Anjam',
  bok: 'Bonjo',
  bol: 'Bole',
  bom: 'Berom',
  bon: 'Bine',
  boo: 'Tiemac\u00e8w\u00e8 Bozo',
  bop: 'Bonkiman',
  boq: 'Bogaya',
  bor: 'Bor\u00f4ro',
  bot: 'Bongo',
  bou: 'Bondei',
  bov: 'Tuwuli',
  bow: 'Rema',
  box: 'Buamu',
  boy: 'Bodo (Central Africa)',
  boz: 'Ti\u00e9yaxo Bozo',
  bpa: 'Dakaka',
  bpb: 'Barbacoas',
  bpd: 'Banda-Banda',
  bpg: 'Bonggo',
  bph: 'Botlikh',
  bpi: 'Bagupi',
  bpj: 'Binji',
  bpk: 'Orowe',
  bpl: 'Broome Pearling Lugger Pidgin',
  bpm: 'Biyom',
  bpn: 'Dzao Min',
  bpo: 'Anasi',
  bpp: 'Kaure',
  bpq: 'Banda Malay',
  bpr: 'Koronadal Blaan',
  bps: 'Sarangani Blaan',
  bpt: 'Barrow Point',
  bpu: 'Bongu',
  bpv: 'Bian Marind',
  bpx: 'Palya Bareli',
  bpy: 'Bishnupriya Manipuri',
  bpz: 'Bilba',
  bqa: 'Tchumbuli',
  bqb: 'Bagusa',
  bqd: 'Bung',
  bqf: 'Baga Kaloum',
  bqg: 'Bago-Kusuntu',
  bqh: 'Baima',
  bqi: 'Bakhtiari',
  bqj: 'Bandial',
  bqk: 'Banda-Mbr\u00e8s',
  bql: 'Bilakura',
  bqm: 'Wumboko',
  bqn: 'Bulgarian Sign Language',
  bqo: 'Balo',
  bqp: 'Busa',
  bqq: 'Biritai',
  bqr: 'Burusu',
  bqs: 'Bosngun',
  bqt: 'Bamukumbit',
  bqu: 'Boguru',
  bqv: 'Begbere-Ejar',
  bqw: 'Buru (Nigeria)',
  bqx: 'Baangi',
  bqy: 'Bengkala Sign Language',
  bqz: 'Bakaka',
  bra: 'Braj',
  brb: 'Lave',
  brc: 'Berbice Creole Dutch',
  brd: 'Baraamu',
  brf: 'Bera',
  brg: 'Baure',
  brh: 'Brahui',
  bri: 'Mokpwe',
  brj: 'Bieria',
  brk: 'Birgid',
  brl: 'Birwa',
  brm: 'Barambu',
  brn: 'Boruca',
  bro: 'Brokkat',
  brp: 'Barapasi',
  brq: 'Breri',
  brr: 'Birao',
  end: 'Ende',
  brt: 'Bitare',
  bru: 'Eastern Bru',
  brv: 'Western Bru',
  brw: 'Bellari',
  brx: 'Bodo (India)',
  bry: 'Burui',
  brz: 'Bilbil',
  bsa: 'Abinomn',
  bsb: 'Brunei Bisaya',
  bsc: 'Bassari',
  bse: 'Wushi',
  bsf: 'Bauchi',
  bsg: 'Bashkardi',
  bsh: 'Kamkata-viri',
  bsi: 'Bassossi',
  bsj: 'Bangwinji',
  bsk: 'Burushaski',
  bsl: 'Basa-Gumna',
  bsm: 'Busami',
  bsn: 'Barasana',
  bso: 'Buso',
  bsp: 'Baga Sitemu',
  bsq: 'Bassa',
  bsr: 'Bassa-Kontagora',
  bss: 'Akoose',
  bst: 'Basketo',
  bsu: 'Bahonsuai',
  bsv: 'Baga Soban\u00e9',
  bsw: 'Baiso',
  bsx: 'Yangkam',
  bsy: 'Sabah Bisaya',
  bta: 'Bata',
  btc: 'Bati (Cameroon)',
  btd: 'Dairi Batak',
  bte: 'Gamo-Ningi',
  btf: 'Birgit',
  btg: 'Gagnoa B\u00e9t\u00e9',
  bth: 'Biatah Bidayuh',
  bti: 'Burate',
  btj: 'Bacanese Malay',
  btm: 'Mandailing Batak',
  btn: 'Ratagnon',
  bto: 'Iriga Bicolano',
  btp: 'Budibud',
  btq: 'Batek',
  btr: 'Baetora',
  bts: 'Simalungun Batak',
  btt: 'Bete-Bendi',
  btu: 'Batu',
  btv: 'Bateri',
  btw: 'Butuanon',
  btx: 'Karo Batak',
  bty: 'Bobot',
  btz: 'Alas-Kluet Batak',
  bua: 'Buryat',
  bub: 'Bua',
  bud: 'Ntcham',
  bue: 'Beothuk',
  buf: 'Bushoong',
  bug: 'Buginese',
  buh: 'Younuo Bunu',
  bui: 'Bongili',
  buj: 'Basa-Gurmana',
  buk: 'Bukawa',
  bum: 'Bulu (Cameroon)',
  bun: 'Sherbro',
  buo: 'Terei',
  bup: 'Busoa',
  buq: 'Brem',
  bus: 'Bokobaru',
  but: 'Bungain',
  buu: 'Budu',
  buv: 'Bun',
  buw: 'Bubi',
  bux: 'Boghom',
  buy: 'Mmani',
  bva: 'Barein',
  bvb: 'Bube',
  bvc: 'Baelelea',
  bvd: 'Baeggu',
  bve: 'Berau Malay',
  bvf: 'Boor',
  bvg: 'Bonkeng',
  bvh: 'Bure',
  bvi: 'Belanda Viri',
  bvj: 'Baan',
  bvk: 'Bukat',
  bvl: 'Bolivian Sign Language',
  bvm: 'Bamunka',
  bvn: 'Buna',
  bvo: 'Bolgo',
  bvp: 'Bumang',
  bvq: 'Birri',
  bvr: 'Burarra',
  bvt: 'Bati (Indonesia)',
  bvu: 'Bukit Malay',
  bvv: 'Baniva',
  bvw: 'Boga',
  bvx: 'Babole',
  bvy: 'Baybayanon',
  bvz: 'Bauzi',
  bwa: 'Bwatoo',
  bwb: 'Namosi-Naitasiri-Serua',
  bwc: 'Bwile',
  bwd: 'Bwaidoka',
  bwe: 'Bwe Karen',
  bwf: 'Boselewa',
  bwg: 'Barwe',
  bwh: 'Bishuo',
  bwi: 'Baniwa',
  bwj: 'L\u00e1\u00e1 L\u00e1\u00e1 Bwamu',
  bwk: 'Bauwaki',
  bwl: 'Bwela',
  bwm: 'Biwat',
  bwn: 'Wunai Bunu',
  xxb: 'Boro',
  bwp: 'Mandobo Bawah',
  bwq: 'Southern Bobo',
  bwr: 'Bura',
  bws: 'Bomboma',
  bwt: 'Bafaw-Balong',
  bwu: 'Buli (Ghana)',
  bww: 'Bwa',
  bwx: 'Bu-Nao Bunu',
  bwy: 'Cwi Bwamu',
  bwz: 'Bwisi',
  bxa: 'Bauro',
  bxb: 'Belanda Bor',
  bxc: 'Molengue',
  bxd: 'Pela',
  bxe: 'Ongota',
  bxf: 'Bilur',
  bxh: 'Buhutu',
  bxi: 'Pirlatapa',
  bxj: 'Bayungu',
  bxk: 'Bukusu',
  bxl: 'Jalkunan',
  bxn: 'Burduna',
  bxo: 'Barikanchi',
  bxp: 'Bebil',
  bxq: 'Beele',
  bxs: 'Busam',
  bxv: 'Berakou',
  bxw: 'Banka',
  bxz: 'Binahari',
  bya: 'Palawan Batak',
  byb: 'Bikya',
  byc: 'Ubaghara',
  byd: "Benyadu'",
  bye: 'Pouye',
  byf: 'Bete',
  byg: 'Baygo',
  byh: 'Bujhyal',
  byi: 'Buyu',
  byj: 'Binawa',
  byk: 'Biao',
  byl: 'Bayono',
  bym: 'Bidyara',
  byn: 'Blin',
  byo: 'Biyo',
  byp: 'Bumaji',
  byq: 'Basay',
  byr: 'Baruya',
  bys: 'Burak',
  byt: 'Berti',
  byv: 'Medumba',
  byw: 'Belhariya',
  byx: 'Qaqet',
  byz: 'Banaro',
  bza: 'Bandi',
  bzb: 'Andio',
  bzd: 'Bribri',
  bze: 'Jenaama Bozo',
  bzf: 'Boikin',
  bzg: 'Babuza',
  bzh: 'Mapos Buang',
  bzi: 'Bisu',
  rop: 'Kriol',
  bzk: 'Nicaraguan Creole',
  bzl: 'Boano (Sulawesi)',
  bzm: 'Bolondo',
  bzn: 'Boano (Maluku)',
  bzo: 'Bozaba',
  bzp: 'Kemberano',
  bzq: 'Buli (Indonesia)',
  bzs: 'Brazilian Sign Language',
  bzu: 'Burmeso',
  bzv: 'Bebe',
  bzx: 'Hainyaxo Bozo',
  bzy: 'Obanliku',
  bzz: 'Evant',
  caa: "Ch'orti'",
  cab: 'Garifuna',
  cac: 'Chuj',
  cad: 'Caddo',
  cae: 'Laalaa',
  caf: 'Southern Carrier',
  cag: 'Nivacl\u00e9',
  cah: 'Cahuarano',
  caj: 'Chan\u00e9',
  cak: 'Kaqchikel',
  cal: 'Carolinian',
  cam: 'Cemuh\u00ee',
  can: 'Chambri',
  cao: 'Ch\u00e1cobo',
  cap: 'Chipaya',
  caq: 'Car Nicobarese',
  car: 'Galibi Carib',
  cas: 'Tsiman\u00e9',
  cav: 'Cavine\u00f1a',
  caw: 'Callawalla',
  cax: 'Chiquitano',
  cay: 'Cayuga',
  caz: 'Canichana',
  cbb: 'Cabiyar\u00ed',
  cbc: 'Carapana',
  omg: 'Omagua',
  cbg: 'Chimila',
  cbi: 'Chachi',
  cbj: 'Ede Cabe',
  cbk: 'Chavacano',
  cbl: 'Bualkhaw Chin',
  cbn: 'Nyah Kur',
  cbo: 'Izora',
  cbq: 'Shuba',
  cbr: 'Cashibo-Cacataibo',
  cbs: 'Cashinahua',
  cbt: 'Chayahuita',
  cbu: 'Candoshi-Shapra',
  cbv: 'Cacua',
  cbw: 'Kinabalian',
  'sai-yri': 'Yuri',
  xak: 'Maku',
  cca: 'Cauca',
  ccc: 'Chamicuro',
  ccd: 'Cafundo Creole',
  cce: 'Chopi',
  ccg: 'Chamba Daka',
  cch: 'Atsam',
  ccj: 'Kasanga',
  ccl: 'Cutchi-Swahili',
  ccm: 'Malaccan Creole Malay',
  cco: 'Comaltepec Chinantec',
  ccp: 'Chakma',
  ccr: 'Cacaopera',
  cda: 'Choni',
  cde: 'Chenchu',
  cdf: 'Chiru',
  cdg: 'Chamari',
  cdh: 'Chambeali',
  cdi: 'Chodri',
  cdj: 'Churahi',
  cdm: 'Chepang',
  cdn: 'Chaudangsi',
  cdo: 'Min Dong',
  cdr: 'Cinda-Regi-Tiyal',
  cds: 'Chadian Sign Language',
  cdy: 'Chadong',
  cdz: 'Koda',
  cea: 'Lower Chehalis',
  ceb: 'Cebuano',
  ceg: 'Chamacoco',
  cen: 'Cen',
  cet: 'Cent\u00fa\u00fam',
  cfa: 'Dijim-Bwilim',
  cfd: 'Cara',
  cfg: 'Como Karim',
  cfm: 'Falam Chin',
  cga: 'Changriwa',
  cgc: 'Kagayanen',
  cgg: 'Rukiga',
  cgk: 'Chocangacakha',
  chb: 'Chibcha',
  chc: 'Catawba',
  chd: 'Highland Oaxaca Chontal',
  chf: 'Tabasco Chontal',
  chg: 'Chagatai',
  chh: 'Chinook',
  chj: 'Ojitl\u00e1n Chinantec',
  chk: 'Chuukese',
  chl: 'Cahuilla',
  chm: 'Eastern Mari',
  chn: 'Chinook Jargon',
  cho: 'Choctaw',
  chp: 'Chipewyan',
  chq: 'Quiotepec Chinantec',
  chr: 'Cherokee',
  cht: 'Chol\u00f3n',
  chw: 'Chuwabu',
  chx: 'Chantyal',
  chy: 'Cheyenne',
  chz: 'Ozumac\u00edn Chinantec',
  cia: 'Cia-Cia',
  cib: 'Ci Gbe',
  cic: 'Chickasaw',
  cid: 'Chimariko',
  cie: 'Cineni',
  cih: 'Chinali',
  cik: 'Chitkuli Kinnauri',
  cim: 'Cimbrian',
  cin: 'Cinta Larga',
  cip: 'Chiapanec',
  cir: 'Tiri',
  ciy: 'Chaima',
  cja: 'Western Cham',
  cje: 'Chru',
  cjh: 'Upper Chehalis',
  cji: 'Chamalal',
  cjk: 'Chokwe',
  cjm: 'Eastern Cham',
  cjn: 'Chenapian',
  cjo: 'Ash\u00e9ninka Pajonal',
  cjp: 'Cab\u00e9car',
  cjs: 'Shor',
  cjv: 'Chuave',
  cjy: 'Jin',
  ckb: 'Central Kurdish',
  ckh: 'Chak',
  ckl: 'Cibak',
  ckn: 'Kaang Chin',
  cko: 'Anufo',
  ckq: 'Kajakse',
  ckr: 'Kairak',
  cks: 'Tayo',
  ckt: 'Chukchi',
  cku: 'Koasati',
  ckv: 'Kavalan',
  ckx: 'Caka',
  cky: 'Cakfem-Mushere',
  ckz: 'Cakchiquel-Quich\u00e9 Mixed Language',
  cla: 'Ron',
  clc: 'Chilcotin',
  cld: 'Chaldean Neo-Aramaic',
  cle: 'Lealao Chinantec',
  clh: 'Chilisso',
  cli: 'Chakali',
  clj: 'Laitu Chin',
  clk: 'Idu',
  cll: 'Chala',
  clm: 'Klallam',
  clo: 'Lowland Oaxaca Chontal',
  clt: 'Lautu Chin',
  clu: 'Caluyanun',
  clw: 'Chulym',
  cly: 'Eastern Highland Chatino',
  cma: 'Maa',
  cme: 'Cerma',
  cmg: 'Classical Mongolian',
  cmi: 'Ember\u00e1-Cham\u00ed',
  cml: 'Campalagian',
  cmm: 'Michigamea',
  cmn: 'Mandarin',
  cmo: 'Central Mnong',
  cmr: 'Mro Chin',
  cms: 'Messapic',
  cmt: 'Camtho',
  cna: 'Changthang',
  cnb: 'Chinbon Chin',
  cnc: 'C\u00f4\u00f4ng',
  cng: 'Northern Qiang',
  cnh: 'Lai',
  cni: 'Ash\u00e1ninka',
  cnk: 'Khumi Chin',
  cnl: 'Lalana Chinantec',
  cno: 'Con',
  cns: 'Central Asmat',
  cnt: 'Tepetotutla Chinantec',
  cnu: 'Chenoua',
  cnw: 'Ngawn Chin',
  cnx: 'Middle Cornish',
  coa: 'Cocos Islands Malay',
  cob: 'Chicomuceltec',
  coc: 'Cocopa',
  cod: 'Cocama',
  coe: 'Koreguaje',
  cof: 'Tsafiki',
  cog: 'Chong',
  coh: 'Chichonyi-Chidzihana-Chikauma',
  coj: 'Cochimi',
  cok: 'Santa Teresa Cora',
  col: 'Columbia-Wenatchi',
  com: 'Comanche',
  con: 'Cof\u00e1n',
  coo: 'Comox',
  cop: 'Coptic',
  coq: 'Coquille',
  cot: 'Caquinte',
  cou: 'Wamey',
  cov: 'Cao Miao',
  cow: 'Cowlitz',
  cox: 'Nanti',
  coy: 'Coyaima',
  coz: 'Chochotec',
  cpa: 'Palantla Chinantec',
  cpb: 'Ucayali-Yur\u00faa Ash\u00e9ninka',
  cpc: 'Ajy\u00edninka Apurucayali',
  cpg: 'Cappadocian Greek',
  cpi: 'Chinese Pidgin English',
  cpn: 'Cherepon',
  cpo: 'Kpee',
  cps: 'Capiznon',
  cpu: 'Pichis Ash\u00e9ninka',
  cpx: 'Puxian',
  cpy: 'South Ucayali Ash\u00e9ninka',
  cqd: 'Chuanqiandian Cluster Miao',
  cra: 'Chara',
  crb: 'Island Carib',
  crc: 'Lonwolwol',
  crd: "Coeur d'Alene",
  crf: 'Caramanta',
  crg: 'Michif',
  crh: 'Crimean Tatar',
  cri: 'S\u00e3otomense',
  crj: 'Southern East Cree',
  crk: 'Plains Cree',
  crl: 'Northern East Cree',
  crm: 'Moose Cree',
  crn: 'Cora',
  cro: 'Crow',
  crq: "Iyo'wujwa Chorote",
  crr: 'Carolina Algonquian',
  crs: 'Seychellois Creole',
  crt: "Iyojwa'ja Chorote",
  crv: 'Chaura',
  crw: 'Chrau',
  crx: 'Carrier',
  cry: 'Cori',
  crz: 'Cruze\u00f1o',
  csa: 'Chiltepec Chinantec',
  csb: 'Kashubian',
  csc: 'Catalan Sign Language',
  csd: 'Chiangmai Sign Language',
  cse: 'Czech Sign Language',
  csf: 'Cuban Sign Language',
  csg: 'Chilean Sign Language',
  csh: 'Asho Chin',
  csi: 'Coast Miwok',
  csj: 'Songlai Chin',
  csk: 'Jola-Kasa',
  csl: 'Chinese Sign Language',
  csm: 'Central Sierra Miwok',
  csn: 'Colombian Sign Language',
  cso: 'Sochiapam Chinantec',
  csq: 'Croatian Sign Language',
  csr: 'Costa Rican Sign Language',
  css: 'Southern Ohlone',
  cst: 'Northern Ohlone',
  csv: 'Sumtu Chin',
  csw: 'Swampy Cree',
  csy: 'Siyin Chin',
  csz: 'Coos',
  cta: 'Tataltepec Chatino',
  tuu: 'Tututni',
  ctd: 'Tedim Chin',
  cte: 'Tepinapa Chinantec',
  ctg: 'Chittagonian',
  cth: 'Thaiphum Chin',
  ctl: 'Tlacoatzintepec Chinantec',
  ctm: 'Chitimacha',
  ctn: 'Chhintange',
  cto: 'Ember\u00e1-Cat\u00edo',
  ctp: 'Western Highland Chatino',
  cts: 'Northern Catanduanes Bicolano',
  ctt: 'Wayanad Chetti',
  ctu: 'Chol',
  ctz: 'Zacatepec Chatino',
  cua: 'Cua',
  cub: 'Cubeo',
  cuc: 'Usila Chinantec',
  mdu: 'Mboko',
  cuh: 'Chuka',
  cui: 'Cuiba',
  cuj: 'Mashco Piro',
  cuk: 'Kuna',
  xpk: 'Kulina',
  cuo: 'Cumanagoto',
  cup: 'Cupe\u00f1o',
  cuq: 'Cun',
  cur: 'Chhulung',
  cut: 'Teutila Cuicatec',
  cuu: 'Tai Ya',
  cuv: 'Cuvok',
  cuw: 'Chukwa',
  cux: 'Tepeuxila Cuicatec',
  cuy: 'Cuitlatec',
  cvg: 'Chug',
  cvn: 'Valle Nacional Chinantec',
  cwa: 'Kabwa',
  cwb: 'Maindo',
  cwd: 'Woods Cree',
  cwe: 'Kwere',
  cwg: 'Chewong',
  cwt: 'Kuwaataay',
  cya: 'Nopala Chatino',
  cyb: 'Cayubaba',
  cyo: 'Cuyunon',
  czh: 'Huizhou',
  czk: 'Knaanic',
  czn: 'Zenzontepec Chatino',
  czo: 'Min Zhong',
  czt: 'Zotung Chin',
  daa: 'Dangal\u00e9at',
  dac: 'Dambi',
  dad: 'Marik',
  dae: 'Duupa',
  dag: 'Dagbani',
  dah: 'Gwahatike',
  dai: 'Day',
  daj: 'Dar Fur Daju',
  dak: 'Dakota',
  dal: 'Dahalo',
  dam: 'Damakawa',
  dao: 'Daai Chin',
  mds: 'Maria',
  das: 'Daho-Doo',
  dau: 'Dar Sila Daju',
  dav: 'Taita',
  daw: 'Davawenyo',
  dax: 'Dayi',
  daz: 'Dao',
  dba: 'Bangi Me',
  dbb: 'Deno',
  dbd: 'Dadiya',
  dbe: 'Dabe',
  dbf: 'Edopi',
  dbg: 'Dogul Dom',
  dbi: 'Doka',
  dbj: "Ida'an",
  dbl: 'Dyirbal',
  dbm: 'Duguri',
  dbn: 'Duriankere',
  dbo: 'Dulbu',
  dbp: 'Duwai',
  dbq: 'Daba',
  dbr: 'Dabarre',
  dbt: 'Ben Tey',
  dbu: 'Bondum Dom Dogon',
  dbv: 'Dungu',
  dbw: 'Bankan Tey Dogon',
  dby: 'Dibiyaso',
  dcc: 'Deccani',
  dcr: 'Negerhollands',
  dda: 'Dadi Dadi',
  ddd: 'Dongotono',
  dde: 'Doondo',
  ddg: 'Fataluku',
  ddi: 'Diodio',
  ddj: 'Jaru',
  ddn: 'Dendi',
  ddo: 'Tsez',
  ddr: 'Dhudhuroa',
  dds: 'Donno So Dogon',
  ddw: 'Dawera-Daweloor',
  dec: 'Dagik',
  ded: 'Dedua',
  dee: 'Dewoin',
  def: 'Dezfuli',
  deg: 'Degema',
  deh: 'Dehwari',
  dei: 'Demisa',
  dek: 'Dek',
  dem: 'Dem',
  den: 'Slavey',
  dep: 'Pidgin Delaware',
  der: 'Deori',
  des: 'Desano',
  dev: 'Domung',
  dez: 'Dengese',
  dga: 'Southern Dagaare',
  dgb: 'Bunoge',
  dgc: 'Casiguran Dumagat Agta',
  dgd: 'Dagaari Dioula',
  dge: 'Degenan',
  dgg: 'Doga',
  dgh: 'Dghwede',
  dgi: 'Northern Dagara',
  dgk: 'Dagba',
  dgn: 'Dagoman',
  dgo: 'Hindi Dogri',
  dgr: 'Dogrib',
  dgs: 'Dogoso',
  dgt: "Ntra'ngith",
  dgu: 'Degaru',
  dgw: 'Daungwurrung',
  dgx: 'Doghoro',
  dgz: 'Daga',
  dhg: 'Dhangu',
  dhi: 'Dhimal',
  dhl: 'Dhalandji',
  sbw: 'Simba',
  dhn: 'Dhanki',
  dho: 'Dhodia',
  dhr: 'Tharrgari',
  dhs: 'Dhaiso',
  dhu: 'Dhurga',
  dhv: 'Drehu',
  dhw: 'Danuwar',
  dhx: 'Dhungaloo',
  dia: 'Dia',
  dib: 'South Central Dinka',
  dic: 'Lakota Dida',
  did: 'Didinga',
  dif: 'Dieri',
  dig: 'Digo',
  dii: 'Dimbong',
  dij: 'Dai',
  dik: 'Southwestern Dinka',
  dil: 'Dilling',
  dim: 'Dime',
  din: 'Dinka',
  dio: 'Dibo',
  dip: 'Northeastern Dinka',
  dir: 'Dirim',
  dis: 'Dimasa',
  diu: 'Gciriku',
  diw: 'Northwestern Dinka',
  dix: 'Dixon Reef',
  diy: 'Diuwe',
  diz: 'Ding',
  dja: 'Djadjawurrung',
  djb: 'Djinba',
  djc: 'Dar Daju Daju',
  djd: 'Jaminjung',
  dje: 'Zarma',
  djf: 'Djangun',
  dji: 'Djinang',
  djj: 'Ndj\u00e9bbana',
  djk: 'Aukan',
  djl: 'Djiwarli',
  djm: 'Jamsay',
  djn: 'Djauan',
  djo: 'Jangkang',
  djr: 'Djambarrpuyngu',
  dju: 'Kapriman',
  djw: 'Djawi',
  twm: 'Tawang Monpa',
  dkk: 'Dakka',
  dkr: 'Kuijau',
  dks: 'Southeastern Dinka',
  dkx: 'Mazagway',
  dlg: 'Dolgan',
  dlk: 'Dahalik',
  dlm: 'Dalmatian',
  dln: 'Darlong',
  dma: 'Duma',
  dmb: 'Mombo Dogon',
  dmc: 'Gavak',
  dmd: 'Madhi Madhi',
  dme: 'Dugwor',
  dmg: 'Upper Kinabatangan',
  dmk: 'Domaaki',
  dml: 'Dameli',
  dmm: 'Dama',
  dmo: 'Kemezung',
  dmr: 'East Damar',
  dms: 'Dampelas',
  dmu: 'Dubu',
  dmv: 'Dumpas',
  dmw: 'Mudburra',
  dmx: 'Dema',
  dmy: 'Demta',
  dna: 'Upper Grand Valley Dani',
  dnd: 'Daonda',
  dne: 'Ndendeule',
  dng: 'Dungan',
  dni: 'Lower Grand Valley Dani',
  dnj: 'Dan',
  dnk: 'Dengka',
  dnn: 'Dzuun',
  dnr: 'Danaru',
  dnt: 'Mid Grand Valley Dani',
  dnu: 'Danau',
  dnv: 'Danu',
  dnw: 'Western Dani',
  dny: 'Den\u00ed',
  doa: 'Dom',
  dob: 'Dobu',
  doc: 'Northern Kam',
  doe: 'Doe',
  dof: 'Domu',
  doh: 'Dong',
  doi: 'Dogri',
  dok: 'Dondo',
  dol: 'Doso',
  don: 'Doura',
  doo: 'Dongo',
  dop: 'Lukpa',
  doq: 'Dominican Sign Language',
  dor: "Dori'o",
  dos: 'Dogos\u00e9',
  dot: 'Dass',
  dov: 'Dombe',
  dow: 'Doyayo',
  dox: 'Bussa',
  doy: 'Dompo',
  doz: 'Dorze',
  dpp: 'Papar',
  drb: 'Dair',
  drc: 'Minderico',
  drd: 'Darmiya',
  drg: 'Rungus',
  dri: 'Lela',
  drl: 'Baagandji',
  drn: 'West Damar',
  dro: 'Daro-Matu Melanau',
  drq: 'Dura',
  drs: 'Gedeo',
  dru: 'Rukai',
  dry: 'Darai',
  dsb: 'Lower Sorbian',
  dse: 'Dutch Sign Language',
  dsh: 'Daasanach',
  dsi: 'Disa',
  dsl: 'Danish Sign Language',
  dsn: 'Dusner',
  dso: 'Desiya',
  dsq: 'Tadaksahak',
  dta: 'Daur',
  dtb: 'Labuk-Kinabatangan Kadazan',
  dtd: 'Ditidaht',
  dth: 'Adithinngithigh',
  dti: 'Ana Tinga Dogon',
  dtk: 'Tene Kan Dogon',
  dtm: 'Tomo Kan Dogon',
  dto: 'Tommo So',
  dtp: 'Central Dusun',
  dtr: 'Lotud',
  dts: 'Toro So Dogon',
  dtt: 'Toro Tegu Dogon',
  dtu: 'Tebul Ure Dogon',
  dty: 'Doteli',
  dua: 'Duala',
  dub: 'Dubli',
  duc: 'Duna',
  dud: 'Duka',
  due: 'Umiray Dumaget Agta',
  duf: 'Dumbea',
  dug: 'Chiduruma',
  duh: 'Dungra Bhil',
  dui: 'Dumun',
  duk: 'Uyajitaya',
  dul: 'Alabat Island Agta',
  dum: 'Middle Dutch',
  dun: 'Dusun Deyah',
  duo: 'Dupaningan Agta',
  dup: 'Duano',
  duq: 'Dusun Malang',
  dur: 'Dii',
  dus: 'Dumi',
  duu: 'Drung',
  duv: 'Duvle',
  duw: 'Dusun Witu',
  dux: 'Duun',
  duy: 'Dicamay Agta',
  duz: 'Duli',
  dva: 'Duau',
  dwa: 'Diri',
  dwr: 'Dawro',
  gnn: 'Gumatj',
  guf: 'Gupapuyngu',
  dww: 'Dawawa',
  dwy: 'Dhuwaya',
  dya: 'Dyan',
  dyb: 'Dyaberdyaber',
  dyd: 'Dyugun',
  dyg: 'Villa Viciosa Agta',
  dyi: 'Djimini',
  yda: 'Yanda',
  dyn: 'Dyangadi',
  dyo: 'Jola-Fonyi',
  dyu: 'Dyula',
  dyy: 'Dyaabugay',
  dza: 'Tunzu',
  dzg: 'Dazaga',
  dzl: 'Dzala',
  dzn: 'Dzando',
  ebg: 'Ebughu',
  ebk: 'Eastern Bontoc',
  ebo: 'Teke-Ebo',
  ebr: 'Ebri\u00e9',
  ebu: 'Embu',
  ecr: 'Eteocretan',
  ecs: 'Ecuadorian Sign Language',
  ecy: 'Eteocypriot',
  eee: 'E',
  efa: 'Efai',
  efe: 'Efe',
  efi: 'Efik',
  ega: 'Ega',
  egl: 'Emilian',
  ego: 'Eggon',
  ehu: 'Ehueun',
  eip: 'Eipomek',
  eit: 'Eitiep',
  eiv: 'Askopan',
  eja: 'Ejamat',
  eka: 'Ekajuk',
  eke: 'Ekit',
  ekg: 'Ekari',
  eki: 'Eki',
  ekl: 'Kolhe',
  ekm: 'Elip',
  eko: 'Koti',
  ekp: 'Ekpeye',
  ekr: 'Yace',
  eky: 'Eastern Kayah',
  ele: 'Elepi',
  elh: 'El Hugeirat',
  eli: 'Nding',
  elk: 'Elkei',
  elm: 'Eleme',
  elo: 'El Molo',
  elu: 'Elu',
  elx: 'Elamite',
  ema: 'Emai',
  scg: 'Sanggau',
  eme: 'Emerillon',
  emg: 'Eastern Meohang',
  emi: 'Mussau-Emira',
  emk: 'Eastern Maninkakan',
  emm: 'Mamulique',
  emn: 'Eman',
  emp: 'Northern Ember\u00e1',
  ems: 'Alutiiq',
  emu: 'Eastern Muria',
  emw: 'Emplawas',
  emx: 'Erromintxela',
  'myn-chl': "Ch'olti'",
  ena: 'Apali',
  enb: 'Markweeta',
  enc: 'En',
  enf: 'Forest Enets',
  enh: 'Tundra Enets',
  enl: 'Enlhet',
  enm: 'Middle English',
  enn: 'Engenni',
  eno: 'Enggano',
  enq: 'Enga',
  enr: 'Emem',
  enu: 'Enu',
  env: 'Enwan',
  enw: 'Enwang',
  enx: 'Enxet',
  eot: 'Eotile',
  epi: 'Epie',
  era: 'Eravallan',
  erg: 'Sie',
  erh: 'Eruwa',
  eri: 'Ogea',
  erk: 'South Efate',
  ero: 'Horpa',
  err: 'Erre',
  ers: 'Ersu',
  ert: 'Eritai',
  erw: 'Erokwanas',
  ese: 'Ese Ejja',
  esh: 'Eshtehardi',
  esi: 'North Alaskan Inupiatun',
  esk: 'Northwest Alaska Inupiatun',
  esl: 'Egyptian Sign Language',
  esm: 'Esuma',
  esn: 'Salvadoran Sign Language',
  eso: 'Estonian Sign Language',
  esq: 'Esselen',
  ess: 'Central Siberian Yupik',
  esy: 'Eskayan',
  etb: 'Etebi',
  etc: 'Etchemin',
  eth: 'Ethiopian Sign Language',
  etn: 'Eton (Vanuatu)',
  eto: 'Eton (Cameroon)',
  etr: 'Edolo',
  ets: 'Yekhee',
  ett: 'Etruscan',
  etu: 'Ejagham',
  etx: 'Eten',
  etz: 'Semimi',
  eve: 'Even',
  evh: 'Uvbie',
  evn: 'Evenki',
  ewo: 'Ewondo',
  ext: 'Extremaduran',
  eya: 'Eyak',
  eyo: 'Keiyo',
  eza: 'Ezaa',
  eze: 'Uzekwe',
  faa: 'Fasu',
  fab: 'Annobonese',
  fad: 'Wagi',
  faf: 'Fagani',
  fag: 'Finongan',
  fah: 'Baissa Fali',
  fai: 'Faiwol',
  faj: 'Faita',
  fak: 'Fang (Cameroon)',
  fal: 'South Fali',
  fam: 'Fam',
  fan: 'Fang (Guinea)',
  fap: 'Palor',
  far: 'Fataleka',
  fau: 'Fayu',
  fax: 'Fala',
  fay: 'Southwestern Fars',
  faz: 'Northwestern Fars',
  fbl: 'West Albay Bikol',
  fcs: 'Quebec Sign Language',
  fer: 'Feroge',
  ffi: 'Foia Foia',
  fgr: 'Fongoro',
  fia: 'Nobiin',
  fie: 'Fyer',
  fip: 'Fipa',
  fir: 'Firan',
  fit: 'Me\u00e4nkieli',
  fiw: 'Fiwaga',
  fkk: 'Kirya-Konzel',
  fkv: 'Kven',
  fla: 'Montana Salish',
  flh: 'Foau',
  fli: 'Fali',
  fll: 'North Fali',
  fln: 'Flinders Island',
  flr: 'Fuliiru',
  fly: 'Tsotsitaal',
  fmp: "Fe'fe'",
  fmu: 'Far Western Muria',
  fng: 'Fanagalo',
  fni: 'Fania',
  fod: 'Foodo',
  foi: 'Foi',
  fom: 'Foma',
  fon: 'Fon',
  for: 'Fore',
  fos: 'Siraya',
  fpe: 'Pichinglis',
  fqs: 'Fas',
  frd: 'Fordata',
  frk: 'Frankish',
  frm: 'Middle French',
  fro: 'Old French',
  frp: 'Franco-Proven\u00e7al',
  frq: 'Forak',
  frr: 'North Frisian',
  frt: 'Fortsenal',
  fse: 'Finnish Sign Language',
  fsl: 'French Sign Language',
  fss: 'Finnish-Swedish Sign Language',
  fud: 'East Futuna',
  fuj: 'Ko',
  fum: 'Fum',
  fun: 'Fulni\u00f4',
  fur: 'Friulian',
  fut: 'Futuna-Aniwa',
  fuu: 'Furu',
  fuy: 'Fuyug',
  fwa: 'Fw\u00e2i',
  fwe: 'Fwe',
  gaa: 'Ga',
  gab: 'Gabri',
  gac: 'Mixed Great Andamanese',
  gad: 'Gaddang',
  gae: 'Warekena',
  gaf: 'Gende',
  gag: 'Gagauz',
  gah: 'Alekano',
  gai: 'Borei',
  gaj: 'Gadsup',
  gak: 'Gamkonora',
  gal: 'Galoli',
  gam: 'Kandawo',
  gan: 'Gan',
  gao: 'Gants',
  gap: 'Gal',
  gaq: "Gata'",
  gar: 'Galeya',
  gas: 'Adiwasi Garasia',
  gat: 'Kenati',
  gau: 'Kondekor',
  gaw: 'Nobonob',
  gay: 'Gayo',
  gba: 'Gbaya',
  gbb: 'Kaytetye',
  gbd: 'Karadjeri',
  gbe: 'Niksek',
  gbf: 'Gaikundi',
  gbg: 'Gbanziri',
  gbh: 'Defi Gbe',
  gbi: 'Galela',
  gbj: 'Bodo Gadaba',
  gbk: 'Gaddi',
  gbl: 'Gamit',
  gbm: 'Garhwali',
  gbn: "Mo'da",
  gbo: 'Northern Grebo',
  gbp: 'Gbaya-Bossangoa',
  gbq: 'Gbaya-Bozoum',
  gbr: 'Gbagyi',
  gbs: 'Gbesi Gbe',
  gbu: 'Gagadu',
  gbv: 'Gbanu',
  gbw: 'Gabi',
  gbx: 'Eastern Xwla Gbe',
  gby: 'Gbari',
  gcc: 'Mali',
  gcd: 'Ganggalida',
  gce: 'Galice',
  gcf: 'Antillean Creole',
  gcl: 'Grenadian Creole English',
  gcn: 'Gaina',
  gcr: 'Guianese Creole',
  gct: 'Colonia Tovar German',
  gdb: 'Ollari',
  gdc: 'Gugu Badhun',
  gdd: 'Gedaged',
  gde: 'Gude',
  gdf: 'Guduf-Gava',
  gdg: "Ga'dang",
  gdh: 'Gadjerawang',
  gdi: 'Gundi',
  gdj: 'Kurtjar',
  gdk: 'Gadang',
  gdl: 'Dirasha',
  gdm: 'Laal',
  gdn: 'Umanakaina',
  gdo: 'Godoberi',
  gdq: 'Mehri',
  gdr: 'Wipi',
  gds: 'Ghandruk Sign Language',
  gdt: 'Kungardutyi',
  gdu: 'Gudu',
  gdx: 'Godwari',
  gea: 'Geruma',
  geb: 'Kire',
  gec: 'Gboloo Grebo',
  ged: 'Gade',
  geg: 'Gengle',
  geh: 'Hutterisch',
  gei: 'Gebe',
  gej: 'Gen',
  gek: 'Gerka',
  kuv: 'Kur',
  kah: 'Fer',
  geq: 'Geme',
  ges: 'Geser-Gorom',
  gev: 'Viya',
  gew: 'Gera',
  gex: 'Garre',
  gey: 'Enya',
  gez: "Ge'ez",
  gfk: 'Patpatar',
  gft: 'Gafat',
  gga: 'Gao',
  ggb: 'Gbii',
  ggd: 'Gugadj',
  gge: 'Guragone',
  ggg: 'Gurgula',
  ggk: 'Kungarakany',
  ggl: 'Ganglau',
  ggn: 'Eastern Gurung',
  ggt: 'Gitua',
  ggu: 'Gban',
  ggw: 'Gogodala',
  gha: 'Ghadames',
  ghe: 'Southern Ghale',
  ghh: 'Northern Ghale',
  ghk: 'Geko Karen',
  ghl: 'Ghulfan',
  ghn: 'Ghanongga',
  gho: 'Ghomara',
  ghr: 'Ghera',
  ghs: 'Guhu-Samane',
  ght: 'Kutang Ghale',
  gia: 'Kitja',
  gib: 'Gibanawa',
  gid: 'Gidar',
  gie: 'Gu\u00e9bie',
  gig: 'Goaria',
  gih: 'Githabul',
  gil: 'Gilbertese',
  gim: 'Papuan Gimi',
  gin: 'Hinukh',
  gio: 'Gelao',
  gip: 'Austronesian Gimi',
  giq: 'Green Gelao',
  gir: 'Red Gelao',
  gis: 'North Giziga',
  git: 'Gitxsan',
  giu: 'Mulao',
  giw: 'White Gelao',
  gix: 'Gilima',
  giy: 'Giyug',
  giz: 'South Giziga',
  gji: 'Geji',
  gjk: 'Kachi Koli',
  gjm: 'Gunditjmara',
  gjn: 'Gonja',
  gju: 'Gojri',
  gka: 'Guya',
  gkd: 'Mag\u0268',
  gke: 'Ndai',
  gkn: 'Gokana',
  gko: 'Kok-Nar',
  gkp: 'Guinea Kpelle',
  glc: 'Bon Gula',
  gld: 'Nanai',
  glh: 'Northwest Pashayi',
  glj: 'Kulaal',
  glk: 'Gilaki',
  glo: 'Galambu',
  glr: 'Glaro-Twabo',
  glu: 'Gula',
  glw: 'Glavda',
  gly: 'Gule',
  gma: 'Gambera',
  gmb: "Gula'alaa",
  gmd: 'M\u00e1ghd\u00ec',
  gmg: 'Magiyi',
  gmh: 'Middle High German',
  gml: 'Middle Low German',
  gmm: 'Gbaya-Mbodomo',
  gmn: 'Gimnime',
  gmu: 'Gumalu',
  gmv: 'Gamo',
  gmx: 'Magoma',
  gmy: 'Mycenaean Greek',
  gmz: 'Mgbo',
  gna: 'Kaansa',
  gnb: 'Gangte',
  gnc: 'Guanche',
  gnd: 'Zulgo-Gemzek',
  gne: 'Ganang',
  gng: 'Ngangam',
  gnh: 'Lere',
  gni: 'Gooniyandi',
  gnj: 'Ngen',
  gnq: 'Gana',
  gnl: 'Gangulu',
  gnm: 'Ginuman',
  gnr: 'Gureng Gureng',
  gnt: 'Guntai',
  gnu: 'Gnau',
  gnw: 'Western Bolivian Guaran\u00ed',
  gnz: 'Ganzi',
  goa: 'Guro',
  gob: 'Playero',
  goc: 'Gorakor',
  god: 'Godi\u00e9',
  goe: 'Gongduk',
  gof: 'Gofa',
  gog: 'Gogo',
  goh: 'Old High German',
  goi: 'Gobasi',
  goj: 'Gowlan',
  gok: 'Gowli',
  gon: 'Gondi',
  goo: 'Gone Dau',
  gop: 'Yeretuar',
  goq: 'Gorap',
  gor: 'Gorontalo',
  got: 'Gothic',
  gou: 'Gavar',
  gow: 'Gorowa',
  gox: 'Gobu',
  goy: 'Goundo',
  goz: 'Gozarkhani',
  gpa: 'Gupa-Abawa',
  gpn: 'Taiap',
  gqa: "Ga'anda",
  gqi: 'Guiqiong',
  gqn: 'Kinikinao',
  gqr: 'Gor',
  gqu: 'Qau',
  gra: 'Rajput Garasia',
  grb: 'Grebo',
  grc: 'Ancient Greek',
  grd: 'Guruntum',
  grh: 'Gbiri-Niragu',
  gri: 'Ghari',
  grj: 'Southern Grebo',
  grm: 'Kota Marudu Talantang',
  gro: 'Groma',
  grq: 'Gorovu',
  grr: 'Taznatit',
  grs: 'Gresi',
  grt: 'Garo',
  gru: 'Kistane',
  grv: 'Central Grebo',
  grw: 'Gweda',
  grx: 'Guriaso',
  gry: 'Barclayville Grebo',
  grz: 'Guramalum',
  gse: 'Ghanaian Sign Language',
  gsg: 'German Sign Language',
  gsl: 'Gusilay',
  gsm: 'Guatemalan Sign Language',
  gsn: 'Gusan',
  gso: 'Southwest Gbaya',
  gsp: 'Wasembo',
  gss: 'Greek Sign Language',
  gsw: 'Alemannic German',
  gta: 'Guat\u00f3',
  gti: 'Gbati-ri',
  gtu: 'Aghu Tharrnggala',
  gua: 'Shiki',
  gub: 'Guajaj\u00e1ra',
  guc: 'Wayuu',
  gud: 'Yocobou\u00e9 Dida',
  gue: 'Gurindji',
  gug: 'Paraguayan Guaran\u00ed',
  guh: 'Guahibo',
  gui: 'Eastern Bolivian Guaran\u00ed',
  guk: 'Gumuz',
  gul: 'Gullah',
  gum: 'Guambiano',
  kgk: 'Kaiw\u00e1',
  guo: 'Guayabero',
  gup: 'Gunwinggu',
  guq: 'Ach\u00e9',
  gur: 'Farefare',
  gus: 'Guinean Sign Language',
  gut: 'Mal\u00e9ku Ja\u00edka',
  guu: 'Yanomam\u00f6',
  guv: 'Gey',
  guw: 'Gun',
  gux: 'Gourmanch\u00e9ma',
  guz: 'Gusii',
  gva: 'Kaskih\u00e1',
  gvc: 'Guanano',
  gve: 'Duwet',
  gvf: 'Golin',
  gvj: 'Guaj\u00e1',
  gvl: 'Gulay',
  gvm: 'Gurmana',
  gvn: 'Kuku-Yalanji',
  gvo: 'Gavi\u00e3o do Jiparan\u00e1',
  gvp: 'Par\u00e1 Gavi\u00e3o',
  gvr: 'Western Gurung',
  gvs: 'Gumawana',
  gvy: 'Guyani',
  gwa: 'Mbato',
  gwb: 'Gwa',
  gwc: 'Kalami',
  gwd: 'Gawwada',
  gwe: 'Gweno',
  gwf: 'Gowro',
  gwg: 'Moo',
  gwi: "Gwich'in",
  gwj: 'Gcwi',
  gwm: 'Awngthim',
  gwn: 'Gwandara',
  gwr: 'Gwere',
  gwt: 'Gawar-Bati',
  gwu: 'Guwamu',
  gww: 'Kwini',
  gwx: 'Gua',
  gxx: 'W\u00e8 Southern',
  gya: 'Northwest Gbaya',
  gyb: 'Garus',
  gyd: 'Kayardild',
  gye: 'Gyem',
  gyf: 'Gungabula',
  gyg: 'Gbayi',
  gyi: 'Gyele',
  gyl: 'Gayil',
  gym: 'Ng\u00e4bere',
  gyn: 'Guyanese Creole English',
  gyr: 'Guarayu',
  gyy: 'Gunya',
  gza: 'Ganza',
  gzn: 'Gane',
  haa: 'H\u00e4n',
  hab: 'Hanoi Sign Language',
  hac: 'Gurani',
  had: 'Hatam',
  haf: 'Haiphong Sign Language',
  hag: 'Hanga',
  hah: 'Hahon',
  hai: 'Haida',
  haj: 'Hajong',
  hak: 'Hakka',
  hal: 'Halang',
  ham: 'Hewa',
  hao: 'Hak\u00f6',
  hap: 'Hupla',
  har: 'Harari',
  has: 'Haisla',
  hav: 'Havu',
  haw: 'Hawaiian',
  hax: 'Southern Haida',
  hay: 'Haya',
  haz: 'Hazaragi',
  hba: 'Hamba',
  hbb: 'Huba',
  hbn: 'Heiban',
  hbu: 'Habu',
  hca: 'Andaman Creole Hindi',
  hch: 'Huichol',
  hdn: 'Northern Haida',
  hds: 'Honduras Sign Language',
  hdy: 'Hadiyya',
  hea: 'Northern Qiandong Miao',
  hed: 'Herd\u00e9',
  heg: 'Helong',
  heh: 'Hehe',
  hei: 'Heiltsuk',
  hem: 'Hemba',
  hgm: 'Hai\u01c1om',
  hgw: 'Haigwai',
  hhi: 'Hoia Hoia',
  hhr: 'Kerak',
  hhy: 'Hoyahoya',
  hia: 'Lamang',
  hib: 'Hibito',
  hid: 'Hidatsa',
  hif: 'Fiji Hindi',
  hig: 'Kamwe',
  hih: 'Pamosu',
  hii: 'Hinduri',
  hij: 'Hijuk',
  hik: 'Seit-Kaitetu',
  hil: 'Hiligaynon',
  tyu: 'Kua',
  hir: 'Himarim\u00e3',
  hit: 'Hittite',
  hiw: 'Hiw',
  hix: 'Hixkaryana',
  hji: 'Haji',
  hka: 'Kahe',
  hke: 'Hunde',
  hkk: 'Hunjara-Kaina Ke',
  hkn: 'Mel-Khaonh',
  hks: 'Hong Kong Sign Language',
  hla: 'Halia',
  hlb: 'Halbi',
  hld: 'Halang Doan',
  hle: 'Hlersu',
  hlt: 'Nga La',
  hma: 'Southern Mashan Hmong',
  hmb: 'Humburi Senni Songhay',
  hmc: 'Central Huishui Hmong',
  hmd: 'A-Hmao',
  hme: 'Eastern Huishui Hmong',
  hmf: 'Hmong Don',
  hmg: 'Southwestern Guiyang Hmong',
  hmh: 'Southwestern Huishui Hmong',
  hmi: 'Northern Huishui Hmong',
  hmj: 'Ge',
  hmk: 'Maek',
  hml: 'Luopohe Hmong',
  hmm: 'Central Mashan Hmong',
  hmp: 'Northern Mashan Hmong',
  hmq: 'Eastern Qiandong Miao',
  hmr: 'Hmar',
  hms: 'Southern Qiandong Miao',
  hmt: 'Hamtai',
  hmu: 'Hamap',
  hmv: 'Hmong D\u00f4',
  hmw: 'Western Mashan Hmong',
  hmy: 'Southern Guiyang Hmong',
  hmz: 'Hmong Shua',
  hna: 'Mina',
  hnd: 'Southern Hindko',
  hne: 'Chhattisgarhi',
  hnh: '\u01c1Ani',
  hni: 'Hani',
  hnj: 'Green Hmong',
  hnn: 'Hanunoo',
  hno: 'Northern Hindko',
  hns: 'Caribbean Hindustani',
  hnu: 'Hung',
  hoa: 'Hoava',
  hob: 'Austronesian Mari',
  hoc: 'Ho',
  hod: 'Holma',
  hoe: 'Horom',
  hoh: 'Hoby\u00f3t',
  hoi: 'Holikachuk',
  hoj: 'Hadothi',
  hol: 'Holu',
  hom: 'Homa',
  hoo: 'Holoholo',
  hop: 'Hopi',
  hor: 'Horo',
  hos: 'Ho Chi Minh City Sign Language',
  hot: 'Hote',
  hov: 'Hovongan',
  how: 'Honi',
  hoy: 'Holiya',
  hoz: 'Hozo',
  hpo: 'Hpon',
  hul: 'Hula',
  hra: 'Hrangkhol',
  hrc: 'Niwer Mil',
  hre: 'Hre',
  hrk: 'Haruku',
  hrm: 'Horned Miao',
  hro: 'Haroi',
  hrp: 'Nhirrpi',
  hrt: 'H\u00e9rtevin',
  hru: 'Hruso',
  hrw: 'Warwar Feni',
  hrx: 'Hunsrik',
  hrz: 'Harzani',
  hsb: 'Upper Sorbian',
  hsh: 'Hungarian Sign Language',
  hsl: 'Hausa Sign Language',
  hsn: 'Xiang',
  hss: 'Harsusi',
  hti: 'Hoti',
  hto: 'Minica Huitoto',
  hts: 'Hadza',
  htu: 'Hitu',
  htx: 'Middle Hittite',
  hub: 'Huambisa',
  huc: '\u01c2Hoan',
  hud: 'Huaulu',
  huf: 'Humene',
  hug: 'Huachipaeri',
  huh: 'Huilliche',
  hui: 'Huli',
  huj: 'Northern Guiyang Hmong',
  huk: 'Hulung',
  hum: 'Hungana',
  huo: 'Hu',
  hup: 'Hupa',
  huq: 'Tsat',
  hur: 'Halkomelem',
  hus: 'Wastek',
  huu: 'Murui Huitoto',
  huv: 'Huave',
  huw: 'Hukumina',
  hux: 'N\u00fcpode Huitoto',
  huy: 'Hulaul\u00e1',
  huz: 'Hunzib',
  hvc: 'Haitian Vodoun Culture Language',
  hvk: 'Haveke',
  hvn: 'Sabu',
  hwa: 'Wan\u00e9',
  hwo: 'Hwana',
  hya: 'Hya',
  iai: 'Iaai',
  ian: 'Iatmul',
  iar: 'Purari',
  iba: 'Iban',
  ibb: 'Ibibio',
  ibd: 'Iwaidja',
  ibe: 'Akpes',
  ibg: 'Ibanag',
  ibh: 'Bih',
  ibl: 'Ibaloi',
  ibm: 'Agoi',
  ibn: 'Ibino',
  ibr: 'Ibuoro',
  ibu: 'Ibu',
  iby: 'Ibani',
  ica: 'Ede Ica',
  ich: 'Etkywan',
  icl: 'Icelandic Sign Language',
  icr: 'Islander Creole English',
  ida: 'Idakho-Isukha-Tiriki',
  idb: 'Indo-Portuguese',
  idc: 'Idon',
  idd: 'Ede Idaca',
  ide: 'Idere',
  idi: 'Idi',
  idr: 'Indri',
  ids: 'Idesa',
  idt: 'Idat\u00e9',
  idu: 'Idoma',
  ifa: 'Amganad Ifugao',
  ifb: 'Batad Ifugao',
  ife: 'If\u00e8',
  iff: 'Ifo',
  ifk: 'Tuwali Ifugao',
  ifm: 'Teke-Fuumu',
  ifu: 'Mayoyao Ifugao',
  ify: 'Keley-I Kallahan',
  igb: 'Ebira',
  ige: 'Igede',
  igg: 'Igana',
  igl: 'Igala',
  igm: 'Kanggape',
  ign: 'Ignaciano',
  igo: 'Isebe',
  igs: 'Glosa',
  igw: 'Igwe',
  ihb: 'Iha Based Pidgin',
  ihi: 'Ihievbe',
  ihp: 'Iha',
  ijc: 'Izon',
  ije: 'Biseni',
  ijj: 'Ede Ije',
  ijn: 'Kalabari',
  ijs: 'Southeast Ijo',
  iki: 'Iko',
  ikl: 'Ikulu',
  iko: 'Olulumo-Ikom',
  ikp: 'Ikpeshi',
  ikr: 'Ikaranggal',
  iks: 'Inuit Sign Language',
  ikv: 'Iku-Gora-Ankwa',
  ikw: 'Ikwere',
  ikx: 'Ik',
  ikz: 'Ikizu',
  ila: 'Ile Ape',
  ilb: 'Ila',
  ilg: 'Ilgar',
  ili: 'Ili Turki',
  ilk: 'Ilongot',
  ill: 'Iranun',
  ilo: 'Ilocano',
  ils: 'International Sign',
  ilu: "Ili'uun",
  ilv: 'Ilue',
  ima: 'Mala Malasar',
  imi: 'Anamgura',
  iml: 'Miluk',
  imn: 'Imonda',
  imo: 'Imbongu',
  imr: 'Imroing',
  ims: 'Marsian',
  imy: 'Milyan',
  inb: 'Inga',
  ing: 'Deg Xinag',
  inh: 'Ingush',
  inj: 'Jungle Inga',
  inl: 'Indonesian Sign Language',
  inm: 'Minaean',
  inn: 'Isinai',
  ino: 'Inoke-Yate',
  inp: 'I\u00f1apari',
  ins: 'Indian Sign Language',
  int: 'Intha',
  inz: 'Inese\u00f1o',
  ior: 'Inor',
  iou: 'Tuma-Irumu',
  iow: 'Chiwere',
  ipi: 'Ipili',
  ipo: 'Ipiko',
  iqu: 'Iquito',
  iqw: 'Ikwo',
  ire: 'Iresim',
  irh: 'Irarutu',
  iri: 'Rigwe',
  irk: 'Iraqw',
  irn: 'Ir\u00e1ntxe',
  irr: 'Ir',
  iru: 'Irula',
  irx: 'Kamberau',
  iry: 'Iraya',
  isa: 'Isabi',
  isc: 'Isconahua',
  isd: 'Isnag',
  ise: 'Italian Sign Language',
  isg: 'Irish Sign Language',
  ish: 'Esan',
  isi: 'Nkem-Nkum',
  isk: 'Ishkashimi',
  ism: 'Masimasi',
  isn: 'Isanzu',
  iso: 'Isoko',
  isr: 'Israeli Sign Language',
  ist: 'Istriot',
  isu: 'Isu',
  itb: 'Binongan Itneg',
  itd: 'Southern Tidong',
  ite: 'Itene',
  iti: 'Inlaod Itneg',
  itk: 'Judeo-Italian',
  itl: 'Itelmen',
  itm: 'Itu Mbon Uzo',
  ito: 'Itonama',
  itr: 'Iteri',
  its: 'Isekiri',
  itt: 'Maeng Itneg',
  itv: 'Itawit',
  itw: 'Ito',
  itx: 'Itik',
  ity: 'Moyadan Itneg',
  itz: 'Itz\u00e1',
  ium: 'Iu Mien',
  ivv: 'Ivatan',
  iwk: 'I-Wak',
  iwo: 'Iwur',
  iws: 'Sepik Iwam',
  ixc: 'Ixcatec',
  ixl: 'Ixil',
  iya: 'Iyayu',
  iyo: 'Mesaka',
  iyx: 'Yaa',
  izh: 'Ingrian',
  izi: 'Izi-Ezaa-Ikwo-Mgbo',
  izr: 'Izere',
  izz: 'Izi',
  jaa: 'Jamamad\u00ed',
  jab: 'Hyam',
  jac: 'Jakaltek',
  jad: 'Jahanka',
  jae: 'Jabem',
  jaf: 'Jara',
  jah: 'Jah Hut',
  jaj: 'Zazao',
  jal: 'Yalahatan',
  jam: 'Jamaican Creole',
  jan: 'Janday',
  jao: 'Yanyuwa',
  jaq: 'Yaqay',
  jas: 'New Caledonian Javanese',
  jat: 'Jakati',
  jau: 'Yaur',
  jax: 'Jambi Malay',
  jay: 'Yan-nhangu',
  jaz: 'Jawe',
  jbe: 'Judeo-Berber',
  jbj: 'Arandai',
  jbk: 'Barikewa',
  jbn: 'Nefusa',
  jbo: 'Lojban',
  jbr: 'Jofotek-Bromnya',
  jbt: 'Jabut\u00ed',
  jbu: 'Jukun Takum',
  jcs: 'Jamaican Country Sign Language',
  jct: 'Krymchak',
  jda: 'Jad',
  jdg: 'Jadgali',
  jdt: 'Judeo-Tat',
  jeb: 'Jebero',
  jee: 'Jerung',
  jeg: 'Jeng',
  jeh: 'Jeh',
  jei: 'Yei',
  jek: 'Jeri Kuo',
  jel: 'Yelmek',
  jen: 'Dza',
  jer: 'Jere',
  jet: 'Manem',
  jeu: 'Jonkor Bourmataguil',
  jgb: 'Ngbee',
  jgo: 'Ngomba',
  jhi: 'Jehai',
  jhs: 'Jhankot Sign Language',
  jia: 'Jina',
  jib: 'Jibu',
  jic: 'Tol',
  jid: 'Bu',
  jie: 'Jilbe',
  jig: 'Jingulu',
  jih: 'Shangzhai',
  jii: 'Jiiddu',
  jil: 'Jilim',
  jmi: 'Jimi',
  jio: 'Jiamao',
  jiq: 'Guanyinqiao',
  jit: 'Jita',
  jiu: 'Youle Jinuo',
  jiv: 'Shuar',
  jiy: 'Buyuan Jinuo',
  jje: 'Jeju',
  jka: 'Kaera',
  jko: 'Kubo',
  jkr: 'Koro (India)',
  jku: 'Labir',
  jle: 'Ngile',
  jls: 'Jamaican Sign Language',
  jma: 'Dima',
  jmb: 'Zumbun',
  jmc: 'Machame',
  jmd: 'Yamdena',
  jml: 'Jumli',
  jmn: 'Makuri Naga',
  jmr: 'Kamara',
  jmw: 'Mouwase',
  jmx: 'Western Juxtlahuaca Mixtec',
  jna: 'Jangshung',
  jnd: 'Jandavra',
  jng: 'Yangman',
  jni: 'Janji',
  jnj: 'Yemsa',
  jnl: 'Rawat',
  jns: 'Jaunsari',
  job: 'Joba',
  jod: 'Wojenaka',
  jor: 'Jor\u00e1',
  jos: 'Jordanian Sign Language',
  jow: 'Jowulu',
  jpr: 'Judeo-Persian',
  jqr: 'Jaqaru',
  jra: 'Jarai',
  jrb: 'Judeo-Arabic',
  jrr: 'Jiru',
  jru: 'Japrer\u00eda',
  jsl: 'Japanese Sign Language',
  jua: 'J\u00fama',
  jub: 'Wannu',
  juc: 'Jurchen',
  jud: 'Worodougou',
  juh: 'Hone',
  juk: 'Wapan',
  jul: 'Jirel',
  jum: 'Jumjum',
  jun: 'Juang',
  juo: 'Jiba',
  jup: 'Hupd\u00eb',
  jur: 'Jur\u00fana',
  jus: 'Jumla Sign Language',
  jut: 'Jutish',
  juu: 'Ju',
  juw: 'W\u00e3pha',
  juy: 'Juray',
  jvd: 'Javindo',
  jvn: 'Caribbean Javanese',
  jwi: 'Jwira-Pepesa',
  jye: 'Judeo-Yemeni Arabic',
  jyy: 'Jaya',
  kaa: 'Karakalpak',
  kab: 'Kabyle',
  kac: 'Jingpho',
  kad: 'Kadara',
  kae: 'Ketangalan',
  kaf: 'Katso',
  kag: 'Kajaman',
  kai: 'Karekare',
  kaj: 'Jju',
  kak: 'Kayapa Kallahan',
  kam: 'Kamba',
  kao: 'Kassonke',
  kap: 'Bezhta',
  kaq: 'Capanahua',
  kaw: 'Old Javanese',
  kax: 'Kao',
  kay: 'Kamayur\u00e1',
  kba: 'Kalarko',
  kbb: 'Kaxuyana',
  kbc: 'Kadiw\u00e9u',
  kbd: 'Kabardian',
  kbe: 'Kanju',
  kbh: 'Cams\u00e1',
  kbi: 'Kaptiau',
  kbk: 'Grass Koiari',
  kbm: 'Iwal',
  kbn: 'Kare (Africa)',
  kbo: 'Keliko',
  kbp: 'Kabiy\u00e9',
  kbq: 'Kamano',
  kbr: 'Kafa',
  kbs: 'Kande',
  kbt: 'Gabadi',
  kbu: 'Kabutra',
  kbv: 'Kamberataro',
  kbw: 'Kaiep',
  kbx: 'Ap Ma',
  kbz: 'Duhwa',
  kca: 'Khanty',
  kcb: 'Kawacha',
  kcc: 'Lubila',
  kcd: 'Ngk\u00e2lmpw Kanum',
  kce: 'Kaivi',
  kcf: 'Ukaan',
  kcg: 'Tyap',
  kch: 'Vono',
  kci: 'Kamantan',
  kcj: 'Kobiana',
  kck: 'Kalanga',
  nlg: 'Gela',
  kcm: 'Tar Gula',
  kcn: 'Nubi',
  kco: 'Kinalakna',
  kcp: 'Kanga',
  kcq: 'Kamo',
  kcr: 'Katla',
  kcs: 'Koenoem',
  kct: 'Kaian',
  kmi: 'Kami',
  kcv: 'Kete',
  kcw: 'Kabwari',
  kcx: 'Kachama-Ganjule',
  kcy: 'Korandje',
  kcz: 'Konongo',
  kda: 'Worimi',
  kdc: 'Kutu',
  kdd: 'Yankunytjatjara',
  kde: 'Makonde',
  kdf: 'Mamusi',
  kdg: 'Seba',
  kdh: 'Tem',
  kue: 'Kuman',
  kdj: 'Karamojong',
  kdk: 'Numee',
  kdl: 'Tsikimba',
  kdm: 'Kagoma',
  kdn: 'Kunda',
  kdp: 'Kaningdon-Nindem',
  kdq: 'Koch',
  kdr: 'Karaim',
  kdt: 'Kuy',
  kdu: 'Kadaru',
  kdv: 'Kado',
  kdw: 'Koneraw',
  kdx: 'Kam',
  kdy: 'Keder',
  kdz: 'Kwaja',
  kea: 'Kabuverdianu',
  keb: 'K\u00e9l\u00e9',
  kec: 'Keiga',
  ked: 'Kerewe',
  kee: 'Eastern Keres',
  kef: 'Kpessi',
  keg: 'Tese',
  keh: 'Keak',
  kei: 'Kei',
  kej: 'Kadar',
  kek: "Q'eqchi",
  kel: 'Kela-Yela',
  kem: 'Kemak',
  ken: 'Kenyang',
  keo: 'Kakwa',
  kep: 'Kaikadi',
  keq: 'Kamar',
  ker: 'Kera',
  kes: 'Kugbo',
  ket: 'Ket',
  keu: 'Akebu',
  kev: 'Kanikkaran',
  kjy: 'Erave',
  kex: 'Kukna',
  key: 'Kupia',
  kez: 'Kukele',
  kfa: 'Kodava',
  kfb: 'Kolami',
  kfc: 'Konda-Dora',
  kfd: 'Korra Koraga',
  kfe: 'Kota',
  kff: 'Koya',
  kfg: 'Kudiya',
  kfh: 'Kurichiya',
  kfi: 'Kannada Kurumba',
  kfj: 'Kemiehua',
  kfk: 'Kinnauri',
  kfl: 'Kung',
  kfn: 'Kuk',
  kfo: 'Koro (West Africa)',
  kfp: 'Korwa',
  kfq: 'Korku',
  kfr: 'Kachchi',
  kfs: 'Bilaspuri',
  kft: 'Kanjari',
  kfu: 'Katkari',
  kfv: 'Kurmukar',
  kfw: 'Kharam Naga',
  kfx: 'Kullu Pahari',
  kfy: 'Kumaoni',
  kfz: 'Koromf\u00e9',
  kga: 'Koyaga',
  kgb: 'Kawe',
  kgd: 'Kataang',
  kge: 'Komering',
  kgf: 'Kube',
  kgg: 'Kusunda',
  kgi: 'Selangor Sign Language',
  kgj: 'Gamale Kham',
  kgl: 'Kunggari',
  kgm: 'Karip\u00fana',
  kgn: 'Karingani',
  kgo: 'Krongo',
  kgp: 'Kaingang',
  kgq: 'Kamoro',
  kgr: 'Abun',
  kgs: 'Kumbainggar',
  kgt: 'Somyev',
  kgu: 'Kobol',
  kgv: 'Karas',
  kgw: 'Karon Dori',
  kgx: 'Kamaru',
  kgy: 'Kyerung',
  kha: 'Khasi',
  khb: 'L\u00fc',
  khc: 'Tukang Besi North',
  khd: 'B\u00e4di Kanum',
  khe: 'Korowai',
  khf: 'Khuen',
  khh: 'Kehu',
  khj: 'Kuturmi',
  khl: 'Lusi',
  kho: 'Khotanese',
  khp: 'Kapauri',
  khq: 'Koyra Chiini',
  khr: 'Kharia',
  khs: 'Kasua',
  kht: 'Khamti',
  khu: 'Nkhumbi',
  khv: 'Khvarshi',
  khw: 'Khowar',
  khy: 'Ekele',
  khz: 'Keapara',
  kia: 'Kim',
  kib: 'Koalib',
  kic: 'Kickapoo',
  kid: 'Koshin',
  kie: 'Kibet',
  kif: 'Eastern Parbate Kham',
  kig: 'Kimaama',
  kih: 'Kilmeri',
  kii: 'Kitsai',
  kij: 'Kilivila',
  kil: 'Kariya',
  kim: 'Tofa',
  kio: 'Kiowa',
  kip: 'Sheshi Kham',
  kiq: 'Kosadle',
  kis: 'Kis',
  kit: 'Agob',
  kiv: 'Kimbu',
  kiw: 'Northeast Kiwai',
  kix: 'Khiamniungan Naga',
  kiy: 'Kirikiri',
  kiz: 'Kisi',
  kja: 'Mlap',
  kjb: "Q'anjob'al",
  kjc: 'Coastal Konjo',
  kjd: 'Southern Kiwai',
  kje: 'Kisar',
  kjg: 'Khmu',
  kjh: 'Khakas',
  kji: 'Zabana',
  kjj: 'Khinalug',
  kjk: 'Highland Konjo',
  kjl: 'Kham',
  kjm: 'Kh\u00e1ng',
  kjn: 'Kunjen',
  kjo: 'Harijan Kinnauri',
  kjp: 'Eastern Pwo Karen',
  kjq: 'Western Keres',
  kjr: 'Kurudu',
  kjt: 'Phrae Pwo Karen',
  kju: 'Kashaya',
  kjx: 'Ramopa',
  kjz: 'Bumthangkha',
  kka: 'Kakanda',
  kkb: 'Kwerisa',
  kkc: 'Odoodee',
  kkd: 'Kinuku',
  kke: 'Kakabe',
  kkf: 'Kalaktang Monpa',
  kkg: 'Mabaka Valley Kalinga',
  kkh: 'Kh\u00fcn',
  kki: 'Kagulu',
  kkj: 'Kako',
  kkk: 'Kokota',
  kkl: 'Kosarek Yale',
  kkm: 'Kiong',
  kkn: 'Kon Keu',
  kko: 'Karko',
  kkp: 'Koko-Bera',
  kkq: 'Kaiku',
  kkr: 'Kir-Balar',
  kks: 'Kirfi',
  kkt: 'Koi',
  kku: 'Tumi',
  kkv: 'Kangean',
  kkw: 'Teke-Kukuya',
  kkx: 'Kohin',
  kky: 'Guugu Yimidhirr',
  kkz: 'Kaska',
  kla: 'Klamath-Modoc',
  klb: 'Kiliwa',
  klc: 'Kolbila',
  kld: 'Gamilaraay',
  klf: 'Kendeje',
  klg: 'Tagakaulu Kalagan',
  klh: 'Weliki',
  kli: 'Kalumpang',
  klj: 'Khalaj',
  klk: 'Kono (Nigeria)',
  kll: 'Kagan Kalagan',
  klm: 'Kolom',
  kln: 'Kalenjin',
  klo: 'Kapya',
  klp: 'Kamasa',
  klq: 'Rumu',
  klr: 'Khaling',
  kls: 'Kalasha',
  klt: 'Nukna',
  klu: 'Klao',
  klv: 'Maskelynes',
  klw: 'Lindu',
  klx: 'Koluwawa',
  kly: 'Kalao',
  klz: 'Kabola',
  kma: 'Konni',
  kmb: 'Kimbundu',
  kmc: 'Southern Kam',
  kmd: 'Madukayang Kalinga',
  kme: 'Bakole',
  kmf: 'Kare (New Guinea)',
  kmg: 'K\u00e2te',
  kmh: 'Kalam',
  kmj: 'Kumarbhag Paharia',
  kmk: 'Limos Kalinga',
  kml: 'Tanudan Kalinga',
  kmm: 'Kom (India)',
  kmn: 'Awtuw',
  kmo: 'Kwoma',
  kmp: 'Gimme',
  kmq: 'Kwama',
  kmr: 'Northern Kurdish',
  kms: 'Kamasau',
  kmt: 'Kemtuik',
  kmu: 'Kanite',
  kmv: 'Karip\u00fana Creole French',
  xom: 'Komo',
  kmx: 'Waboda',
  kmy: 'Koma',
  kmz: 'Khorasani Turkish',
  kna: 'Kanakuru',
  knb: 'Lubuagan Kalinga',
  knd: 'Konda',
  kne: 'Kankanaey',
  knf: 'Mankanya',
  kni: 'Kanufi',
  knj: 'Akatek',
  knk: 'Kuranko',
  knl: 'Keninjal',
  knt: 'Katukina',
  kno: 'Kono (Sierra Leone)',
  knp: 'Kwanja',
  knq: 'Kintaq',
  knr: 'Kaningra',
  kns: 'Kensiu',
  knu: 'Kono (Guinea)',
  knv: 'Tabo',
  knx: 'Kendayan',
  kny: 'Kanyok',
  knz: 'Kalams\u00e9',
  koa: 'Konomala',
  koc: 'Kpati',
  kod: 'Kodi',
  koe: 'Kacipo-Balesi',
  kof: 'Kubi',
  kog: 'Cogui',
  koh: 'Koyo',
  koi: 'Komi-Permyak',
  kok: 'Konkani',
  kol: 'Kol (New Guinea)',
  koo: 'Konzo',
  kop: 'Waube',
  koq: 'Ikota',
  kos: 'Kosraean',
  kot: 'Lagwan',
  kou: 'Koke',
  kov: 'Kudu-Camo',
  kow: 'Kugama',
  koy: 'Koyukon',
  koz: 'Korak',
  kpa: 'Kutto',
  kpb: 'Mullu Kurumba',
  kpc: 'Curripaco',
  kpd: 'Koba',
  kpe: 'Kpelle',
  kpf: 'Komba',
  kpg: 'Kapingamarangi',
  kph: 'Kplang',
  kpi: 'Kofei',
  kpj: 'Karaj\u00e1',
  kpk: 'Kpan',
  kpl: 'Kpala',
  kpm: 'Koho',
  kpn: 'Kepkiriw\u00e1t',
  kpo: 'Ikposo',
  kpp: 'Paku Karen',
  kpq: 'Korupun-Sela',
  kpr: 'Korafe-Yegha',
  kps: 'Tehit',
  kpt: 'Karata',
  kpu: 'Kafoa',
  kpv: 'Komi-Zyrian',
  kpw: 'Kobon',
  kpx: 'Mountain Koiari',
  kpy: 'Koryak',
  kpz: 'Kupsabiny',
  kqa: 'Mum',
  kqb: 'Kovai',
  kqc: 'Doromu-Koki',
  kqd: 'Koy Sanjaq Surat',
  kqe: 'Kalagan',
  kqf: 'Kakabai',
  kqg: 'Khe',
  kqh: 'Kisankasa',
  kqi: 'Koitabu',
  kqj: 'Koromira',
  kqk: 'Kotafon Gbe',
  kql: 'Kyenele',
  kqm: 'Khisa',
  kqn: 'Kaonde',
  kqo: 'Eastern Krahn',
  kqp: 'Kimr\u00e9',
  kqq: 'Krenak',
  kqr: 'Kimaragang',
  kqs: 'Northern Kissi',
  kqt: 'Klias River Kadazan',
  kqu: 'Seroa',
  kqv: 'Okolod',
  kqw: 'Kandas',
  kqx: 'Mser',
  kqy: 'Koorete',
  kqz: 'Korana',
  kra: 'Kumhali',
  krb: 'Karkin',
  krc: 'Karachay-Balkar',
  krd: 'Kairui-Midiki',
  kre: 'Panar\u00e1',
  krf: 'Koro (Vanuatu)',
  krh: 'Kurama',
  kri: 'Krio',
  krj: 'Kinaray-a',
  krk: 'Kerek',
  krl: 'Karelian',
  krm: 'Krim',
  krn: 'Sapo',
  krp: 'Korop',
  krr: "Kru'ng",
  krs: 'Kresh',
  kru: 'Kurukh',
  krv: 'Kavet',
  krw: 'Western Krahn',
  krx: 'Karon',
  kry: 'Kryts',
  krz: 'Sota Kanum',
  ksa: 'Shuwa-Zamani',
  ksb: 'Shambala',
  ksc: 'Southern Kalinga',
  ksd: 'Tolai',
  kse: 'Kuni',
  ksf: 'Bafia',
  ksg: 'Kusaghe',
  ksi: 'Krisa',
  ksj: 'Uare',
  ksk: 'Kansa',
  ksl: 'Kumalu',
  ksm: 'Kumba',
  ksn: 'Kasiguranin',
  kso: 'Kofa',
  ksp: 'Kaba',
  ksq: 'Kwaami',
  ksr: 'Borong',
  kss: 'Southern Kisi',
  kst: 'Winy\u00e9',
  ksu: 'Khamyang',
  ksv: 'Kusu',
  ksw: "S'gaw Karen",
  ksx: 'Kedang',
  ksy: 'Kharia Thar',
  ksz: 'Kodaku',
  kta: 'Katua',
  ktb: 'Kambaata',
  ktc: 'Kholok',
  ktd: 'Kokata',
  ktf: 'Kwami',
  ktg: 'Kalkatungu',
  kth: 'Karanga',
  kti: 'North Muyu',
  ktj: 'Plapo Krumen',
  ktk: 'Kaniet',
  ktl: 'Koroshi',
  ktm: 'Kurti',
  ktn: 'Kariti\u00e2na',
  kto: 'Kuot',
  ktp: 'Kaduo',
  ktq: 'Katabaga',
  ktr: 'Kota Marudu Tinagas',
  kts: 'South Muyu',
  ktt: 'Ketum',
  ktu: 'Kituba',
  ktv: 'Eastern Katu',
  ktw: 'Kato',
  ktx: 'Kaxarar\u00ed',
  kty: 'Kango',
  ktz: "Ju\u01c0'hoan",
  kub: 'Kutep',
  kuc: 'Kwinsu',
  kud: 'Auhelawa',
  kuf: 'Western Katu',
  kug: 'Kupa',
  kuh: 'Kushi',
  kui: 'Kuik\u00faro',
  kuj: 'Kuria',
  kuk: "Kepo'",
  kul: 'Kulere',
  kum: 'Kumyk',
  kun: 'Kunama',
  kuo: 'Kumukio',
  kup: 'Kunimaipa',
  kuq: 'Karipuna',
  kus: 'Kusaal',
  kut: 'Kutenai',
  kuu: 'Upper Kuskokwim',
  kuw: 'Kpagua',
  kux: 'Kukatja',
  kuy: "Kuuku-Ya'u",
  kuz: 'Kunza',
  kva: 'Bagvalal',
  kvb: 'Kubu',
  kvc: 'Kove',
  kvd: 'Kui (Indonesia)',
  kve: 'Kalabakan',
  kvf: 'Kabalai',
  kvg: 'Kuni-Boazi',
  kvh: 'Komodo',
  kvj: 'Psikye',
  kvk: 'Korean Sign Language',
  kvl: 'Brek Karen',
  kvm: 'Kendem',
  kvn: 'Border Kuna',
  kvo: 'Dobel',
  kvp: 'Kompane',
  kvq: 'Geba Karen',
  kvr: 'Kerinci',
  kvt: 'Lahta Karen',
  kvu: 'Yinbaw Karen',
  kvv: 'Kola',
  kvw: 'Wersing',
  kvx: 'Parkari Koli',
  kvy: 'Yintale Karen',
  kvz: 'Tsakwambo',
  kwa: 'D\u00e2w',
  kwb: 'Baa',
  kwc: 'Likwala',
  kwd: 'Kwaio',
  kwe: 'Kwerba',
  kwf: "Kwara'ae",
  kwg: 'Sara Kaba Deme',
  kwh: 'Kowiai',
  kwi: 'Awa-Cuaiquer',
  kwj: 'Kwanga',
  kwk: "Kwak'wala",
  kwl: 'Kofyar',
  kwm: 'Kwambi',
  kwn: 'Kwangali',
  kwo: 'Kwomtari',
  kwp: 'Kodia',
  kwq: 'Kwak',
  kwr: 'Kwer',
  kws: 'Kwese',
  kwt: 'Kwesten',
  kwu: 'Kwakum',
  kwv: 'Sara Kaba N\u00e1\u00e0',
  kww: 'Kwinti',
  kwx: 'Khirwar',
  kwz: 'Kwadi',
  kxa: 'Kairiru',
  kxb: 'Krobu',
  kxc: 'Khonso',
  kxd: 'Brunei Malay',
  kxe: 'Kakihum',
  kxf: 'Manumanaw Karen',
  kxi: 'Keningau Murut',
  kxj: 'Kulfa',
  kxk: 'Zayein Karen',
  kxl: 'Nepali Kurux',
  kxm: 'Northern Khmer',
  kxn: 'Kanowit',
  kxo: 'Kano\u00e9',
  kxp: 'Wadiyara Koli',
  kxq: 'Sm\u00e4rky Kanum',
  kxr: 'Koro (New Guinea)',
  kxs: 'Kangjia',
  kxt: 'Koiwat',
  kxu: 'Kui (India)',
  kxv: 'Kuvi',
  kxw: 'Konai',
  kxx: 'Likuba',
  kxy: 'Kayong',
  kxz: 'Kerewo',
  kya: 'Kwaya',
  kyb: 'Butbut Kalinga',
  kyc: 'Kyaka',
  kyd: 'Karey',
  kye: 'Krache',
  kyf: 'Kouya',
  kyg: 'Keyagana',
  kyh: 'Karok',
  kyi: 'Kiput',
  kyj: 'Karao',
  kyk: 'Kamayo',
  kyl: 'Kalapuya',
  kym: 'Kpatili',
  kyn: 'Karolanos',
  kyo: 'Kelon',
  kyp: 'Kang',
  kyq: 'Kenga',
  kyr: 'Kuru\u00e1ya',
  kys: 'Baram Kayan',
  kyt: 'Kayagar',
  kyu: 'Western Kayah',
  kyv: 'Kayort',
  kyw: 'Kudmali',
  kyx: 'Rapoisi',
  kyy: 'Kambaira',
  kyz: 'Kayab\u00ed',
  kza: 'Western Karaboro',
  kzb: 'Kaibobo',
  kzc: 'Bondoukou Kulango',
  kze: 'Kosena',
  kzf: "Da'a Kaili",
  kzg: 'Kikai',
  kzh: 'Dongolawi',
  kzi: 'Kelabit',
  kzj: 'Coastal Kadazan',
  kzk: 'Kazukuru',
  kzl: 'Kayeli',
  kzm: 'Kais',
  kzn: 'Kokola',
  kzo: 'Kaningi',
  kzp: 'Kaidipang',
  kzq: 'Kaike',
  kzs: 'Sugut Dusun',
  kzt: 'Tambunan Dusun',
  kzu: 'Kayupulau',
  kzv: 'Komyandaret',
  kzw: 'Kariri',
  kzx: 'Kamarian',
  kzy: 'Kango-Sua',
  kzz: 'Kalabra',
  laa: 'Lapuyan Subanun',
  lab: 'Linear A',
  lac: 'Lacandon',
  lad: 'Ladino',
  lae: 'Pattani',
  laf: 'Lafofa',
  lag: 'Langi',
  lah: 'Lahnda',
  lai: 'Lambya',
  laj: 'Lango (Uganda)',
  lak: 'Laka',
  lal: 'Lalia',
  lam: 'Lamba',
  lan: 'Laru',
  lap: 'Kabba-Laka',
  laq: 'Qabiao',
  lar: 'Larteh',
  las: 'Gur Lama',
  lau: 'Laba',
  law: 'Lauje',
  lax: 'Tiwa',
  lay: 'Lama Bai',
  laz: 'Aribwatsa',
  lba: 'Lui',
  lbb: 'Label',
  lbc: 'Lakkia',
  lbe: 'Lak',
  lbf: 'Tinani',
  lbg: 'Laopang',
  lbi: "La'bi",
  lbj: 'Ladakhi',
  lbk: 'Central Bontoc',
  lbl: 'Libon Bikol',
  lbm: 'Lodhi',
  lbn: 'Lamet',
  lbo: 'Laven',
  lbq: 'Wampar',
  lbr: 'Northern Lorung',
  lbs: 'Libyan Sign Language',
  lbt: 'Lachi',
  lbu: 'Labu',
  lbv: 'Lavatbura-Lamusong',
  lbw: 'Tolaki',
  lbx: 'Lawangan',
  lby: 'Lamu-Lamu',
  lbz: 'Lardil',
  lcc: 'Legenyem',
  lcd: 'Lola',
  lce: 'Loncong',
  lcf: 'Lubu',
  lch: 'Luchazi',
  lcl: 'Lisela',
  lcm: 'Tungag',
  lcp: 'Western Lawa',
  lcq: 'Luhu',
  lcs: 'Lisabata-Nuniali',
  lda: 'Kla',
  ldb: 'Idun',
  ldd: 'Luri (Nigeria)',
  ldg: 'Lenyima',
  ldh: 'Lamja-Dengsa-Tola',
  ldj: 'Lemoro',
  ldk: 'Leelau',
  ldl: 'Kaan',
  ldm: 'Landoma',
  ldn: 'L\u00e1adan',
  ldo: 'Loo',
  ldp: 'Tso',
  ldq: 'Lufu',
  lea: 'Lega-Shabunda',
  leb: 'Lala-Bisa',
  lec: 'Leco',
  led: 'Lendu',
  lee: 'Ly\u00e9l\u00e9',
  lef: 'Lelemi',
  leh: 'Lenje',
  lei: 'Lemio',
  lej: 'Lengola',
  lek: 'Leipon',
  lel: 'Bashilele',
  lem: 'Nomaande',
  len: 'Honduran Lenca',
  leo: 'Leti (Cameroon)',
  lep: 'Lepcha',
  leq: 'Lembena',
  ler: 'Lenkau',
  les: 'Lese',
  let: 'Lesing-Gelimi',
  leu: 'Kara (New Guinea)',
  lev: 'Lamma',
  lew: 'Ledo Kaili',
  lex: 'Luang',
  ley: 'Lemolang',
  lfa: 'Lefa',
  lfn: 'Lingua Franca Nova',
  lga: 'Lungga',
  lgb: 'Laghu',
  lgg: 'Lugbara',
  lgh: 'Laghuu',
  lgi: 'Lengilu',
  lgk: 'Neverver',
  lgl: 'Wala',
  lgm: 'Lega-Mwenga',
  lgn: 'Opuuo',
  lgq: 'Logba',
  lgr: 'Lengo',
  lgt: 'Pahi',
  lgu: 'Longgu',
  lgz: 'Ligenza',
  lha: 'Laha (Vietnam)',
  lhh: 'Laha (Indonesia)',
  lhi: 'Lahu Shi',
  lhl: 'Lahul Lohar',
  lhn: 'Lahanan',
  lhp: 'Lhokpu',
  lhs: 'Mlahs\u00f6',
  lht: 'Lo-Toga',
  lhu: 'Lahu',
  lia: 'West-Central Limba',
  lib: 'Likum',
  lid: 'Nyindrou',
  lie: 'Likila',
  lif: 'Limbu',
  lig: 'Ligbi',
  lih: 'Lihir',
  lii: 'Lingkhim',
  lij: 'Ligurian',
  lik: 'Lika',
  lil: 'Lillooet',
  lio: 'Liki',
  lip: 'Sekpele',
  liq: 'Libido',
  lir: 'Liberian English',
  lis: 'Lisu',
  liu: 'Logorik',
  liv: 'Livonian',
  liw: 'Col',
  lix: 'Liabuku',
  liy: 'Banda-Bambari',
  liz: 'Libinza',
  lja: 'Golpa',
  lje: 'Rampi',
  lji: 'Laiyolo',
  ljl: "Li'o",
  ljp: 'Lampung Api',
  ljw: 'Yirandali',
  ljx: 'Yuru',
  lka: 'Lakalei',
  lkb: 'Kabras',
  lkc: 'Kucong',
  lkd: 'Lakond\u00ea',
  lke: 'Kenyi',
  lkh: 'Lakha',
  lki: 'Laki',
  lkj: 'Remun',
  lkl: 'Laeko-Libuat',
  lkm: 'Kalaamaya',
  lkn: 'Lakon',
  lko: 'Khayo',
  lkr: 'P\u00e4ri',
  lks: 'Kisa',
  lkt: 'Lakota',
  lku: 'Kungkari',
  lky: 'Lokoya',
  lla: 'Lala-Roba',
  llb: 'Lolo',
  llc: 'Lele (Guinea)',
  lld: 'Ladin',
  lle: 'Lele (New Guinea)',
  llf: 'Hermit',
  llg: 'Lole',
  llh: 'Lamu',
  lli: 'Teke-Laali',
  llj: 'Ladji-Ladji',
  llk: 'Lelak',
  lll: 'Lilau',
  llm: 'Lasalimu',
  lln: 'Lele (Chad)',
  llo: 'Khlor',
  llp: 'North Efate',
  llq: 'Lolak',
  lls: 'Lithuanian Sign Language',
  llu: 'Lau',
  llx: 'Lauan',
  lma: 'East Limba',
  lmb: 'Merei',
  lmc: 'Limilngan',
  lmd: 'Lumun',
  lme: 'P\u00e9v\u00e9',
  lmf: 'South Lembata',
  lmg: 'Lamogai',
  lmh: 'Lambichhong',
  lmj: 'West Lembata',
  lmk: 'Lamkang',
  lml: 'Raga',
  lmn: 'Lambadi',
  lmo: 'Lombard',
  lmp: 'Limbum',
  lmq: 'Lamatuka',
  lmr: 'Lamalera',
  lmu: 'Lamenu',
  lmv: 'Lomaiviti',
  lmw: 'Lake Miwok',
  lmx: 'Laimbue',
  lmy: 'Lamboya',
  lmz: 'Lumbee',
  lna: 'Langbashe',
  lnb: 'Mbalanhu',
  lnd: 'Lun Bawang',
  lnh: 'Lanoh',
  lni: "Daantanai'",
  lnj: 'Linngithigh',
  lnl: 'South Central Banda',
  lnm: 'Langam',
  lnn: 'Lorediakarkar',
  lno: 'Lango (Sudan)',
  lns: "Lamnso'",
  lnu: 'Longuda',
  lnw: 'Lanima',
  lnz: 'Lonzo',
  loa: 'Loloda',
  lob: 'Lobi',
  loc: 'Inonhan',
  lod: 'Berawan',
  loe: 'Saluan',
  lof: 'Logol',
  log: 'Logo',
  loh: 'Narim',
  loi: 'Lomakka',
  loj: 'Lou',
  lok: 'Loko',
  lol: 'Mongo',
  lom: 'Looma',
  lon: 'Malawi Lomwe',
  loo: 'Lombo',
  lop: 'Lopa',
  loq: 'Lobala',
  lor: 'T\u00e9\u00e9n',
  los: 'Loniu',
  lot: 'Lotuko',
  lou: 'Louisiana Creole French',
  lov: 'Lopi',
  low: 'Tampias Lobu',
  lox: 'Loun',
  loz: 'Lozi',
  lpa: 'Lelepa',
  lpe: 'Lepki',
  lpn: 'Long Phuri Naga',
  lpo: 'Lipo',
  lpx: 'Lopit',
  lra: "Rara Bakati'",
  lrc: 'Northern Luri',
  lre: 'Laurentian',
  lrg: 'Laragia',
  lri: 'Marachi',
  lrk: 'Loarki',
  lrl: 'Lari',
  lrm: 'Marama',
  lrn: 'Lorang',
  lro: 'Laro',
  ybi: 'Yamphu',
  lrt: 'Larantuka Malay',
  lrv: 'Larevat',
  lrz: 'Lemerig',
  lsa: 'Lasgerdi',
  lsd: 'Lishana Deni',
  lse: 'Lusengo',
  lsh: 'Lish',
  lsi: 'Lashi',
  lsl: 'Latvian Sign Language',
  lsm: 'Saamia',
  lso: 'Laos Sign Language',
  lsp: 'Panamanian Sign Language',
  lwu: 'Lawu',
  lss: 'Lasi',
  lst: 'Trinidad and Tobago Sign Language',
  lsy: 'Mauritian Sign Language',
  ltc: 'Middle Chinese',
  ltg: 'Latgalian',
  lti: 'Leti (Indonesia)',
  ltn: 'Latund\u00ea',
  lto: 'Olutsotso',
  lts: 'Lutachoni',
  ltu: 'Latu',
  lua: 'Luba-Kasai',
  luc: 'Aringa',
  lud: 'Ludian',
  lue: 'Luvale',
  luf: 'Laua',
  lui: 'Luise\u00f1o',
  luj: 'Luna',
  luk: 'Lunanakha',
  lul: "Olu'bo",
  lum: 'Luimbi',
  lun: 'Lunda',
  luo: 'Luo',
  lup: 'Lumbu',
  luq: 'Lucumi',
  lur: 'Laura',
  lus: 'Mizo',
  lut: 'Lushootseed',
  luu: 'Lumba-Yakkha',
  luv: 'Luwati',
  luy: 'Luhya',
  luz: 'Southern Luri',
  lva: "Maku'a",
  lvk: 'Lavukaleve',
  lvu: 'Levuka',
  lwa: 'Lwalu',
  lwe: 'Lewo Eleng',
  lwg: 'Oluwanga',
  lwh: 'White Lachi',
  lwl: 'Eastern Lawa',
  lwm: 'Laomian',
  lwo: 'Luwo',
  lws: 'Malawian Sign Language',
  lwt: 'Lewotobi',
  lww: 'Lewo',
  lya: 'Layakha',
  lyg: 'Lyngngam',
  lyn: 'Luyana',
  lzh: 'Literary Chinese',
  lzl: 'Litzlitz',
  lzn: 'Leinong Naga',
  lzz: 'Laz',
  maa: 'San Jer\u00f3nimo Tec\u00f3atl Mazatec',
  mab: 'Yutanduchi Mixtec',
  mad: 'Madurese',
  mae: 'Bo-Rukul',
  maf: 'Mafa',
  mag: 'Magahi',
  mai: 'Maithili',
  maj: 'Jalapa De D\u00edaz Mazatec',
  mak: 'Makasar',
  mam: 'Mam',
  man: 'Mandingo',
  maq: 'Chiquihuitl\u00e1n Mazatec',
  mas: 'Maasai',
  mat: 'Matlatzinca',
  mau: 'Huautla Mazatec',
  mav: 'Sater\u00e9-Maw\u00e9',
  maw: 'Mampruli',
  max: 'North Moluccan Malay',
  maz: 'Central Mazahua',
  mba: 'Higaonon',
  mbb: 'Western Bukidnon Manobo',
  mbc: 'Macushi',
  mbd: 'Dibabawon Manobo',
  mbe: 'Molale',
  mbf: 'Baba Malay',
  mbh: 'Mangseng',
  mbi: 'Ilianen Manobo',
  mbj: 'Nad\u00ebb',
  mbk: 'Malol',
  mbl: 'Maxakal\u00ed',
  mbm: 'Ombamba',
  mbn: 'Macagu\u00e1n',
  mbo: 'Mbo (Cameroon)',
  mbp: 'Wiwa',
  mbq: 'Maisin',
  mbr: 'Nukak Mak\u00fa',
  mbs: 'Sarangani Manobo',
  mbt: 'Matigsalug Manobo',
  mbu: 'Mbula-Bwazza',
  mbv: 'Mbulungish',
  mbw: 'Maring',
  mbx: 'Sepik Mari',
  mby: 'Memoni',
  mbz: 'Amoltepec Mixtec',
  mca: 'Maca',
  mcb: 'Machiguenga',
  mcc: 'Bitur',
  mcd: 'Sharanahua',
  mce: 'Itundujia Mixtec',
  mcf: 'Mats\u00e9s',
  mcg: 'Mapoyo',
  mch: 'Maquiritari',
  mci: 'Mese',
  mcj: 'Mvanip',
  mck: 'Mbunda',
  mcl: 'Macaguaje',
  mcm: 'Kristang',
  mcn: 'Masana',
  mco: 'Coatl\u00e1n Mixe',
  mcp: 'Makaa',
  mcq: 'Ese',
  mcr: 'Menya',
  mcs: 'Mambai',
  mct: 'Mengisa',
  mcu: 'Cameroon Mambila',
  mcv: 'Minanibai',
  wma: 'Mawa',
  mcx: 'Mpiemo',
  mcy: 'South Watut',
  mcz: 'Mawan',
  mda: 'Mada (Nigeria)',
  mdb: 'Morigi',
  mdc: 'Male',
  mde: 'Bura Mabang',
  mdf: 'Moksha',
  mdg: 'Massalat',
  mdh: 'Maguindanao',
  mdi: 'Mamvu',
  mdj: 'Mangbetu',
  mdk: 'Mangbutu',
  mdl: 'Maltese Sign Language',
  mdm: 'Mayogo',
  mdn: 'Mbati',
  mdp: 'Mbala',
  mdq: 'Mbole',
  mdr: 'Mandar',
  mdt: 'Mbere',
  mdv: 'Santa Luc\u00eda Monteverde Mixtec',
  mdw: 'Mbosi',
  mdx: 'Dizin',
  mdy: 'Maale',
  mdz: 'Suru\u00ed Do Par\u00e1',
  mea: 'Menka',
  meb: 'Ikobi-Mena',
  mec: 'Mara',
  med: 'Melpa',
  mee: 'Mengen',
  mef: 'Megam',
  meg: 'Mea',
  meh: 'Southwestern Tlaxiaco Mixtec',
  mei: 'Midob',
  mej: 'Meyah',
  mek: 'Mekeo',
  mel: 'Central Melanau',
  mem: 'Mangala',
  men: 'Mende',
  meo: 'Kedah Malay',
  mep: 'Miriwung',
  meq: 'Merey',
  mer: 'Meru',
  mes: 'Masmaje',
  met: 'Mato',
  meu: 'Motu',
  mev: 'Mano',
  mew: 'Maaka',
  mey: 'Hassaniya',
  mez: 'Menominee',
  mfa: 'Pattani Malay',
  mfb: 'Bangka',
  mfc: 'Mba',
  mfd: 'Mendankwe-Nkwen',
  mfe: 'Mauritian Creole',
  mho: 'Mashi',
  mfg: 'Mixifore',
  mfh: 'Matal',
  tbf: 'Mandara',
  mfj: 'Mefele',
  mfk: 'North Mofu',
  mfl: 'Putai',
  mfm: 'Marghi South',
  mfn: 'Cross River Mbembe',
  mfo: 'Mbe',
  mfp: 'Makassar Malay',
  mfq: 'Moba',
  xru: 'Marriammu',
  zmj: 'Maridjabin',
  zmm: 'Marimanindji',
  zmy: 'Mariyedi',
  mfs: 'Mexican Sign Language',
  mft: 'Mokerang',
  mfu: 'Mbwela',
  mfv: 'Mandjak',
  mfw: 'Mulaha',
  mfx: 'Melo',
  mfy: 'Mayo',
  mfz: 'Mabaan',
  mga: 'Middle Irish',
  mgb: 'Mararit',
  mgc: 'Morokodo',
  mgd: 'Moru',
  mge: 'Mango',
  mgf: 'Maklew',
  mgg: 'Mpongmpong',
  mgh: 'Makhuwa-Meetto',
  mgi: 'Jili',
  mgj: 'Abureni',
  mgk: 'Mawes',
  mgl: 'Maleu-Kilenge',
  mgm: 'Mambae',
  mgn: 'Mbangi',
  mgo: "Meta'",
  mgp: 'Eastern Magar',
  mgq: 'Malila',
  mgr: 'Mambwe-Lungu',
  mha: 'Manda',
  mgt: 'Mongol',
  mgu: 'Mailu',
  mgv: 'Matengo',
  mgw: 'Matumbi',
  mgy: 'Mbunga',
  mgz: 'Mbugwe',
  mhb: 'Mahongwe',
  mhc: 'Mocho',
  mhd: 'Mbugu',
  mhe: 'Besisi',
  mhf: 'Mamaa',
  mhg: 'Margu',
  mhi: "Ma'di",
  mhj: 'Mogholi',
  mhk: 'Mungaka',
  mhl: 'Mauwake',
  mhm: 'Makhuwa-Moniga',
  mhn: 'M\u00f2cheno',
  mhp: 'Balinese Malay',
  mhq: 'Mandan',
  mhs: 'Buru (Indonesia)',
  mht: 'Mandahuaca',
  mhu: 'Taraon',
  mhw: 'Mbukushu',
  mhx: 'Lhao Vo',
  mhy: "Ma'anyan",
  mhz: 'Austronesian Mor',
  mia: 'Miami',
  mib: 'Atatl\u00e1huca Mixtec',
  mic: "Mi'kmaq",
  mid: 'Mandaic',
  mie: 'Ocotepec Mixtec',
  mif: 'Mofu-Gudur',
  mig: 'San Miguel El Grande Mixtec',
  mih: 'Chayuco Mixtec',
  mii: 'Chigmecatitl\u00e1n Mixtec',
  mij: 'Mungbam',
  mik: 'Mikasuki',
  mil: 'Pe\u00f1oles Mixtec',
  mim: 'Alacatlatzala Mixtec',
  min: 'Minangkabau',
  mio: 'Pinotepa Nacional Mixtec',
  mip: 'Apasco-Apoala Mixtec',
  miq: 'Miskito',
  mir: 'Isthmus Mixe',
  mit: 'Southern Puebla Mixtec',
  miu: 'Cacaloxtepec Mixtec',
  miw: 'Akoye',
  mix: 'Mixtepec Mixtec',
  miy: 'Ayutla Mixtec',
  miz: 'Coatzospan Mixtec',
  mjb: 'Makalero',
  mjc: 'San Juan Colorado Mixtec',
  mjd: 'Northwest Maidu',
  mje: 'Muskum',
  mjg: 'Monguor',
  mwe: 'Mwera',
  mji: 'Kim Mun',
  mjj: 'Mawak',
  mjk: 'Matukar',
  mjl: 'Mandeali',
  mjm: 'Medebur',
  msj: 'Ma',
  mjo: 'Malankuravan',
  mjp: 'Malapandaram',
  mjq: 'Malaryan',
  mjr: 'Malavedan',
  mjs: 'Miship',
  mjt: 'Sawriya Paharia',
  mju: 'Manna-Dora',
  mjv: 'Mannan',
  mjw: 'Karbi',
  mjx: 'Mahali',
  mjy: 'Mahican',
  mjz: 'Majhi',
  mka: 'Mbre',
  mkb: 'Mal Paharia',
  mkc: 'Siliput',
  mke: 'Mawchi',
  mkf: 'Miya',
  mkg: 'Mak (China)',
  mki: 'Dhatki',
  mkj: 'Mokilese',
  mkk: 'Byep',
  mkl: 'Mokole',
  mkm: 'Moklen',
  mkn: 'Kupang Malay',
  mko: 'Mingang Doso',
  mkp: 'Moikodi',
  mkq: 'Bay Miwok',
  mkr: 'Malas',
  mks: 'Silacayoapan Mixtec',
  mkt: 'Vamale',
  mku: 'Konyanka Maninka',
  mkv: 'Mafea',
  mkx: 'Cinamiguin Manobo',
  mky: 'East Makian',
  mkz: 'Makasae',
  mla: 'Tamambo',
  mlb: 'Mbule',
  mlc: 'Caolan',
  mle: 'Manambu',
  mlf: 'Mal',
  mlh: 'Mape',
  mli: 'Malimpung',
  mlj: 'Miltu',
  mlk: 'Ilwana',
  mll: 'Malua Bay',
  mlm: 'Mulam',
  mln: 'Malango',
  mlo: 'Mlomp',
  mlp: 'Bargam',
  mlq: 'Western Maninkakan',
  mlr: 'Vame',
  mls: 'Masalit',
  mlu: "To'abaita",
  mlv: 'Mwotlap',
  mlw: 'Moloko',
  mlx: 'Malfaxal',
  mlz: 'Malaynon',
  mma: 'Mama',
  mmb: 'Momina',
  mmc: 'Michoac\u00e1n Mazahua',
  mmd: 'Maonan',
  mme: 'Mae',
  mmf: 'Mundat',
  mmg: 'North Ambrym',
  mmh: 'Mehin\u00e1ku',
  mmi: 'Musar',
  mmj: 'Majhwar',
  mmk: 'Mukha-Dora',
  mml: 'Man Met',
  mmm: 'Maii',
  mmn: 'Mamanwa',
  mmo: 'Mangga Buang',
  mmp: 'Musan',
  mmq: 'Aisi',
  mmr: 'Western Xiangxi Miao',
  mmt: 'Malalamai',
  mmu: 'Mmaala',
  mmv: 'Miriti',
  mmw: 'Emae',
  mmx: 'Madak',
  mmy: 'Migaama',
  mmz: 'Mabaale',
  mna: 'Mbula',
  mnb: 'Muna',
  mnc: 'Manchu',
  mnd: 'Mond\u00e9',
  mne: 'Naba',
  mnf: 'Mundani',
  mng: 'Eastern Mnong',
  mnh: 'Mono (Congo)',
  mni: 'Manipuri',
  mnj: 'Munji',
  mnk: 'Mandinka',
  mnl: 'Tiale',
  mnm: 'Mapena',
  mnn: 'Southern Mnong',
  mnp: 'Min Bei',
  mnq: 'Minriq',
  mnr: 'Mono (California)',
  mns: 'Mansi',
  mnt: 'Maykulan',
  mnu: 'Mer',
  mnv: 'Rennellese',
  mnw: 'Mon',
  mnx: 'Manikion',
  mny: 'Manyawa',
  mnz: 'Moni',
  moa: 'Mwan',
  moc: 'Mocov\u00ed',
  mod: 'Mobilian',
  moe: 'Montagnais',
  mog: 'Mongondow',
  moh: 'Mohawk',
  moi: 'Mboi',
  moj: 'Monzombo',
  mok: 'Morori',
  mom: 'Monimbo',
  moo: 'Monom',
  mop: 'Mopan Maya',
  moq: 'Papuan Mor',
  mos: 'Moore',
  mot: 'Bar\u00ed',
  mou: 'Mogum',
  mov: 'Mojave',
  mow: 'Moi (Congo)',
  mox: 'Molima',
  moy: 'Shekkacho',
  moz: 'Mukulu',
  mpa: 'Mpoto',
  mpb: 'Mullukmulluk',
  mpc: 'Mangarayi',
  mpd: 'Machinere',
  mpe: 'Majang',
  mpg: 'Marba',
  mph: 'Maung',
  mpi: 'Mpade',
  mpj: 'Martu Wangka',
  mpk: 'Mbara (Chad)',
  mpl: 'Middle Watut',
  mpm: 'Yosond\u00faa Mixtec',
  mpn: 'Mindiri',
  mpo: 'Miu',
  mpp: 'Migabac',
  mpq: 'Mat\u00eds',
  mpr: 'Vangunu',
  mps: 'Dadibi',
  mpt: 'Mian',
  mpu: 'Makur\u00e1p',
  mpv: 'Mungkip',
  mpw: 'Mapidian',
  mpx: 'Misima-Paneati',
  mpy: 'Mapia',
  mpz: 'Mpi',
  mqa: 'Maba',
  mqb: 'Mbuko',
  mqc: 'Mangole',
  mqe: 'Matepi',
  mqf: 'Momuna',
  mqg: 'Kota Bangun Kutai Malay',
  mqh: 'Tlazoyaltepec Mixtec',
  mqi: 'Mariri',
  mqj: 'Mamasa',
  mqk: 'Rajah Kabunsuwan Manobo',
  mql: 'Mbelime',
  mqm: 'South Marquesan',
  mqn: 'Moronene',
  mqo: 'Modole',
  mqp: 'Manipa',
  mqq: 'Minokok',
  mqr: 'Mander',
  mqs: 'West Makian',
  mqt: 'Mok',
  mqu: 'Mandari',
  mqv: 'Mosimo',
  mqw: 'Murupi',
  mqx: 'Mamuju',
  mqy: 'Manggarai',
  mqz: 'Malasanga',
  mra: 'Mlabri',
  mrb: 'Marino',
  mrc: 'Maricopa',
  mrd: 'Western Magar',
  mre: "Martha's Vineyard Sign Language",
  mrf: 'Elseng',
  mrh: 'Mara Chin',
  mrj: 'Western Mari',
  mrk: 'Hmwaveke',
  mrl: 'Mortlockese',
  mrm: 'Merlav',
  mrn: 'Cheke Holo',
  mro: 'Mru',
  mrp: 'Morouas',
  mrq: 'North Marquesan',
  mrr: 'Hill Maria',
  mrs: 'Maragus',
  mrt: 'Margi',
  mru: 'Mono (Cameroon)',
  mrv: 'Mangarevan',
  mrw: 'Maranao',
  mrx: 'Dineor',
  mry: 'Karaga Mandaya',
  mrz: 'Marind',
  msb: 'Masbatenyo',
  msc: 'Sankaran Maninka',
  msd: 'Yucatec Maya Sign Language',
  mse: 'Musey',
  msf: 'Mekwei',
  msg: 'Moraid',
  msi: 'Sabah Malay',
  msk: 'Mansaka',
  msl: 'Molof',
  msm: 'Agusan Manobo',
  msn: 'Vur\u00ebs',
  mso: 'Mombum',
  msp: 'Maritsau\u00e1',
  msq: 'Caac',
  msr: 'Mongolian Sign Language',
  mss: 'West Masela',
  msu: 'Musom',
  msv: 'Maslam',
  msw: 'Mansoanka',
  msx: 'Moresada',
  msy: 'Aruamu',
  msz: 'Momare',
  mta: 'Cotabato Manobo',
  mtb: 'Anyin Morofo',
  mtc: 'Munit',
  mtd: 'Mualang',
  mte: 'Alu',
  mtf: 'Murik (New Guinea)',
  mtg: 'Una',
  mth: 'Munggui',
  mti: 'Maiwa (New Guinea)',
  mtj: 'Moskona',
  mtk: "Mbe'",
  mtl: 'Montol',
  mtm: 'Mator',
  mtn: 'Matagalpa',
  mto: 'Totontepec Mixe',
  mtp: 'Wich\u00ed Lhamt\u00e9s Nocten',
  mtq: 'Muong',
  mtr: 'Mewari',
  mts: 'Yora',
  mtt: 'Mota',
  mtu: 'Tututepec Mixtec',
  mtv: "Asaro'o",
  mtw: 'Magahat',
  mtx: 'Tida\u00e1 Mixtec',
  mty: 'Nabi',
  mua: 'Mundang',
  mub: 'Mubi',
  muc: "Mbu'",
  mud: 'Mednyj Aleut',
  mue: 'Media Lengua',
  mug: 'Musgu',
  muh: 'M\u00fcnd\u00fc',
  mui: 'Musi',
  muj: 'Mabire',
  mul: 'Translingual',
  mum: 'Maiwala',
  muo: 'Nyong',
  mup: 'Malvi',
  muq: 'Eastern Xiangxi Miao',
  mur: 'Murle',
  mus: 'Creek',
  mut: 'Western Muria',
  muu: 'Yaaku',
  muv: 'Muthuvan',
  mux: 'Bo-Ung',
  muy: 'Muyang',
  muz: 'Mursi',
  mva: 'Manam',
  mvb: 'Mattole',
  mvd: 'Mamboru',
  mvg: 'Yucua\u00f1e Mixtec',
  mvh: 'Mire',
  mvi: 'Miyako',
  mvk: 'Mekmek',
  mvl: 'Mbara (Australia)',
  mvm: 'Muya',
  mvn: 'Minaveha',
  mvo: 'Marovo',
  mvp: 'Duri',
  mvq: 'Moere',
  mvr: 'Marau',
  mvs: 'Massep',
  mvt: 'Mpotovoro',
  mvu: 'Marfa',
  mvv: 'Tagal Murut',
  mvw: 'Machinga',
  mvx: 'Meoswar',
  mvy: 'Indus Kohistani',
  mvz: 'Mesqan',
  mwa: 'Mwatebu',
  mwb: 'Juwal',
  mwc: 'Are',
  mwf: 'Murrinh-Patha',
  mwg: 'Aiklep',
  mwh: 'Mouk-Aria',
  mwi: 'Labo',
  mwk: 'Kita Maninkakan',
  mwl: 'Mirandese',
  mwm: 'Sar',
  mwn: 'Nyamwanga',
  mwo: 'Central Maewo',
  mwp: 'Kala Lagaw Ya',
  mwq: 'M\u00fcn Chin',
  mwr: 'Marwari',
  mws: 'Mwimbi-Muthambi',
  mwt: 'Moken',
  mwu: 'Mittu',
  mwv: 'Mentawai',
  mww: 'White Hmong',
  mwz: 'Moingi',
  mxa: 'Northwest Oaxaca Mixtec',
  mxb: 'Tezoatl\u00e1n Mixtec',
  mxc: 'Manyika',
  mxd: 'Modang',
  mxe: 'Mele-Fila',
  mxf: 'Malgbe',
  mxg: 'Mbangala',
  mxh: 'Mvuba',
  mxi: 'Mozarabic',
  mxj: 'Miju',
  mxk: 'Monumbo',
  mxl: 'Maxi Gbe',
  mxm: 'Meramera',
  mxn: 'Moi (Indonesia)',
  mxo: 'Mbowe',
  mxp: 'Tlahuitoltepec Mixe',
  mxq: 'Juquila Mixe',
  mxr: 'Murik (Malaysia)',
  mxs: 'Huitepec Mixtec',
  mxt: 'Jamiltepec Mixtec',
  mxu: 'Mada (Cameroon)',
  mxv: 'Metlat\u00f3noc Mixtec',
  mxw: 'Namo',
  mxx: 'Mahou',
  mxy: 'Southeastern Nochixtl\u00e1n Mixtec',
  mxz: 'Central Masela',
  myb: 'Mbay',
  myc: 'Mayeka',
  myd: 'Maramba',
  mye: 'Myene',
  myf: 'Bambassi',
  myg: 'Manta',
  myh: 'Makah',
  myj: 'Mangayat',
  myk: 'Mamara Senoufo',
  myl: 'Moma',
  mym: "Me'en",
  myo: 'Anfillo',
  myp: 'Pirah\u00e3',
  myr: 'Muniche',
  mys: 'Mesmes',
  myu: 'Munduruk\u00fa',
  myv: 'Erzya',
  myw: 'Muyuw',
  myx: 'Masaba',
  myy: 'Macuna',
  myz: 'Classical Mandaic',
  mza: 'Santa Mar\u00eda Zacatepec Mixtec',
  mzb: 'Tumzabt',
  mzc: 'Madagascar Sign Language',
  mzd: 'Malimba',
  mze: 'Morawa',
  mzg: 'Monastic Sign Language',
  mzh: 'Wich\u00ed Lhamt\u00e9s G\u00fcisnay',
  mzi: 'Ixcatl\u00e1n Mazatec',
  mzj: 'Manya',
  mzk: 'Nigeria Mambila',
  mzl: 'Mazatl\u00e1n Mixe',
  mzm: 'Mumuye',
  mzn: 'Mazanderani',
  mzo: 'Matipuhy',
  mzp: 'Movima',
  mzq: 'Mori Atas',
  mzr: 'Mar\u00fabo',
  mzs: 'Macanese',
  mzt: 'Mintil',
  mzu: 'Inapang',
  mzv: 'Manza',
  mzw: 'Deg',
  mzx: 'Mawayana',
  mzy: 'Mozambican Sign Language',
  mzz: 'Maiadomu',
  naa: 'Namla',
  nab: 'Nambikwara',
  nac: 'Narak',
  nae: "Naka'ela",
  naf: 'Nabak',
  nag: 'Naga Pidgin',
  nah: 'Nahuatl',
  naj: 'Nalu',
  nak: 'Nakanai',
  nal: 'Nalik',
  nam: "Ngan'gityemerri",
  nan: 'Min Nan',
  nao: 'Naaba',
  nap: 'Neapolitan',
  naq: 'Nama',
  nar: 'Iguta',
  nas: 'Nasioi',
  nat: 'Hungworo',
  naw: 'Nawuri',
  nax: 'Nakwi',
  nay: 'Ngarrindjeri',
  naz: 'Coatepec Nahuatl',
  nba: 'Nyemba',
  nbb: 'Ndoe',
  nbc: 'Chang',
  nbd: 'Ngbinda',
  nbe: 'Konyak Naga',
  nbg: 'Nagarchal',
  nbh: 'Ngamo',
  nbi: 'Mao Naga',
  nbj: 'Ngarinman',
  nbk: 'Nake',
  nbm: "Ngbaka Ma'bo",
  nbo: 'Nkukoli',
  nbp: 'Nnam',
  nbq: 'Nggem',
  nbr: 'Numana-Nunku-Gbantu-Numbu',
  nbs: 'Namibian Sign Language',
  nbt: 'Na',
  nbu: 'Rongmei Naga',
  nbv: 'Ngamambo',
  nbw: 'Southern Ngbandi',
  nby: 'Ningera',
  nca: 'Iyo',
  ncb: 'Central Nicobarese',
  ncc: 'Ponam',
  ncd: 'Nachering',
  nce: 'Yale',
  ncf: 'Notsi',
  ncg: "Nisga'a",
  nch: 'Central Huasteca Nahuatl',
  nci: 'Classical Nahuatl',
  ncj: 'Northern Puebla Nahuatl',
  nck: 'Nakara',
  ncl: 'Michoac\u00e1n Nahuatl',
  ncm: 'Nambo',
  ncn: 'Nauna',
  nco: 'Sibe',
  ncr: 'Ncane',
  ncs: 'Nicaraguan Sign Language',
  nct: 'Chothe Naga',
  ncu: 'Chumburung',
  ncx: 'Central Puebla Nahuatl',
  ncz: 'Natchez',
  nda: 'Ndasa',
  ndb: 'Kenswei Nsei',
  ndc: 'Ndau',
  ndd: 'Nde-Nsele-Nta',
  ndf: 'Nadruvian',
  ndg: 'Ndengereko',
  ndh: 'Ndali',
  ndi: 'Chamba Leko',
  ndj: 'Ndamba',
  ndk: 'Ndaka',
  ndl: 'Ndolo',
  ndm: 'Ndam',
  ndn: 'Ngundi',
  ndp: 'Ndo',
  ndq: 'Ndombe',
  ndr: 'Ndoola',
  nds: 'Low German',
  ndt: 'Ndunga',
  ndu: 'Dugun',
  ndv: 'Ndut',
  ndw: 'Ndobo',
  ndx: 'Nduga',
  ndy: 'Lutos',
  ndz: 'Ndogo',
  nea: "Eastern Ngad'a",
  neb: 'Toura',
  nec: 'Nedebang',
  ned: 'Nde-Gbite',
  nee: 'Kumak',
  nef: 'Nefamese',
  neg: 'Negidal',
  nsn: 'Nehan',
  nei: 'Neo-Hittite',
  nej: 'Neko',
  nek: 'Neku',
  nem: 'Nemi',
  nen: 'Nengone',
  neo: 'N\u00e1-Meo',
  neq: 'North Central Mixe',
  ner: 'Yahadian',
  nes: 'Bhoti Kinnauri',
  net: 'Nete',
  neu: 'Neo',
  nev: 'Nyaheun',
  new: 'Newari',
  nex: 'Neme',
  ney: 'Neyo',
  nez: 'Nez Perce',
  nfa: 'Dhao',
  nfd: 'Ahwai',
  nfl: 'Aiwoo',
  nfr: 'Nafaanra',
  nfu: 'Mfumte',
  nga: 'Ngbaka',
  ngb: 'Northern Ngbandi',
  ngc: 'Lingombe',
  ngd: 'Bagandou',
  nge: 'Ngemba',
  ngg: 'Ngbaka Manza',
  ngh: 'N\u01c0uu',
  ngi: 'Ngizim',
  ngj: 'Ngie',
  ngk: 'Ngalkbun',
  ngl: 'Lomwe',
  ngm: "Ngatik Men's Creole",
  ngn: 'Ngwo',
  ngp: 'Ngulu',
  ngq: 'Ngoreme',
  ngr: 'Nagu',
  ngs: 'Gvoko',
  ngt: 'Ngeq',
  ngu: 'Guerrero Nahuatl',
  ngv: 'Nagumi',
  ngw: 'Ngwaba',
  ngx: 'Nggwahyi',
  ngy: 'Tibea',
  ngz: 'Ngungwel',
  nha: 'Nhanda',
  nhb: 'Beng',
  nhc: 'Tabasco Nahuatl',
  nhd: 'Chirip\u00e1',
  nhe: 'Eastern Huasteca Nahuatl',
  nhf: 'Nhuwala',
  nhg: 'Tetelcingo Nahuatl',
  nhh: 'Nahari',
  nhi: 'Zacatl\u00e1n-Ahuacatl\u00e1n-Tepetzintla Nahuatl',
  nhk: 'Cosoleacaque Nahuatl',
  nhm: 'Morelos Nahuatl',
  nhn: 'Central Nahuatl',
  nho: 'Takuu',
  nhp: 'Pajapan Nahuatl',
  nhq: 'Huaxcaleca Nahuatl',
  nhr: 'Naro',
  nht: 'Ometepec Nahuatl',
  nhu: 'Noone',
  nhv: 'Temascaltepec Nahuatl',
  nhw: 'Western Huasteca Nahuatl',
  nhx: 'Mecayapan Nahuatl',
  nhy: 'Northern Oaxaca Nahuatl',
  nhz: 'Santa Mar\u00eda La Alta Nahuatl',
  nia: 'Nias',
  nib: 'Nakame',
  nid: 'Ngandi',
  nie: 'Niellim',
  nif: 'Nek',
  nig: 'Ngalakan',
  nih: 'Nyiha',
  nii: 'Nii',
  nij: 'Ngaju',
  nik: 'Southern Nicobarese',
  nil: 'Nila',
  nim: 'Nilamba',
  nin: 'Ninzo',
  nio: 'Nganasan',
  niq: 'Nandi',
  nir: 'Nimboran',
  nis: 'Nimi',
  nit: 'Southeastern Kolami',
  niu: 'Niuean',
  niv: 'Nivkh',
  niw: 'Nimo',
  nix: 'Hema',
  niy: 'Ngiti',
  niz: 'Ningil',
  nja: 'Nzanyi',
  njb: 'Nocte Naga',
  njd: 'Ndonde Hamba',
  njh: 'Lotha Naga',
  nji: 'Gudanji',
  njj: 'Njen',
  njl: 'Njalgulgule',
  njm: 'Angami',
  njn: 'Liangmai Naga',
  njo: 'Ao',
  njr: 'Njerep',
  njs: 'Nisa',
  njt: 'Ndyuka-Trio Pidgin',
  nju: 'Ngadjunmaya',
  njx: 'Kunyi',
  njy: 'Njyem',
  yso: 'Nisi',
  nka: 'Nkoya',
  nkb: 'Khoibu Naga',
  nkc: 'Nkongho',
  nkd: 'Koireng',
  nke: 'Duke',
  nkf: 'Inpui Naga',
  nkg: 'Nekgini',
  nkh: 'Khezha Naga',
  nki: 'Thangal Naga',
  nkj: 'Nakai',
  nkk: 'Nokuku',
  nkm: 'Namat',
  nkn: 'Nkangala',
  nko: 'Nkonya',
  nkp: 'Niuatoputapu',
  nkq: 'Nkami',
  nkr: 'Nukuoro',
  nks: 'North Asmat',
  nkt: 'Nyika',
  nku: 'Bouna Kulango',
  nkw: 'Nkutu',
  nkx: 'Nkoroo',
  nkz: 'Nkari',
  nla: 'Ngombale',
  nlc: 'Nalca',
  nle: 'East Nyala',
  nli: 'Grangali',
  nlj: 'Nyali',
  nlk: 'Ninia Yali',
  nll: 'Nihali',
  nlm: 'Mankiyali',
  nlo: 'Ngul',
  nlq: 'Lao Naga',
  nlu: 'Nchumbulu',
  nlv: 'Orizaba Nahuatl',
  nlw: 'Walangama',
  nlx: 'Nahali',
  nly: 'Nyamal',
  nlz: 'Nal\u00f6go',
  nma: 'Maram Naga',
  nmb: 'Big Nambas',
  nmc: 'Ngam',
  nmd: 'Ndumu',
  nme: 'Mzieme Naga',
  nmf: 'Tangkhul Naga',
  nmg: 'Kwasio',
  nmh: 'Monsang Naga',
  nmi: 'Nyam',
  nmj: 'Bangando-Ngombe',
  nmk: 'Namakura',
  nml: 'Ndemli',
  nmm: 'Manangba',
  nmn: '\u01c3X\u00f3\u00f5',
  nmo: 'Moyon Naga',
  nmp: 'Nimanbur',
  nmq: 'Nambya',
  nmr: 'Nimbari',
  nms: 'Letemboi',
  nmt: 'Namonuito',
  nmu: 'Northeast Maidu',
  nmv: 'Ngamini',
  nmw: 'Nimoa',
  nmy: 'Namuyi',
  nmz: 'Nawdm',
  nna: 'Nyangumarta',
  nnb: 'Nande',
  nnc: 'Nancere',
  nnd: 'West Ambae',
  nne: 'Ngandyera',
  nnf: 'Ngaing',
  nng: 'Maring Naga',
  nnh: 'Ngiemboon',
  nni: 'North Nuaulu',
  nnj: 'Nyangatom',
  nnk: 'Nankina',
  nnl: 'Northern Rengma Naga',
  nnm: 'Namia',
  nnn: 'Ngete',
  nnp: 'Wancho',
  nnq: 'Ngindo',
  nnr: 'Narungga',
  nns: 'Ningye',
  nnt: 'Nanticoke',
  nnu: 'Dwang',
  nnv: 'Nukunu',
  nnw: 'Southern Nuni',
  nnx: 'Ngong',
  nny: 'Nyangga',
  nnz: "Nda'nda'",
  noa: 'Woun Meu',
  noc: 'Nuk',
  nod: 'Northern Thai',
  noe: 'Nimadi',
  nof: 'Nomane',
  nog: 'Nogai',
  noh: 'Nomu',
  noi: 'Noiri',
  noj: 'Nonuya',
  nok: 'Nooksack',
  nol: 'Nomlaki',
  nom: 'Nocam\u00e1n',
  non: 'Old Norse',
  nop: 'Numanggang',
  noq: 'Ngongo',
  nos: 'Eastern Nisu',
  not: 'Nomatsiguenga',
  nou: 'Ewage-Notu',
  nov: 'Novial',
  now: 'Nyambo',
  noy: 'Noy',
  noz: 'Nayi',
  npa: 'Nar Phu',
  npb: 'Nupbikha',
  nph: 'Phom',
  npl: 'Southeastern Puebla Nahuatl',
  npn: 'Mondropolon',
  npo: 'Pochuri Naga',
  nps: 'Nipsan',
  npu: 'Puimei Naga',
  npy: 'Napu',
  nqg: 'Ede Nago',
  nqk: 'Kura Ede Nago',
  nqm: 'Ndom',
  nqn: 'Nen',
  nqo: "N'Ko",
  nqq: 'Kyan-Karyaw Naga',
  nra: 'Ngom',
  nrb: 'Nara',
  nrc: 'Noric',
  nre: 'Southern Rengma Naga',
  nrf: 'Norman',
  nrg: 'Narango',
  nri: 'Chokri Naga',
  nrk: 'Ngarla',
  nrl: 'Ngarluma',
  nrm: 'Narom',
  nrn: 'Norn',
  nrp: 'North Picene',
  nrr: 'Norra',
  nrt: 'Northern Kalapuya',
  nru: 'Narua',
  nrx: 'Ngurmbur',
  nrz: 'Lala',
  nsa: 'Sangtam Naga',
  nsc: 'Nshi',
  nsd: 'Southern Nisu',
  nse: 'Nsenga',
  nsg: 'Ngasa',
  nsh: 'Ngoshie',
  nsi: 'Nigerian Sign Language',
  nsk: 'Naskapi',
  nsl: 'Norwegian Sign Language',
  nsm: 'Sema',
  nso: 'Northern Sotho',
  nsp: 'Nepalese Sign Language',
  nsq: 'Northern Sierra Miwok',
  nsr: 'Maritime Sign Language',
  nss: 'Nali',
  nst: 'Tangsa',
  nsu: 'Sierra Negra Nahuatl',
  nsv: 'Southwestern Nisu',
  nsw: 'Navut',
  nsx: 'Nsongo',
  nsy: 'Nasal',
  nsz: 'Nisenan',
  ntd: 'Northern Tidong',
  nte: 'Nathembo',
  ntg: 'Ngantangarra',
  nti: 'Natioro',
  ntj: 'Ngaanyatjarra',
  ntk: 'Ikoma',
  ntm: 'Nateni',
  nto: 'Ntomba',
  ntp: 'Northern Tepehuan',
  ntr: 'Delo',
  nts: 'Natagaimas',
  ntu: 'Nat\u00fcgu',
  ntw: 'Nottoway',
  ntx: 'Somra',
  nty: 'Mantsi',
  nua: 'Yuaga',
  nuc: 'Nukuini',
  nue: 'Ngundu',
  nuf: 'Nusu',
  nug: 'Nungali',
  nuh: 'Ndunda',
  nui: 'Ngumbi',
  nuj: 'Nyole (Uganda)',
  nuk: 'Nootka',
  nul: 'Nusa Laut',
  num: "Niuafo'ou",
  nut: 'Nung',
  nuo: 'Ngu\u00f4n',
  nup: 'Nupe',
  nuq: 'Nukumanu',
  nur: 'Nuguria',
  nus: 'Nuer',
  nuu: 'Ngbundu',
  nuv: 'Northern Nuni',
  nuw: 'Nguluwan',
  nux: 'Mehek',
  nuy: 'Nunggubuyu',
  nuz: 'Tlamacazapa Nahuatl',
  nvh: 'Nasarian',
  nvm: 'Namiae',
  nvo: 'Nyokon',
  nwa: 'Nawathinehena',
  nwb: 'Nyabwa',
  nwc: 'Classical Newari',
  nwe: 'Ngwe',
  nwi: 'Southwest Tanna',
  nwm: 'Nyamusa-Molo',
  nwo: 'Nauo',
  nwr: 'Nawaru',
  nwx: 'Middle Newar',
  nwy: 'Nottoway-Meherrin',
  nxa: 'Nauete',
  nxd: 'Longandu',
  nxe: 'Nage',
  nxg: 'Ngadha',
  nxi: 'Nindi',
  nxl: 'South Nuaulu',
  nxm: 'Numidian',
  nxn: 'Ngawun',
  nxo: 'Ndambomo',
  nxq: 'Naxi',
  nxr: 'Ninggerum',
  nxu: 'Narau',
  nxx: 'Nafri',
  nyb: 'Nyangbo',
  nyc: 'Nyanga-li',
  nyd: 'Nyole (Kenya)',
  nye: 'Nyengo',
  nyf: 'Giryama',
  nyg: 'Nyindu',
  nyh: 'Nyigina',
  nyi: 'Nyimang',
  nyj: 'Nyanga (Congo)',
  nyk: 'Nyaneka',
  nyl: 'Nyeu',
  nym: 'Nyamwezi',
  nyn: 'Nyankole',
  nyo: 'Nyoro',
  nyp: "Nyang'i",
  nyr: 'Shinyiha',
  nys: 'Nyunga',
  nyt: 'Nyawaygi',
  nyu: 'Nyungwe',
  nyv: 'Nyulnyul',
  nyw: 'Nyaw',
  nyx: 'Nganyaywana',
  nyy: 'Nyakyusa',
  nza: 'Tigon Mbembe',
  nzb: 'Njebi',
  nzd: 'Nzadi',
  nzi: 'Nzima',
  nzk: 'Nzakara',
  nzm: 'Zeme Naga',
  nzs: 'New Zealand Sign Language',
  nzu: 'Teke-Nzikou',
  nzy: 'Nzakambay',
  nzz: 'Nanga Dama Dogon',
  oaa: 'Orok',
  oac: 'Oroch',
  oav: 'Old Avar',
  obi: 'Obispe\u00f1o',
  obk: 'Southern Bontoc',
  obl: 'Oblo',
  obm: 'Moabite',
  obo: 'Obo Manobo',
  obr: 'Old Burmese',
  obt: 'Old Breton',
  obu: 'Obulom',
  oca: 'Ocaina',
  och: 'Old Chinese',
  oco: 'Old Cornish',
  ocu: 'Tlahuica',
  oda: 'Odut',
  odk: 'Od',
  odt: 'Old Dutch',
  odu: 'Odual',
  ofo: 'Ofo',
  ofs: 'Old Frisian',
  ofu: 'Efutop',
  ogb: 'Ogbia',
  ogc: 'Ogbah',
  oge: 'Old Georgian',
  ogg: 'Ogbogolo',
  ogo: 'Khana',
  ogu: 'Ogbronuagum',
  oht: 'Old Hittite',
  ohu: 'Old Hungarian',
  oia: 'Oirata',
  oin: 'Inebu One',
  ojb: 'Northwestern Ojibwa',
  ojc: 'Central Ojibwa',
  ojg: 'Eastern Ojibwa',
  ojp: 'Old Japanese',
  ojs: 'Severn Ojibwa',
  ojv: 'Ontong Java',
  ojw: 'Western Ojibwa',
  oka: 'Okanagan',
  okb: 'Okobo',
  okd: 'Okodia',
  oke: 'Okpe (Southwestern Edo)',
  okg: 'Kok-Paponk',
  okh: 'Koresh-e Rostam',
  oki: 'Okiek',
  okj: 'Oko-Juwoi',
  okk: 'Kwamtim One',
  okl: 'Old Kentish Sign Language',
  okm: 'Middle Korean',
  okn: 'Oki-No-Erabu',
  oko: 'Old Korean',
  okr: 'Kirike',
  oks: 'Oko-Eni-Osayen',
  oku: 'Oku',
  okx: 'Okpe (Northwestern Edo)',
  old: 'Mochi',
  ole: 'Olekha',
  olm: 'Oloma',
  olo: 'Livvi',
  olr: 'Olrat',
  olt: 'Old Lithuanian',
  olu: 'Kuvale',
  oma: 'Omaha-Ponca',
  omb: 'Omba',
  omc: 'Mochica',
  omi: 'Omi',
  omk: 'Omok',
  oml: 'Ombo',
  omn: 'Minoan',
  omo: 'Utarmbung',
  omp: 'Old Manipuri',
  omr: 'Old Marathi',
  omt: 'Omotik',
  omu: 'Omurano',
  omw: 'South Tairora',
  omx: 'Old Mon',
  ona: "Selk'nam",
  onb: 'Lingao',
  one: 'Oneida',
  ong: 'Olo',
  oni: 'Onin',
  onj: 'Onjob',
  onk: 'Kabore One',
  onn: 'Onobasulu',
  ono: 'Onondaga',
  onp: 'Sartang',
  onr: 'Northern One',
  ons: 'Ono',
  ont: 'Ontenu',
  onu: 'Unua',
  onw: 'Old Nubian',
  onx: 'Onin Based Pidgin',
  ood: "O'odham",
  oog: 'Ong',
  oon: '\u00d6nge',
  oor: 'Oorlams',
  oos: 'Old Ossetic',
  opa: 'Okpamheri',
  opk: 'Kopkaka',
  opm: 'Oksapmin',
  opo: 'Opao',
  opt: 'Opata',
  opy: 'Ofay\u00e9',
  ora: 'Oroha',
  ore: 'Orej\u00f3n',
  org: 'Oring',
  orh: 'Oroqen',
  oro: 'Orokolo',
  orr: 'Oruma',
  ort: 'Adivasi Oriya',
  oru: 'Ormuri',
  orv: 'Old East Slavic',
  orw: 'Oro Win',
  orx: 'Oro',
  orz: 'Ormu',
  osa: 'Osage',
  osc: 'Oscan',
  osi: 'Osing',
  oso: 'Ososo',
  osp: 'Old Spanish',
  ost: 'Osatu',
  osu: 'Southern One',
  osx: 'Old Saxon',
  ota: 'Ottoman Turkish',
  otd: 'Ot Danum',
  ote: 'Mezquital Otomi',
  oti: 'Oti',
  otk: 'Old Turkic',
  otl: 'Tilapa Otomi',
  otm: 'Eastern Highland Otomi',
  otn: 'Tenango Otomi',
  otq: 'Quer\u00e9taro Otomi',
  otr: 'Otoro',
  ots: 'Estado de M\u00e9xico Otomi',
  ott: 'Temoaya Otomi',
  otu: 'Otuke',
  otw: 'Ottawa',
  otx: 'Texcatepec Otomi',
  oty: 'Old Tamil',
  otz: 'Ixtenco Otomi',
  oua: 'Tagargrent',
  oub: 'Glio-Oubi',
  oue: 'Oune',
  oui: 'Old Uyghur',
  oum: 'Ouma',
  ovd: 'Elfdalian',
  owi: 'Owiniga',
  owl: 'Old Welsh',
  oyb: 'Oy',
  oyd: 'Oyda',
  oym: 'Wayampi',
  oyy: "Oya'oya",
  ozm: 'Koonzime',
  pab: 'Pareci',
  pac: 'Pacoh',
  pad: 'Paumar\u00ed',
  pae: 'Pagibete',
  paf: 'Paranaw\u00e1t',
  pag: 'Pangasinan',
  pah: 'Tenharim',
  pai: 'Pe',
  pak: 'Parakan\u00e3',
  pal: 'Middle Persian',
  pam: 'Kapampangan',
  pao: 'Northern Paiute',
  pap: 'Papiamentu',
  paq: 'Parya',
  par: 'Panamint',
  pas: 'Papasena',
  pat: 'Papitalai',
  pau: 'Palauan',
  pav: "Wari'",
  paw: 'Pawnee',
  pax: 'Pankarar\u00e9',
  pay: 'Pech',
  paz: 'Pankarar\u00fa',
  pbb: 'P\u00e1ez',
  pbc: 'Patamona',
  pbe: 'Mezontla Popoloca',
  pbf: 'Coyotepec Popoloca',
  pbg: 'Paraujano',
  pbh: 'Panare',
  pbi: 'Podoko',
  pbl: 'Mak (Nigeria)',
  pbm: 'Puebla Mazatec',
  pbn: 'Kpasam',
  pbo: 'Papel',
  pbp: 'Badyara',
  pbr: 'Pangwa',
  pbs: 'Central Pame',
  pbv: 'Pnar',
  pby: 'Pyu',
  pca: 'Santa In\u00e9s Ahuatempan Popoloca',
  pcb: 'Pear',
  pcc: 'Bouyei',
  pcd: 'Picard',
  pce: 'Ruching Palaung',
  pcf: 'Paliyan',
  pcg: 'Paniya',
  pch: 'Pardhan',
  pci: 'Duruwa',
  pcj: 'Parenga',
  pck: 'Paite',
  pcl: 'Pardhi',
  pcm: 'Nigerian Pidgin',
  pcn: 'Piti',
  pcp: 'Pacahuara',
  pcw: 'Pyapun',
  pda: 'Anam',
  pdc: 'Pennsylvania German',
  pdi: 'Pa Di',
  pdn: 'Fedan',
  pdo: 'Padoe',
  pdt: 'Plautdietsch',
  pdu: 'Kayan',
  pea: 'Peranakan Indonesian',
  peb: 'Eastern Pomo',
  ped: 'Mala (New Guinea)',
  pee: 'Taje',
  pef: 'Northeastern Pomo',
  peg: 'Pengo',
  peh: 'Bonan',
  pei: 'Chichimeca-Jonaz',
  pej: 'Northern Pomo',
  pek: 'Penchal',
  pel: 'Pekal',
  pem: 'Phende',
  peo: 'Old Persian',
  pep: 'Kunja',
  peq: 'Southern Pomo',
  pev: 'P\u00e9mono',
  pex: 'Petats',
  pey: 'Petjo',
  pez: 'Eastern Penan',
  pfa: 'P\u00e1\u00e1fang',
  pfe: 'Peere',
  pga: 'Juba Arabic',
  pgd: 'Gandhari',
  pgg: 'Pangwali',
  pgi: 'Pagi',
  pgk: 'Rerep',
  pgl: 'Primitive Irish',
  pgn: 'Paelignian',
  pgs: 'Pangseng',
  pgu: 'Pagu',
  pgz: 'Papua New Guinean Sign Language',
  pha: 'Pa-Hng',
  phd: 'Phudagi',
  phg: 'Phuong',
  phh: 'Phukha',
  phk: 'Phake',
  phl: 'Phalura',
  phm: 'Phimbi',
  phn: 'Phoenician',
  pho: 'Phunoi',
  phq: "Phana'",
  phr: 'Pahari-Potwari',
  pht: 'Phu Thai',
  phu: 'Phuan',
  phv: 'Pahlavani',
  phw: 'Phangduwali',
  pia: 'Pima Bajo',
  pie: 'Piro',
  pic: 'Pinji',
  pid: 'Piaroa',
  pif: 'Pingelapese',
  pig: 'Pisabo',
  pih: 'Pitcairn-Norfolk',
  pii: 'Pini',
  pij: 'Pijao',
  pil: 'Yom',
  pim: 'Powhatan',
  pin: 'Piame',
  pio: 'Piapoco',
  pip: 'Pero',
  pir: 'Piratapuyo',
  pis: 'Pijin',
  pit: 'Pitta-Pitta',
  piu: 'Pintupi-Luritja',
  piv: 'Pileni',
  piw: 'Pimbwe',
  pix: 'Piu',
  piy: 'Piya-Kwonci',
  piz: 'Pije',
  pjt: 'Pitjantjatjara',
  pka: 'Ardhamagadhi Prakrit',
  pkb: 'Kipfokomo',
  pkc: 'Baekje',
  pkg: 'Pak-Tong',
  pkh: 'Pankhu',
  pkn: 'Pakanha',
  pko: 'P\u00f6koot',
  pkp: 'Pukapukan',
  pkr: 'Attapady Kurumba',
  pks: 'Pakistan Sign Language',
  pkt: 'Maleng',
  pku: 'Paku',
  pla: 'Miani',
  plb: 'Polonombauk',
  plc: 'Central Palawano',
  ple: "Palu'e",
  plg: 'Pilag\u00e1',
  plh: 'Paulohi',
  plj: 'Polci',
  plk: 'Kohistani Shina',
  pll: 'Shwe Palaung',
  pln: 'Palenquero',
  plo: 'Oluta Popoluca',
  plp: 'Palpa',
  plq: 'Palaic',
  plr: 'Palaka Senoufo',
  pls: 'San Marcos Tlalcoyalco Popoloca',
  plu: 'Palikur',
  plv: 'Southwest Palawano',
  plw: "Brooke's Point Palawano",
  ply: 'Bolyu',
  plz: 'Paluan',
  pma: 'Paama',
  pmb: 'Pambia',
  pmd: 'Pallanganmiddang',
  pme: 'Pwaamei',
  pmf: 'Pamona',
  pmh: 'Maharastri Prakrit',
  pmi: 'Northern Pumi',
  pmj: 'Southern Pumi',
  pmk: 'Pamlico',
  pml: 'Sabir',
  pmm: 'Pol',
  pmn: 'Pam',
  pmo: 'Pom',
  pmq: 'Northern Pame',
  pmr: 'Paynamar',
  pms: 'Piedmontese',
  pmt: 'Tuamotuan',
  pmu: 'Mirpur Panjabi',
  pmw: 'Plains Miwok',
  pmx: 'Poumei Naga',
  pmy: 'Papuan Malay',
  pmz: 'Southern Pame',
  pna: 'Punan Bah-Biau',
  pnb: 'Western Panjabi',
  pnc: 'Pannei',
  pne: 'Western Penan',
  png: 'Pongu',
  pnh: 'Penrhyn',
  pni: 'Aoheng',
  pnj: 'Pinjarup',
  pnk: 'Paunaca',
  pnl: 'Paleni',
  pnm: 'Punan Batu',
  pnn: 'Pinai-Hagahai',
  pno: 'Panobo',
  pnp: 'Pancana',
  pnq: 'Pana (West Africa)',
  pnr: 'Panim',
  pns: 'Ponosakan',
  pnt: 'Pontic Greek',
  pnu: 'Jiongnai Bunu',
  pnv: 'Pinigura',
  pnw: 'Panyjima',
  pnx: 'Phong-Kniang',
  pny: 'Pinyin',
  pnz: 'Pana (Central Africa)',
  poc: 'Poqomam',
  poe: 'San Juan Atzingo Popoloca',
  pof: 'Poke',
  pog: 'Potigu\u00e1ra',
  poh: "Poqomchi'",
  poi: 'Highland Popoluca',
  pok: 'Pokang\u00e1',
  pom: 'Southeastern Pomo',
  pon: 'Pohnpeian',
  poo: 'Central Pomo',
  pop: 'Pwapwa',
  poq: 'Texistepec Popoluca',
  pos: 'Sayula Popoluca',
  pot: 'Potawatomi',
  pov: 'Guinea-Bissau Creole',
  pow: 'San Felipe Otlaltepec Popoloca',
  pox: 'Polabian',
  poy: 'Pogolo',
  ppa: 'Pao',
  ppe: 'Papi',
  ppi: 'Paipai',
  ppk: 'Uma',
  ppl: 'Pipil',
  ppm: 'Papuma',
  ppn: 'Papapana',
  suq: 'Suri',
  ppp: 'Pelende',
  ppq: 'Pei',
  pps: 'San Lu\u00eds Temalacayuca Popoloca',
  ppt: 'Pa',
  ppu: 'Papora',
  pqa: "Pa'a",
  pqm: 'Malecite-Passamaquoddy',
  prc: 'Parachi',
  pre: 'Principense',
  prf: 'Paranan',
  prg: 'Old Prussian',
  prh: 'Porohanon',
  pri: 'Paic\u00ee',
  prk: 'Parauk',
  prl: 'Peruvian Sign Language',
  prm: 'Kibiri',
  prn: 'Prasuni',
  pro: 'Old Occitan',
  prq: 'Ash\u00e9ninka Peren\u00e9',
  prr: 'Puri',
  prt: 'Phai',
  pru: 'Puragi',
  prw: 'Parawen',
  prx: 'Purik',
  prz: 'Providencia Sign Language',
  psa: 'Asue Awyu',
  psc: 'Persian Sign Language',
  psd: 'Plains Indian Sign Language',
  pse: 'Central Malay',
  psg: 'Penang Sign Language',
  psh: 'Southwest Pashayi',
  psi: 'Southeast Pashayi',
  psl: 'Puerto Rican Sign Language',
  psm: 'Pauserna',
  psn: 'Panasuan',
  pso: 'Polish Sign Language',
  psp: 'Philippine Sign Language',
  psr: 'Portuguese Sign Language',
  pss: 'Kaulong',
  psw: 'Port Sandwich',
  psy: 'Piscataway',
  pta: 'Pai Tavytera',
  pth: 'Patax\u00f3 H\u00e3-Ha-H\u00e3e',
  pti: 'Pintiini',
  ptn: 'Patani',
  pto: "Zo'\u00e9",
  ptp: 'Patep',
  ptq: 'Pattapu',
  ptr: 'Piamatsina',
  ptt: 'Enrekang',
  ptu: 'Bambam',
  ptv: 'Port Vato',
  ptw: 'Pentlatch',
  pty: 'Pathiya',
  pua: 'Purepecha',
  pub: 'Purum',
  puc: 'Punan Merap',
  pud: 'Punan Aput',
  pue: 'Puelche',
  puf: 'Punan Merah',
  pug: 'Phuie',
  pui: 'Puinave',
  puj: 'Punan Tubu',
  pum: 'Puma',
  puo: 'Puoc',
  pup: 'Pulabu',
  puq: 'Puquina',
  pur: 'Purubor\u00e1',
  put: 'Putoh',
  puu: 'Punu',
  puw: 'Puluwat',
  pux: 'Puare',
  puy: 'Purisime\u00f1o',
  pwa: 'Pawaia',
  pwb: 'Panawa',
  pwg: 'Gapapaiwa',
  pwi: 'Patwin',
  pwm: 'Molbog',
  pwn: 'Paiwan',
  pwo: 'Western Pwo',
  pwr: 'Powari',
  pww: 'Northern Pwo',
  pxm: 'Quetzaltepec Mixe',
  pye: 'Pye Krumen',
  pym: 'Fyam',
  pyn: 'Poyan\u00e1wa',
  pys: 'Paraguayan Sign Language',
  pyu: 'Puyuma',
  pyx: 'Tircul',
  pyy: 'Pyen',
  pzn: 'Para Naga',
  qua: 'Quapaw',
  quc: "K'iche'",
  qui: 'Quileute',
  qum: 'Sipakapense',
  qun: 'Quinault',
  quq: 'Quinqui',
  quv: 'Sacapulteco',
  qvy: 'Queyu',
  qwc: 'Classical Quechua',
  'trk-mmk': 'Mamluk-Kipchak',
  qwt: 'Kwalhioqua-Tlatskanai',
  qxs: 'Southern Qiang',
  qya: 'Quenya',
  qyp: 'Quiripi',
  raa: 'Dungmali',
  rab: 'Chamling',
  rac: 'Rasawa',
  rad: 'Rade',
  raf: 'Western Meohang',
  rag: 'Logooli',
  rah: 'Rabha',
  rai: 'Ramoaaina',
  raj: 'Rajasthani',
  tcy: 'Tulu',
  ral: 'Ralte',
  xra: 'Krah\u00f4',
  ran: 'Riantana',
  rao: 'Rao',
  rap: 'Rapa Nui',
  raq: 'Saam',
  rar: 'Rarotongan',
  ras: 'Tegali',
  rat: 'Razajerdi',
  rau: 'Raute',
  rav: 'Sampang',
  raw: 'Rawang',
  rax: 'Rang',
  ray: 'Rapa',
  raz: 'Rahambuu',
  rbb: 'Rumai Palaung',
  rbk: 'Northern Bontoc',
  rbl: 'Miraya Bikol',
  rcf: 'R\u00e9union Creole French',
  rdb: 'Rudbari',
  rea: 'Rerau',
  reb: 'Rembong',
  ree: 'Rejang Kayan',
  reg: 'Kara (Tanzania)',
  rei: 'Reli',
  rej: 'Rejang',
  rel: 'Rendille',
  ren: 'Rengao',
  rer: 'Rer Bare',
  res: 'Reshe',
  ret: 'Retta',
  rey: 'Reyesano',
  rga: 'Roria',
  rge: 'Romani Greek',
  rgk: 'Rangkas',
  rgn: 'Romagnol',
  rgr: 'Res\u00edgaro',
  rgs: 'Southern Roglai',
  rgu: 'Ringgou',
  rhg: 'Rohingya',
  rhp: 'Yahang',
  ril: 'Riang',
  rif: 'Tarifit',
  rim: 'Nyaturu',
  rin: 'Nungu',
  rir: 'Ribun',
  rit: 'Ritarungo',
  riu: 'Riung',
  rjg: 'Rajong',
  rji: 'Raji',
  rjs: 'Rajbanshi',
  rka: 'Kraol',
  rkb: 'Rikbaktsa',
  rkh: 'Rakahanga-Manihiki',
  rki: 'Rakhine',
  rkm: 'Marka',
  rkt: 'Kamta',
  rkw: 'Arakwal',
  rma: 'Rama',
  rmb: 'Rembarunga',
  rmc: 'Carpathian Romani',
  rmd: 'Traveller Danish',
  rme: 'Angloromani',
  rmf: 'Kalo Finnish Romani',
  rmg: 'Traveller Norwegian',
  rmh: 'Murkim',
  rmi: 'Lomavren',
  rmk: 'Romkun',
  rml: 'Baltic Romani',
  rmm: 'Roma',
  rmn: 'Balkan Romani',
  rmo: 'Sinte Romani',
  rmp: 'Rempi',
  rmq: 'Cal\u00f3',
  rms: 'Romanian Sign Language',
  rmt: 'Domari',
  rmu: 'Tavringer Romani',
  rmv: 'Romanova',
  rmw: 'Welsh Romani',
  rmx: 'Romam',
  rmy: 'Vlax Romani',
  rmz: 'Marma',
  rnd: 'Ruund',
  rng: 'Ronga',
  rnl: 'Ranglong',
  rnn: 'Roon',
  rnp: 'Rongpo',
  rnw: 'Rungwa',
  rob: "Tae'",
  roc: 'Cacgia Roglai',
  rod: 'Rogo',
  roe: 'Ronji',
  rof: 'Rombo',
  rog: 'Northern Roglai',
  rol: 'Romblomanon',
  roo: 'Rotokas',
  ror: 'Rongga',
  rou: 'Runga',
  row: 'Dela-Oenale',
  rpn: 'Repanbitip',
  rpt: 'Rapting',
  rri: 'Ririo',
  rro: 'Roro',
  rrt: 'Arritinngithigh',
  rsb: 'Romano-Serbian',
  rsl: 'Russian Sign Language',
  rsm: 'Miriwoong Sign Language',
  rtc: 'Rungtu',
  rth: 'Ratahan',
  rtm: 'Rotuman',
  rtw: 'Rathawi',
  rub: 'Gungu',
  ruc: 'Ruuli',
  rue: 'Rusyn',
  ruf: 'Luguru',
  rug: 'Roviana',
  ruh: 'Ruga',
  rui: 'Rufiji',
  ruk: 'Che',
  ruo: 'Istro-Romanian',
  rup: 'Aromanian',
  ruq: 'Megleno-Romanian',
  rut: 'Rutul',
  ruu: 'Lanas Lobu',
  ruy: 'Mala (Nigeria)',
  ruz: 'Ruma',
  rwa: 'Rawo',
  rwk: 'Rwa',
  rwm: 'Amba',
  rwo: 'Rawa',
  rxd: 'Ngardi',
  rxw: 'Karuwali',
  ryn: 'Northern Amami-Oshima',
  rys: 'Yaeyama',
  ryu: 'Okinawan',
  rzh: 'Razihi',
  saa: 'Saba',
  sab: 'Buglere',
  sac: 'Fox',
  sad: 'Sandawe',
  sae: 'Saban\u00ea',
  saf: 'Safaliba',
  sah: 'Yakut',
  saj: 'Sahu',
  sak: 'Sake',
  sam: 'Samaritan Aramaic',
  sao: 'Sause',
  saq: 'Samburu',
  sar: 'Saraveca',
  sas: 'Sasak',
  sat: 'Santali',
  sau: 'Saleman',
  sav: 'Saafi-Saafi',
  saw: 'Sawi',
  sax: 'Sa',
  say: 'Saya',
  saz: 'Saurashtra',
  sba: 'Ngambay',
  sbb: 'Simbo',
  sbc: "Gele'",
  sbd: 'Southern Samo',
  sbe: 'Saliba (New Guinea)',
  sbf: 'Shabo',
  sbg: 'Seget',
  sbh: 'Sori-Harengan',
  sbi: 'Seti',
  sbj: 'Surbakhal',
  sbk: 'Safwa',
  sbl: 'Botolan Sambal',
  sbm: 'Sagala',
  sbn: 'Sindhi Bhil',
  sbo: 'Sab\u00fcm',
  sbp: 'Sangu',
  sbq: 'Sileibi',
  sbr: 'Sembakung Murut',
  sbs: 'Subiya',
  sbt: 'Kimki',
  sbu: 'Stod Bhoti',
  sbv: 'Sabine',
  sbx: 'Seberuang',
  sby: 'Soli',
  sbz: 'Sara Kaba',
  scb: 'Chut',
  sce: 'Dongxiang',
  scf: 'San Miguel Creole French',
  sch: 'Sakachep',
  sci: 'Sri Lankan Creole Malay',
  sck: 'Sadri',
  scl: 'Shina',
  scn: 'Sicilian',
  sco: 'Scots',
  scp: 'Yolmo',
  scq: "Sa'och",
  scs: 'North Slavey',
  scu: 'Shumcho',
  scv: 'Sheni',
  scw: 'Sha',
  scx: 'Sicel',
  sda: "Toraja-Sa'dan",
  sdb: 'Shabak',
  sdc: 'Sassarese',
  sde: 'Surubu',
  sdf: 'Sarli',
  sdg: 'Savi',
  sdh: 'Southern Kurdish',
  sdj: 'Suundi',
  sdk: 'Sos Kundi',
  sdl: 'Saudi Arabian Sign Language',
  sdm: 'Semandang',
  sdn: 'Gallurese',
  sdo: 'Bukar-Sadung Bidayuh',
  sdp: 'Sherdukpen',
  sdr: 'Oraon Sadri',
  sds: 'Sened',
  sdu: 'Sarudu',
  sdx: 'Sibu Melanau',
  sea: 'Semai',
  sec: 'Sechelt',
  sed: 'Sedang',
  see: 'Seneca',
  sef: 'Cebaara Senoufo',
  seg: 'Segeju',
  seh: 'Sena',
  sei: 'Seri',
  sej: 'Sene',
  sek: 'Sekani',
  sel: 'Selkup',
  sen: 'Nanerig\u00e9 S\u00e9noufo',
  seo: 'Suarmin',
  sep: 'S\u00ecc\u00ect\u00e9 S\u00e9noufo',
  seq: 'Senara S\u00e9noufo',
  'azc-ktn': 'Kitanemuk',
  ses: 'Koyraboro Senni',
  set: 'Sentani',
  seu: 'Serui-Laut',
  sev: 'Nyarafolo Senoufo',
  sew: 'Sewa Bay',
  sey: 'Secoya',
  sez: 'Senthang Chin',
  sfb: 'French Belgian Sign Language',
  sfm: 'Small Flowery Miao',
  sfs: 'South African Sign Language',
  sfw: 'Sehwi',
  sga: 'Old Irish',
  sgb: 'Mag-Anchi Ayta',
  sgc: 'Kipsigis',
  sgd: 'Surigaonon',
  sge: 'Segai',
  sgg: 'Swiss-German Sign Language',
  sgh: 'Shughni',
  sgi: 'Suga',
  sgk: 'Sangkong',
  sgm: 'Singa',
  sgp: 'Singpho',
  sgr: 'Sangisari',
  sgs: 'Samogitian',
  sgt: 'Brokpake',
  sgu: 'Salas',
  sgw: 'Sebat Bet Gurage',
  sgx: 'Sierra Leone Sign Language',
  sgy: 'Sanglechi',
  sgz: 'Sursurunga',
  sha: 'Shall-Zwall',
  shb: 'Ninam',
  shc: 'Sonde',
  shd: 'Kundal Shahi',
  she: 'Sheko',
  shg: 'Shua',
  shh: 'Shoshone',
  shi: 'Tashelhit',
  shj: 'Shatt',
  shk: 'Shilluk',
  shl: 'Shendu',
  shm: 'Shahrudi',
  shn: 'Shan',
  sho: 'Shanga',
  shp: 'Shipibo-Conibo',
  shq: 'Sala',
  shr: 'Shi',
  shs: 'Shuswap',
  sht: 'Shasta',
  shu: 'Chadian Arabic',
  shv: 'Shehri',
  shw: 'Shwai',
  shx: 'She',
  shy: 'Tachawit',
  shz: 'Syenara Senoufo',
  sia: 'Akkala Sami',
  sib: 'Sebop',
  sid: 'Sidamo',
  sie: 'Simaa',
  sif: 'Siamou',
  sig: 'Paasaal',
  sih: 'Zire',
  sii: 'Shom Peng',
  sij: 'Numbami',
  sik: 'Sikiana',
  sil: 'Tumulung Sisaala',
  sim: 'Seim',
  sip: 'Sikkimese',
  siq: 'Sonia',
  sir: 'Siri',
  sis: 'Siuslaw',
  siu: 'Sinagen',
  siv: 'Sumariup',
  siw: 'Siwai',
  six: 'Sumau',
  siy: 'Sivandi',
  siz: 'Siwi',
  sja: 'Epena',
  sjb: 'Sajau Basap',
  sjd: 'Kildin Sami',
  sje: 'Pite Sami',
  sjg: 'Assangori',
  sjk: 'Kemi Sami',
  sjl: 'Miji',
  sjm: 'Mapun',
  sjn: 'Sindarin',
  sjo: 'Xibe',
  sjp: 'Surjapuri',
  sjr: 'Siar-Lak',
  sjs: 'Senhaja De Srair',
  sjt: 'Ter Sami',
  sju: 'Ume Sami',
  sjw: 'Shawnee',
  ska: 'Skagit',
  skb: 'Saek',
  skc: 'Ma Manda',
  skd: 'Southern Sierra Miwok',
  skj: 'Seke',
  skf: 'Sakirabi\u00e1',
  skh: 'Sikule',
  ski: 'Sika',
  skk: 'Sok',
  skm: 'Sakam',
  skn: 'Kolibugan Subanon',
  sko: 'Seko Tengah',
  skp: 'Sekapan',
  skq: 'Sininkere',
  skr: 'Seraiki',
  sks: 'Maia',
  skt: 'Sakata',
  sku: 'Sakao',
  skw: 'Skepi Creole Dutch',
  skx: 'Seko Padang',
  sky: 'Sikaiana',
  skz: 'Sekar',
  slc: 'Saliba (Colombia)',
  sld: 'Sissala',
  sle: 'Sholaga',
  slf: 'Swiss-Italian Sign Language',
  slg: 'Selungai Murut',
  slh: 'Southern Puget Sound Salish',
  slj: 'Salum\u00e1',
  sll: 'Salt-Yui',
  slm: 'Pangutaran Sama',
  sln: 'Salinan',
  slp: 'Lamaholot',
  slq: 'Salchuq',
  slr: 'Salar',
  sls: 'Singapore Sign Language',
  slt: 'Sila',
  slu: 'Selaru',
  slw: 'Sialum',
  slx: 'Salampasu',
  sly: 'Selayar',
  slz: "Ma'ya",
  sma: 'Southern Sami',
  smb: 'Simbari',
  smc: 'Som',
  smd: 'Sama',
  smf: 'Auwe',
  smg: 'Simbali',
  smh: 'Samei',
  smj: 'Lule Sami',
  smk: 'Bolinao',
  sml: 'Central Sama',
  smm: 'Musasa',
  smn: 'Inari Sami',
  smp: 'Samaritan Hebrew',
  smq: 'Samo',
  smr: 'Simeulue',
  sms: 'Skolt Sami',
  smt: 'Simte',
  smu: 'Somray',
  smv: 'Samvedi',
  smw: 'Sumbawa',
  smx: 'Samba',
  smz: 'Simeku',
  snb: 'Sebuyau',
  snc: 'Sinaugoro',
  sne: 'Bau Bidayuh',
  snf: 'Noon',
  sng: 'Sanga (Congo)',
  sni: 'Sensi',
  snj: 'Riverain Sango',
  snk: 'Soninke',
  snl: 'Sangil',
  snm: "Southern Ma'di",
  snn: 'Siona',
  sno: 'Snohomish',
  snp: 'Siane',
  snq: 'Chango',
  snr: 'Sihan',
  sns: 'Nahavaq',
  snu: 'Senggi',
  snv: "Sa'ban",
  snw: 'Selee',
  snx: 'Sam',
  sny: 'Saniyo-Hiyewe',
  snz: 'Sinsauru',
  soa: 'Thai Song',
  sob: 'Sobei',
  soc: 'Soko',
  sod: 'Songoora',
  soe: 'Songomeno',
  soh: 'Aka (Sudan)',
  soi: 'Sonha',
  sok: 'Sokoro',
  sol: 'Solos',
  soo: 'Songo',
  sop: 'Songe',
  soq: 'Kanasi',
  sor: 'Somrai',
  sos: 'Seenku',
  sou: 'Southern Thai',
  sov: 'Sonsorolese',
  sow: 'Sowanda',
  sox: 'Swo',
  soy: 'Miyobe',
  soz: 'Temi',
  spb: 'Sepa (Indonesia)',
  spc: 'Sap\u00e9',
  spd: 'Saep',
  spe: 'Sepa (New Guinea)',
  spg: 'Sian',
  spi: 'Saponi',
  spk: 'Sengo',
  spl: 'Selepet',
  spm: 'Sepen',
  spn: 'Sanapan\u00e1',
  spo: 'Spokane',
  spp: 'Supyire',
  spr: 'Saparua',
  sps: 'Saposa',
  spt: 'Spiti Bhoti',
  spu: 'Sapuan',
  spv: 'Sambalpuri',
  spx: 'South Picene',
  spy: 'Sabaot',
  sqa: 'Shama-Sambuga',
  sqh: 'Shau',
  sqk: 'Albanian Sign Language',
  sqm: 'Suma',
  sqn: 'Susquehannock',
  sqo: 'Sorkhei',
  sqq: 'Sou',
  sqr: 'Siculo-Arabic',
  sqs: 'Sri Lankan Sign Language',
  sqt: 'Soqotri',
  squ: 'Squamish',
  sra: 'Saruga',
  srb: 'Sora',
  srf: 'Nafi',
  srg: 'Sulod',
  srh: 'Sarikoli',
  sri: 'Siriano',
  srk: 'Serudung Murut',
  srl: 'Isirawa',
  srm: 'Saramaccan',
  srn: 'Sranan Tongo',
  srq: 'Sirion\u00f3',
  srr: 'Serer',
  srs: 'Sarcee',
  srt: 'Sauri',
  sru: 'Suru\u00ed',
  srv: 'Waray Sorsogon',
  srw: 'Serua',
  srx: 'Sirmauri',
  sry: 'Sera',
  srz: 'Shahmirzadi',
  ssb: 'Southern Sama',
  ssc: 'Suba-Simbiti',
  ssd: 'Siroi',
  sse: 'Balangingi',
  ssf: 'Thao',
  ssg: 'Seimat',
  ssh: 'Shihhi Arabic',
  ssi: 'Sansi',
  ssj: 'Sausi',
  ssk: 'Sunam',
  ssl: 'Western Sisaala',
  ssm: 'Semnam',
  ssn: 'Waata',
  sso: 'Sissano',
  ssp: 'Spanish Sign Language',
  ssq: "So'a",
  ssr: 'Swiss-French Sign Language',
  sss: 'S\u00f4',
  sst: 'Sinasina',
  ssu: 'Susuami',
  ssv: 'Shark Bay',
  ssx: 'Samberigi',
  ssy: 'Saho',
  ssz: 'Sengseng',
  stb: 'Northern Subanen',
  std: 'Sentinelese',
  ste: 'Liana-Seti',
  stf: 'Seta',
  stg: 'Trieng',
  sth: 'Shelta',
  sti: 'Bulo Stieng',
  stj: 'Matya Samo',
  stk: 'Arammba',
  stm: 'Setaman',
  stn: 'Owa',
  sto: 'Stoney',
  stp: 'Southeastern Tepehuan',
  stq: 'Saterland Frisian',
  str: 'Saanich',
  sts: 'Shumashti',
  stt: 'Budeh Stieng',
  stu: 'Samtao',
  stv: "Silt'e",
  stw: 'Satawalese',
  sty: 'Siberian Tatar',
  sua: 'Sulka',
  sub: 'Suku',
  suc: 'Western Subanon',
  sue: 'Suena',
  sug: 'Suganga',
  sui: 'Suki',
  suk: 'Sukuma',
  sur: 'Mwaghavul',
  sus: 'Susu',
  sut: 'Subtiaba',
  suv: 'Sulung',
  suw: 'Sumbwa',
  sux: 'Sumerian',
  suy: 'Suy\u00e1',
  suz: 'Sunwar',
  sva: 'Svan',
  svb: 'Ulau-Suain',
  svc: 'Vincentian Creole English',
  sve: 'Serili',
  svk: 'Slovakian Sign Language',
  svm: 'Molise Croatian',
  svs: 'Savosavo',
  svx: 'Skalvian',
  swb: 'Maore Comorian',
  swf: 'Sere',
  swg: 'Swabian',
  swi: 'Sui',
  swj: 'Sira',
  swl: 'Swedish Sign Language',
  swm: 'Samosa',
  swn: 'Sokna',
  swo: 'Shanenawa',
  swp: 'Suau',
  swq: 'Sharwa',
  swr: 'Saweru',
  sws: 'Seluwasan',
  swt: 'Sawila',
  swu: 'Suwawa',
  sww: 'Sowa',
  swx: 'Suruah\u00e1',
  swy: 'Sarua',
  sxb: 'Suba',
  sxc: 'Sicanian',
  sxe: 'Sighu',
  sxg: 'Shixing',
  sxk: 'Southern Kalapuya',
  sxl: 'Selonian',
  sxm: 'Samre',
  sxn: 'Sangir',
  sxo: 'Sorothaptic',
  sxr: 'Saaroa',
  sxs: 'Sasaru',
  sxw: 'Saxwe Gbe',
  sya: 'Siang',
  syb: 'Central Subanen',
  syc: 'Classical Syriac',
  syi: 'Seki',
  syk: 'Sukur',
  syl: 'Sylheti',
  sym: 'Maya Samo',
  syn: 'Senaya',
  syo: 'Suoy',
  sys: 'Sinyar',
  syw: 'Kagate',
  syx: 'Shamay',
  syy: 'Al-Sayyid Bedouin Sign Language',
  sza: 'Semelai',
  szb: 'Ngalum',
  szc: 'Semaq Beri',
  szd: 'Seru',
  sze: 'Seze',
  szg: 'Sengele',
  szl: 'Silesian',
  szn: 'Sula',
  szp: 'Suabo',
  szv: 'Isubu',
  szw: 'Sawai',
  taa: 'Lower Tanana',
  tab: 'Tabasaran',
  tac: 'Lowland Tarahumara',
  tad: 'Tause',
  tae: 'Tariana',
  taf: 'Tapirap\u00e9',
  tag: 'Tagoi',
  taj: 'Eastern Tamang',
  tak: 'Tala',
  tal: 'Tal',
  tan: 'Tangale',
  tao: 'Yami',
  tap: 'Taabwa',
  taq: 'Tamasheq',
  tar: 'Central Tarahumara',
  tas: 'Tay Boi',
  tau: 'Upper Tanana',
  tav: 'Tatuyo',
  tax: 'Tamki',
  tay: 'Atayal',
  taz: 'Tocho',
  tba: 'Aikan\u00e3',
  tbb: 'Tapeba',
  tbc: 'Takia',
  tbd: 'Kaki Ae',
  tbe: 'Tanimbili',
  tbg: 'North Tairora',
  tbh: 'Thurawal',
  tbi: 'Gaam',
  tbj: 'Tiang',
  tbk: 'Calamian Tagbanwa',
  tbl: 'Tboli',
  tbm: 'Tagbu',
  tbn: 'Barro Negro Tunebo',
  tbo: 'Tawala',
  tbp: 'Taworta',
  tbr: 'Tumtum',
  tbs: 'Tanguat',
  tbt: 'Kitembo',
  tbu: 'Tubar',
  tbv: 'Tobo',
  tbw: 'Tagbanwa',
  tbx: 'Kapin',
  tby: 'Tabaru',
  tbz: 'Ditammari',
  tca: 'Ticuna',
  tcb: 'Tanacross',
  tcc: 'Datooga',
  tcd: 'Tafi',
  tce: 'Southern Tutchone',
  tcf: 'Malinaltepec Tlapanec',
  tcg: 'Tamagario',
  tch: 'Turks And Caicos Creole English',
  tci: 'W\u00e1ra',
  tck: 'Tchitchege',
  tcl: 'Taman (Burma)',
  tcm: 'Tanahmerah',
  tco: 'Taungyo',
  tcp: 'Tawr Chin',
  tcq: 'Kaiy',
  tcs: 'Torres Strait Creole',
  tct: "T'en",
  tcu: 'Southeastern Tarahumara',
  tcw: 'Tecpatl\u00e1n Totonac',
  tcx: 'Toda',
  tcz: 'Thado Chin',
  tda: 'Tagdal',
  tdb: 'Panchpargania',
  tdc: 'Ember\u00e1-Tad\u00f3',
  tdd: 'Tai N\u00fca',
  tde: 'Tiranige Diga Dogon',
  tdf: 'Talieng',
  tdg: 'Western Tamang',
  tdh: 'Thulung',
  tdi: 'Tomadino',
  tdj: 'Tajio',
  tdk: 'Tambas',
  tdl: 'Sur',
  tdm: 'Taruma',
  tdn: 'Tondano',
  tdo: 'Teme',
  tdq: 'Tita',
  tdr: 'Todrah',
  tds: 'Doutai',
  tdt: 'Tetun Dili',
  tdu: 'Tempasuk Dusun',
  tdv: 'Toro',
  tdy: 'Tadyawan',
  tea: 'Temiar',
  teb: 'Tetete',
  tec: 'Terik',
  ted: 'Tepo Krumen',
  tee: 'Huehuetla Tepehua',
  tef: 'Teressa',
  teg: 'Teke-Tege',
  'sai-ptg': 'Patag\u00f3n',
  tek: 'Ibali Teke',
  tem: 'Temne',
  ten: 'Tama (Colombia)',
  teo: 'Ateso',
  tep: 'Tepecano',
  teq: 'Temein',
  ter: 'Tereno',
  tes: 'Tengger',
  tet: 'Tetum',
  teu: 'Soo',
  tev: 'Teor',
  tew: 'Tewa',
  tex: 'Tennet',
  tey: 'Tulishi',
  tez: 'Tetserret',
  tfi: 'Tofin Gbe',
  tfn: "Dena'ina",
  tfo: 'Tefaro',
  tfr: 'Teribe',
  tft: 'Ternate',
  tga: 'Sagalla',
  tgb: 'Tobilung',
  tgc: 'Tigak',
  tgd: 'Ciwogai',
  tge: 'Eastern Gorkha Tamang',
  tgf: 'Chali',
  tgh: 'Tobagonian Creole English',
  tgi: 'Lawunuia',
  tgn: 'Tandaganon',
  tgo: 'Sudest',
  tgp: 'Tangoa',
  tgq: 'Tring',
  tgr: 'Tareng',
  tgs: 'Nume',
  tgt: 'Central Tagbanwa',
  tgu: 'Tanggu',
  tgv: 'Tingui-Boto',
  tgw: 'Tagwana Senoufo',
  tgx: 'Tagish',
  tgy: 'Togoyo',
  thc: 'Tai Hang Tong',
  thd: 'Kuuk Thaayorre',
  the: 'Chitwania Tharu',
  thf: 'Thangmi',
  thh: 'Northern Tarahumara',
  thi: 'Tai Long',
  thk: 'Tharaka',
  thl: 'Dangaura Tharu',
  thm: 'Thavung',
  thn: 'Thachanadan',
  thp: 'Thompson',
  thq: 'Kochila Tharu',
  thr: 'Rana Tharu',
  ths: 'Thakali',
  tht: 'Tahltan',
  thu: 'Thuri',
  thv: 'Tahaggart Tamahaq',
  thy: 'Tha',
  thz: 'Tayart Tamajeq',
  tia: 'Tidikelt Tamazight',
  tic: 'Tira',
  tif: 'Tifal',
  tig: 'Tigre',
  tih: 'Timugon Murut',
  tii: 'Tiene',
  tij: 'Tilung',
  tik: 'Tikar',
  til: 'Tillamook',
  tim: 'Timbe',
  tin: 'Tindi',
  tio: 'Teop',
  tip: 'Trimuris',
  tiq: 'Ti\u00e9fo',
  tis: 'Masadiit Itneg',
  tit: 'Tinigua',
  tiu: 'Adasen',
  tiv: 'Tivi',
  tiw: 'Tiwi',
  tix: 'Southern Tiwa',
  tiy: 'Tiruray',
  tiz: 'Tai Hongjin',
  tja: 'Tajuasohn',
  tjg: 'Tunjung',
  tji: 'Northern Tujia',
  tjm: 'Timucua',
  tjn: 'Tonjon',
  tjo: 'Temacine Tamazight',
  tjs: 'Southern Tujia',
  tju: 'Tjurruru',
  tjw: 'Chaap Wuurong',
  tka: 'Truk\u00e1',
  tkb: 'Buksa',
  tkd: 'Tukudede',
  tke: 'Takwane',
  tkf: 'Tukumanf\u00e9d',
  tkl: 'Tokelauan',
  tkm: 'Takelma',
  tkn: 'Toku-No-Shima',
  tkp: 'Tikopia',
  tkq: 'Tee',
  tkr: 'Tsakhur',
  tks: 'Takestani',
  tkt: 'Kathoriya Tharu',
  tku: 'Upper Necaxa Totonac',
  tkv: 'Mur Pano',
  tkw: 'Teanu',
  tkx: 'Tangko',
  tkz: 'Takua',
  tla: 'Southwestern Tepehuan',
  tlb: 'Tobelo',
  tlc: 'Misantla Totonac',
  tld: 'Talaud',
  tlf: 'Telefol',
  tlg: 'Tofanma',
  tlh: 'Klingon',
  tli: 'Tlingit',
  tlj: 'Talinga-Bwisi',
  tlk: 'Taloki',
  tll: 'Tetela',
  tlm: 'Tolomako',
  tln: "Talondo'",
  tlo: 'Talodi',
  tlp: 'Filomena Mata-Coahuitl\u00e1n Totonac',
  tlq: 'Tai Loi',
  tlr: 'Talise',
  tls: 'Tambotalo',
  tlt: 'Teluti',
  tlu: 'Tulehu',
  tlv: 'Taliabu',
  tlx: 'Khehek',
  tly: 'Talysh',
  tma: 'Tama (Chad)',
  tmb: 'Avava',
  tmc: 'Tumak',
  tmd: 'Haruai',
  tme: 'Trememb\u00e9',
  tmf: 'Toba-Maskoy',
  tmg: 'Ternate\u00f1o',
  tmh: 'Tuareg',
  tmi: 'Tutuba',
  tmj: 'Samarokena',
  tmk: 'Northwestern Tamang',
  tml: 'Tamnim Citak',
  tmm: 'Tai Thanh',
  tmn: 'Taman (Indonesia)',
  tmo: 'Temoq',
  tmp: 'Tai M\u00e8ne',
  tmq: 'Tumleo',
  tms: 'Tima',
  tmt: 'Tasmate',
  tmu: 'Iau',
  tmv: 'Motembo',
  tmy: 'Tami',
  tmz: 'Tamanaku',
  tna: 'Tacana',
  tnb: 'Western Tunebo',
  tnc: 'Tanimuca-Retuar\u00e3',
  tnd: 'Angosturas Tunebo',
  tne: 'Tinoc Kallahan',
  tng: 'Tobanga',
  tnh: 'Maiani',
  tni: 'Tandia',
  tnk: 'Kwamera',
  tnl: 'Lenakel',
  tnm: 'Tabla',
  tnn: 'North Tanna',
  tno: 'Toromono',
  tnp: 'Whitesands',
  tnq: 'Ta\u00edno',
  tnr: 'Bedik',
  tns: 'Tenis',
  tnt: 'Tontemboan',
  tnu: 'Tay Khang',
  tnv: 'Tangchangya',
  tnw: 'Tonsawang',
  tnx: 'Tanema',
  tny: 'Tongwe',
  tnz: "Ten'edn",
  tob: 'Toba',
  toc: 'Coyutla Totonac',
  tod: 'Toma',
  tof: 'Gizrra',
  tog: 'Tonga (Malawi)',
  toh: 'Tonga (Mozambique)',
  toi: 'Tonga (Zambia)',
  toj: 'Tojolabal',
  tol: 'Tolowa',
  tom: 'Tombulu',
  too: 'Xicotepec De Ju\u00e1rez Totonac',
  top: 'Papantla Totonac',
  toq: 'Toposa',
  tor: 'Togbo-Vara Banda',
  tos: 'Highland Totonac',
  tou: 'Tho',
  tov: 'Upper Taromi',
  tow: 'Jemez',
  tox: 'Tobian',
  toy: 'Topoiyo',
  toz: 'To',
  tpa: 'Taupota',
  tpc: "Azoy\u00fa Me'phaa",
  tpe: 'Tippera',
  tpf: 'Tarpia',
  tpg: 'Kula',
  tpi: 'Tok Pisin',
  tpj: 'Tapiet\u00e9',
  tpk: 'Tupinikin',
  tpl: "Tlacoapa Me'phaa",
  tpm: 'Tampulma',
  tpn: 'Tupinamb\u00e1',
  tpo: 'Tai Pao',
  tpp: 'Pisaflores Tepehua',
  tpq: 'Tukpa',
  tpr: 'Tupar\u00ed',
  tpt: 'Tlachichilco Tepehua',
  tpu: 'Tampuan',
  tpv: 'Tanapag',
  tpw: 'Old Tupi',
  tpx: "Acatepec Me'phaa",
  tpy: 'Trumai',
  tpz: 'Tinputz',
  tqb: 'Temb\u00e9',
  tql: 'Lehali',
  tqm: 'Turumsa',
  tqn: 'Tenino',
  tqo: 'Toaripi',
  tqp: 'Tomoip',
  tqq: 'Tunni',
  tqr: 'Torona',
  tqt: 'Western Totonac',
  tqu: 'Touo',
  tqw: 'Tonkawa',
  tra: 'Tirahi',
  trb: 'Terebu',
  trc: 'Copala Triqui',
  trd: 'Turi',
  tre: 'East Tarangan',
  trf: 'Trinidadian Creole English',
  trg: 'Lish\u00e1n Did\u00e1n',
  trh: 'Turaka',
  tri: 'Tri\u00f3',
  trj: 'Toram',
  trl: 'Traveller Scottish',
  trm: 'Tregami',
  trn: 'Trinitario',
  tro: 'Tarao',
  trp: 'Kokborok',
  trq: 'San Mart\u00edn Itunyoso Triqui',
  trr: 'Taushiro',
  trs: 'Chicahuaxtla Triqui',
  trt: 'Tunggare',
  tru: 'Turoyo',
  trv: 'Taroko',
  trw: 'Torwali',
  trx: 'Tringgus',
  try: 'Turung',
  trz: 'Tor\u00e1',
  tsa: 'Tsaangi',
  tsb: 'Tsamai',
  tsc: 'Tswa',
  tsd: 'Tsakonian',
  tse: 'Tunisian Sign Language',
  tsf: 'Southwestern Tamang',
  tsg: 'Tausug',
  tsh: 'Tsuvan',
  tsi: 'Tsimshian',
  tsj: 'Tshangla',
  tsl: "Ts'\u00fcn-Lao",
  tsm: 'Turkish Sign Language',
  tsp: 'Northern Toussian',
  tsq: 'Thai Sign Language',
  tsr: 'Akei',
  tss: 'Taiwan Sign Language',
  tsu: 'Tsou',
  tsv: 'Tsogo',
  tsw: 'Tsishingini',
  tsx: 'Mubami',
  tsy: 'Tebul Sign Language',
  tta: 'Tutelo',
  ttb: 'Gaa',
  ttc: 'Tektiteko',
  ttd: 'Tauade',
  tte: 'Bwanabwana',
  ttf: 'Tuotomb',
  ttg: 'Tutong',
  tth: "Upper Ta'oih",
  tti: 'Tobati',
  ttj: 'Tooro',
  ttk: 'Totoro',
  ttl: 'Totela',
  ttm: 'Northern Tutchone',
  ttn: 'Towei',
  tto: "Lower Ta'oih",
  ttp: 'Tombelala',
  ttq: 'Tawallammat Tamajaq',
  ttr: 'Tera',
  tts: 'Isan',
  ttt: 'Tat',
  ttu: 'Torau',
  ttv: 'Titan',
  ttw: 'Long Wat',
  tty: 'Sikaritai',
  ttz: 'Tsum',
  tua: 'Wiarumus',
  tub: 'T\u00fcbatulabal',
  tuc: 'Mutu',
  tud: 'Tux\u00e1',
  tue: 'Tuyuca',
  tuf: 'Central Tunebo',
  tug: 'Tunia',
  tuh: 'Taulil',
  tui: 'Tupuri',
  tuj: 'Tugutil',
  tul: 'Tula',
  tum: 'Tumbuka',
  tun: 'Tunica',
  tuo: 'Tucano',
  tuq: 'Tedaga',
  tus: 'Tuscarora',
  tuv: 'Turkana',
  tux: 'Tuxin\u00e1wa',
  tuy: 'Tugen',
  tuz: 'Turka',
  tva: 'Vaghua',
  tvd: 'Tsuvadi',
  tve: "Te'un",
  tvk: 'Southeast Ambrym',
  tvl: 'Tuvaluan',
  tvm: 'Tela-Masbuar',
  tvn: 'Tavoyan',
  tvo: 'Tidore',
  tvs: 'Taveta',
  tvt: 'Tutsa Naga',
  tvu: 'Tunen',
  tvw: 'Sedoa',
  tvy: 'Timor Pidgin',
  twa: 'Twana',
  twb: 'Western Tawbuid',
  twc: 'Teshenawa',
  twe: 'Teiwa',
  twf: 'Taos',
  twg: 'Tereweng',
  twh: 'Tai D\u00f3n',
  twl: 'Tawara',
  twn: 'Twendi',
  two: 'Tswapong',
  twp: 'Ere',
  twq: 'Tasawaq',
  twr: 'Southwestern Tarahumara',
  twt: 'Turiw\u00e1ra',
  twu: 'Termanu',
  tww: 'Tuwari',
  twx: 'Tewe',
  twy: 'Tawoyan',
  txa: 'Tombonuo',
  txb: 'Tocharian B',
  txc: 'Tsetsaut',
  txe: 'Totoli',
  txg: 'Tangut',
  txh: 'Thracian',
  txi: 'Ikpeng',
  txj: 'Tarjumo',
  txm: 'Tomini',
  txn: 'West Tarangan',
  txo: 'Toto',
  txq: 'Tii',
  txr: 'Tartessian',
  txs: 'Tonsea',
  txt: 'Citak',
  txu: 'Kayap\u00f3',
  txx: 'Tatana',
  tya: 'Tauya',
  tye: 'Kyenga',
  tyh: "O'du",
  tyi: 'Teke-Tsaayi',
  tyj: 'Tai Do',
  tyl: 'Thu Lao',
  tyn: 'Kombai',
  typ: 'Kuku-Thaypan',
  tyr: 'Tai Daeng',
  tys: 'Sapa',
  tyt: 'T\u00e0y Tac',
  tyv: 'Tuvan',
  tyx: 'Teke-Tyee',
  tza: 'Tanzanian Sign Language',
  tzh: 'Tzeltal',
  tzj: "Tz'utujil",
  tzl: 'Talossan',
  tzm: 'Central Atlas Tamazight',
  tzn: 'Tugun',
  tzo: 'Tzotzil',
  tzx: 'Tabriak',
  uam: 'Uamu\u00e9',
  uan: 'Kuan',
  uar: 'Tairuma',
  uba: 'Ubang',
  ubi: 'Ubi',
  ubl: "Buhi'non Bikol",
  ubr: 'Ubir',
  ubu: 'Umbu-Ungu',
  uby: 'Ubykh',
  uda: 'Uda',
  ude: 'Udihe',
  udg: 'Muduga',
  udi: 'Udi',
  udj: 'Ujir',
  udl: 'Uldeme',
  udm: 'Udmurt',
  udu: 'Uduk',
  ues: 'Kioko',
  ufi: 'Ufim',
  uga: 'Ugaritic',
  ugb: 'Kuku-Ugbanh',
  uge: 'Ughele',
  ugn: 'Ugandan Sign Language',
  ugo: 'Gong',
  ugy: 'Uruguayan Sign Language',
  uha: 'Uhami',
  uhn: 'Damal',
  uis: 'Uisai',
  uiv: 'Iyive',
  uji: 'Tanjijili',
  uka: 'Kaburi',
  ukg: 'Ukuriguma',
  ukh: 'Ukhwejo',
  ukk: 'Muak Sa-aak',
  ukl: 'Ukrainian Sign Language',
  ukp: 'Ukpe-Bayobiri',
  ukq: 'Ukwa',
  uks: 'Kaapor Sign Language',
  uku: 'Ukue',
  ukw: 'Ukwuani-Aboh-Ndoni',
  uky: 'Kuuk Yak',
  ula: 'Fungwa',
  ulb: 'Ulukwumi',
  ulc: 'Ulch',
  ule: 'Lule',
  ulf: 'Afra',
  uli: 'Ulithian',
  ulk: 'Meriam',
  ull: 'Ullatan',
  ulm: "Ulumanda'",
  uln: 'Unserdeutsch',
  ulu: "Uma' Lung",
  ulw: 'Ulwa',
  uma: 'Umatilla',
  umb: 'Umbundu',
  umc: 'Marrucinian',
  umd: 'Umbindhamu',
  umg: 'Umbuygamu',
  umi: 'Ukit',
  umm: 'Umon',
  umn: 'Makyan Naga',
  umo: 'Umot\u00edna',
  ump: 'Umpila',
  umr: 'Umbugarla',
  ums: 'Pendau',
  umu: 'Munsee',
  una: 'North Watut',
  und: 'Undetermined',
  une: 'Uneme',
  ung: 'Ngarinyin',
  unk: 'Enawen\u00e9-Naw\u00e9',
  unm: 'Unami',
  unn: 'Kurnai',
  unr: 'Mundari',
  unu: 'Unubahe',
  unz: 'Unde Kaili',
  uok: 'Uokha',
  upi: 'Umeda',
  upv: 'Uripiv-Wala-Rano-Atchin',
  ura: 'Urarina',
  urb: 'Urub\u00fa-Kaapor',
  urc: 'Urningangg',
  ure: 'Uru',
  urf: 'Uradhi',
  urg: 'Urigina',
  urh: 'Urhobo',
  uri: 'Urim',
  urk: "Urak Lawoi'",
  url: 'Urali',
  urm: 'Urapmin',
  urn: 'Uruangnirin',
  uro: 'Ura (New Guinea)',
  urp: 'Uru-Pa-In',
  urr: 'Lehalurup',
  urt: 'Urat',
  uru: 'Urumi',
  urv: 'Uruava',
  urw: 'Sop',
  urx: 'Urimo',
  ury: 'Orya',
  urz: 'Uru-Eu-Wau-Wau',
  usa: 'Usarufa',
  ush: 'Ushojo',
  usi: 'Usui',
  usk: 'Usaghade',
  usp: 'Uspanteco',
  usu: 'Uya',
  uta: 'Otank',
  ute: 'Ute',
  utp: 'Aba',
  utr: 'Etulo',
  utu: 'Utu',
  uum: 'Urum',
  uun: 'Kulon-Pazeh',
  uur: 'Ura (Vanuatu)',
  uuu: 'U',
  uve: 'West Uvean',
  uvh: 'Uri',
  uvl: 'Lote',
  uwa: 'Kuku-Uwanh',
  uya: 'Doko-Uyanga',
  vaa: 'Vaagri Booli',
  vae: 'Vale',
  vaf: 'Vafsi',
  vag: 'Vagla',
  vah: 'Varhadi-Nagpuri',
  vai: 'Vai',
  vaj: 'Sekele',
  val: 'Vehes',
  vam: 'Vanimo',
  van: 'Valman',
  vao: 'Vao',
  vap: 'Vaiphei',
  var: 'Huarijio',
  vas: 'Vasavi',
  vau: 'Vanuma',
  vav: 'Varli',
  vay: 'Vayu',
  vbb: 'Southeast Babar',
  vbk: 'Southwestern Bontoc',
  vec: 'Venetian',
  ved: 'Veddah',
  vem: 'Vemgo-Mabas',
  veo: 'Venture\u00f1o',
  vep: 'Veps',
  ver: 'Mom Jango',
  vgr: 'Vaghri',
  vgt: 'Flemish Sign Language',
  vic: 'Virgin Islands Creole',
  vid: 'Vidunda',
  vif: 'Vili',
  vig: 'Viemo',
  vil: 'Vilela',
  vis: 'Vishavan',
  vit: 'Viti',
  viv: 'Iduna',
  vka: 'Kariyarra',
  vki: 'Ija-Zuba',
  vkj: 'Kujarge',
  vkk: 'Kaur',
  vkl: 'Kulisusu',
  vkm: 'Kamakan',
  vko: 'Kodeoha',
  vkp: 'Korlai Creole Portuguese',
  vkt: 'Tenggarong Kutai Malay',
  vku: 'Kurrama',
  vlp: 'Valpei',
  vls: 'West Flemish',
  vma: 'Martuthunira',
  vmb: 'Mbabaram',
  vmc: 'Juxtlahuaca Mixtec',
  vmd: 'Mudu Koraga',
  vme: 'East Masela',
  vmf: 'East Franconian',
  vmg: 'Minigir',
  vmh: 'Maraghei',
  vmi: 'Miwa',
  vmj: 'Ixtayutla Mixtec',
  vmk: 'Makhuwa-Shirima',
  vml: 'Malgana',
  vmm: 'Mitlatongo Mixtec',
  vmp: 'Soyaltepec Mazatec',
  vmq: 'Soyaltepec Mixtec',
  vmr: 'Marenje',
  vmu: 'Muluridyi',
  vmv: 'Valley Maidu',
  vmw: 'Makhuwa',
  vmx: 'Tamazola Mixtec',
  vmy: 'Ayautla Mazatec',
  vmz: 'Mazatl\u00e1n Mazatec',
  vnk: 'Lovono',
  vnm: "Neve'ei",
  vnp: 'Vunapu',
  vor: 'Voro',
  vot: 'Votic',
  vra: "Vera'a",
  vro: 'V\u00f5ro',
  vrs: 'Varisi',
  vrt: 'Banam Bay',
  vsi: 'Moldova Sign Language',
  vsl: 'Venezuelan Sign Language',
  vsv: 'Valencian Sign Language',
  vto: 'Vitou',
  vum: 'Vumbu',
  vun: 'Vunjo',
  vut: 'Vute',
  vwa: 'Awa (China)',
  waa: 'Walla Walla',
  wab: 'Wab',
  wac: 'Wasco-Wishram',
  wad: 'Wandamen',
  waf: 'Wakon\u00e1',
  wag: "Wa'ema",
  wah: 'Watubela',
  waj: 'Waffa',
  wal: 'Wolaytta',
  wam: 'Massachusett',
  wan: 'Wan',
  wao: 'Wappo',
  wap: 'Wapishana',
  waq: 'Wageman',
  war: 'Waray-Waray',
  was: 'Washo',
  wat: 'Kaninuwa',
  wau: 'Wauja',
  wav: 'Waka',
  waw: 'Waiwai',
  wax: 'Watam',
  way: 'Wayana',
  waz: 'Wampur',
  wba: 'Warao',
  wbb: 'Wabo',
  wbe: 'Waritai',
  wbf: 'Wara',
  wbh: 'Wanda',
  wbi: 'Wanji',
  wbj: 'Alagwa',
  wbk: 'Waigali',
  wbl: 'Wakhi',
  wbm: 'Wa',
  wbp: 'Warlpiri',
  wbq: 'Waddar',
  wbr: 'Wagdi',
  wbt: 'Wanman',
  wbv: 'Wajarri',
  wbw: 'Woi',
  wca: 'Yanom\u00e1mi',
  wci: 'Waci Gbe',
  wdd: 'Wandji',
  wdg: 'Wadaginam',
  wdj: 'Wadjiginy',
  wdu: 'Wadjigu',
  wdy: 'Wadjabangayi',
  wea: 'Wewaw',
  wec: 'W\u00e8 Western',
  wed: 'Wedau',
  weh: 'Weh',
  wei: 'Kiunum',
  wem: 'Weme Gbe',
  weo: 'Wemale',
  wer: 'Weri',
  wes: 'Cameroon Pidgin',
  wet: 'Perai',
  weu: 'Welaung',
  wew: 'Weyewa',
  wfg: 'Yafi',
  wga: 'Wagaya',
  wgb: 'Wagawaga',
  wgg: 'Wangganguru',
  wgi: 'Wahgi',
  wgo: 'Waigeo',
  wgu: 'Wirangu',
  wgy: 'Warrgamay',
  wha: 'Manusela',
  whg: 'North Wahgi',
  whk: 'Wahau Kenyah',
  whu: 'Wahau Kayan',
  wib: 'Southern Toussian',
  wic: 'Wichita',
  wie: 'Wik-Epa',
  wif: 'Wik-Keyangan',
  wig: 'Wik-Ngathana',
  wih: "Wik-Me'anha",
  wii: 'Minidien',
  wij: 'Wik-Iiyanh',
  wik: 'Wikalkan',
  wil: 'Wilawila',
  wim: 'Wik-Mungkan',
  win: 'Winnebago',
  wir: 'Wiraf\u00e9d',
  wiu: 'Wiru',
  wiv: 'Muduapa',
  wiy: 'Wiyot',
  wja: 'Waja',
  wji: 'Warji',
  wka: "Kw'adza",
  wkb: 'Kumbaran',
  wkd: 'Mo',
  wkl: 'Kalanadi',
  wku: 'Kunduvadi',
  wkw: 'Wakawaka',
  wky: 'Wangkayutyuru',
  wla: 'Walio',
  wlc: 'Mwali Comorian',
  wle: 'Wolane',
  wlg: 'Kunbarlang',
  wli: 'Waioli',
  wlk: 'Wailaki',
  wll: 'Wali (Sudan)',
  wlm: 'Middle Welsh',
  wlo: 'Wolio',
  wlr: 'Wailapa',
  wls: 'Wallisian',
  wlu: 'Wuliwuli',
  wlv: 'Wich\u00ed Lhamt\u00e9s Vejoz',
  wlw: 'Walak',
  wlx: 'Wali (Ghana)',
  wly: 'Waling',
  wmb: 'Wambaya',
  wmc: 'Wamas',
  wmd: 'Mamaind\u00e9',
  wme: 'Wambule',
  wmh: "Waima'a",
  wmi: 'Wamin',
  wmm: 'Maiwa (Indonesia)',
  wmn: 'Waamwang',
  wmo: 'Wam',
  wms: 'Wambon',
  wmt: 'Walmajarri',
  wmw: 'Mwani',
  wmx: 'Womo',
  wnb: 'Wanambre',
  wnc: 'Wantoat',
  wnd: 'Wandarang',
  wne: 'Waneci',
  wng: 'Wanggom',
  wni: 'Ndzwani Comorian',
  wnk: 'Wanukaka',
  wnm: 'Wanggamala',
  wno: 'Wano',
  wnp: 'Wanap',
  wnu: 'Usan',
  wnw: 'Wintu',
  wny: 'Wanyi',
  woa: 'Tyaraity',
  wob: 'W\u00e8 Northern',
  woc: 'Wogeo',
  wod: 'Wolani',
  woe: 'Woleaian',
  wog: 'Wogamusin',
  woi: 'Kamang',
  wok: 'Longto',
  wom: 'Perema',
  won: 'Wongo',
  woo: 'Manombai',
  wor: 'Woria',
  wos: 'Hanga Hundi',
  wow: 'Wawonii',
  wpc: 'Wir\u00f6',
  wra: 'Warapu',
  wrb: 'Warluwara',
  wrg: 'Warungu',
  wrh: 'Wiradhuri',
  wri: 'Wariyangga',
  xrw: 'Karawa',
  wrl: 'Warlmanpa',
  wrm: 'Warumungu',
  wrn: 'Warnang',
  wro: 'Worora',
  wrp: 'Waropen',
  wrr: 'Wardaman',
  wrs: 'Waris',
  wru: 'Waru',
  wrv: 'Waruna',
  wrw: 'Gugu Warra',
  wrx: 'Wae Rana',
  wrz: 'Warray',
  wsa: 'Warembori',
  wsi: 'Wusi',
  wsk: 'Waskia',
  wsr: 'Owenia',
  wss: 'Wasa',
  wsu: 'Wasu',
  wsv: 'Wotapuri-Katarqalai',
  wtf: 'Watiwa',
  wth: 'Wathaurong',
  wti: 'Berta',
  wtk: 'Watakataui',
  wtm: 'Mewati',
  wtw: 'Wotu',
  wua: 'Wikngenchera',
  wub: 'Wunambal',
  wud: 'Wudu',
  wuh: 'Wutunhua',
  wul: 'Silimo',
  wum: 'Wumbvu',
  wun: 'Bungu',
  wur: 'Wurrugu',
  wut: 'Wutung',
  wuu: 'Wu',
  wuv: 'Wuvulu-Aua',
  wux: 'Wulna',
  wuy: 'Wauyai',
  wwa: 'Waama',
  wwo: 'Dorig',
  wwr: 'Warrwa',
  www: 'Wawa',
  wxa: 'Waxianghua',
  wxw: 'Wardandi',
  wya: 'Wyandot',
  wyb: 'Ngiyambaa',
  wyi: 'Woiwurrung',
  wym: 'Vilamovian',
  wyr: 'Wayor\u00f3',
  wyy: 'Western Fijian',
  xaa: 'Andalusian Arabic',
  xab: 'Sambe',
  xac: 'Kachari',
  xad: 'Adai',
  xae: 'Aequian',
  xag: 'Aghwan',
  xai: 'Kaimb\u00e9',
  xaj: 'Ararandew\u00e1ra',
  xal: 'Kalmyk',
  xam: '\u01c0Xam',
  xan: 'Xamtanga',
  xao: 'Khao',
  xap: 'Apalachee',
  xaq: 'Aquitanian',
  xar: 'Karami',
  xas: 'Kamassian',
  xat: 'Katawixi',
  xau: 'Kauwera',
  xav: 'Xavante',
  xaw: 'Kawaiisu',
  xay: 'Kayan Mahakam',
  xbb: 'Lower Burdekin',
  xbc: 'Bactrian',
  xbd: 'Bindal',
  xbe: 'Bigambal',
  xbg: 'Bunganditj',
  xbi: 'Kombio',
  xbj: 'Birrpayi',
  xbm: 'Middle Breton',
  xbn: 'Kenaboi',
  xbo: 'Bulgar',
  xbp: 'Bibbulman',
  xbr: 'Kambera',
  xbw: 'Kambiw\u00e1',
  xby: 'Butchulla',
  xcb: 'Cumbric',
  xcc: 'Camunic',
  xce: 'Celtiberian',
  xch: 'Chemakum',
  xcl: 'Old Armenian',
  xcm: 'Comecrudo',
  xcn: 'Cotoname',
  xco: 'Chorasmian',
  xcr: 'Carian',
  xct: 'Classical Tibetan',
  xcu: 'Curonian',
  xcv: 'Chuvantsy',
  xcw: 'Coahuilteco',
  xcy: 'Cayuse',
  xda: 'Darkinjung',
  xdc: 'Dacian',
  xdk: 'Dharug',
  xdm: 'Edomite',
  xdy: 'Malayic Dayak',
  xeb: 'Eblaite',
  xed: 'Hdi',
  xeg: '\u01c1Xegwi',
  xel: 'Kelo',
  xem: 'Kembayan',
  xep: 'Epi-Olmec',
  xer: 'Xer\u00e9nte',
  xes: 'Kesawai',
  xet: 'Xet\u00e1',
  xeu: 'Keoru-Ahia',
  xfa: 'Faliscan',
  xga: 'Galatian',
  xgb: 'Gbin',
  xgd: 'Gudang',
  xgf: 'Gabrielino-Fernande\u00f1o',
  xgg: 'Goreng',
  xgi: 'Garingbal',
  xgl: 'Galindan',
  xgm: 'Darumbal',
  xgr: 'Garza',
  xgu: 'Unggumi',
  xgw: 'Guwa',
  xha: 'Harami',
  xhc: 'Hunnic',
  xhd: 'Hadrami',
  xhe: 'Khetrani',
  xhr: 'Hernican',
  xht: 'Hattic',
  xhu: 'Hurrian',
  xhv: 'Khua',
  xib: 'Iberian',
  xii: 'Xiri',
  xil: 'Illyrian',
  xir: 'Xiri\u00e2na',
  xis: 'Kisan',
  xiv: 'Indus Valley Language',
  xiy: 'Xipaya',
  xjb: 'Minjungbal',
  xka: 'Kalkoti',
  xkb: 'Manigri-Kambol\u00e9 Ede Nago',
  xkc: "Kho'ini",
  xkd: 'Mendalam Kayan',
  xke: 'Kereho',
  xkf: 'Khengkha',
  xkg: 'Kagoro',
  xki: 'Kenyan Sign Language',
  xkj: 'Kajali',
  xkk: "Kaco'",
  xkl: 'Bakung',
  xkn: 'Kayan River Kayan',
  xko: 'Kiorr',
  xkp: 'Kabatei',
  xkq: 'Koroni',
  xkr: 'Xakriab\u00e1',
  xks: 'Kumbewaha',
  xkt: 'Kantosi',
  xku: 'Kaamba',
  xkv: 'Kgalagadi',
  xkw: 'Kembra',
  xkx: 'Karore',
  xky: "Uma' Lasan",
  xkz: 'Kurtop',
  xla: 'Kamula',
  xlb: 'Loup B',
  xlc: 'Lycian',
  xld: 'Lydian',
  xle: 'Lemnian',
  xlg: 'Ancient Ligurian',
  xli: 'Liburnian',
  xlo: 'Loup A',
  xlp: 'Lepontic',
  xls: 'Lusitanian',
  xlu: 'Luwian',
  xly: 'Elymian',
  xma: 'Mushungulu',
  xmb: 'Mbonga',
  xmc: 'Makhuwa-Marrevone',
  xmd: 'Mbudum',
  xme: 'Old Median',
  xmf: 'Mingrelian',
  xmg: 'Mengaka',
  xmh: 'Kugu-Muminh',
  xmj: 'Majera',
  xmk: 'Ancient Macedonian',
  xml: 'Malaysian Sign Language',
  xmm: 'Manado Malay',
  xmo: 'Morerebi',
  xmp: "Kuku-Mu'inh",
  xmq: 'Kuku-Mangk',
  xmr: 'Meroitic',
  xms: 'Moroccan Sign Language',
  xmt: 'Matbat',
  xmu: 'Kamu',
  xmx: 'Maden',
  xmy: 'Mayaguduna',
  xmz: 'Mori Bawah',
  xna: 'Ancient North Arabian',
  xnb: 'Kanakanabu',
  xng: 'Middle Mongolian',
  xnh: 'Kuanhua',
  xni: 'Ngarigu',
  xnk: 'Nganakarti',
  xnn: 'Northern Kankanay',
  xnr: 'Kangri',
  xns: 'Kanashi',
  xnt: 'Narragansett',
  xnu: 'Nukunul',
  xny: 'Nyiyaparli',
  xoc: "O'chi'chi'",
  xod: 'Kokoda',
  xog: 'Soga',
  xoi: 'Kominimung',
  xok: 'Xokleng',
  xon: 'Konkomba',
  xoo: 'Xukur\u00fa',
  xop: 'Kopar',
  xor: 'Korubo',
  xow: 'Kowaki',
  xpa: 'Pirriya',
  xpc: 'Pecheneg',
  xpe: 'Liberia Kpelle',
  xpg: 'Phrygian',
  xpi: 'Pictish',
  xpj: 'Mpalitjanh',
  xpm: 'Pumpokol',
  xpn: 'Kapinaw\u00e1',
  xpo: 'Pochutec',
  xpp: 'Puyo-Paekche',
  xpq: 'Mohegan-Pequot',
  xpr: 'Parthian',
  xps: 'Pisidian',
  xpu: 'Punic',
  xpy: 'Buyeo',
  xqa: 'Karakhanid',
  xqt: 'Qatabanian',
  xrb: 'Eastern Karaboro',
  xrd: 'Gundungurra',
  xre: 'Kreye',
  xrg: 'Minang',
  xri: 'Krikati-Timbira',
  xrm: 'Armazic',
  xrn: 'Arin',
  xrq: 'Karranga',
  xrr: 'Raetic',
  xrt: 'Aranama-Tamique',
  xsa: 'Sabaean',
  xsb: 'Sambali',
  xsd: 'Sidetic',
  xse: 'Sempan',
  xsh: 'Shamang',
  xsi: 'Sio',
  xsl: 'South Slavey',
  xsm: 'Kasem',
  xsn: 'Sanga (Nigeria)',
  xso: 'Solano',
  xsp: 'Silopi',
  xsq: 'Makhuwa-Saka',
  xsr: 'Sherpa',
  xss: 'Assan',
  xsu: 'Sanum\u00e1',
  xsv: 'Sudovian',
  xsy: 'Saisiyat',
  xta: 'Alcozauca Mixtec',
  xtb: 'Chazumba Mixtec',
  xtc: 'Kadugli',
  xtd: 'Diuxi-Tilantongo Mixtec',
  xte: 'Ketengban',
  xth: 'Yitha Yitha',
  xti: 'Sinicahua Mixtec',
  xtj: 'San Juan Teita Mixtec',
  xtl: 'Tijaltepec Mixtec',
  xtm: 'Magdalena Pe\u00f1asco Mixtec',
  xtn: 'Northern Tlaxiaco Mixtec',
  xto: 'Tocharian A',
  xtp: 'San Miguel Piedras Mixtec',
  xtq: 'Tumshuqese',
  xtr: 'Early Tripuri',
  xts: 'Sindihui Mixtec',
  xtt: 'Tacahua Mixtec',
  xtu: 'Cuyamecalco Mixtec',
  xtv: 'Thawa',
  xtw: 'Tawand\u00ea',
  xty: 'Yoloxochitl Mixtec',
  xtz: 'Tasmanian',
  xua: 'Alu Kurumba',
  xub: 'Betta Kurumba',
  xud: 'Umiida',
  xug: 'Kunigami',
  xuj: 'Jennu Kurumba',
  xul: 'Ngunawal',
  xum: 'Umbrian',
  xun: 'Unggaranggu',
  xuo: 'Kuo',
  xup: 'Upper Umpqua',
  xur: 'Urartian',
  xut: 'Kuthant',
  xuu: 'Khwe',
  xve: 'Venetic',
  xvn: 'Vandalic',
  xvo: 'Volscian',
  xvs: 'Vestinian',
  xwa: 'Kwaza',
  xwc: 'Woccon',
  xwd: 'Wadi Wadi',
  xwe: 'Xwela Gbe',
  xwg: 'Kwegu',
  xwj: 'Wajuk',
  xwk: 'Wangkumara',
  xwl: 'Western Xwla Gbe',
  xwo: 'Written Oirat',
  xwr: 'Kwerba Mamberamo',
  xww: 'Wemba-Wemba',
  xxk: "Ke'o",
  xxm: 'Minkin',
  xxr: 'Korop\u00f3',
  xxt: 'Tambora',
  xya: 'Yaygir',
  xyb: 'Yandjibara',
  xyl: 'Yalakalore',
  xyy: 'Yorta Yorta',
  xzh: 'Zhang-Zhung',
  xzm: 'Zemgalian',
  xzp: 'Ancient Zapotec',
  yaa: 'Yaminahua',
  yab: 'Yuhup',
  yac: 'Pass Valley Yali',
  yad: 'Yagua',
  yae: 'Pum\u00e9',
  yag: 'Y\u00e1mana',
  yah: 'Yazgulyam',
  yai: 'Yagnobi',
  yaj: 'Banda-Yangere',
  yak: 'Yakima',
  yal: 'Yalunka',
  yam: 'Yamba',
  yan: 'Mayangna',
  yao: 'Yao',
  yap: 'Yapese',
  yaq: 'Yaqui',
  yar: 'Yabarana',
  yas: 'Gunu',
  yat: 'Yambeta',
  yau: 'Yuwana',
  yav: 'Yangben',
  yaw: 'Yawalapit\u00ed',
  yax: 'Yauma',
  yay: 'Agwagwune',
  yaz: 'Lokaa',
  yba: 'Yala',
  ybb: 'Yemba',
  ybe: 'Western Yugur',
  ybh: 'Yakkha',
  ybj: 'Hasha',
  ybk: 'Bokha',
  ybl: 'Yukuben',
  ybm: 'Yaben',
  ybn: 'Yaba\u00e2na',
  ybo: 'Yabong',
  ybx: 'Yawiyo',
  yby: 'Yaweyuha',
  ych: 'Chesu',
  ycl: 'Lolopo',
  ycn: 'Yucuna',
  yde: 'Yangum Dey',
  ydg: 'Yidgha',
  ydk: 'Yoidik',
  yea: 'Ravula',
  yec: 'Yeniche',
  yee: 'Yimas',
  yei: 'Yeni',
  yej: 'Yevanic',
  yen: 'Yendang',
  yer: 'Tarok',
  yes: 'Yeskwa',
  yet: 'Yetfa',
  yeu: 'Yerukula',
  yev: 'Yapunda',
  yey: 'Yeyi',
  ygi: 'Yiningayi',
  ygl: 'Yangum Gel',
  ygm: 'Yagomi',
  ygp: 'Gepo',
  ygr: 'Yagaria',
  ygs: 'Yolngu Sign Language',
  ygu: 'Yugul',
  ygw: 'Yagwoia',
  yha: 'Baha',
  yhd: 'Judeo-Iraqi Arabic',
  yhl: 'Hlepho Phowa',
  yia: 'Yinggarda',
  yiz: 'Azhe',
  yig: 'Wusa Nasu',
  yii: 'Yidiny',
  yij: 'Yindjibarndi',
  yik: 'Dongshanba Lalo',
  yil: 'Yindjilandji',
  yim: 'Yimchungru Naga',
  yin: 'Yinchia',
  yip: 'Pholo',
  yiq: 'Miqie',
  yir: 'North Awyu',
  yis: 'Yis',
  yit: 'Eastern Lalu',
  yiu: 'Awu',
  yiv: 'Northern Nisu',
  yix: 'Axi Yi',
  yiy: 'Yir-Yoront',
  yka: 'Yakan',
  ykg: 'Northern Yukaghir',
  yki: 'Yoke',
  ykk: 'Yakaikeke',
  ykl: 'Khlula',
  ykm: 'Kap',
  ykn: 'Kua-nsi',
  yko: 'Yasa',
  ykr: 'Yekora',
  ykt: 'Kathu',
  yku: 'Kuamasi',
  yky: 'Yakoma',
  yla: 'Yaul',
  ylb: 'Yaleba',
  yle: 'Yele',
  ylg: 'Yelogu',
  yli: 'Angguruk Yali',
  yll: 'Yil',
  yln: 'Langnian Buyang',
  ylo: 'Naluo Yi',
  ylr: 'Yalarnnga',
  ylu: 'Aribwaung',
  yly: 'Ny\u00e2layu',
  ymb: 'Yambes',
  ymc: 'Southern Muji',
  ymd: 'Muda',
  yme: 'Yameo',
  ymg: 'Yamongeri',
  ymh: 'Mili',
  ymi: 'Moji',
  ymk: 'Makwe',
  yml: 'Iamalele',
  ymm: 'Maay',
  ymn: 'Sunum',
  ymo: 'Yangum Mon',
  ymp: 'Yamap',
  ymq: 'Qila Muji',
  ymr: 'Malasar',
  yms: 'Mysian',
  ymx: 'Northern Muji',
  ymz: 'Muzi',
  yna: 'Aluo',
  ynd: 'Yandruwandha',
  yne: "Lang'e",
  yng: 'Yango',
  ynk: 'Naukanski',
  ynl: 'Yangulam',
  ynn: 'Yana',
  yno: 'Yong',
  yns: 'Yansi',
  ynu: 'Yahuna',
  yob: 'Yoba',
  yog: 'Yogad',
  yoi: 'Yonaguni',
  yol: 'Yola',
  yon: 'Yongkom',
  yox: 'Yoron',
  yoy: 'Yoy',
  ypa: 'Phala',
  ypb: 'Labo Phowa',
  ypg: 'Phola',
  yph: 'Phupha',
  ypm: 'Phuma',
  ypn: 'Ani Phowa',
  ypo: 'Alo Phola',
  ypp: 'Phupa',
  ypz: 'Phuza',
  yra: 'Yerakai',
  yrb: 'Yareba',
  yre: 'Yaour\u00e9',
  yri: 'Yar\u00ed',
  yrk: 'Tundra Nenets',
  yrl: 'Nhengatu',
  yrn: 'Yerong',
  yro: 'Yaroam\u00eb',
  yrw: 'Yarawata',
  yry: 'Yarluyandi',
  ysc: 'Jassic',
  ysd: 'Samatao',
  ysg: 'Sonaga',
  ysl: 'Yugoslavian Sign Language',
  ysn: 'Sani',
  ysp: 'Southern Lolopo',
  ysr: 'Sirenik',
  yss: 'Yessan-Mayo',
  ysy: 'Sanie',
  yta: 'Talu',
  ytl: 'Tanglang',
  ytp: 'Thopho',
  ytw: 'Yout Wam',
  yty: 'Yatay',
  yua: 'Yucatec Maya',
  yub: 'Yugambal',
  yuc: 'Yuchi',
  yud: 'Judeo-Tripolitanian Arabic',
  yue: 'Cantonese',
  yuf: 'Havasupai-Walapai-Yavapai',
  yug: 'Yug',
  yui: 'Yurut\u00ed',
  yuj: 'Karkar-Yuri',
  yuk: 'Yuki',
  yul: 'Yulu',
  yum: 'Yuma',
  yup: 'Yukpa',
  yuq: 'Yuqui',
  yur: 'Yurok',
  yut: 'Yopno',
  yuw: 'Finisterre Yau',
  yux: 'Southern Yukaghir',
  yuy: 'East Yugur',
  yuz: 'Yuracare',
  yva: 'Yawa',
  yvt: 'Yavitero',
  ywa: 'Kalou',
  ywg: 'Yinhawangka',
  ywl: 'Western Lalu',
  ywn: 'Yawanawa',
  ywq: 'Nasu',
  ywr: 'Yawuru',
  ywt: 'Xishanba Lalo',
  ywu: 'Wumeng Nasu',
  yww: 'Yawarawarga',
  yxa: 'Mayawali',
  yxg: 'Yagara',
  yxl: 'Yarli',
  yxm: 'Yinwum',
  yxu: 'Yuyu',
  yxy: 'Yabula Yabula',
  yyu: 'Torricelli Yau',
  yyz: 'Ayizi',
  yzg: "E'ma Buyang",
  yzk: 'Zokhuo',
  zaa: 'Sierra de Ju\u00e1rez Zapotec',
  zab: 'San Juan Guelav\u00eda Zapotec',
  zac: 'Ocotl\u00e1n Zapotec',
  zad: 'Cajonos Zapotec',
  zae: 'Yareni Zapotec',
  zaf: 'Ayoquesco Zapotec',
  zag: 'Zaghawa',
  zah: 'Zangwal',
  zai: 'Isthmus Zapotec',
  zaj: 'Zaramo',
  zak: 'Zanaki',
  zal: 'Zauzou',
  zam: 'Central Mahuatl\u00e1n Zapoteco',
  zao: 'Ozolotepec Zapotec',
  zap: 'Zapotec',
  zaq: 'Alo\u00e1pam Zapotec',
  zar: 'Rinc\u00f3n Zapotec',
  zas: 'Santo Domingo Albarradas Zapotec',
  zat: 'Tabaa Zapotec',
  zau: 'Zangskari',
  zav: 'Yatzachi Zapotec',
  zaw: 'Mitla Zapotec',
  zax: 'Xadani Zapotec',
  zay: 'Zayse-Zergulla',
  zaz: 'Zari',
  zbt: 'Batui',
  zca: 'Coatecas Altas Zapotec',
  zdj: 'Ngazidja Comorian',
  zea: 'Zealandic',
  zeg: 'Zenag',
  zen: 'Zenaga',
  zga: 'Kinga',
  zgh: 'Moroccan Amazigh',
  zgr: 'Magori',
  zhb: 'Zhaba',
  zhi: 'Zhire',
  zhw: 'Zhoa',
  zia: 'Zia',
  zib: 'Zimbabwe Sign Language',
  zik: 'Zimakani',
  zil: 'Zialo',
  zim: 'Mesme',
  zin: 'Zinza',
  zir: 'Ziriya',
  ziw: 'Zigula',
  ziz: 'Zizilivakan',
  zka: 'Kaimbulawa',
  zkb: 'Koibal',
  zkg: 'Goguryeo',
  zkh: 'Khorezmian Turkic',
  zkk: 'Karankawa',
  zko: 'Kott',
  zkp: 'S\u00e3o Paulo Kaing\u00e1ng',
  zkr: 'Zakhring',
  zkt: 'Khitan',
  zku: 'Kaurna',
  zkv: 'Krevinian',
  zkz: 'Khazar',
  zma: 'Australian Manda',
  zmb: 'Zimba',
  zmc: 'Margany',
  zmd: 'Maridan',
  zme: 'Mangerr',
  zmf: 'Mfinu',
  zmg: 'Marti Ke',
  zmh: 'Makolkol',
  zmi: 'Negeri Sembilan Malay',
  zmk: 'Mandandanyi',
  zml: 'Madngele',
  zmn: 'Mbangwe',
  zmo: 'Molo',
  zmp: 'Mpuono',
  zmq: 'Mituku',
  zmr: 'Maranungku',
  zms: 'Mbesa',
  zmt: 'Maringarr',
  zmu: 'Muruwari',
  zmv: 'Mbariman-Gudhinma',
  zmw: 'Mbo (Congo)',
  zmx: 'Bomitaba',
  zmz: 'Mbandja',
  zna: 'Zan Gula',
  zng: 'Mang',
  znk: 'Manangkari',
  zns: 'Mangas',
  zoc: 'Copainal\u00e1 Zoque',
  zoh: 'Chimalapa Zoque',
  zom: 'Zou',
  zoo: 'Asunci\u00f3n Mixtepec Zapotec',
  zoq: 'Tabasco Zoque',
  zor: 'Ray\u00f3n Zoque',
  zos: 'Francisco Le\u00f3n Zoque',
  zpa: 'Lachiguiri Zapotec',
  zpb: 'Yautepec Zapotec',
  zpc: 'Choapan Zapotec',
  zpd: 'Southeastern Ixtl\u00e1n Zapotec',
  zpe: 'Petapa Zapotec',
  zpf: 'San Pedro Quiatoni Zapotec',
  zpg: 'Guevea de Humboldt Zapotec',
  zph: 'Totomachapan Zapotec',
  zpi: 'Santa Mar\u00eda Quiegolani Zapotec',
  zpj: 'Quiavicuzas Zapotec',
  zpk: 'Tlacolulita Zapotec',
  zpl: 'Lachix\u00edo Zapotec',
  zpm: 'Mixtepec Zapotec',
  zpn: 'Santa In\u00e9s Yatzechi Zapotec',
  zpo: 'Amatl\u00e1n Zapotec',
  zpp: 'El Alto Zapotec',
  zpq: 'Zoogocho Zapotec',
  zpr: 'Santiago Xanica Zapotec',
  zps: 'Coatl\u00e1n Zapotec',
  zpt: 'San Vicente Coatl\u00e1n Zapotec',
  zpu: 'Yal\u00e1lag Zapotec',
  zpv: 'Chichicapan Zapotec',
  zpw: 'Zaniza Zapotec',
  zpx: 'San Baltazar Loxicha Zapotec',
  zpy: 'Mazaltepec Zapotec',
  zpz: 'Texmelucan Zapotec',
  zra: 'Kaya',
  zrg: 'Mirgan',
  zrn: 'Zirenkel',
  zsa: 'Sarasira',
  zsk: 'Kaskean',
  zsl: 'Zambian Sign Language',
  zsr: 'Southern Rincon Zapotec',
  zsu: 'Sukurum',
  zte: 'Elotepec Zapotec',
  ztg: 'Xanagu\u00eda Zapotec',
  ztl: 'Lapagu\u00eda-Guivini Zapotec',
  ztm: 'San Agust\u00edn Mixtepec Zapotec',
  ztn: 'Santa Catarina Albarradas Zapotec',
  ztp: 'Loxicha Zapotec',
  ztq: 'Quioquitani-Quier\u00ed Zapotec',
  zts: 'Tilquiapan Zapotec',
  ztt: 'Tejalapan Zapotec',
  ztu: 'San Pablo G\u00fcil\u00e1 Zapotec',
  ztx: 'Zaachila Zapotec',
  zty: 'Yatee Zapotec',
  zua: 'Zeem',
  zuh: 'Tokano',
  zum: 'Kumzari',
  zun: 'Zuni',
  zuy: 'Zumaya',
  zwa: 'Zay',
  zyp: 'Zyphe',
  zza: 'Zazaki',
  'aav-pro': 'Proto-Austroasiatic',
  'afa-pro': 'Proto-Afro-Asiatic',
  'alg-aga': 'Agawam',
  'alg-pro': 'Proto-Algonquian',
  'alv-ama': 'Amasi',
  'alv-bgu': 'Ba\u00efnounk Gub\u00ebeher',
  'alv-edo-pro': 'Proto-Edoid',
  'alv-gwa': 'Gwara',
  'alv-pro': 'Proto-Atlantic-Congo',
  'apa-pro': 'Proto-Apachean',
  'aql-pro': 'Proto-Algic',
  'art-blk': 'Bolak',
  'art-bsp': 'Black Speech',
  'art-com': 'Communicationssprache',
  'art-dtk': 'Dothraki',
  'art-elo': 'Eloi',
  'art-gld': "Goa'uld",
  'art-lap': 'Lapine',
  'art-man': 'Mandalorian',
  'art-mun': 'Mundolinco',
  'art-nav': "Na'vi",
  'art-nox': 'Noxilo',
  'art-top': 'Toki Pona',
  'art-una': 'Unas',
  'ath-nic': 'Nicola',
  'ath-pro': 'Proto-Athabaskan',
  'auf-pro': 'Proto-Arawa',
  'aus-alu': 'Alungul',
  'aus-and': 'Andjingith',
  'aus-ang': 'Angkula',
  'aus-arn-pro': 'Proto-Arnhem',
  'aus-bra': 'Barranbinya',
  'aus-brm': 'Barunggam',
  'aus-bru': 'Bruny Island',
  'aus-cww-pro': 'Proto-Central New South Wales',
  'aus-dal-pro': 'Proto-Daly',
  'aus-guw': 'Guwar',
  'aus-lsw': 'Little Swanport',
  'aus-nyu-pro': 'Proto-Nyulnyulan',
  'aus-pam-pro': 'Proto-Pama-Nyungan',
  'aus-par': 'Paredarerme',
  'aus-pee': 'Peerapper',
  'aus-psl': 'Port Sorell',
  'aus-pye': 'Pyemmairre',
  'aus-set': 'Southeast Tasmanian',
  'aus-tom': 'Tommeginne',
  'aus-too': 'Toogee',
  'aus-uwi': 'Uwinymil',
  'aus-wdj-pro': 'Proto-Iwaidjan',
  'aus-won': 'Wong-gie',
  'aus-wul': 'Wulguru',
  'aus-ynk': 'Yangkaal',
  'awd-ama': 'Amarizana',
  'awd-amc-pro': 'Proto-Amuesha-Chamicuro',
  'awd-ana': 'Anauy\u00e1',
  'awd-apo': 'Apolista',
  'awd-cav': 'Cavere',
  'awd-gnu': 'Guinau',
  'awd-kar': 'Cariay',
  'awd-kaw': 'Kawishana',
  'awd-kmp-pro': 'Proto-Kampa',
  'awd-kus': 'Kustenau',
  'awd-man': 'Manao',
  'awd-mar': 'Marawan',
  'awd-mpr': 'Maypure',
  'awd-mrt': 'Mariat\u00e9',
  'awd-nwk-pro': 'Proto-Nawiki',
  'awd-pai': 'Paikoneka',
  'awd-pas': 'Pass\u00e9',
  'awd-pro': 'Proto-Arawak',
  'awd-prw-pro': 'Proto-Paresi-Waura',
  'awd-she': 'Shebayo',
  'awd-taa-pro': 'Proto-Ta-Arawak',
  'awd-wai': 'Wainum\u00e1',
  'awd-yum': 'Yumana',
  'azc-caz': 'Cazcan',
  'azc-cup-pro': 'Proto-Cupan',
  'azc-nah-pro': 'Proto-Nahuan',
  'azc-num-pro': 'Proto-Numic',
  'azc-pro': 'Proto-Uto-Aztecan',
  'azc-tak-pro': 'Proto-Takic',
  'azc-tat': 'Tataviam',
  'ber-pro': 'Proto-Berber',
  'bnt-cmw': 'Chimwiini',
  'bnt-ind': 'Indanga',
  'bnt-ngu-pro': 'Proto-Nguni',
  'bnt-phu': 'Phuthi',
  'bnt-pro': 'Proto-Bantu',
  'btk-pro': 'Proto-Batak',
  'cau-abz-pro': 'Proto-Abkhaz-Abaza',
  'cau-ava-pro': 'Proto-Avaro-Andian',
  'cau-cir-pro': 'Proto-Circassian',
  'cau-drg-pro': 'Proto-Dargwa',
  'cau-lzg-pro': 'Proto-Lezghian',
  'cau-nec-pro': 'Proto-Northeast Caucasian',
  'cau-nkh-pro': 'Proto-Nakh',
  'cau-nwc-pro': 'Proto-Northwest Caucasian',
  'cau-tsz-pro': 'Proto-Tsezian',
  'cba-ata': 'Atanques',
  'cba-cat': 'Cat\u00edo Chibcha',
  'cba-dor': 'Dorasque',
  'cba-dui': 'Duit',
  'cba-hue': 'Huetar',
  'cba-nut': 'Nutabe',
  'cba-pro': 'Proto-Chibchan',
  'ccn-pro': 'Proto-North Caucasian',
  'ccs-gzn-pro': 'Proto-Georgian-Zan',
  'ccs-pro': 'Proto-Kartvelian',
  'cdc-cbm-pro': 'Proto-Central Chadic',
  'cdc-pro': 'Proto-Chadic',
  'cdd-pro': 'Proto-Caddoan',
  'cel-bry-pro': 'Proto-Brythonic',
  'cel-gal': 'Gallaecian',
  'cel-gau': 'Gaulish',
  'cel-pro': 'Proto-Celtic',
  'chi-pro': 'Proto-Chimakuan',
  'cmc-pro': 'Proto-Chamic',
  'cpe-mar': 'Maroon Spirit Language',
  'cpe-spp': 'Samoan Plantation Pidgin',
  'crp-gep': 'Greenlandic Pidgin',
  'crp-rsn': 'Russenorsk',
  'crp-tpr': 'Taimyr Pidgin Russian',
  'csu-sar-pro': 'Proto-Sara',
  'cus-pro': 'Proto-Cushitic',
  'dra-mkn': 'Nadugannada',
  'dra-okn': 'Halegannada',
  'dra-pro': 'Proto-Dravidian',
  'dru-pro': 'Proto-Rukai',
  'egx-dem': 'Demotic',
  'elu-prk': 'Helu',
  'esx-esk-pro': 'Proto-Eskimo',
  'esx-inu-pro': 'Proto-Inuit',
  'esx-pro': 'Proto-Eskimo-Aleut',
  'euq-pro': 'Proto-Basque',
  'fiu-fin-pro': 'Proto-Finnic',
  'gem-bur': 'Burgundian',
  'gem-pro': 'Proto-Germanic',
  'gme-cgo': 'Crimean Gothic',
  'gmq-bot': 'Westrobothnian',
  'gmq-gut': 'Gutnish',
  'gmq-jmk': 'Jamtish',
  'gmq-mno': 'Middle Norwegian',
  'gmq-oda': 'Old Danish',
  'gmq-osw': 'Old Swedish',
  'gmq-pro': 'Proto-Norse',
  'gmq-scy': 'Scanian',
  'gmw-cfr': 'Central Franconian',
  'gmw-ecg': 'East Central German',
  'gmw-gts': 'Gottscheerish',
  'gmw-jdt': 'Jersey Dutch',
  'gmw-rfr': 'Rhine Franconian',
  'gmw-stm': 'Sathmar Swabian',
  'gmw-tsx': 'Transylvanian Saxon',
  'gmw-vog': 'Volga German',
  'gmw-zps': 'Zipser German',
  'grk-cal': 'Calabrian Greek',
  'grk-ita': 'Italiot Greek',
  'grk-mar': 'Mariupol Greek',
  'grk-pro': 'Proto-Hellenic',
  'hmn-pro': 'Proto-Hmong',
  'hmx-mie-pro': 'Proto-Mien',
  'hmx-pro': 'Proto-Hmong-Mien',
  'hyx-pro': 'Proto-Armenian',
  'iir-nur-pro': 'Proto-Nuristani',
  'iir-pro': 'Proto-Indo-Iranian',
  'inc-ash': 'Ashokan Prakrit',
  'inc-dar-pro': 'Proto-Dardic',
  'inc-gup': 'Gurjar Apabhramsa',
  'inc-mas': 'Middle Assamese',
  'inc-mbn': 'Middle Bengali',
  'inc-mgd': 'Magadhi Prakrit',
  'inc-mgu': 'Middle Gujarati',
  'inc-mor': 'Middle Oriya',
  'inc-oas': 'Old Assamese',
  'inc-obn': 'Old Bengali',
  'inc-ogu': 'Old Gujarati',
  'inc-ohi': 'Old Hindi',
  'inc-oor': 'Old Oriya',
  'inc-pro': 'Proto-Indo-Aryan',
  'inc-sap': 'Sauraseni Apabhramsa',
  'ine-ana-pro': 'Proto-Anatolian',
  'ine-bsl-pro': 'Proto-Balto-Slavic',
  'ine-pae': 'Paeonian',
  'ine-pro': 'Proto-Indo-European',
  'ine-toc-pro': 'Proto-Tocharian',
  'ira-azr': 'Old Azari',
  'ira-ker': 'Kermanic',
  'ira-kls': 'Kalasuri',
  'ira-klt': 'Kilit',
  'ira-mny-pro': 'Proto-Munji-Yidgha',
  'ira-pro': 'Proto-Iranian',
  'ira-sak-pro': 'Proto-Saka',
  'ira-sgc-pro': 'Proto-Sogdic',
  'ira-sgi-pro': 'Proto-Sanglechi-Ishkashimi',
  'ira-shr-pro': 'Proto-Shughni-Roshani',
  'ira-shy-pro': 'Proto-Shughni-Yazgulami',
  'ira-sym-pro': 'Proto-Shughni-Yazghulami-Munji',
  'ira-wnj': 'Vanji',
  'iro-min': 'Mingo',
  'iro-pro': 'Proto-Iroquoian',
  'itc-ola': 'Old Latin',
  'itc-pro': 'Proto-Italic',
  'jpx-pro': 'Proto-Japonic',
  'jpx-ryu-pro': 'Proto-Ryukyuan',
  'kar-pro': 'Proto-Karen',
  'khi-kun': '\u01c3Kung',
  'lic-pro': 'Proto-Hlai',
  'map-ata-pro': 'Proto-Atayalic',
  'map-bms': 'Banyumasan',
  'map-pro': 'Proto-Austronesian',
  'mkh-ban-pro': 'Proto-Bahnaric',
  'mkh-kat-pro': 'Proto-Katuic',
  'mkh-khm-pro': 'Proto-Khmuic',
  'mkh-law': 'Lawi',
  'mkh-mkm': 'Middle Khmer',
  'mkh-mvi': 'Middle Vietnamese',
  'mkh-okm': 'Old Khmer',
  'mkh-pal-pro': 'Proto-Palaungic',
  'mkh-pea-pro': 'Proto-Pearic',
  'mkh-pro': 'Proto-Mon-Khmer',
  'mkh-vie-pro': 'Proto-Vietic',
  'mun-pro': 'Proto-Munda',
  'myn-pro': 'Proto-Mayan',
  'nai-ala': 'Alazapa',
  'nai-bay': 'Bayogoula',
  'nai-bvy': 'Buena Vista Yokuts',
  'nai-cal': 'Calusa',
  'nai-chi': 'Chiquimulilla',
  'nai-chu-pro': 'Proto-Chumash',
  'nai-cig': 'Ciguayo',
  'nai-ckn-pro': 'Proto-Chinookan',
  'nai-dly': 'Delta Yokuts',
  'nai-gsy': 'Gashowu',
  'nai-guz': 'Guazacap\u00e1n',
  'nai-hit': 'Hitchiti',
  'nai-ipa': 'Ipai',
  'nai-jtp': 'Jutiapa',
  'nai-jum': 'Jumaytepeque',
  'nai-kat': 'Kathlamet',
  'nai-knm': 'Konomihu',
  'nai-kry': 'Kings River Yokuts',
  'nai-kum': 'Kumeyaay',
  'nai-mac': 'Macoris',
  'nai-mdu-pro': 'Proto-Maidun',
  'nai-miz-pro': 'Proto-Mixe-Zoque',
  'nai-nao': 'Naolan',
  'nai-nrs': 'New River Shasta',
  'nai-nvy': 'Northern Valley Yokuts',
  'nai-okw': 'Okwanuchu',
  'nai-per': 'Peric\u00fa',
  'nai-pic': 'Picuris',
  'nai-ply': 'Palewyami',
  'nai-pom-pro': 'Proto-Pomo',
  'nai-qng': 'Quinigua',
  'nai-sca-pro': 'Proto-Siouan-Catawban',
  'nai-sin': 'Sinacant\u00e1n',
  'nai-sln': 'Salvadoran Lenca',
  'nai-spt': 'Sahaptin',
  'nai-svy': 'Southern Valley Yokuts',
  'nai-tap': 'Tapachultec',
  'nai-taw': 'Tawasa',
  'nai-tip': 'Tipai',
  'nai-tky': 'Tule-Kaweah Yokuts',
  'nai-tot-pro': 'Proto-Totozoquean',
  'nai-utn-pro': 'Proto-Utian',
  'nai-wai': 'Waikuri',
  'nai-yav': 'Yavapai',
  'nai-yup': 'Yupiltepeque',
  'nds-de': 'German Low German',
  'nds-nl': 'Dutch Low Saxon',
  'nic-bco-pro': 'Proto-Benue-Congo',
  'nic-bod-pro': 'Proto-Bantoid',
  'nic-gur-pro': 'Proto-Gur',
  'nic-pro': 'Proto-Niger-Congo',
  'nic-ubg-pro': 'Proto-Ubangian',
  'nub-har': 'Haraza',
  'nub-pro': 'Proto-Nubian',
  'omq-maz-pro': 'Proto-Mazatec',
  'omq-mix-pro': 'Proto-Mixtecan',
  'omq-pro': 'Proto-Oto-Manguean',
  'paa-kom': 'K\u00f3mnzo',
  'paa-kwn': 'Kuwani',
  'paa-nun': 'Nungon',
  'phi-din': 'Dinapigue Agta',
  'phi-kal-pro': 'Proto-Kalamian',
  'phi-nag': 'Nagtipunan Agta',
  'phi-pro': 'Proto-Philippine',
  'poz-abi': 'Abai',
  'poz-bal': 'Baliledo',
  'poz-btk-pro': 'Proto-Bungku-Tolaki',
  'poz-cet-pro': 'Proto-Central-Eastern Malayo-Polynesian',
  'poz-hce-pro': 'Proto-Halmahera-Cenderawasih',
  'poz-lgx-pro': 'Proto-Lampungic',
  'poz-mcm-pro': 'Proto-Malayo-Chamic',
  'poz-mly-pro': 'Proto-Malayic',
  'poz-msa-pro': 'Proto-Malayo-Sumbawan',
  'poz-oce-pro': 'Proto-Oceanic',
  'poz-pep-pro': 'Proto-Eastern Polynesian',
  'poz-pnp-pro': 'Proto-Nuclear Polynesian',
  'poz-pol-pro': 'Proto-Polynesian',
  'poz-pro': 'Proto-Malayo-Polynesian',
  'poz-ssw-pro': 'Proto-South Sulawesi',
  'poz-sus-pro': 'Proto-Sunda-Sulawesi',
  'poz-swa-pro': 'Proto-North Sarawak',
  'pqe-pro': 'Proto-Eastern Malayo-Polynesian',
  'qfa-cka-pro': 'Proto-Chukotko-Kamchatkan',
  'qfa-hur-pro': 'Proto-Hurro-Urartian',
  'qfa-kms-pro': 'Proto-Kam-Sui',
  'qfa-kor-pro': 'Proto-Korean',
  'qfa-tak-pro': 'Proto-Tai-Kadai',
  'qfa-yen-pro': 'Proto-Yeniseian',
  'qfa-yuk-pro': 'Proto-Yukaghir',
  'qwe-kch': 'Kichwa',
  'roa-ang': 'Angevin',
  'roa-bbn': 'Bourbonnais-Berrichon',
  'roa-brg': 'Bourguignon',
  'roa-cha': 'Champenois',
  'roa-fcm': 'Franc-Comtois',
  'roa-gal': 'Gallo',
  'roa-leo': 'Leonese',
  'roa-lor': 'Lorrain',
  'roa-oan': 'Navarro-Aragonese',
  'roa-oca': 'Old Catalan',
  'roa-ole': 'Old Leonese',
  'roa-opt': 'Old Portuguese',
  'roa-orl': 'Orl\u00e9anais',
  'roa-poi': 'Poitevin-Saintongeais',
  'roa-tar': 'Tarantino',
  'roa-tou': 'Tourangeau',
  'sai-all': 'Allentiac',
  'sai-and': 'Andoquero',
  'sai-ayo': 'Ayom\u00e1n',
  'sai-bae': 'Baenan',
  'sai-bag': 'Bagua',
  'sai-bet': 'Betoi',
  'sai-cac': 'Cac\u00e1n',
  'sai-caq': 'Caranqui',
  'sai-cat': 'Catacao',
  'sai-chi': 'Chirino',
  'sai-chn': 'Chan\u00e1',
  'sai-chp': 'Chapacura',
  'sai-chr': 'Charrua',
  'sai-chu': 'Churuya',
  'sai-cmg': 'Comechingon',
  'sai-cno': 'Chono',
  'sai-cnr': 'Ca\u00f1ari',
  'sai-coe': 'Coeruna',
  'sai-col': 'Col\u00e1n',
  'sai-cop': 'Copall\u00e9n',
  'sai-crd': 'Coroado Puri',
  'sai-ctq': 'Catuquinaru',
  'sai-cul': 'Culli',
  'sai-cva': 'Cueva',
  'sai-esm': 'Esmeralda',
  'sai-ewa': 'Ewarhuyana',
  'sai-gay': 'Gay\u00f3n',
  'sai-gmo': 'Guamo',
  'sai-gue': 'G\u00fcenoa',
  'sai-hau': 'Haush',
  'sai-jko': 'Jeik\u00f3',
  'sai-jrj': 'Jirajira',
  'sai-kat': 'Katembri',
  'sai-mal': 'Malal\u00ed',
  'sai-mar': 'Maratino',
  'sai-mat': 'Matanawi',
  'sai-mcn': 'Mocana',
  'sai-men': 'Menien',
  'sai-mil': 'Millcayac',
  'sai-mlb': 'Malibu',
  'sai-msk': 'Masakar\u00e1',
  'sai-muc': 'Mucuch\u00ed',
  'sai-mue': 'Muellama',
  'sai-muz': 'Muzo',
  'sai-mys': 'Maynas',
  'sai-nat': 'Nat\u00fa',
  'sai-opo': 'Op\u00f3n',
  'sai-oto': 'Otomaco',
  'sai-pal': 'Palta',
  'sai-pam': 'Pamigua',
  'sai-par': 'Parati\u00f3',
  'sai-pnz': 'Panzaleo',
  'sai-prh': 'Puruh\u00e1',
  'sai-pur': 'Purukot\u00f3',
  'sai-pyg': 'Payagu\u00e1',
  'sai-qmb': 'Quimbaya',
  'sai-qtm': 'Quitemo',
  'sai-rab': 'Rabona',
  'sai-ram': 'Ramanos',
  'sai-sac': 'S\u00e1cata',
  'sai-san': 'Sanaviron',
  'sai-sap': 'Sapar\u00e1',
  'sai-sec': 'Sechura',
  'sai-sin': 'Sin\u00fafana',
  'sai-tab': 'Tabancale',
  'sai-tal': 'Tall\u00e1n',
  'sai-teu': 'Teushen',
  'sai-tim': 'Timote',
  'sai-tpr': 'Taparita',
  'sai-trr': 'Tarairi\u00fa',
  'sai-wai': 'Waitak\u00e1',
  'sai-way': 'Wayumar\u00e1',
  'sai-wnm': 'Wanham',
  'sai-xoc': 'Xoc\u00f3',
  'sai-yao': 'Yao (South America)',
  'sai-yar': 'Yarum\u00e1',
  'sai-yup': 'Yupua',
  'sai-yur': 'Yurumangu\u00ed',
  'sal-pro': 'Proto-Salish',
  'sem-amm': 'Ammonite',
  'sem-amo': 'Amorite',
  'sem-cha': 'Chaha',
  'sem-dad': 'Dadanitic',
  'sem-dum': 'Dumaitic',
  'sem-has': 'Hasaitic',
  'sem-him': 'Himyaritic',
  'sem-his': 'Hismaic',
  'sem-mhr': 'Muher',
  'sem-pro': 'Proto-Semitic',
  'sem-saf': 'Safaitic',
  'sem-tay': 'Taymanitic',
  'sem-tha': 'Thamudic',
  'sio-pro': 'Proto-Siouan',
  'sit-bok': 'Bokar',
  'sit-cha': 'Chairel',
  'sit-gkh': 'Gokhy',
  'sit-jap': 'Japhug',
  'sit-liz': 'Lizu',
  'sit-mor': 'Moran',
  'sit-prn': 'Puiron',
  'sit-pro': 'Proto-Sino-Tibetan',
  'sit-sit': 'Situ',
  'sit-tan-pro': 'Proto-Tani',
  'sit-tgm': 'Tangam',
  'sit-tos': 'Tosu',
  'sit-tsh': 'Tshobdun',
  'sit-zbu': 'Zbu',
  'sla-pro': 'Proto-Slavic',
  'smi-pro': 'Proto-Samic',
  'son-pro': 'Proto-Songhay',
  'sqj-pro': 'Proto-Albanian',
  'syd-fne': 'Forest Nenets',
  'syd-pro': 'Proto-Samoyedic',
  'tai-pro': 'Proto-Tai',
  'tai-swe-pro': 'Proto-Southwestern Tai',
  'tbq-bdg-pro': 'Proto-Bodo-Garo',
  'tbq-kuk-pro': 'Proto-Kuki-Chin',
  'tbq-laz': 'Laze',
  'tbq-lol-pro': 'Proto-Loloish',
  'tbq-plg': 'Pai-lang',
  'tbq-pro': 'Proto-Tibeto-Burman',
  'trk-dkh': 'Dukhan',
  'trk-oat': 'Old Anatolian Turkish',
  'trk-ogz-pro': 'Proto-Oghuz',
  'trk-pro': 'Proto-Turkic',
  'tup-gua-pro': 'Proto-Tupi-Guarani',
  'tup-kab': 'Kabishiana',
  'tup-pro': 'Proto-Tupian',
  'tut-pro': 'Proto-Altaic',
  'tuw-pro': 'Proto-Tungusic',
  'tuw-sol': 'Solon',
  'und-isa': 'Isaurian',
  'und-kas': 'Kassite',
  'und-mil': 'Milang',
  'und-mmd': 'Mimi of Decorse',
  'und-mmn': 'Mimi of Nachtigal',
  'und-phi': 'Philistine',
  'und-wji': 'Western Jicaque',
  'urj-mdv-pro': 'Proto-Mordvinic',
  'urj-prm-pro': 'Proto-Permic',
  'urj-pro': 'Proto-Uralic',
  'urj-ugr-pro': 'Proto-Ugric',
  'xgn-kha': 'Khamnigan Mongol',
  'xgn-mgl': 'Mongghul',
  'xgn-mgr': 'Mangghuer',
  'xgn-pro': 'Proto-Mongolic',
  'xnd-pro': 'Proto-Na-Dene',
  'ypk-pro': 'Proto-Yupik',
  'zhx-min-pro': 'Proto-Min',
  'zhx-sht': 'Shaozhou Tuhua',
  'zhx-tai': 'Taishanese',
  'zhx-teo': 'Teochew',
  'zle-ono': 'Old Novgorodian',
  'zlw-ocs': 'Old Czech',
  'zlw-opl': 'Old Polish',
  'zlw-pom': 'Pomeranian',
} as const

export type LanguageCode = keyof typeof all_lang_dict
export type LanguageName =
  | typeof ALL_LANGUAGES_STRING
  | typeof all_lang_dict[LanguageCode]

const common_lang_dict = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  de: 'German',
  grc: 'Ancient Greek',
  la: 'Latin',
  pt: 'Portuguese',
  'ine-pro': 'Proto-Indo-European',
} as const

export { all_lang_dict, common_lang_dict }

// languages = stage_cursor.e('SELECT * FROM languages WHERE key_language=1')
// lang_dict = {l[1]: l[0] for l in languages}; lang_dict['en']
// f = open("/Users/nish/development/projects/wordkin/wordkin/utils/lang_dict.js","w")
// f.write('export const lang_dict={\n')
// # f.write('{\n')
// for item in lang_dict.items():
//     f.write(f'\t{json.dumps(item[0])}: {json.dumps(item[1])},\n')
// f.write('}')
// f.close()
