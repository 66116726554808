import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import MuiListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useOverlayDispatch } from 'overlay/Controller'
import { usePathNavigate, usePathParams } from 'pathManager/hooks'
import { MouseEvent, useCallback } from 'react'
import { useNavigate } from 'router'
import { Path } from 'router/constants'
import { pathToInfo } from 'router/functions'
import { useRootPath } from 'utils/hooks'

type ListItemProps = {
  path: Path
  leaveSidebarOpen?: boolean
}
export const SidebarListItem = ({ path, leaveSidebarOpen }: ListItemProps) => {
  const overlayDispatch = useOverlayDispatch()
  const pathNavigate = usePathNavigate()
  const { word, language } = usePathParams()
  const { title, PathIcon, hideInfoIcon } = pathToInfo(path)

  const navigate = useNavigate()
  const rootPath = useRootPath()

  const handleClick = useCallback(() => {
    if (!leaveSidebarOpen) {
      overlayDispatch('normal')
    }
    pathNavigate({ word, language, component: path })
  }, [leaveSidebarOpen, pathNavigate, word, language, path, overlayDispatch])

  const handleInfoClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      overlayDispatch('normal')
      navigate(`/${Path.INFO}${hideInfoIcon === undefined ? '#' + path : ''}`)
    },
    [overlayDispatch, navigate, hideInfoIcon, path]
  )

  return (
    <MuiListItem
      button
      selected={rootPath === path}
      style={{ paddingLeft: 22 }}
      onClick={handleClick}
    >
      {PathIcon && (
        <ListItemIcon>
          <PathIcon />
        </ListItemIcon>
      )}
      <ListItemText primary={title} />
      {hideInfoIcon !== true && (
        <IconButton onClick={handleInfoClick}>
          <InfoOutlinedIcon color="disabled" />
        </IconButton>
      )}
    </MuiListItem>
  )
}
