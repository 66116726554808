import Drawer, { DrawerProps } from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import { useOverlayStore } from 'overlay/Controller'
import { DRAWER_WIDTH } from 'utils/constants'
import Appbar from './Appbar'
import Main from './Main'
import { isMobile } from 'react-device-detect'
import { ThemeConstants } from 'theme/constants'

const Sidebar = () => {
  const overlayStore = useOverlayStore()

  const drawerOpen = overlayStore === 'main' || overlayStore === 'settings'

  return (
    <SideBarWrapper variant="persistent" open={drawerOpen} anchor="left">
      <Appbar />
      {overlayStore === 'main' ? <Main /> : null}
    </SideBarWrapper>
  )
}
export default Sidebar

const SideBarWrapper = styled(Drawer)<DrawerProps>({
  zIndex: ThemeConstants.Z_INDEX_SIDEBAR,
  pointerEvents: 'auto',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: isMobile ? '100%' : DRAWER_WIDTH,
    boxSizing: 'border-box',
  },
})
