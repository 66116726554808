import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useKeyPress } from 'utils/hooks'

type State = ActionPoint | undefined

type Action = Dispatch<SetStateAction<ActionPoint | undefined>>

const initialState: State = undefined

type ActionPoint = {
  x: number
  y: number
  word: string
  language: string
}

const StoreContext = createContext<State>(initialState)
const DispatchContext = createContext<Action>(() => undefined)

export const useActionPointStore = () => useContext(StoreContext)
export const useActionPointDispatch = () => useContext(DispatchContext)

export const ActionPointController = ({
  children,
}: {
  children: ReactNode
}) => {
  const [point, setPoint] = useState<ActionPoint>()
  const escapePressed = useKeyPress('Escape')

  useEffect(() => {
    escapePressed && setPoint(undefined)
  }, [escapePressed])

  return (
    <DispatchContext.Provider value={setPoint}>
      <StoreContext.Provider value={point}>{children}</StoreContext.Provider>
    </DispatchContext.Provider>
  )
}
