import { lazyLoad } from 'utils/loadable'
import { LoadingPage } from 'pages/LoadingPage'

export default lazyLoad(
  () => import('./index'),
  (module) => module.default,
  {
    fallback: <LoadingPage />,
  }
)
