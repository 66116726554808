import { useAuthStore } from 'auth/hooks'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { StorageItems, getStorageItem } from 'utils/storage'
import { LocationState } from 'utils/types'

export const AuthRoute = () => {
  const { user } = useAuthStore()
  const { state } = useLocation()
  const authRedirect = getStorageItem(StorageItems.AUTH_REDIRECT)
  const redirectUrl =
    (state as LocationState)?.from?.pathname ?? authRedirect ?? '/'
  return user?.isAnonymous === false ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <Outlet />
  )
}
