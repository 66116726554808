import { styled } from '@mui/material/styles'
import TextField, { TextFieldProps } from '@mui/material/TextField'

const AutocompleteTextField = styled(TextField)<TextFieldProps>(() => ({
  '.MuiInputBase-input.MuiAutocomplete-input': {
    fontWeight: 500,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    visibility: 'hidden',
  },
  '& .MuiInputLabel-root.MuiInputLabel-outlined.MuiFormLabel-root': {
    // opacity: Boolean(console.log('value', value)) || Boolean(value) ? 0 : 1,
  },
  '& .MuiInputLabel-root.MuiInputLabel-outlined.MuiFormLabel-root.Mui-focused':
    {
      color: 'inherit',
      opacity: 0,
      // animation: 'slide',
      // animationDuration: '1.5s',
      // animationTimingFunction: 'ease-out',
    },
}))

export default AutocompleteTextField
