import { LoadingPage } from 'pages/LoadingPage'
import { Navigate } from 'react-router-dom'
import { DEFAULT_CHILD_PATH } from 'router/constants'
import { useWordsOfTheDay } from 'wotd/hooks'

export const WotdRedirect = () => {
  const { todaysWotd } = useWordsOfTheDay(1)
  return !todaysWotd ? (
    <LoadingPage />
  ) : (
    <Navigate
      to={`/${DEFAULT_CHILD_PATH}/${todaysWotd?.language}/${todaysWotd?.word}`}
      replace
    />
  )
}
