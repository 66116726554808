import { PathParams } from 'pathManager/types'
import { useCallback, useEffect, useState } from 'react'
import { ThemeKey } from 'theme/constants'
import { PromotionTimestamps, WordOfTheDayType } from 'types/backend-types'

const VERSION = 'v0' // local storage version
const APP = 'etymology-explorer-web'

export enum StorageItems {
  FIRST_LOGIN = `first-login`,
  THEME = `theme`,
  SEARCH_LANGUAGE = `search-language`,
  PROMOTIONS = `promotions`,
  FIREBASE_TOKEN = `firebase_token`,
  AUTH_REDIRECT = `auth_redirect`,
  PATH_PARAMS = `last_path_params`,
  VIEWED_JOIN_MAIL_LISTS = `viewed_join_mail_lists`,
  LAST_WOTD_VIEWED_DATE_STRING = `last_wotd_viewed_date_string`,
  LAST_WOTD_ROOT_VIEWED_DATE_STRING = `last_wotd_root_viewed_date_string`,
}

const StorageLocations: { [key in StorageItems]: 'local' | 'session' } = {
  [StorageItems.FIRST_LOGIN]: 'local',
  [StorageItems.THEME]: 'local',
  [StorageItems.SEARCH_LANGUAGE]: 'local',
  [StorageItems.PROMOTIONS]: 'local',
  [StorageItems.FIREBASE_TOKEN]: 'session',
  [StorageItems.AUTH_REDIRECT]: 'local',
  [StorageItems.PATH_PARAMS]: 'local',
  [StorageItems.VIEWED_JOIN_MAIL_LISTS]: 'local',
  [StorageItems.LAST_WOTD_VIEWED_DATE_STRING]: 'local',
  [StorageItems.LAST_WOTD_ROOT_VIEWED_DATE_STRING]: 'local',
}

type StorageItemTypes = {
  [StorageItems.FIRST_LOGIN]: string
  [StorageItems.THEME]: ThemeKey
  [StorageItems.SEARCH_LANGUAGE]: string
  [StorageItems.PROMOTIONS]: PromotionTimestamps
  [StorageItems.FIREBASE_TOKEN]: string
  [StorageItems.VIEWED_JOIN_MAIL_LISTS]: boolean
  [StorageItems.AUTH_REDIRECT]: string
  [StorageItems.PATH_PARAMS]: Partial<PathParams>
  [StorageItems.LAST_WOTD_VIEWED_DATE_STRING]: WordOfTheDayType['date']
  [StorageItems.LAST_WOTD_ROOT_VIEWED_DATE_STRING]: WordOfTheDayType['date']
}
type StorageItem = typeof StorageItems[keyof typeof StorageItems]

type StorageKey<I extends StorageItem> = `${typeof APP}-${I}-${typeof VERSION}`

type StorageKeyDict = { [item in StorageItem]: StorageKey<item> }

export const STORAGE_KEYS: StorageKeyDict = Object.values(StorageItems).reduce(
  (accu, item) => ({
    ...accu,
    [item]: `${APP}-${item}-${VERSION}`,
  }),
  {} as StorageKeyDict
)

export const getStorageItem = <T extends StorageItem>(
  item: T
): StorageItemTypes[T] | undefined => {
  if (StorageLocations[item] === 'local') return getLocalStorageItem(item)
  if (StorageLocations[item] === 'session') return getSessionStorageItem(item)
  throw new Error(`Unknown storage type for ${item}`)
}

export const setStorageItem = <T extends StorageItem>(
  item: T,
  value: StorageItemTypes[T] | null,
  preventDispatchIfPossible = false
) => {
  if (StorageLocations[item] === 'local') {
    setLocalStorageItem(item, value, preventDispatchIfPossible)
    return
  }
  if (StorageLocations[item] === 'session') {
    setSessionStorageItem(item, value, preventDispatchIfPossible)
    return
  }
  throw new Error(`Unknown storage type for ${item}`)
}

const getLocalStorageItem = <T extends StorageItem>(
  item: T
): StorageItemTypes[T] | undefined => {
  const key = STORAGE_KEYS[item]
  const raw_value = localStorage.getItem(key)
  let return_value = undefined
  try {
    if (raw_value === 'undefined' || raw_value === null) {
      return_value = undefined
    } else {
      return_value = JSON.parse(raw_value)
    }
  } catch (e) {
    console.warn({ e, return_value })
  }
  // console.log(`Returning ${JSON.stringify(return_value)} for ${key}`)
  return return_value
}

/**
 * Local storage saves null and undefined as strings, so this function deletes it instead
 */
const setLocalStorageItem = <T extends StorageItem>(
  item: T,
  value: StorageItemTypes[T] | null,
  preventDispatchIfPossible = false
) => {
  const key = STORAGE_KEYS[item]
  if (value === null || value === undefined) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, JSON.stringify(value))
  }
  !preventDispatchIfPossible && window.dispatchEvent(new Event('storage'))
}

export const removeStorageItem = () => {
  throw new Error('Use setLocalStorageItem with null to remove')
}

export const clearAllLocalStorage = (preventDispatchIfPossible = false) => {
  Object.values(StorageItems).forEach((item) => {
    const key = STORAGE_KEYS[item]
    localStorage.removeItem(key)
  })
  !preventDispatchIfPossible && window.dispatchEvent(new Event('storage'))
}

const getSessionStorageItem = <T extends StorageItem>(
  item: T
): StorageItemTypes[T] | undefined => {
  const key = STORAGE_KEYS[item]
  const raw_value = sessionStorage.getItem(key)
  let return_value = undefined
  try {
    if (raw_value === 'undefined' || raw_value === null) {
      return_value = undefined
    } else {
      return_value = JSON.parse(raw_value)
    }
  } catch (e) {
    console.warn({ e, return_value })
  }
  // console.log(`Returning ${JSON.stringify(return_value)} for ${key}`)
  return return_value
}

const setSessionStorageItem = <T extends StorageItem>(
  item: T,
  value: StorageItemTypes[T] | null,
  preventDispatchIfPossible = false
) => {
  const key = STORAGE_KEYS[item]
  if (value === null || value === undefined) {
    sessionStorage.removeItem(key)
  } else {
    sessionStorage.setItem(key, JSON.stringify(value))
    !preventDispatchIfPossible && window.dispatchEvent(new Event('storage'))
  }
}

export const clearAllSessionStorage = () => {
  Object.values(StorageItems).forEach((item) => {
    const key = STORAGE_KEYS[item]
    sessionStorage.removeItem(key)
  })
  window.dispatchEvent(new Event('storage'))
}

export const useStoreOnChange = <T extends StorageItems>(
  item: T,
  value: StorageItemTypes[T]
) => {
  useEffect(() => {
    const storedValue = getStorageItem(item)
    if (storedValue !== value) {
      console.debug('Updating value in local storate', item, value)
      setStorageItem(item, value)
    }
  }, [item, value])
}

export const useStorage = <T extends StorageItems>(
  itemKey: T
): StorageItemTypes[T] | undefined => {
  const [value, setValue] = useState<StorageItemTypes[T] | undefined>(
    getStorageItem(itemKey)
  )

  const storageListener = useCallback(() => {
    const newValue = getStorageItem(itemKey)
    console.log('storageListener update', itemKey, newValue)
    setValue(newValue)
  }, [itemKey])

  useEffect(() => {
    window.addEventListener('storage', storageListener)
    return () => window.removeEventListener('storage', storageListener)
  }, [storageListener])

  // console.log('useStorage', itemKey, value)

  return value
}
