import { Truthy } from 'utils/functions'
import { AuthPage, ChildPage, FreePage, RootPage, WordPage } from 'router/types'
import { ConnectionDirection, CONNECTION_DIRECTIONS } from './types'
import { PathHelper, Path } from 'router/constants'
import * as H from 'history'
import { WordStrata } from 'componentData/types'

export const isPath = (path: string): path is Path =>
  Object.values(Path).includes(path as Path)

export const isWordComponent = (path: Path | undefined): path is WordPage =>
  PathHelper.WORD_COMPONENTS.includes(path as WordPage)

export const isAuthComponent = (path: Path | undefined): path is AuthPage =>
  PathHelper.AUTH_COMPONENTS.includes(path as AuthPage)

export const isChildFocusedComponent = (
  path: Path | undefined
): path is ChildPage =>
  PathHelper.CHILD_FOCUSED_COMPONENTS.includes(path as ChildPage)

export const isRootFocusedComponent = (
  path: Path | undefined
): path is RootPage =>
  PathHelper.ROOT_FOCUSED_COMPONENTS.includes(path as RootPage)

export const isFreeComponent = (path: string | undefined): path is FreePage =>
  PathHelper.FREE_COMPONENTS.includes(path as FreePage)

export const isConnectionDirection = (
  str: string
): str is Truthy<ConnectionDirection> =>
  CONNECTION_DIRECTIONS.includes(str as ConnectionDirection)

export const getPathParamsFromLocation = (location: H.Location) => {
  let word: string | undefined = undefined
  let language: string | undefined = undefined
  let id: string | undefined = undefined
  let direction: ConnectionDirection = undefined
  let component: Path | undefined = undefined
  const path: string | undefined = location.pathname

  const pathParts = location.pathname
    .split('/')
    .filter((p, idx) => idx === 0 || p !== '')
  if (pathParts.length >= 2 && isPath(pathParts[1])) {
    component = pathParts[1]
  }

  if (pathParts.length === 3) {
    word = decodeURI(pathParts[2])
  } else if (pathParts.length === 4 && pathParts[2] === 'id') {
    id = pathParts[3]
  } else if (pathParts.length === 4 && pathParts[2] !== 'id') {
    language = decodeURI(pathParts[2])
    word = decodeURI(pathParts[3])
  } else if (pathParts.length >= 5) {
    language = decodeURI(pathParts[2])
    word = decodeURI(pathParts[3])
    if (isConnectionDirection(pathParts[4])) {
      direction = pathParts[4]
    }
  }

  return { word, language, id, direction, component, path }
}

export const getPathWordStrata = (path: Path | undefined): WordStrata =>
  !path
    ? WordStrata.ANY
    : isRootFocusedComponent(path)
    ? WordStrata.ROOT
    : isChildFocusedComponent(path)
    ? WordStrata.CHILD
    : WordStrata.ANY
