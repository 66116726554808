import { getPathWordStrata } from 'pathManager/functions'
import { usePathParams } from 'pathManager/hooks'
import { useInfiniteQuery } from 'react-query'
import { Path } from 'router/constants'
import { WordOfTheDayType } from 'types/backend-types'
import { dateToFirebaseDate } from 'utils/functions'
import { getStorageItem, StorageItems } from 'utils/storage'
import { getWordsOfTheDay } from './functions'
import { WordOfTheDayQueryKey, WOTD_QUERY_KEY } from './types'

const DEFAULT_WOTD_AMOUNT = 15
export const useWordsOfTheDay = (amount = DEFAULT_WOTD_AMOUNT) => {
  // console.log('useWordsOfTheDay', amount)
  const { component, word, language } = usePathParams()
  const wordStrata = getPathWordStrata(component)
  const {
    data: wotdData,
    isSuccess,
    ...rest
  } = useInfiniteQuery<
    WordOfTheDayType[],
    Error,
    WordOfTheDayType[],
    WordOfTheDayQueryKey
  >(
    WOTD_QUERY_KEY,
    ({ pageParam }) =>
      getWordsOfTheDay(
        pageParam ? amount : Math.max(amount, DEFAULT_WOTD_AMOUNT),
        pageParam
      ),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage?.[lastPage.length - 1]?.date,
    }
  )
  const lastSeenWotdDateString = getStorageItem(
    StorageItems.LAST_WOTD_VIEWED_DATE_STRING
  )

  const allWotds: WordOfTheDayType[] =
    wotdData?.pages.reduce<WordOfTheDayType[]>(
      (accu, wotd) => [...accu, ...wotd],
      []
    ) ?? []

  const today = dateToFirebaseDate(new Date())
  const todaysWotd = allWotds?.find(({ date }) => date === today)
  const todaysWotdRoot = todaysWotd
    ? {
        word: todaysWotd.group_word,
        language: todaysWotd.group_language,
        id: todaysWotd.group_id,
        date: todaysWotd.date,
      }
    : undefined

  const todaysWotdUrl = !todaysWotd
    ? `/${Path.WOTD_LIST}`
    : wordStrata === 'root' &&
      todaysWotd?.group_word &&
      todaysWotd.group_language
    ? `/${component}/${todaysWotd.group_language}/${todaysWotd.group_word}`
    : `/${component}/${todaysWotd.language}/${todaysWotd.word}`

  const isWotd =
    todaysWotd && todaysWotd.word === word && todaysWotd?.language === language

  const isWotdRoot =
    todaysWotd &&
    todaysWotd.group_word === word &&
    todaysWotd?.group_language === language

  const hasSeenTodaysWotd =
    isSuccess &&
    lastSeenWotdDateString !== undefined &&
    lastSeenWotdDateString === todaysWotd?.date
  // console.log(
  //   'hasSeenTodaysWotd',
  //   hasSeenTodaysWotd,
  //   lastSeenWotdDateString,
  //   todaysWotd?.date
  // )

  return {
    wotdData,
    allWotds,
    todaysWotd,
    todaysWotdRoot,
    todaysWotdUrl,
    isWotd,
    isWotdRoot,
    hasSeenTodaysWotd,
    isSuccess,
    ...rest,
  }
}

// export const useFreeWordsOfTheDay = () => {
//   const { component } = usePathParams()
//   const wordStrata = getPathWordStrata(component)

//   const { data: freeWotdData, ...rest } = useQuery<
//     WordOfTheDayType[],
//     Error,
//     WordOfTheDayType[],
//     FreeWotdQueryKey
//   >(FREE_WOTD_QUERY_KEY, () => getWordsOfTheDay(FREE_WOTDS))

//   const today = dateToFirebaseDate(new Date())
//   const todaysWotd = freeWotdData?.find(({ date }) => date === today)

//   const todaysWotdUrl = !todaysWotd
//     ? `/${Path.WOTD}`
//     : wordStrata === 'root' &&
//       todaysWotd?.group_word &&
//       todaysWotd.group_language
//     ? `/${component}/${todaysWotd.group_language}/${todaysWotd.group_word}`
//     : `/${component}/${todaysWotd.language}/${todaysWotd.word}`

//   return { freeWotdData, todaysWotd, todaysWotdUrl, ...rest }
// }

// export const useWotdGroups = () => {
//   const { data: wotdGroupData, ...rest } = useQuery<
//     WotdGroupDict,
//     Error,
//     WotdGroupDict,
//     WotdGroupQueryKey
//   >(WOTD_GROUP_QUERY_KEY, () => getWotdGroups(), {
//     keepPreviousData: true,
//   })
//   return { wotdGroupData, ...rest }
// }

// export const useGroupedWotds = () => {
//   const {
//     wotdData,
//     fetchNextPage,
//     isLoading: isLoadingWordsOfTheDay,
//   } = useWordsOfTheDay()
//   const { wotdGroupData = {}, isLoading: isLoadingWotdGroups } = useWotdGroups()
//   // How should I get all the wotds grouped? go through the WOTDs, and put them in the right
//   // group based on date they fall into

//   const isLoading = isLoadingWordsOfTheDay || isLoadingWotdGroups

//   const allWotds: WordOfTheDayType[] =
//     wotdData?.pages.reduce<WordOfTheDayType[]>(
//       (accu, wotd) => [...accu, ...wotd],
//       []
//     ) ?? []

//   const groupedWotds: WotdGroupWithWotds[] = Object.values(wotdGroupData)
//     .map((wotdGroup) => ({
//       ...wotdGroup,
//       wotds: allWotds.filter(
//         (wotd) =>
//           wotd.date >= wotdGroup.start &&
//           (!wotdGroup.end || wotd.date <= wotdGroup.end)
//       ),
//     }))
//     .sort((a, b) => (a.start < b.start ? 1 : -1))
//     .filter((group) => group.wotds.length > 0)

//   return { groupedWotds, fetchNextPage, isLoading }
// }
