import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useKeyPress } from 'utils/hooks'
import { OverlayState } from './types'

const OverlayStoreContext = createContext<OverlayState>('normal')
const OverlayDispatchContext = createContext<Dispatch<OverlayState>>(
  () => undefined
)

export const useOverlayDispatch = () => useContext(OverlayDispatchContext)
export const useOverlayStore = () => useContext(OverlayStoreContext)

export const OverlayController = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  const [state, setState] = useState<OverlayState>('normal')
  const escapePressed = useKeyPress('Escape')

  useEffect(() => {
    escapePressed && setState('normal')
  }, [escapePressed])

  useEffect(() => {
    setState('normal')
  }, [location])

  return (
    <OverlayDispatchContext.Provider value={setState}>
      <OverlayStoreContext.Provider value={state}>
        {children}
      </OverlayStoreContext.Provider>
    </OverlayDispatchContext.Provider>
  )
}
