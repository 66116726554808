import { AuthController } from 'auth/Controller'
import { ChannelController } from 'channel'
import { ActionPointController } from 'contexts/ActionPointController'
import { AppController } from 'contexts/AppContext'
import { HelmetController } from 'contexts/HelmetController'
import { SearchLanguageController } from 'contexts/SearchLanguageController'
import SnackbarController from 'contexts/SnackbarController'
import { DialogController } from 'dialog'
import { ReactNode, StrictMode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RouterController } from 'router/Controller'
import { ThemeController } from 'theme'

const AppProviders = ({
  queryClient,
  children,
}: {
  queryClient: QueryClient
  children: ReactNode
}) => (
  //ThemeController must be above DialogController for theme Dialog
  <ThemeController>
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterController>
          {/* HelmetProvider must be inside Router for access to location */}
          <HelmetController>
            <ChannelController>
              {/* auth must be inside the queryClient to prefetch subscription information*/}
              <AuthController>
                {/* SearchLanguage must be above DialogController for language Dialog */}
                <SearchLanguageController>
                  <DialogController>
                    {/* Snackbar must be above app for data loading snacks */}
                    <SnackbarController>
                      {/* Action Point user is in Overlay in App.tsx */}
                      <ActionPointController>
                        <AppController>{children}</AppController>
                      </ActionPointController>
                    </SnackbarController>
                  </DialogController>
                </SearchLanguageController>
              </AuthController>
            </ChannelController>
          </HelmetController>
        </RouterController>
      </QueryClientProvider>
    </StrictMode>
  </ThemeController>
)

export default AppProviders
