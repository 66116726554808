import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningIcon from '@mui/icons-material/Warning'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { keyframes, styled } from '@mui/material/styles'
import {
  useFrequencyType,
  useNavigateToRandomEtymology,
} from 'componentData/hooks'
import { WordStrata } from 'componentData/types'
import { TextActionLink } from 'components'
import { useOverlayDispatch } from 'overlay/Controller'
import { Other } from 'pages/InfoPage'
import { usePathNavigate, usePathParams } from 'pathManager/hooks'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'router'
import { DEFAULT_CHILD_PATH, Path } from 'router/constants'
import { pathToInfo } from 'router/functions'
import { useActivePlan } from 'subscriptions'
import { SEARCH_BAR_WIDTH } from 'utils/constants'
import { snakeCaseToTitleCase } from 'utils/functions'
import { useWordsOfTheDay } from 'wotd/hooks'
import { Link } from './Link'

const enum ExplorerError {
  ROOT_IN_CHILD_EXPLORER = 'root_in_child_explorer',
  CHILD_IN_ROOT_EXPLORER = 'child_in_root_explorer',
}

const RootInChildExplorerError = () => {
  const { hasPremium } = useActivePlan()
  const navigateToRandomEtymology = useNavigateToRandomEtymology(false)
  const { todaysWotd } = useWordsOfTheDay(1)
  const { word, language } = usePathParams()
  const pathNavigate = usePathNavigate()
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StyledErrorIcon color="error" style={{ marginRight: 10 }} />
      <div>
        This view is intended for{' '}
        <Link to={`/${Path.INFO}#${Other.CHILDREN_AND_ROOT_PAGES}`}>child</Link>{' '}
        words since it shows a word{"'"}s roots. Try exploring{' '}
        {!hasPremium && todaysWotd ? (
          <TextActionLink
            onClick={() =>
              pathNavigate({
                word: todaysWotd.word,
                language: todaysWotd.language,
              })
            }
          >
            the word of the day
          </TextActionLink>
        ) : (
          <TextActionLink
            onClick={() => navigateToRandomEtymology(WordStrata.CHILD)}
          >
            a random descendant word
          </TextActionLink>
        )}{' '}
        or a{' '}
        <Link to={`/${Path.NODE_CHILD}/${language}/${word}`}>
          root explorer
        </Link>{' '}
      </div>
    </div>
  )
}

const ChildInRootExplorerError = () => {
  const { hasPremium } = useActivePlan()
  const { word, language } = usePathParams()
  const { todaysWotd } = useWordsOfTheDay(1)
  const navigateToRandomEtymology = useNavigateToRandomEtymology(false)
  const pathNavigate = usePathNavigate()
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <WarningIcon color="error" style={{ marginRight: 10 }} />
      <div>
        This view is intended for{' '}
        <Link to={`/${Path.INFO}#${Other.CHILDREN_AND_ROOT_PAGES}`}>root</Link>{' '}
        words since it shows a word{"'"}s descendants. Try exploring{' '}
        {!hasPremium && todaysWotd?.group_word && todaysWotd?.group_language ? (
          <TextActionLink
            onClick={() =>
              pathNavigate({
                word: todaysWotd.group_word,
                language: todaysWotd.group_language,
              })
            }
          >
            the word of the day
          </TextActionLink>
        ) : (
          <TextActionLink
            onClick={() => navigateToRandomEtymology(WordStrata.ROOT)}
          >
            a random root word
          </TextActionLink>
        )}{' '}
        or a{' '}
        <Link to={`/${DEFAULT_CHILD_PATH}/${language}/${word}`}>
          descendant explorer
        </Link>{' '}
      </div>
    </div>
  )
}

const ComponentIndicator = () => {
  const { component, word, language } = usePathParams()
  const navigate = useNavigate()
  const overlayDispatch = useOverlayDispatch()
  const { wordStrata } = useFrequencyType()
  const [showErrors, setShowErrors] = useState(false)
  const rootishWord = wordStrata === WordStrata.ROOT
  const childishWord = wordStrata === WordStrata.CHILD
  const rootExplorer =
    component &&
    [Path.PROGENY_TABLE, Path.PROGENY_TREE, Path.NODE_CHILD].includes(component)
  const childExplorer = component && [Path.TREE, Path.NODE].includes(component)
  const errors =
    rootishWord && childExplorer
      ? [ExplorerError.ROOT_IN_CHILD_EXPLORER]
      : childishWord && rootExplorer
      ? [ExplorerError.CHILD_IN_ROOT_EXPLORER]
      : []

  useEffect(() => {
    setShowErrors(false)
  }, [component, word, language])

  const { PathIcon } = pathToInfo(component)

  return (
    <Wrapper elevation={3} onMouseLeave={() => setShowErrors(false)}>
      <TopBarWrapper onClick={() => overlayDispatch('main')}>
        <div
          style={{
            fontWeight: 500,
            display: 'flex',
            gap: 10,
            pointerEvents: 'none',
            WebkitTouchCallout: 'none',
            WebkitUserSelect: 'none',
            KhtmlUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none',
          }}
        >
          <IconButton style={{ padding: 0 }}>
            <PathIcon color="action" />
          </IconButton>
          {snakeCaseToTitleCase(component ?? '', 'word')} Explorer
        </div>
        <div style={{ display: 'flex', gap: 8 }}>
          {errors.length <= 0 ? null : (
            <IconButton
              style={{ padding: 0 }}
              onMouseEnter={() => setShowErrors(true)}
              onClick={(e) => {
                if (isMobile) {
                  e.stopPropagation()
                  setShowErrors((e) => !e)
                }
              }}
            >
              <StyledErrorIcon color="error" />
            </IconButton>
          )}
          <IconButton
            style={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation()
              navigate(`/${Path.INFO}${'#' + component}`)
            }}
          >
            <InfoOutlinedIcon color="disabled" />
          </IconButton>
        </div>
      </TopBarWrapper>
      {showErrors && (
        <BottomBarWrapper>
          <Divider />
          <div
            style={{
              paddingTop: '0.5rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            {errors.map((error) =>
              error === ExplorerError.CHILD_IN_ROOT_EXPLORER ? (
                <ChildInRootExplorerError />
              ) : (
                <RootInChildExplorerError />
              )
            )}
          </div>
        </BottomBarWrapper>
      )}
    </Wrapper>
  )
}

export default ComponentIndicator

const TopBarWrapper = styled('div')({
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'space-between',
  paddingLeft: '1rem',
  paddingRight: '1rem',
})

const BottomBarWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingTop: '0.5rem',
})

const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  width: isMobile ? '100%' : SEARCH_BAR_WIDTH,
  pointerEvents: 'all',
  flex: 1,
})

const rotate = keyframes`
0% {
		transform: scale(0.6);
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.7);
    opacity: 0.3;
	}

	100% {
		transform: scale(0.9);
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.7);
    opacity: 1;
	}
`

const StyledErrorIcon = styled(WarningIcon)`
  animation: ${rotate} 1s alternate infinite;
  animation-timing-function: ease-out;
  border-radius: 50%;
  padding: 0.2rem;
  color: red;
`
