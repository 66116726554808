import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import { Row } from 'components/Row'
import { useSearchLanguage } from 'contexts/SearchLanguageController'
import { Dispatch, forwardRef, SetStateAction } from 'react'
import { isMobile } from 'react-device-detect'
import { ALL_LANGUAGES_STRING, DEFAULT_LANGUAGE } from 'utils/constants'
import AutocompleteTextField from './AutocompleteTextField'
import { MenuButton } from './MenuButton'
import { RandomButton } from './RandomButton'
import { SearchLanguage } from './SearchLanguage'

type SearchInputProps = {
  params: AutocompleteRenderInputParams
  setSearchWord: Dispatch<SetStateAction<string>>
}
export const SearchInput = forwardRef<HTMLDivElement, SearchInputProps>(
  ({ params, setSearchWord }, ref) => {
    const { searchLanguage } = useSearchLanguage()
    return (
      <Row ref={ref} alignItems="center">
        <MenuButton />
        <AutocompleteTextField
          {...params}
          onChange={(e) => setSearchWord(e.currentTarget.value)}
          placeholder={`Search${
            searchLanguage === DEFAULT_LANGUAGE
              ? ''
              : searchLanguage === ALL_LANGUAGES_STRING
              ? ' words in any language'
              : ' ' + searchLanguage + ' words'
          }`}
          InputLabelProps={{
            shrink: false,
          }}
          size="small"
          style={{ flex: 1 }}
        />
        <RandomButton />
        {isMobile && <SearchLanguage />}
      </Row>
    )
  }
)

SearchInput.displayName = 'SearchInput'

// const AbsoluteWrapper = styled('div')({
//   position: 'absolute',
//   display: 'flex',
//   flexDirection: 'row',
// })

// const SearchBarWrapper = styled(Paper)(({ theme }) => ({
//   // ...globalStyles.shadow,
//   width: SEARCH_BAR_WIDTH,
//   borderRadius: 8,
//   pointerEvents: 'all',

//   alignItems: 'center',
//   boxSizing: 'border-box',
//   paddingLeft: 10,
//   paddingRight: 10,
// }))
