import { createContext, Dispatch } from 'react'
import { Action, State } from './types'

export const DispatchContext = createContext<Dispatch<Action>>(() => undefined)

export const INITIAL_STATE: State = {
  alert: {
    title: '',
    subtitle: '',
    open: false,
  },
  language: {
    open: false,
  },
  theme: {
    open: false,
  },
  invitation: {
    open: false,
  },
}
