import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useOverlayDispatch, useOverlayStore } from 'overlay/Controller'
import { useCallback } from 'react'

const Appbar = () => {
  const overlayDispatch = useOverlayDispatch()
  const overlayStore = useOverlayStore()

  const handleClose = useCallback(() => {
    overlayDispatch('normal')
  }, [overlayDispatch])

  const title = overlayStore === 'main' ? 'EtymologyExplorer' : 'Unknown'

  return (
    <AppBar position="static">
      <Toolbar>
        <Box
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
export default Appbar
