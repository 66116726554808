import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const TreeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 790 612">
      <rect x="239" y="416" width="312" height="196" rx="20" />
      <rect x="477" width="313" height="196" rx="20" />
      <rect width="313" height="196" rx="20" />
      <path d="M602 196H665V298C665 311.807 653.807 323 640 323H602V196Z" />
      <path d="M125 196H188V323H150C136.193 323 125 311.807 125 298V196Z" />
      <rect x="364" y="323" width="62" height="93" />
      <rect x="188" y="259" width="414" height="64" />
    </SvgIcon>
  )
}
