import TodayIcon from '@mui/icons-material/Today'
import ReportIcon from '@mui/icons-material/Report'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material/SvgIcon'
import { ChildrenPackIcon } from 'components/icons/ChildrenPackIcon'
import { ChildrenTableIcon } from 'components/icons/ChildrenTableIcon'
import { ConnectionsIcon } from 'components/icons/ConnectionsIcon'
import { NodeChildrenIcon } from 'components/icons/NodeChildrenIcon'
import ArticleIcon from '@mui/icons-material/Article'
import { NodesIcon } from 'components/icons/NodesIcon'
import { TreeIcon } from 'components/icons/TreeIcon'
import { Path } from './constants'
import AppsIcon from '@mui/icons-material/Apps'
import { WithRequired } from 'utils/types'
import { snakeCaseToTitleCase } from 'utils/functions'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'

type PathInfo = {
  title?: string
  description?: string
  PathIcon?: OverridableComponent<SvgIconTypeMap>
  hideInfoIcon?: boolean
}

export const pathToInfo = (
  path: Path | undefined
): WithRequired<PathInfo, 'PathIcon' | 'title'> => ({
  title: path ? snakeCaseToTitleCase(path, 'word') : 'EtymologyExplorer',
  PathIcon: AppsIcon,
  ...(path ? PATH_TO_INFO[path] : {}),
})

const PATH_TO_INFO: {
  [key in Path]: PathInfo
} = {
  [Path.REGISTER]: {
    PathIcon: LoginIcon,
  },
  [Path.LOGIN]: {
    PathIcon: LoginIcon,
  },
  [Path.LOGOUT]: {
    PathIcon: LogoutIcon,
  },
  [Path.ACCOUNT]: {
    PathIcon: PersonIcon,
    hideInfoIcon: true,
  },
  [Path.PLANS]: {
    PathIcon: ShoppingBagIcon,
    hideInfoIcon: true,
  },
  [Path.SEE_WOTD]: { title: 'Word of the Day', PathIcon: TodayIcon },
  [Path.WOTD_LIST]: { title: 'Word of the Day', PathIcon: TodayIcon },
  [Path.CONN]: {
    PathIcon: ConnectionsIcon,
  },
  [Path.DETAIL]: {
    PathIcon: ArticleIcon,
  },
  [Path.NODE]: {
    PathIcon: NodesIcon,
  },
  [Path.NODE_CHILD]: {
    PathIcon: NodeChildrenIcon,
  },
  [Path.PROGENY_TREE]: {
    PathIcon: ChildrenPackIcon,
  },
  [Path.PROGENY_TABLE]: {
    PathIcon: ChildrenTableIcon,
  },
  [Path.TREE]: {
    PathIcon: TreeIcon,
    title: 'Etymology Tree',
  },
  [Path.INFO]: {
    PathIcon: InfoOutlinedIcon,
  },
  [Path.VANGUARD]: {
    PathIcon: LocalPoliceIcon,
  },
  [Path.REPORT]: {
    PathIcon: ReportIcon,
  },
  // map: {
  //   PathIcon: PublicIcon,
  //   description: 'Cartographical Visualisation of Etymology',
  // },
}
