export const FREE_WOTD_QUERY_KEY = 'free-wotd'
export const WOTD_QUERY_KEY = 'wotd'
export const WOTD_GROUP_QUERY_KEY = 'wotd_group'

/**
 * query key and initial index
 */
export type WordOfTheDayQueryKey = typeof WOTD_QUERY_KEY

export type WotdGroupQueryKey = typeof WOTD_GROUP_QUERY_KEY

export type FreeWotdQueryKey = typeof FREE_WOTD_QUERY_KEY
