/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy, Suspense, ComponentType, ComponentProps, ReactNode } from 'react'

interface Opts {
  fallback: ReactNode
}
type Unpromisify<T> = T extends Promise<infer P> ? P : never

export const lazyLoad = <T extends Promise<any>, U extends ComponentType<any>>(
  importFunc: () => T,
  selectorFunc?: (s: Unpromisify<T>) => U,
  opts: Opts = { fallback: null }
) => {
  let lazyFactory: () => Promise<{ default: U }> = importFunc

  if (selectorFunc) {
    lazyFactory = () =>
      importFunc().then((module) => ({ default: selectorFunc(module) }))
  }

  const LazyComponent = lazy(lazyFactory)

  const Component = (props: ComponentProps<U>): JSX.Element => (
    <Suspense fallback={opts.fallback ?? null}>
      <LazyComponent {...props} />
    </Suspense>
  )

  return Component
}
