import { Path } from 'router/constants'

export type PathParams = {
  word: string | undefined
  language: string | undefined
  id: string | undefined
  direction: ConnectionDirection
  component: Path | undefined
}

export const CONNECTION_DIRECTIONS = [
  'desc',
  'root',
  'ance',
  undefined,
] as const

export type ConnectionDirection = typeof CONNECTION_DIRECTIONS[number]
