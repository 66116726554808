import Stack from '@mui/material/Stack'
import styled from '@mui/material/styles/styled'
import ComponentIndicator from 'components/ComponentIndicator'
import SearchBar from 'components/SearchBar'
import { SearchLanguage } from 'components/SearchBar/SearchLanguage'
import Sidebar from 'components/Sidebar'
import { isAuthComponent, isWordComponent } from 'pathManager/functions'
import { usePathParams } from 'pathManager/hooks'
import { isMobile } from 'react-device-detect'
import { use100vh } from 'react-div-100vh'
import { ThemeConstants } from 'theme/constants'
import { useIsLandscape } from 'utils/hooks'
import { ActionRing } from './ActionRing'
import {
  OverlayController,
  useOverlayDispatch,
  useOverlayStore,
} from './Controller'
import Footer from './Footer'
import MobileAppBanner from './MobileAppBanner'
import NavIcons from './NavIcons'
import { Shortcuts } from './Shortcuts'

const OverlayWrapper = () => (
  <OverlayController>
    <Overlay />
  </OverlayController>
)

const Overlay = () => {
  const height = use100vh()
  const overlayStore = useOverlayStore()
  const overlayDispatch = useOverlayDispatch()
  const drawerOpen = overlayStore === 'main' || overlayStore === 'settings'
  const { component } = usePathParams()
  const { isLandscape } = useIsLandscape()

  return isAuthComponent(component) ? null : (
    <Wrapper>
      <Sidebar />
      {drawerOpen && (
        <OverlayBackdrop onClick={() => overlayDispatch('normal')} />
      )}
      <OverlayContainer height={height}>
        <OverlayTopBarContainer>
          <OverlayTopLeft direction={isLandscape ? 'row' : 'column'}>
            <Stack direction="row" flex={2}>
              <SearchBar />
              {!isMobile && <SearchLanguage />}
            </Stack>
            {isWordComponent(component) && <ComponentIndicator />}
          </OverlayTopLeft>
          {!isMobile && <Shortcuts />}
          {!isMobile && <NavIcons />}
        </OverlayTopBarContainer>
        {isMobile ? <MobileAppBanner /> : <Footer />}
        <ActionRing />
      </OverlayContainer>
    </Wrapper>
  )
}

const OverlayTopLeft = styled(Stack)({
  position: 'absolute',
  gap: 8,
  width: '100%',
})

const OverlayTopBarContainer = styled('div')({
  position: 'absolute',
  left: ThemeConstants.OVERLAY_INSET,
  top: ThemeConstants.OVERLAY_INSET,
  right: ThemeConstants.OVERLAY_INSET,
  bottom: ThemeConstants.OVERLAY_INSET,
})

const OverlayBackdrop = styled('div')({
  position: 'absolute',
  pointerEvents: 'all',
  height: '100%',
  width: '100%',
  backgroundColor: '#00000088',
})

const OverlayContainer = styled('div', {
  shouldForwardProp: (p) => p !== 'height',
})<{ height: number | null }>((p) => ({
  position: 'relative',
  display: 'flex',
  flex: 1,
  width: '100vw',
  height: p.height ? p.height + 'px' : '100vh',
  // hack to make it not take up space
  // https://www.reddit.com/r/css/comments/sunvnh/position_sticky_without_taking_up_space_or_fixed/
  marginTop: p.height ? -p.height + 'px' : '-100vh',
}))

const Wrapper = styled('div')({
  position: 'sticky',
  pointerEvents: 'none',
  top: 0,
  bottom: 0,
  width: '100%',
  zIndex: ThemeConstants.Z_INDEX_OVERLAY_WRAPPER, // Needed at 10 to keep on top of all screens and leafs
})

export default OverlayWrapper
