import { usePathRedirect, useStoreWordPaths } from 'pathManager/hooks'
import { useAnchorScroll } from './hooks'
import { BrowserRouter } from 'react-router-dom'

export const RouterController = ({ children }: { children: JSX.Element }) => {
  return (
    <BrowserRouter>
      <InnerRouterController>{children}</InnerRouterController>
    </BrowserRouter>
  )
}

// Must be inside the BrowserRouter to be able to use the useLocation hooks
const InnerRouterController = ({ children }: { children: JSX.Element }) => {
  console.debug('Internal Router Controller rerendering')
  usePathRedirect()
  useStoreWordPaths()
  useAnchorScroll()
  return children
}
