import { styled } from '@mui/material/styles'

export const P = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  fontFamily: 'Roboto',
  lineHeight: '1.5',
  color: theme.palette.text.primary,
  marginTop: 0,
  marginBottom: theme.spacing(2),
}))
