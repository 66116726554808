import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Button } from 'components/Button'
import { Link } from 'components/Link'
import { LoadingPage } from 'pages/LoadingPage'
import { Spacer } from 'components/Spacer'
import { useAlertDialog } from 'dialog/hooks'
import { isRootFocusedComponent } from 'pathManager/functions'
import { usePathParams } from 'pathManager/hooks'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useCanSeeContent } from 'subscriptions/hooks'
import { snakeCaseToTitleCase } from 'utils/functions'
import { Path } from './constants'

export const PreviewAccess = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { word, component } = usePathParams()
  const { canSeeContent, todaysWotd, loading } = useCanSeeContent()
  const { openAlert, closeAlert } = useAlertDialog()

  const wotdPath = todaysWotd
    ? `/${component}/${todaysWotd.language}/${todaysWotd.word}`
    : undefined
  const wotdRootPath =
    todaysWotd?.group_language && todaysWotd?.group_word
      ? `/${component}/${todaysWotd.group_language}/${todaysWotd.group_word}`
      : undefined

  useEffect(() => {
    if (loading) return
    if (!canSeeContent) {
      openAlert({
        blur: true,
        title: 'Premium Access Required',
        subtitle: (
          <>
            <Typography>
              Viewing the{' '}
              <span style={{ color: theme.palette.info.main }}>
                {component ? snakeCaseToTitleCase(component) : ''} Explorer
              </span>{' '}
              for{' '}
              <span style={{ color: theme.palette.info.main }}>
                {`"${word}"`}
              </span>{' '}
              requires Premium access
            </Typography>
            <Spacer vertical />
            <Typography>
              You can preview the{' '}
              {isRootFocusedComponent(component) &&
              wotdRootPath &&
              todaysWotd ? (
                <>
                  root of the word of the day:{' '}
                  <Link to={wotdRootPath} style={{ textDecoration: 'none' }}>
                    <Button variant="contained">
                      <b>{`${todaysWotd.group_word}`}</b>
                    </Button>
                  </Link>
                </>
              ) : wotdPath && todaysWotd ? (
                <>
                  word of the day:{' '}
                  <Link to={wotdPath} style={{ textDecoration: 'none' }}>
                    <Button variant="contained">
                      <b>{`${todaysWotd.word}`}</b>
                    </Button>
                  </Link>
                </>
              ) : (
                <Link to={`/${Path.WOTD_LIST}`}>Word of the day</Link>
              )}
            </Typography>
            <Spacer vertical />
            <Typography>
              Otherwise you must use a different view or upgrade to{' '}
              <span style={{ color: theme.palette.info.main }}>Premium</span> or{' '}
              <span style={{ color: theme.palette.info.main }}>Vanguard</span>{' '}
              access
            </Typography>
          </>
        ),
        onConfirm: () => navigate(`/${Path.PLANS}`),
        onCancel: () => navigate(-1),
        confirmText: 'Upgrade',
        cancelText: 'Go Back',
      })
    }
    // This is a router component, so when we navigate away, make sure to close it
    return () => closeAlert()
  }, [
    canSeeContent,
    closeAlert,
    component,
    loading,
    navigate,
    openAlert,
    theme.palette.info.main,
    todaysWotd,
    word,
    wotdPath,
    wotdRootPath,
  ])
  return loading ? (
    <LoadingPage />
  ) : (
    <Outlet context={{ showExample: !canSeeContent }} />
  )
}

// const WotdLink = styled(Link)<LinkProps & { to: string }>({
//   fontWeight: '700',
// })
