import styled from '@mui/material/styles/styled'
import { useSearchLanguage } from 'contexts/SearchLanguageController'
import { useDialogDispatch } from 'dialog'
import { useThemeKey } from 'theme/Controller'
import { titleCase } from 'utils/functions'
import { Version } from 'utils/env'
import { Spacer } from 'components/Spacer'
import { A } from 'components'
import { useTheme } from '@mui/material/styles'
import { ThemeConstants } from 'theme/constants'

const Footer = () => {
  const dispatchDialog = useDialogDispatch()
  const { mode } = useThemeKey()
  const { searchLanguage } = useSearchLanguage()
  const theme = useTheme()

  return (
    <Wrapper>
      <A
        href="https://etymologyexplorer.com/terms_and_conditions.html"
        target="_blank"
        style={{
          marginRight: 10,
          marginLeft: 1,
          color: theme.palette.text.primary,
        }}
        rel="noreferrer"
      >
        Terms
      </A>
      <A
        href="https://etymologyexplorer.com/privacy_policy.html"
        target="_blank"
        style={{
          marginRight: 10,
          marginLeft: 1,
          color: theme.palette.text.primary,
        }}
        rel="noreferrer"
      >
        Privacy
      </A>
      <FooterLink
        onClick={() =>
          dispatchDialog({ type: 'open', payload: { type: 'language' } })
        }
      >
        {searchLanguage ?? 'English'}
      </FooterLink>
      <FooterLink
        onClick={() =>
          dispatchDialog({ type: 'open', payload: { type: 'theme' } })
        }
      >
        {titleCase(mode)}
      </FooterLink>

      <Version />
      <Spacer size={5} />
    </Wrapper>
  )
}

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#50505088' : '#90909088',
  backdropFilter: 'blur(2px)',
  pointerEvents: 'all',
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: ThemeConstants.Z_INDEX_FOOTER_WRAPPER,
  color: 'white',
  fontSize: 12,
  padding: 3,
  display: 'flex',
  flexDirection: 'row',
}))

const FooterLink = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: 1,
  marginRight: 10,
  textDecoration: 'none',
  color: theme.palette.text.primary,
  [`&:hover`]: {
    textDecoration: 'underline',
  },
}))

export default Footer
