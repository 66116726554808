import AppProviders from 'contexts/AppProviders'
import Overlay from 'overlay'
import { Router } from 'router'
import { queryClient } from 'utils/setup'

const App = () => {
  return (
    <AppProviders queryClient={queryClient}>
      <Overlay />
      <Router />
    </AppProviders>
  )
}

export default App
