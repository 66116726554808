import { useAuthStore } from 'auth/hooks'
import { ref, get, update } from 'firebase/database'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { SettingsType } from 'types/backend-types'
import { database } from './firebase'

export const SETTINGS_QUERY_KEY = 'settings'

type UID = string | undefined
export type SettingsQueryKey = [typeof SETTINGS_QUERY_KEY, UID]

export const useSettings = () => {
  const { user } = useAuthStore()
  const { data: settings, ...rest } = useQuery<
    Partial<SettingsType> | undefined,
    Error,
    Partial<SettingsType> | undefined,
    SettingsQueryKey
  >(
    [SETTINGS_QUERY_KEY, user?.uid],
    () => (user?.uid ? getSettings(user.uid) : undefined),
    {
      enabled: !!user?.uid,
    }
  )

  return { settings, ...rest }
}

export const getSettings = (uid: string): Promise<Partial<SettingsType>> => {
  const settingsRef = ref(database, `/settings/${uid}`)
  const accountData = get(settingsRef).then((snapshot) => snapshot.val() ?? {})
  return accountData
}

export const useUpdateSettings = () => {
  const queryClient = useQueryClient()
  const { user } = useAuthStore()
  const mutation = useMutation<
    void,
    Error,
    Partial<SettingsType>,
    Partial<SettingsType>
  >(
    ['mutateSettings'],
    (partialSettings) => {
      if (!user?.uid) {
        throw new Error()
      } else {
        return update(ref(database, `/settings/${user.uid}/`), partialSettings)
      }
    },
    {
      onMutate: (partialSettings) => {
        const existingSettings =
          queryClient.getQueryData<Partial<SettingsType>>([
            SETTINGS_QUERY_KEY,
            user?.uid,
          ]) ?? {}
        queryClient.setQueryData<Partial<SettingsType>>(
          [SETTINGS_QUERY_KEY, user?.uid],
          (old) => ({ ...old, ...partialSettings })
        )
        return existingSettings
      },
      onSuccess: () => {
        queryClient.invalidateQueries([SETTINGS_QUERY_KEY, user?.uid])
      },
      onError: (error, variables, context) => {
        console.log('useUpdateSettings onError')
        queryClient.setQueryData<Partial<SettingsType>>(
          [SETTINGS_QUERY_KEY, user?.uid],
          context ?? {}
        )
      },
    }
  )
  return mutation
}
