import { styled } from '@mui/material/styles'
import MuiButton from '@mui/material/Button'
import { Link } from 'react-router-dom'

export const A = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
    opacity: '0.8',
  },

  '&:active': {
    opacity: '0.4',
  },
}))

export const Button = styled(MuiButton)(() => ({
  // backgroundColor: theme.palette.primary.main,
}))

export const ExternalLink = styled(Link)(({ theme }) => ({
  marginLeft: 1,
  marginRight: 10,
  textDecoration: 'none',
  color: theme.palette.text.primary,
  [`&:hover`]: {
    textDecoration: 'underline',
  },
}))

export const Ol = styled('ol')(({ theme }) => ({
  fontSize: '1rem',
  fontFamily: 'Roboto',
  lineHeight: '1.5',
  color: theme.palette.text.primary,
  marginTop: 0,
  marginBottom: theme.spacing(2),
}))

export const Subtitle = styled('h2')(({ theme }) => ({
  fontSize: '1.5rem',
  margin: 0,
  color: theme.palette.text.primary,
}))

export const TextActionLink = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
    opacity: '0.8',
  },

  '&:active': {
    opacity: '0.4',
  },
}))

export const Ul = styled('ul')(({ theme }) => ({
  fontSize: '1rem',
  fontFamily: 'Roboto',
  lineHeight: '1.5',
  color: theme.palette.text.primary,
  marginTop: 0,
  marginBottom: theme.spacing(2),
}))
