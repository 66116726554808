import { useAuthStore } from 'auth/hooks'
import queryString from 'query-string'
import { removeEmpty } from 'utils/functions'
import { useChannel } from 'channel'
import { getDetails, getIdentifier } from 'componentData/functions'
import { useNavigateToRandomEtymology } from 'componentData/hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useOutletContext } from 'react-router-dom'
import { useNavigate } from 'router'
import { DEFAULT_CHILD_PATH, DEFAULT_PATH, Path } from 'router/constants'
import { StorageItems, setStorageItem, getStorageItem } from 'utils/storage'
import { useWordsOfTheDay } from 'wotd/hooks'
import {
  getPathWordStrata,
  getPathParamsFromLocation,
  isWordComponent,
  isFreeComponent,
} from './functions'
import { PathParams } from './types'
import { useActivePlan } from 'subscriptions'
import { DEFAULT_LANGUAGE } from 'utils/constants'
import { useQueryClient } from 'react-query'
import { getWordsOfTheDay } from 'wotd/functions'
import { WOTD_QUERY_KEY } from 'wotd/types'
import { WordStrata } from 'componentData/types'

export const useStoreWordPaths = () => {
  const { isWotd, isWotdRoot, todaysWotd, todaysWotdRoot } = useWordsOfTheDay(1)
  const { word, language, direction, component } = usePathParams()

  if (isWotd && todaysWotd) {
    setStorageItem(StorageItems.LAST_WOTD_VIEWED_DATE_STRING, todaysWotd.date)
  }

  if (isWotdRoot && todaysWotdRoot) {
    setStorageItem(
      StorageItems.LAST_WOTD_ROOT_VIEWED_DATE_STRING,
      todaysWotdRoot.date
    )
  }

  if (isWordComponent(component)) {
    setStorageItem(StorageItems.PATH_PARAMS, {
      ...getStorageItem(StorageItems.PATH_PARAMS),
      ...removeEmpty({
        word,
        language,
        // id, // don't store id or causes issues
        direction,
        component,
      }),
    })
  }
}

/**
 * updates the react-router-dom path and url bar for consistency
 */
export const usePathRedirect = () => {
  const { word, language, id, direction, component, path } = usePathParams()
  const { canUpdateConnections, loading: authLoading } = useAuthStore()
  const pathNavigate = usePathNavigate()
  const { channel } = useChannel()

  // If there isn't a component then the component is the Tree
  // If there isn't a language, then the language is english
  // If there isn't a word, then get one randomly and redirect
  console.debug(
    'usePathRedirect useEffect',
    word,
    language,
    id,
    direction,
    channel,
    canUpdateConnections,
    authLoading,
    component,
    path
  )

  useEffect(() => {
    const needMorePathDetails =
      word === undefined ||
      language === undefined ||
      (component === Path.CONN && direction === undefined)

    if (path === '/' || (isWordComponent(component) && needMorePathDetails)) {
      pathNavigate({ replace: true, word, language, component })
    }
  }, [component, direction, language, path, pathNavigate, word])
}

export const usePathParams = () => {
  const location = useLocation()

  const params = useMemo(() => getPathParamsFromLocation(location), [location])

  // console.debug('path params refresh', params)
  return params
}

export const useQueryParams = () => {
  const location = useLocation()

  const queryParams = useMemo(
    () =>
      queryString.parse(location.search, {
        parseBooleans: true,
        parseNumbers: true,
      }),
    [location.search]
  )

  // console.debug('path params refresh', params)
  return { queryParams }
}

export const usePathIdentifier = () => {
  const { word, language, id, direction, component } = usePathParams()

  // See if we should be showing an example (WOTD) because of payment plan
  const outletContext = useOutletContext<{ showExample: boolean } | null>()
  const { showExample } = outletContext ?? {}
  const { todaysWotd } = useWordsOfTheDay(1)
  const wordStrata = getPathWordStrata(component)

  const { identifier, cacheIdentifier } = !showExample
    ? getIdentifier({ word, language, id })
    : wordStrata === 'root' &&
      todaysWotd?.group_word &&
      todaysWotd?.group_language
    ? getIdentifier({
        word: todaysWotd.group_word,
        language: todaysWotd.group_language,
      })
    : getIdentifier({ word: todaysWotd?.word, language: todaysWotd?.language })

  return { identifier, cacheIdentifier, direction }
}

/**
 * Keep path components if possible
 */
export const usePathNavigate = () => {
  const {
    id: pathId,
    word: pathWord,
    language: pathLanguage,
    component: pathComponent,
    direction: pathDirection,
  } = usePathParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { hasPremium } = useActivePlan()
  const { canUpdateConnections } = useAuthStore()
  const { todaysWotd, hasSeenTodaysWotd } = useWordsOfTheDay(1)

  const navigateToRandomEtymology = useNavigateToRandomEtymology(true)
  const pathNavigate = useCallback(
    async ({
      id,
      word,
      language,
      component,
      direction,
      replace,
    }: Partial<PathParams> & { replace?: boolean }) => {
      const options = replace ? { replace } : undefined

      const storedPathParams = getStorageItem(StorageItems.PATH_PARAMS)
      const storedSearchLanguage = getStorageItem(StorageItems.SEARCH_LANGUAGE)

      const accessibleStoredComponent =
        (isFreeComponent(storedPathParams?.component) || hasPremium
          ? storedPathParams?.component
          : DEFAULT_PATH) ?? DEFAULT_PATH

      // console.log('stored', storedPathParams, storedSearchLanguage)

      const requestedComponent = component ?? pathComponent
      const requestedWord = word ?? pathWord
      const requestedLanguage = language ?? pathLanguage

      const requestedId = id ?? pathId
      const newLanguage =
        requestedLanguage ??
        storedPathParams?.language ??
        storedSearchLanguage ??
        'English'

      const newWord = requestedWord ?? storedPathParams?.word
      const newComponent = requestedComponent ?? accessibleStoredComponent
      const newDirection =
        '/' +
        (direction ?? pathDirection ?? (canUpdateConnections ? 'ance' : 'root'))

      console.debug('usePathNavigate', {
        newComponent,
        newId: requestedId,
        newWord,
        newLanguage,
        newDirection,
      })

      if (!isWordComponent(newComponent)) {
        console.log('usePathNavigate not isWordComponent')
        navigate(`/${newComponent}`)
      } else if (requestedId) {
        console.log('usePathNavigate have requestedId')
        // console.log('usePathNavigate have newId')
        getDetails({ identifier: { ids: requestedId } }).then(
          ({ etymologyObject: { word, language_name: language } }) =>
            navigate(`/${newComponent}/${language}/${word}`, { replace: true })
        )

        // If we requested a specific word, go there
      } else if (requestedWord) {
        console.log('usePathNavigate have requestedWord')
        navigate(
          `/${requestedComponent ?? DEFAULT_PATH}/${
            requestedLanguage ?? DEFAULT_LANGUAGE
          }/${requestedWord}${
            requestedComponent === Path.CONN ? newDirection : ''
          }`,
          options
        )
      } else if (!hasSeenTodaysWotd) {
        // TODO: Ideally the user will only go to an old stored word if the strata fits to it
        console.log('usePathNavigate have not hasSeenTodaysWotd')
        const wotd =
          todaysWotd ??
          (await queryClient
            .fetchQuery({
              queryKey: WOTD_QUERY_KEY,
              queryFn: () => getWordsOfTheDay(1),
            })
            .then(([wotd]) => wotd))
        const wotdComponent = requestedComponent ?? DEFAULT_CHILD_PATH
        const componentStrata = getPathWordStrata(wotdComponent)
        if (wotd && componentStrata === WordStrata.ROOT) {
          navigate(
            `/${wotdComponent}/${wotd.group_language}/${wotd.group_word}`,
            { replace: true }
          )
        } else if (wotd) {
          navigate(`/${wotdComponent}/${wotd.language}/${wotd.word}`, {
            replace: true,
          })
        } else {
          navigateToRandomEtymology(WordStrata.CHILD, newComponent, newLanguage)
        }
      } else if (newWord) {
        console.log('usePathNavigate have newWord')
        navigate(
          `/${newComponent}/${newLanguage}/${newWord}${
            newComponent === Path.CONN ? newDirection : ''
          }`,
          options
        )
      } else {
        console.log(
          'usePathNavigate randomEtymology',
          hasSeenTodaysWotd,
          todaysWotd
        )
        navigateToRandomEtymology(WordStrata.CHILD, newComponent, newLanguage)
      }
    },
    [
      canUpdateConnections,
      hasPremium,
      hasSeenTodaysWotd,
      navigate,
      navigateToRandomEtymology,
      pathComponent,
      pathDirection,
      pathId,
      pathLanguage,
      pathWord,
      queryClient,
      todaysWotd,
    ]
  )
  return pathNavigate
}
