const tintColor = '#70bc9f' //'#81d9b7';//'#2f95dc';
const darkTintColor = '#57ba94'
const menuBackgroundColor = '#f0eff5' //https://static.makeuseof.com/wp-content/uploads/2018/07/app-store-payment-information.jpeg
const menuTintColor = '#63b274'
const menuTintColorDM = '#2e2e2e'
const backgroundColorDM = '#212121'
const leafColor = '#232323'
const defaultColor = '#ccc'
const eeWhite = '#fafafa'
const eeExtraLightGray = '#f4f4f4'
const eeGray = '#bbb'
const eeLightGray = '#888888'
const eeMediumGray = '#707070'
const eeDarkGray = '#555'
const eeExtraDarkGray = '#555'
const eeBlack = '#303133'
const eeSelectionBlue = '#40A0FF' //'rgba(64, 152, 245, 1)'
const etymologyGreen = {
  50: '#e9f7ee',
  100: '#c7edd4',
  200: '#a4e0b9',
  300: '#83d6a0',
  400: '#66cc8a',
  500: '#4ac274',
  600: '#42b36b',
  700: '#379e62',
  800: '#308c5b',
  900: '#1e694b',
  A100: '#ccff90',
  A200: '#b2ff59',
  A400: '#76ff03',
  A700: '#64dd17',
}

const hashCode = (str: string) => {
  let hash = 0
  let i
  let chr
  let len
  if (str.length === 0) return hash
  for (i = 0, len = str.length; i < len; i++) {
    chr = str.charCodeAt(i) - 96
    hash = (hash << 5) - hash + chr + 1
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

const englishOffset = (Math.floor(Math.abs(hashCode('English'))) % 360) - 240 // 240 is target for English
//console.log(englishOffset)

const getHue = (language: string) => {
  return (Math.floor(Math.abs(hashCode(language))) - englishOffset) % 360
}

export const getLanguageColor = (
  language: string,
  transparency = 1,
  saturation = '100%'
) => {
  let hue = 0 //default value
  let lightness = '80%'

  try {
    //const rawPos = (language.slice(0).toLowerCase().charCodeAt(0)-96) * (language.slice(-1).toLowerCase().charCodeAt(0)-96)
    hue = getHue(language)
    //hue = Math.floor( 170 ) % 360 ) //625 is max value and 255 is max hue

    if (language.indexOf('Middle ') !== -1) {
      lightness = '85%'
      hue = getHue(language.replace('Middle ', ''))
    } else if (language === 'Latin') {
      lightness = '85%'
    } else if (language === 'Ancient Greek') {
      lightness = '90%'
    } else if (language.indexOf('Old ') !== -1) {
      lightness = '90%'
      hue = getHue(language.replace('Old ', ''))
    } else if (language.indexOf('Proto-') !== -1) {
      lightness = '95%'
      hue = getHue(language.replace('Proto- ', ''))
    }
  } catch (err) {
    console.warn(`Error in getLanguageColor: ${JSON.stringify(err)}`)
  }
  return `hsla(${hue}, ${saturation}, ${lightness}, ${transparency})`
}

const Colors = {
  tintColor,
  darkTintColor,
  leafColor,
  defaultColor,
  menuBackgroundColor,
  menuTintColor,

  menuTintColorDM,
  backgroundColorDM,

  eeWhite,
  eeExtraLightGray,
  eeLightGray,
  eeMediumGray,
  eeGray,
  eeDarkGray,
  eeExtraDarkGray,
  eeBlack,
  eeSelectionBlue,

  treeLeaf: eeWhite,
  treeArrow: eeWhite,

  leafWord: leafColor,
  leafLanguage: leafColor,
  leafDefinition: leafColor,
  leafPosName: leafColor,
  leafTitle: leafColor,

  wordKinHeader: eeWhite,

  tabIconDefault: defaultColor,
  tabIconSelected: tintColor,
  tabBar: eeWhite,
  tabBarSelected: '#d8e0db',

  detailLinkColor: leafColor,

  etymologyGreen: etymologyGreen,

  wordKinGradient_1: '#81d9b7',
  wordKinGradient_2: '#a9dea9',
  wordKinGradient_15: '#95dcb0',

  facebookBlue: '#4267B2', //https://brandpalettes.com/facebook-colors/
  googleBlue: '#4285F4',
  stripeCheckoutBranding: '#525f7f',

  likeColor: '#ed8484', //'#FF6666'

  randomColor: () => `#${Math.floor(Math.random() * 16777215).toString(16)}`,

  // Old Colors
  //wordKinGradient_1: '#859ff3',
  //wordKinGradient_2: '#b3ecf5',
  //wordKinGradient_15: '#9dc8f4',
}

export default Colors
