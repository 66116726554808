import { usePathParams } from 'pathManager/hooks'
import { Path } from 'router/constants'
import { useActivePlan } from 'subscriptions'
import { NavLinkIconButton } from './NavLinkIconButton'

type LinkIconsProps = {
  word?: string
  language?: string
  hideConnections?: boolean
  hideDetails?: boolean
  hideRootExplorers?: boolean
  activeFirst?: boolean
}
export const LinkIcons = ({
  word,
  language,
  hideConnections,
  hideDetails,
  hideRootExplorers,
  activeFirst,
}: LinkIconsProps) => {
  const { component } = usePathParams()
  const { availablePaths } = useActivePlan()

  const info = { word, language }

  const sortedPaths = activeFirst
    ? availablePaths
        .slice()
        .sort((a, b) => (a === component ? -1 : b === component ? 1 : 0))
    : availablePaths

  return (
    <>
      {sortedPaths
        .filter(
          (p) =>
            (p !== Path.DETAIL || !hideDetails) &&
            (![Path.NODE_CHILD, Path.PROGENY_TABLE, Path.PROGENY_TREE].includes(
              p
            ) ||
              !hideRootExplorers) &&
            (p !== Path.CONN || !hideConnections)
        )
        .map((path) => (
          <NavLinkIconButton key={path} path={path} {...info} />
        ))}
    </>
  )
}
