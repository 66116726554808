import Button from '@mui/material/Button'
import { useNavigateToRandomEtymology } from 'componentData/hooks'
import { WordStrata } from 'componentData/types'
import { getPathWordStrata } from 'pathManager/functions'
import { usePathParams } from 'pathManager/hooks'

export const RandomButton = () => {
  const navigateToRandomEtymology = useNavigateToRandomEtymology(false)
  const { component } = usePathParams()
  const pathStrata = getPathWordStrata(component)
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        navigateToRandomEtymology()
      }}
      size="small"
      color="inherit"
      style={{
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
    >
      {pathStrata === WordStrata.ROOT ? 'Random Root' : 'Random'}
    </Button>
  )
}
