// Use consistent styling
import { QueryClient } from 'react-query'
import 'utils/consoleModules'
import 'utils/firebase'

// const urlParams = new URLSearchParams(window.location.search)
// const urlChannel = urlParams.get('channel')
// let channel: EnvironmentKey = 'dev' // default
// if (ChannelOptions.includes(urlChannel as EnvironmentKey)) {
//   channel = urlChannel as EnvironmentKey
// }

// Sentry.init({
//   //TODO PUBLISH: put this in an environment variable
//   dsn: 'https://4da5e166951a41ee847480ee27711f92@o390238.ingest.sentry.io/5231544',
//   environment: channel,
//   debug: false,
//   enabled: channel === 'prod',
// })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // https://codemachine.dev/things-i-learned-while-using-react-query-part-2
      retry: process.env.NODE_ENV === 'production',
      refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      refetchOnMount: false,
      cacheTime: 1000 * 60 * 60 * 1,
      staleTime: 1000 * 60 * 60 * 1,
    },
  },
})

if (
  !process.env.REACT_APP_API_KEY ||
  !process.env.REACT_APP_AUTH_DOMAIN ||
  !process.env.REACT_APP_DATABASE_URL ||
  !process.env.REACT_APP_PROJECT_ID ||
  !process.env.REACT_APP_STORAGE_BUCKET ||
  !process.env.REACT_APP_MESSAGING_SENDER_ID ||
  !process.env.REACT_APP_APP_ID ||
  !process.env.REACT_APP_MEASUREMENT_ID
) {
  throw new Error('Environment variables are not all available!')
}

export { queryClient }
