import styled from '@mui/material/styles/styled'
import { NavLinkIconButton } from 'components/NavLinkIconButton'
import { Path } from 'router/constants'
import { ThemeConstants } from 'theme/constants'

export const Shortcuts = () => {
  return (
    <Wrapper>
      <NavLinkIconButton path={Path.WOTD_LIST} />
      <NavLinkIconButton path={Path.ACCOUNT} />
    </Wrapper>
  )
}

const Wrapper = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: ThemeConstants.Z_INDEX_SHORTCUTS,
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  // pointerEvents: 'all',
}))
