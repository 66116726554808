import { ReactNode, useReducer } from 'react'
import AlertDialog from './AlertDialog'
import { DispatchContext, INITIAL_STATE } from './constants'
import InvitationDialog from './InvitationDialog'
import LanguageDialog from './LanguageDialog'
import { reducer } from './reducer'
import ThemeDialog from './ThemeDialog'
import { usePageInvitationWatcher } from 'utils/hooks'

const Controller = ({ children }: { children: ReactNode }) => {
  const [data, reduceData] = useReducer(reducer, INITIAL_STATE)

  usePageInvitationWatcher(reduceData)

  return (
    <DispatchContext.Provider value={reduceData}>
      {children}
      <AlertDialog data={data.alert} />
      <LanguageDialog open={data.language.open} />
      <ThemeDialog open={data.theme.open} />
      <InvitationDialog open={data.invitation.open} />
    </DispatchContext.Provider>
  )
}

export default Controller
