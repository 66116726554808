import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { useTheme } from '@mui/material/styles'
import { colorTypeToThemeColor } from 'theme/constants'

export const ConnectionsIcon = (
  props: SvgIconProps & { strokeColor?: string }
) => {
  const theme = useTheme()
  const { strokeColor: strokeColorProp, ...rest } = props
  const strokeColor =
    strokeColorProp ?? colorTypeToThemeColor(props.color, theme)
  return (
    <SvgIcon viewBox="100 100 825 825" {...rest}>
      <path
        d="M240 451L783 451"
        strokeWidth="100"
        strokeLinecap="round"
        stroke={strokeColor}
      />
      <path
        d="M240 598L783 598"
        strokeWidth="100"
        strokeLinecap="round"
        stroke={strokeColor}
      />
      <path
        d="M240 598L346.066 704.066"
        strokeWidth="100"
        strokeLinecap="round"
        stroke={strokeColor}
      />
      <path
        d="M677 345L783.066 451.066"
        strokeWidth="100"
        strokeLinecap="round"
        stroke={strokeColor}
      />
    </SvgIcon>
  )
}
