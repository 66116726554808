// # Snackbar Controller

// To use this module, wrap the app in the SnackbarController, then use reduceData from SnackbarDispathContext

// | Name    | Type             | Req | Default | Desc                    |
// | ------- | ---------------- | --- | ------- | ----------------------- |
// | message | string           | Yes |         |                         |
// | type    | Success or Error | No  | Success |                         |
// | show    | boolean          | No  |         | shows or hides snackbar |
// | timeout | number           | No  | 2000    | ms to show snackbar     |

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Slide, { SlideProps } from '@mui/material/Slide'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import {
  createContext,
  Dispatch,
  useContext,
  ReactNode,
  useReducer,
  SyntheticEvent,
} from 'react'

import { PartialBy } from '../utils/types'

type Action =
  | {
      type: 'resetSnackbar'
    }
  | {
      type: 'showSnackbar' // default timeout of 2000
      payload: PartialBy<State, 'show' | 'timeout' | 'type'>
    }

type State = {
  message: string
  /**
   * default in showSnackbar is "Success"
   */
  type: 'Success' | 'Error'
  /**
   * default in showSnackbar is true
   */
  show: boolean
  /**
   * default in showSnackbar is 2000
   */
  timeout: number
}

const INITIAL_STATE: State = {
  message: '',
  timeout: 0,
  type: 'Success',
  show: false,
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'resetSnackbar':
      return {
        message: '',
        timeout: 0,
        type: 'Success',
        show: false,
      }
    case 'showSnackbar':
      return {
        ...state,
        show: true, // default value
        timeout: action.payload.timeout ?? 2000, // default value
        type: action.payload.type ?? 'Success', // default value
        message: action.payload.message,
      }
    default:
      throw new Error('unrecognized settings action in snackbar reducer')
  }
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />
}

const DispatchContext = createContext<Dispatch<Action>>(() => undefined)

export const useShowSnackbar = () => {
  const dispatch = useContext(DispatchContext)
  const showSnackbar = ({
    timeout,
    message,
    type,
  }: PartialBy<State, 'show' | 'timeout' | 'type'>) => {
    console.debug('Showing snackbar', message)
    dispatch({
      type: 'showSnackbar',
      payload: {
        message,
        timeout,
        type,
      },
    })
  }
  return showSnackbar
}

const Controller = ({ children }: { children: ReactNode }) => {
  const [data, reduceData] = useReducer(reducer, INITIAL_STATE)
  const { show, timeout, message, type } = data

  const handleCloseSnackbar = (
    event: SyntheticEvent<MouseEvent> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }
    reduceData({ type: 'resetSnackbar' })
  }

  const handleCloseButton = () => {
    reduceData({ type: 'resetSnackbar' })
  }

  const action =
    timeout === 0 ? (
      <CircularProgress size={32} />
    ) : (
      <Button
        onClick={handleCloseButton}
        color={type === 'Error' ? 'secondary' : 'primary'}
        size="small"
      >
        close
      </Button>
    )

  return (
    <DispatchContext.Provider value={reduceData}>
      {children}
      <Snackbar
        open={show}
        autoHideDuration={timeout === 0 ? null : timeout}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
        TransitionComponent={SlideTransition}
        message={message}
        action={action}
      />
    </DispatchContext.Provider>
  )
}

export default Controller
