import { PromotionDataType, SubscriptionInfo } from 'types/backend-types'
import { SubscriptionPriceWithId } from 'types/stripe-types'
import { HOUR, IS_PRODUCTION } from 'utils/constants'

export const FIRESTORE_USER_PATH = 'users'
export const FIRESTORE_SUBSCRIPTIONS_PATH = 'subscriptions'
export const FIRESTORE_PAYMENTS_PATH = 'payments'
export const FIRESTORE_CHECKOUT_SESSIONS_PATH = 'checkout_sessions'
export const FIREBASE_SUBSCRIPTION_PRODUCT_OPTIONS_QUERY_KEY =
  'subscription-products'
export const ACTIVE_FIREBASE_SUBSCRIPTION_QUERY_KEY = 'active-subscription'
export const ACTIVE_FIREBASE_ONE_TIME_PAYMENT_QUERY_KEY =
  'active-one-time-payment'

export const PREMIUM_PRODUCT_ID = IS_PRODUCTION
  ? 'prod_LfaHV6zwztnbLN'
  : 'prod_JhJqsWtFVEpjNq'
export const VANGUARD_PRODUCT_ID = IS_PRODUCTION
  ? 'prod_LfaHtR40NIp9I6'
  : 'prod_LD9io8tt4C95eR'

export enum PlanName {
  FREE = 'FREE',
  PREMIUM_LIFETIME = 'PREMIUM_LIFETIME',
  PREMIUM_ANNUAL = 'PREMIUM_ANNUAL',
  PREMIUM_MONTHLY = 'PREMIUM_MONTHLY',
  VANGUARD_LIFETIME = 'VANGUARD_LIFETIME',
  VANGUARD_ANNUAL = 'VANGUARD_ANNUAL',
  VANGUARD_MONTHLY = 'VANGUARD_MONTHLY',
}

const PREMIUM_LIFETIME = IS_PRODUCTION
  ? 'price_1KyajkBbn4hkmIWhN3gnokBg'
  : 'price_1KyaFsBbn4hkmIWhxc3SZA8u'
const PREMIUM_ANNUAL = IS_PRODUCTION
  ? 'price_1KyaidBbn4hkmIWhh8mA6VnM'
  : 'price_1KyaF1Bbn4hkmIWhuNEcCokO'
const PREMIUM_MONTHLY = IS_PRODUCTION
  ? 'price_1KyajBBbn4hkmIWhOwpIWatE'
  : 'price_1KyaFXBbn4hkmIWh4hxNkF3H'
const VANGUARD_LIFETIME = IS_PRODUCTION
  ? 'price_1KyaAuBbn4hkmIWhWv0ZS8II'
  : 'price_1KyaD8Bbn4hkmIWh7KjjKc0e'
const VANGUARD_ANNUAL = IS_PRODUCTION
  ? 'price_1KyaAeBbn4hkmIWhRsf1sg5D'
  : 'price_1KyaClBbn4hkmIWh67LuaCip'
const VANGUARD_MONTHLY = IS_PRODUCTION
  ? 'price_1KyaB6Bbn4hkmIWhEK6bI8KN'
  : 'price_1KyaD0Bbn4hkmIWhaWOs2CPJ'

export const PRICE_IDS: { [key in PlanName]: FirebaseSubscriptionPriceId } = {
  FREE: 'free',
  PREMIUM_LIFETIME,
  PREMIUM_ANNUAL,
  PREMIUM_MONTHLY,
  VANGUARD_LIFETIME,
  VANGUARD_ANNUAL,
  VANGUARD_MONTHLY,
}

export type FirebaseSubscriptionPriceId =
  | 'free'
  | typeof PREMIUM_LIFETIME
  | typeof PREMIUM_ANNUAL
  | typeof PREMIUM_MONTHLY
  | typeof VANGUARD_LIFETIME
  | typeof VANGUARD_ANNUAL
  | typeof VANGUARD_MONTHLY

const PREMIUM_FEATURES = [
  'All Free plan features',
  'Ad-free access',
  'Node explorer',
  'Descendant node explorer',
  'Descendant pack explorer',
  'Descendant table',
  'Connections explorer',
]

const VANGUARD_FEATURES = [
  'All Premium plan features',
  'Private Slack Channel',
  'API Access',
  'Beta Access',
]

const FIREBASE_SUBSCRIPTION_INFO: SubscriptionInfo[] = [
  {
    plan_name: PlanName.FREE,
    price_id: 'free',
    long_title: 'EtymologyExplorer Free Plan',
    short_title: 'Free',
    plan: 'free',
    subheader: undefined,
    is_special_price: false,
    description: [
      'Tree explorer',
      'Detail explorer',
      'Word of the day list',
      '1,400,000+ words to explore',
      '13,000+ languages',
      'Premium features for WOTD',
      'Mobile app',
    ],
    interval: 'free',
    tier: 'free',
  },
  {
    plan_name: PlanName.PREMIUM_LIFETIME,
    price_id: PREMIUM_LIFETIME,
    long_title: 'EtymologyExplorer Premium Lifetime',
    short_title: 'Premium',
    plan: 'etymology_explorer_lifetime',
    subheader: 'Most popular',
    is_special_price: false,
    description: PREMIUM_FEATURES,
    interval: 'lifetime',
    tier: 'premium',
  },
  {
    plan_name: PlanName.PREMIUM_MONTHLY,
    price_id: PREMIUM_MONTHLY,
    long_title: 'EtymologyExplorer Premium Monthly',
    short_title: 'Premium',
    plan: 'etymology_explorer_monthly',
    subheader: 'Most popular',
    is_special_price: false,
    description: PREMIUM_FEATURES,
    interval: 'monthly',
    tier: 'premium',
  },
  {
    plan_name: PlanName.PREMIUM_ANNUAL,
    price_id: PREMIUM_ANNUAL,
    long_title: 'EtymologyExplorer Premium Annual',
    short_title: 'Premium',
    plan: 'etymology_explorer_annual',
    subheader: 'Most popular',
    is_special_price: false,
    description: PREMIUM_FEATURES,
    interval: 'annual',
    tier: 'premium',
  },
  {
    plan_name: PlanName.VANGUARD_LIFETIME,
    price_id: VANGUARD_LIFETIME,
    long_title: 'EtymologyExplorer Vanguard Lifetime',
    short_title: 'Vanguard',
    plan: 'etymology_explorer_vanguard_lifetime',
    subheader: undefined,
    is_special_price: false,
    description: VANGUARD_FEATURES,
    interval: 'lifetime',
    tier: 'vanguard',
  },
  {
    plan_name: PlanName.VANGUARD_MONTHLY,
    price_id: VANGUARD_MONTHLY,
    long_title: 'EtymologyExplorer Vanguard Monthly',
    short_title: 'Vanguard',
    plan: 'etymology_explorer_vanguard_monthly',
    subheader: undefined,
    is_special_price: false,
    description: VANGUARD_FEATURES,
    interval: 'monthly',
    tier: 'vanguard',
  },
  {
    plan_name: PlanName.VANGUARD_ANNUAL,
    price_id: VANGUARD_ANNUAL,
    long_title: 'EtymologyExplorer Vanguard Annual',
    short_title: 'Vanguard',
    plan: 'etymology_explorer_vanguard_annual',
    subheader: undefined,
    is_special_price: false,
    description: VANGUARD_FEATURES,
    interval: 'annual',
    tier: 'vanguard',
  },
]

type SubscriptionInfoDict = {
  [price in FirebaseSubscriptionPriceId]: SubscriptionInfo
}
export const FIREBASE_SUBSCRIPTION_INFO_DICT: SubscriptionInfoDict =
  FIREBASE_SUBSCRIPTION_INFO.reduce(
    (accu, s) => ({
      ...accu,
      [s.price_id]: s,
    }),
    {} as SubscriptionInfoDict
  )

export const FREE_PRICE: SubscriptionPriceWithId = {
  id: 'free',
  active: true,
  description: 'Free plan',
  metadata: {
    firebaseRole: undefined,
  },

  billing_scheme: 'per_unit',
  currency: 'usd',
  interval: null,
  interval_count: null,
  recurring: null,
  tiers: null,
  tiers_mode: null,
  transform_quantity: null,
  trial_period_days: null,
  unit_amount: 0, // cents
  type: 'free',
}

export const NEW_USER_PROMOTION: PromotionDataType = {
  discountPercent: 50,
  promotion: 'NEW_USER',
  headerText: 'New User - 50% Off!',
  description:
    'To celebrate this new foray into etymologies, here is a welcome offer for premium access to all content with no distractions at a reduced price',
  shortDescription: 'Discount for new users!',
  // description: "Premium now has more features! So you don't miss out, here is a 1 hour offer for access to all content with no ads at a reduced price",
  duration: 1 * HOUR,
  // minAppUse: 0, // Should have the app for at least a day!
  priority: 2,
  minVersion: '5.3.0', // must at least be on this version
  stripeCode: IS_PRODUCTION
    ? 'promo_1KyaxSBbn4hkmIWhToSCkbBa'
    : 'promo_1KX44IBbn4hkmIWhXPCOfIR2', //TODO PUBLISH: Update this code to real promo
}
export const WEB_LAUNCH_PROMOTION: PromotionDataType = {
  discountPercent: 50,
  promotion: 'WEB_LAUNCH',
  headerText: 'Web Launch - 50% Off!',
  description:
    'To celebrate the launch of Etymology Explorer on the web, all plans are priced at 50% for a limited time!',
  shortDescription: 'Celebrate Etymology Explorer on the web!',
  // description: "Premium now has more features! So you don't miss out, here is a 1 hour offer for access to all content with no ads at a reduced price",
  // duration: 1 * HOUR,
  // minAppUse: 24 * HOUR, // Should have the app for at least a day!
  priority: 3, //higher is more important
  minVersion: '5.3.0', // must at least be on this version
  stripeCode: IS_PRODUCTION
    ? 'promo_1KyaxSBbn4hkmIWhToSCkbBa'
    : 'promo_1KX44IBbn4hkmIWhXPCOfIR2', //TODO PUBLISH: Update this code to real promo
}
export const LOCAL_PROMOTIONS: PromotionDataType[] = [
  NEW_USER_PROMOTION,
  WEB_LAUNCH_PROMOTION,
]

// This code is for legacy purposes
export const DEFAULT_PROMOTION_CODE = 'promo_1KX44IBbn4hkmIWhXPCOfIR2' //TODO PUBLISH: Update this code to real promo
