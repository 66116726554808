import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import ListSubheader from '@mui/material/ListSubheader'
import Popper from '@mui/material/Popper'
import { styled, useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  createContext,
  forwardRef,
  HTMLProps,
  ReactChild,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { ListChildComponentProps, VariableSizeList } from 'react-window'

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  }

  if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  )
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})
OuterElementType.displayName = 'OuterElementType'

function useResetCache(data: unknown) {
  const ref = useRef<VariableSizeList>(null)
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponentWrapper = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & { children: ReactChild[] }
>((props, ref) => {
  const { children, ...other } = props
  const itemData: ReactChild[] = []
  children?.forEach((item: ReactChild & { children?: ReactChild[] }) => {
    itemData.push(item)
    itemData.push(...(item.children || []))
  })

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child: ReactChild) => {
    if (Object.prototype.hasOwnProperty.call(child, 'group')) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

ListboxComponentWrapper.displayName = 'ListboxComponentWrapper'
// function random(length: number) {
//   const characters =
//     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
//   let result = ''

//   for (let i = 0; i < length; i += 1) {
//     result += characters.charAt(Math.floor(Math.random() * characters.length))
//   }

//   return result
// }

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

// const OPTIONS = Array.from(new Array(10000))
//   .map(() => random(10 + Math.ceil(Math.random() * 20)))
//   .sort((a: string, b: string) => a.toUpperCase().localeCompare(b.toUpperCase()));

export default function Virtualize<T extends string | null>({
  options,
  disableGroupBy,
  ...props
}: {
  options: T[]
  [key: string]: unknown
  disableGroupBy: boolean
}) {
  return (
    <Autocomplete<T>
      id="virtualize-demo"
      style={{ width: 300 }}
      disableListWrap
      PopperComponent={StyledPopper}
      //@ts-ignore
      ListboxComponent={ListboxComponentWrapper}
      options={options}
      groupBy={
        disableGroupBy
          ? undefined
          : (option) =>
              option !== null ? String(option[0]).toUpperCase() : 'All'
      }
      renderInput={(params) => <TextField {...params} label="10,000 options" />}
      renderOption={(props, option) => [props, option]}
      renderGroup={disableGroupBy ? undefined : (params) => params}
      {...props}
    />
  )
}
