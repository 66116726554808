import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Paper, { PaperProps } from '@mui/material/Paper'
import { styled, useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { useFrequencyType } from 'componentData/hooks'
import { WordStrata } from 'componentData/types'
import { getPathWordStrata, isWordComponent } from 'pathManager/functions'
import { usePathParams } from 'pathManager/hooks'
import { Path } from 'router/constants'
import { pathToInfo } from 'router/functions'
import { snakeCaseToTitleCase } from 'utils/functions'
import { getStorageItem, StorageItems } from 'utils/storage'
import { useWordsOfTheDay } from 'wotd/hooks'
import { Link } from './Link'

export const NavLinkIconButton = ({
  word,
  language,
  path,
  size,
  elevation = 6,
  hideShadow,
  stopDissuade,
  ...rest
}: Omit<IconButtonProps, 'size'> & {
  elevation?: number
  title?: string
  path: Path
  word?: string
  language?: string
  hideShadow?: boolean
  stopDissuade?: boolean
  size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'
}) => {
  const { isWotd = false, isWotdRoot = false } = useWordsOfTheDay(1)
  const { wordStrata } = useFrequencyType(
    stopDissuade ? null : word && language ? { word, language } : undefined
  )
  const { component, word: pathWord, language: pathLanguage } = usePathParams()
  const storedPathParams = getStorageItem(StorageItems.PATH_PARAMS)
  const title = snakeCaseToTitleCase(path, 'word')
  const selected =
    component === path &&
    (!word || word === pathWord) &&
    (!language || language === pathLanguage)
  const theme = useTheme()

  const componentStrata = getPathWordStrata(path)
  const dissuade =
    wordStrata !== WordStrata.ANY &&
    componentStrata !== WordStrata.ANY &&
    componentStrata !== wordStrata

  const isWotdHighlight = (isWotd || isWotdRoot) && path === Path.WOTD_LIST

  // console.log('NavLinkIconButton', {
  //   componentStrata,
  //   wordStrata,
  //   dissuade,
  // })
  const iconSize =
    size === 'extra-small'
      ? 'small'
      : size === 'small'
      ? 'small'
      : size === 'medium'
      ? 'medium'
      : size === 'large'
      ? 'medium'
      : size === 'extra-large'
      ? 'large'
      : 'medium'

  const buttonSize =
    size === 'extra-small'
      ? 'small'
      : size === 'small'
      ? 'medium'
      : size === 'medium'
      ? 'medium'
      : size === 'large'
      ? 'large'
      : size === 'extra-large'
      ? 'large'
      : 'large'

  const { PathIcon } = pathToInfo(path)

  return (
    <Tooltip
      title={
        <>
          {title}
          {!isWotdHighlight ? (
            ''
          ) : (
            <>
              <br />
              <span style={{ color: theme.palette.info.main }}>
                You{"'"}re viewing today{"'"}s{' '}
                {isWotdRoot ? 'root of the ' : ''} word of the day
              </span>
            </>
          )}
          {!dissuade ? (
            ''
          ) : (
            <>
              <br />
              <span style={{ color: theme.palette.warning.light }}>
                This Explorer is not recommended for this word
              </span>
            </>
          )}
        </>
      }
    >
      <Link
        onClick={(e) => e.stopPropagation()} // Stops WOTD redirect to the WOTD detail page
        to={
          isWordComponent(path)
            ? `/${path}/${
                language ?? pathLanguage ?? storedPathParams?.language
              }/${word ?? pathWord ?? storedPathParams?.word}`
            : `/${path}`
        }
      >
        <LinkIconButtonWrapper
          hideShadow={hideShadow}
          selected={selected}
          isWotdHighlight={isWotdHighlight}
          elevation={dissuade ? 1 : elevation}
        >
          <IconButton size={buttonSize} {...rest}>
            <PathIcon
              fontSize={iconSize}
              color={selected ? 'primary' : dissuade ? 'disabled' : 'inherit'}
            />
          </IconButton>
        </LinkIconButtonWrapper>
      </Link>
    </Tooltip>
  )
}

const LinkIconButtonWrapper = styled(Paper, {
  shouldForwardProp: (p) =>
    p !== 'hideShadow' && p !== 'selected' && p !== 'isWotdHighlight',
})<
  PaperProps & {
    selected: boolean
    hideShadow?: boolean
    isWotdHighlight: boolean
  }
>(({ theme, selected, hideShadow, isWotdHighlight }) => ({
  borderRadius: '50%',
  boxShadow:
    !hideShadow && isWotdHighlight
      ? `0 0 20px ${theme.palette.info.main} `
      : !hideShadow && selected
      ? `0 0 20px ${theme.palette.primary.main} `
      : undefined,
  pointerEvents: 'all',
}))
