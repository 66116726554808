import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

const LoadingPage = () => {
  return (
    <Wrapper>
      <CircularProgress color="primary" size="7rem" />
    </Wrapper>
  )
}

const Wrapper = styled('div')({
  pointerEvents: 'none',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { LoadingPage }
