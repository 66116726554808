/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import VirtualizedAutocomplete from 'components/VirtualizedAutocomplete'
import {
  useSearchLanguage,
  useSetSearchLanguage,
} from 'contexts/SearchLanguageController'
import { useCallback, useEffect, useState } from 'react'
import { all_lang_dict, common_lang_dict } from 'utils/lang_dict'
import { useDispatchDialog } from './hooks'

export interface LanguageDialogProps {
  open: boolean
}

const LanguageDialog = ({ open }: LanguageDialogProps) => {
  const dispatch = useDispatchDialog()
  const { searchLanguage } = useSearchLanguage()
  const setSearchLanguage = useSetSearchLanguage()
  const [localSearchLanguage, setLocalSearchLanguage] = useState(searchLanguage)

  const [includeUncommonLanguages, setIncludeUncommonLanguages] =
    useState(false)

  useEffect(() => {
    setLocalSearchLanguage(searchLanguage)
  }, [searchLanguage])

  const handleCancel = () => dispatch({ type: 'close', payload: 'language' })

  const handleSave = useCallback(() => {
    setSearchLanguage(localSearchLanguage)
    dispatch({ type: 'close', payload: 'language' })
  }, [dispatch, localSearchLanguage, setSearchLanguage])

  return (
    <Dialog onClose={handleCancel} open={open} maxWidth="xs">
      <DialogTitle style={{ textAlign: 'center' }}>
        Choose a language for searches and random words
      </DialogTitle>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>Include languages with less data</Typography>
        <Checkbox
          value={includeUncommonLanguages}
          onChange={(e, checked) => setIncludeUncommonLanguages(checked)}
        />
      </div>
      <div style={{ height: 20 }} />
      <VirtualizedAutocomplete<string | null>
        disableGroupBy={!includeUncommonLanguages}
        style={{ paddingLeft: 20, paddingRight: 20 }}
        options={[
          'All Languages',
          ...Object.values(
            includeUncommonLanguages ? all_lang_dict : common_lang_dict
          ).sort(),
        ]}
        // getOptionLabel={(option) =>
        //   option?.team_name ??
        //   `All Workspaces (${slackIntegrations?.length ?? 0})`
        // }
        // getOptionSelected={(option, value) =>
        //   option?.team_id === value?.team_id || (!option && !value)
        // }
        onChange={(event: any, newValue: string) =>
          setLocalSearchLanguage(newValue ?? 'English')
        }
        value={localSearchLanguage}
        renderInput={(params: any) => (
          <TextField {...params} label="Language" variant="outlined" />
        )}
      />

      <div style={{ height: 20 }} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Button variant="contained" color="info" onClick={handleSave}>
          Save
        </Button>
        <Button variant="contained" color="error" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
      <div style={{ height: 20 }} />
    </Dialog>
  )
}

export default LanguageDialog
