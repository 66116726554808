import { styled } from '@mui/material/styles'

type SpacerProps = {
  size?: number
  vertical?: boolean
}
export const Spacer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'vertical',
})<SpacerProps>(({ theme, size, vertical }) =>
  vertical
    ? {
        height: size ?? theme.spacing(2),
      }
    : {
        width: size ?? theme.spacing(2),
      }
)
