import { Bounds, EnvironmentKey } from 'utils/types'

import {
  PromotionDataType,
  PlanType,
  MobileVersionOption,
} from 'types/backend-types'

import { Value } from 'react-svg-pan-zoom'

export const SECOND = 1000 as const
export const MINUTE = 60000 as const
export const HOUR = 3600000 as const
export const DAY = 86400000 as const
export const WEEK = 604800000 as const

export const BOTHER_MAX_FREQ = 300000 as const

// Android has different plan name for annual, lifetime and lifetime special
export const PLAN_MONTHLY: PlanType = {
  productId: 'etymology_explorer_monthly',
  // productId:'etymology_explorer_remove_ads_v0',
  title: 'Monthly',
  localizedPrice: '$3.99',
  frequency: 'Per month',
}
export const PLAN_MONTHLY_SPECIAL: PlanType = {
  productId: 'etymology_explorer_monthly_special',
  title: 'Monthly',
  localizedPrice: '$1.99',
  frequency: 'Per month',
}
export const PLAN_ANNUAL: PlanType = {
  productId: 'etymology_explorer_annual',
  title: 'Annual',
  localizedPrice: '$19.99',
  frequency: 'Per year',
}
export const PLAN_ANNUAL_SPECIAL: PlanType = {
  productId: 'etymology_explorer_annual_special',
  title: 'Annual',
  localizedPrice: '$9.99',
  frequency: 'Per year',
} // And some default info
export const PLAN_LIFETIME: PlanType = {
  productId: 'etymology_explorer_lifetime',
  title: 'Lifetime',
  localizedPrice: '$59.99',
  frequency: 'One payment',
} // And some default info
export const PLAN_LIFETIME_SPECIAL: PlanType = {
  productId: 'etymology_explorer_lifetime_special',
  title: 'Lifetime',
  localizedPrice: '$29.99',
  frequency: 'One payment',
} // And some default info
export const PLAN_FREE: PlanType = {
  productId: 'free',
  title: 'Basic',
  localizedPrice: '$0',
  features: ['Default features'],
} // And some default info
export const PLAN_SILVER: PlanType = {
  productId: 'etymology_explorer_remove_ads_v0',
  title: 'Supporter',
  features: ['Remove ads!', 'Support the project!'],
}
export const PLAN_GOLD: PlanType = {
  productId: 'etymology_explorer_gold',
  title: 'Language Lover',
  description: 'Coming soon!',
  localizedPrice: 'TBD',
  features: ['Remove ads', 'Support the project'],
}

export const ITUNES_CONNECT_SHARED_SECRET = '15f3d832472a45eebb2c5d1b809633ce'

export const AppleAppID = '1189423897'
export const GooglePackageName = 'com.nishiyama.etymologyexplorer'

export const AppleStoreUrl = `https://apps.apple.com/us/app/etymologyexplorer/id${AppleAppID}`
export const GooglePlayUrl = `market://details?id=${GooglePackageName}`

export const DEFAULT_INTERSTITIAL_SCHEDULE = [4, 8, 12, 16, 20, 24, 28]
export const DEFAULT_INTERSTITIAL_LATE_INCREMENTS = 8

// If the app version is past this one, then show the notes
// ie, this is the version of the notes
// TODO PUBLISH: If the update info notes change, then update this to match
export const UPDATE_INFO_VERSION: MobileVersionOption = '6.0.0' // or could use version

export const DEFAULT_REVIEW_REQUEST_SCHEDULE = [
  7200000, 604800000, 2592000000, 31622400000, 42163200000, 52704000000,
  63244800000, 73785600000,
]

export const PROMOTION_NEW_USER: PromotionDataType = {
  discountPercent: 50,
  promotion: 'NEW_USER',
  headerText: 'Welcome Offer 50% Off!',
  description:
    'To celebrate this new foray into etymologies, here is a welcome offer for access to all content with no distractions at a reduced price',
  duration: 30 * MINUTE,
}

export const PROMOTION_VERSION_5_3: PromotionDataType = {
  discountPercent: 50,
  promotion: 'VERSION_5_3',
  headerText: 'New Premium Features 50% Off!',
  description:
    "Premium now has more features! So you don't miss out, here is a 1 hour offer for access to all content with no ads at a reduced price",
  duration: 1 * HOUR,
  minAppUse: 24 * HOUR, // Should have the app for at least a day!
  priority: 1.5,
  minVersion: '5.3.0', // must at least be on this version
}

export const RATE_OPTIONS = {
  AppleAppID: AppleAppID,
  GooglePackageName: GooglePackageName,
  preferInApp: false, // recommend true when sure you want a 1-time chance to ask users to rate your app from within the app
  inAppDelay: 3,
  openAppStoreIfInAppFails: true,
}

export const PROMOTION_TIMESTAMP_POSTFIX = '_PromotionTimestamp'

export const MAX_PROGENY_GROUP_DEF_LEN = 50

export const INITIAL_VALUE: Value = {
  version: 2,
  mode: 'idle',
  focus: false,
  a: 0, // zoom (x? y?)
  b: 0,
  c: 0,
  d: 0,
  e: 0,
  f: 0,
  viewerWidth: 5000,
  viewerHeight: 5000,
  SVGWidth: 5000,
  SVGHeight: 5000,
  startX: null,
  startY: null,
  endX: null,
  endY: null,
  miniatureOpen: false,
}

export const KIN_MARGIN = { top: 20, right: 20, bottom: 20, left: 20 }

export const INITIAL_BOUNDS: Bounds = {
  xMin: -1000,
  xMax: 1000,
  yMin: -1000,
  yMax: 1000,
}

export const ROOT_DIAMETER = 450
export const ROOT_SEPARATION = (2 * Math.PI) / 6 / ROOT_DIAMETER // whole circle in radians / 6 pieces
export const ROOT_SIZE: [number, number] = [ROOT_DIAMETER, ROOT_DIAMETER]

export const DRAWER_WIDTH = 408
export const SEARCH_BAR_WIDTH = 392

export const CONNECTION_ITEM_WIDTH = 200
export const CONNECTION_ARROW_WIDTH = 25
export const CONNECTION_SOURCE_WIDTH = 60

// miliseconds
export const DEFAULT_DEBOUNCE_DUR = 500

export const IS_PRODUCTION = process.env.REACT_APP_CHANNEL === 'prod'

export const SVG_CONTAINER_HEIGHT = 1500
export const SVG_CONTAINER_WIDTH = 1500

export const SELECTED_LANGUAGE = 'English'
export const DEFAULT_RADIUS = 5

export const FULL_COLOR = true

/**
 * For a given progeny_frequencies_log, what is the percentile of the root for number of popular roots
 */
export const ROOT_FREQUENCY_PERCENTILE_LOOKUP: { [key: number]: number } = {
  0: 2,
  1: 10,
  2: 18,
  3: 26,
  4: 34,
  5: 42,
  6: 48,
  7: 52,
  8: 56,
  9: 60,
  10: 64,
  11: 68,
  12: 72,
  13: 76,
  14: 80,
  15: 84,
  16: 88,
  23: 92,
  24: 96,
  25: 100,
}

/**
 * For a given word frequency, if it is higher than the given key, it is above (or equal to) that percentile for word use
 */
export const WORD_FREQUENCY_PERCENTILE_LOOKUP: { [key: number]: number } = {
  0: 1,
  1: 3,
  2: 5,
  3: 6,
  4: 7,
  5: 8,
  6: 9,
  7: 10,
  8: 11,
  9: 12,
  11: 13,
  12: 14,
  14: 15,
  16: 16,
  18: 17,
  21: 18,
  23: 19,
  25: 20,
  27: 21,
  31: 22,
  34: 23,
  35: 24,
  39: 25,
  44: 26,
  49: 27,
  53: 28,
  61: 29,
  68: 30,
  70: 31,
  78: 32,
  90: 33,
  99: 34,
  105: 35,
  118: 36,
  133: 37,
  144: 38,
  162: 39,
  176: 40,
  200: 41,
  220: 42,
  243: 43,
  272: 44,
  301: 45,
  336: 46,
  373: 47,
  408: 48,
  457: 49,
  509: 50,
  571: 51,
  634: 52,
  707: 53,
  787: 54,
  880: 55,
  980: 56,
  1086: 57,
  1213: 58,
  1354: 59,
  1500: 60,
  1670: 61,
  1863: 62,
  2072: 63,
  2308: 64,
  2574: 65,
  2868: 66,
  3191: 67,
  3564: 68,
  3975: 69,
  4430: 70,
  4926: 71,
  5496: 72,
  6112: 73,
  6819: 74,
  7632: 75,
  8554: 76,
  9580: 77,
  10756: 78,
  12078: 79,
  13614: 80,
  15375: 81,
  19756: 82,
  22575: 83,
  25864: 84,
  29838: 85,
  34535: 86,
  40319: 87,
  47305: 88,
  55982: 89,
  66940: 90,
  81187: 91,
  100254: 92,
  125530: 93,
  161822: 94,
  215005: 95,
  296532: 96,
  440785: 97,
  724812: 98,
  1602056: 99,
  1030104370: 100,
}

export const DEFAULT_LANGUAGE = 'English'
export const ALL_LANGUAGES_STRING = 'All Languages'

export const FREE_WOTDS = 2

export const ENV_CHANNEL = process.env.REACT_APP_CHANNEL as EnvironmentKey

export const PAGE_WRAPPER_ID = 'page-wrapper'

console.debug(`Loading with channel:${ENV_CHANNEL}`)

export const MAX_DETAIL_HINT_PREFETCH = 200

export const INVITATION_MIN_PAGE_COUNT = 2

export const INVITATION_COUNT_DOWN = 20
