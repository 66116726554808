import styled from '@mui/material/styles/styled'
import { CSSProperties, HTMLProps } from 'react'

export const Row = styled('div', {
  shouldForwardProp: (p) => p !== 'justifyContent' && p !== 'alignItems',
})<
  HTMLProps<HTMLDivElement> & {
    justifyContent?: CSSProperties['justifyContent']
    alignItems?: CSSProperties['alignItems']
  }
>(({ justifyContent, alignItems }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: justifyContent ?? 'center',
  alignItems: alignItems ?? 'center',
}))
