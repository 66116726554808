import { useQueryParams } from 'pathManager/hooks'
import { createContext, ReactNode, useContext, useState } from 'react'
import { EnvironmentKey, isEnvironmentKey } from 'utils/types'
import { INITIAL_STATE } from './constants'

const Context = createContext(INITIAL_STATE)

export const useChannel = () => useContext(Context)

const Controller = ({ children }: { children: ReactNode }) => {
  console.debug('Rerender channel controller')
  const [channel, setChannel] = useState<EnvironmentKey>(INITIAL_STATE.channel)
  const isProduction = channel === 'prod'
  const { queryParams } = useQueryParams()

  if (
    typeof queryParams.channel === 'string' &&
    isEnvironmentKey(queryParams.channel)
  ) {
    setChannel(queryParams.channel)
  }

  return (
    <Context.Provider
      value={{
        channel,
        isProduction,
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default Controller
