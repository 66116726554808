import { styled } from '@mui/material/styles'
import { HTMLProps } from 'react'

export const StyledTitle = styled('h1')<HTMLProps<HTMLParagraphElement>>(
  ({ theme }) => ({
    fontSize: '2.25rem',
    fontWeight: '700',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  })
)

export const Title = ({ children, style }: HTMLProps<HTMLParagraphElement>) => (
  <StyledTitle style={style}>{children}</StyledTitle>
)
