/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { drag } from 'd3-drag'
import { Simulation, SimulationNodeDatum } from 'd3-force'
import queryString from 'query-string'
import { useCallback, useEffect, useRef, useState } from 'react'
import { transliterate } from 'transliteration'
import { DEFAULT_LANGUAGE } from './constants'
// import transliteration_dict from './transliteration_dict.json'
import {
  NewspaperRespellingType,
  NonNullableProperties,
  ParamsDict,
  RecursiveStringProperties,
} from './types'

// UTILITY FUNCTIONS
export const deepCopy = <T extends object>(obj: T): T =>
  JSON.parse(JSON.stringify(obj))

export const arraysEqual = (a: any[], b: any[]): boolean => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false
  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export const setsEqual = (aSet: Set<any>, bSet: Set<any>): boolean => {
  if (aSet.size !== bSet.size) return false
  for (const a of [...aSet]) if (!bSet.has(a)) return false
  return true
}

export const objsAreSame = (
  obj1: { [key: string]: any } | undefined,
  obj2: { [key: string]: any } | undefined
): boolean => {
  //Loop through properties in object 1
  if (!obj1 && !obj2) return true
  if ((obj1 && !obj2) || (!obj1 && obj2)) return false

  for (const p in obj1) {
    //Check property exists on both objects
    if (
      Object.prototype.hasOwnProperty.call(obj1, p) !==
      Object.prototype.hasOwnProperty.call(obj2, p)
    ) {
      return false
    }

    switch (typeof obj1[p]) {
      //Deep compare objects
      case 'object':
        if (!objsAreSame(obj1[p], (obj2 ?? {})[p])) return false
        break
      //Compare function code
      case 'function':
        if (
          typeof (obj2 ?? {})[p] == 'undefined' ||
          (p !== 'compare' && obj1[p].toString() !== (obj2 ?? {})[p].toString())
        )
          return false
        break
      //Compare values
      default:
        if (obj1[p] !== (obj2 ?? {})[p]) return false
    }
  }

  //Check object 2 for any extra properties
  for (const q in obj2) {
    if (typeof (obj1 ?? {})[q] === 'undefined') return false
  }
  return true
}

// TREE FUNCTIONS
export type RecursiveTreeType = { [key: number]: RecursiveTreeType }

const getLayersRecursive = (
  dict: RecursiveTreeType,
  layer: number,
  affixes: string[]
): [number, string][] => {
  let layer_arrays: [number, string][] = []
  for (const key in dict) {
    layer_arrays.push([layer, String(key)])
    if (!affixes.includes(key)) {
      layer_arrays = layer_arrays.concat(
        getLayersRecursive(dict[key] || {}, layer + 1, affixes)
      )
    }
  }
  return layer_arrays
}

// Converts the nested dictionary into a list of layers to show
export const getLayers = (
  dict: RecursiveTreeType,
  affixes: string[]
): string[][] => {
  const return_array: string[][] = []

  if (dict === undefined) return []

  const layer_arrays = getLayersRecursive(dict, 0, affixes)

  layer_arrays.forEach((p) => {
    if (return_array[p[0]]) {
      return_array[p[0]].push(p[1])
    } else {
      return_array[p[0]] = [p[1]]
    }
  })
  return return_array
}

type NewpaperDictType = {
  [key: string]: string
}

const IPA_TO_NEWSPAPER_DICT: NewpaperDictType = {
  // Support
  '/': '',
  ' ': ' ',
  ˌ: '-',
  ˈ: '-',
  ʹ: '-',
  ː: '-', // vowel or consonant length "long"
  '[': '',
  ']': '',
  ',': '-', // probably an errror like '/æɹɪˈtiːnɔɪd, əˈɹɪtn̩ɔɪd/'
  ʔ: '-',
  '.': '-',
  ˑ: '-', // vowel or consonant length "half long"
  '(': '(', // subtle
  ')': ')', // subtle

  // Unsure
  '̀': '',
  '̌': '', // '/pʰɛ̌ː.mɛː/'
  '̃': '', // croissant nasalization https://en.wiktionary.org/wiki/Appendix:English_pronunciation
  '̥': '', // https://en.wiktionary.org/wiki/raison_d%27%C3%AAtre
  '̯': '', // delightful
  '̚': '', // delightful
  '̩': '', // Still's murmur
  '͡': '', // jig
  '-': '', // Greco- , think it's just for prefixes
  '‿': '', // raison d’être
  '̂': '', // zongzi
  '̆': '', // enstraiten
  '\u200d': '', //Orchidaceae
  '̪': '', // ['Anand', '/aːn.ənd̪/'

  // Vowels
  a: 'a', // daʼan (RP)
  æ: 'a', // bad
  ɑ: 'ah', // 'aw'? father, boss, wasp
  ã: 'ah', // avant garde (second "a")
  ä: 'ah', // artisanal
  ɐ: 'ah', // aight
  ɒ: 'aw', // caught
  ɔ: 'aw', // caught
  aɪ: 'ie', // rice
  ɛ: 'eh', // 'e'?
  e: 'eh', // 'roman à clef'
  ɘ: 'eh', //careless
  eɪ: 'ay', // 'ey'? pain, day
  ə: 'uh', // '' about
  ᵊ: '(uh)', // artisanal
  ɪ: 'ih', // 'i'?
  ɨ: 'ih', // falutin
  y: 'ih', // "l'ultime avertissement", '/lyl.ti.ma.vɛʁ.tis.mɑ̃/'
  i: 'ee',
  oʊ: 'oh', // rote
  ʊ: 'oo', // '' put?
  ø: 'oo', // https://www.youtube.com/watch?v=S7i4wL2QdAU
  u: 'oo',
  ɔɪ: 'oi', // 'oy'? noise
  aʊ: 'ou', // 'ow'? tower
  ʌ: 'uh', // run
  ɜ: 'ur', // ???
  ɝ: 'ur',
  ɵ: 'uh',
  ʉ: 'uh', // nucular
  ô: 'uh', //'Seconal', '/ˈsekəˌnôl/'

  // Consonants
  b: 'b', // but, web, rubble
  t͡ʃ: 'ch', //chat, teach, nature
  c: 'ch', // error on benzocaine?
  d: 'd', //dot, idea, nod
  f: 'f', //fan, left, enough, photo
  ɡ: 'g', //get, bag
  g: 'g', //get, bag
  h: 'h', //ham
  ʰ: '(h)', //whittle
  ʱ: '(h)', // https://en.wikipedia.org/wiki/Breathy_voice https://www.quora.com/Is-*b%CA%B0-aspirated-bilabial-stop-in-the-Proto-Indo-European-language-voiced-As-b%CA%B0-in-Sanskrit
  ç: 'h', // ['schrecklichkeit', '/ˈʃɹɛklɪçkaɪt/'
  ʍ: 'w', //which
  d͡ʒ: 'j', //joy, agile, age
  dʒ: 'j', //joy, agile, age
  k: 'k', //cat, tack
  x: 'kh', //loch (in Scottish English)
  χ: 'kh', //challah
  l: 'l', //left
  l̩: 'l', // 'uhl'? little
  ɫ: 'l', //delightful
  m: 'm', //man, animal, him
  m̩: 'm', // 'uhm'? spasm, prism
  n: 'n', //note, ant, pan
  n̩: 'n', // 'uhn'? hidden
  ń: 'n', //nightdream
  ŋ: 'ng', //singer, ring
  p: 'p', //pen, spin, top, apple
  r: 'r', //from
  ɹ: 'r', //run, very
  ʀ: 'r',
  ʁ: 'gr', // https://www.youtube.com/watch?v=T1TFuZMYoeQ
  s: 's', //set, list, ice
  ʃ: 'sh', //ash, sure, ration
  t: 't', //ton, butt
  θ: 'th', //thin, nothing, moth
  ð: 'th', //this, father, clothe
  v: 'v', //voice, navel
  w: 'w', //wet
  j: 'y', //yes
  ʲ: '(y)',
  z: 'z', //zoo, quiz, rose
  ʒ: 'zh', //vision, treasure

  // Ancient Greek
  β: 'b',
  o: 'ah', //Guess

  // Armenian
  ɾ: 'r',

  // General English
  ɚ: 'uhr',
}

// Newspaper pronunciation
export const newspaper_respelling = (ipa: string): NewspaperRespellingType => {
  /*
    Receive an IPA coden and return the newspaper respelling
    e.g. pharmaceutical /ˌfɑɹməˈs(j)utɪkl̩/ (FAR-muh-sue-ti-kal)
    """
	*/
  ipa = ipa.toLowerCase()

  //{'a', 'd', 'e', 'l', 'm', 'n', 'o', 't', 'ɔ'}
  // possible_doubles = set(k[0] for k in IPA_TO_NEWSPAPER_DICT.keys() if len(k)>1)
  const possible_doubles = Object.keys(IPA_TO_NEWSPAPER_DICT).reduce(
    (accu, key) => {
      if ((IPA_TO_NEWSPAPER_DICT[key]?.length || 0) > 1) {
        return accu.add(key[0])
      }
      return accu
    },
    new Set()
  )
  // newspaper = []
  let capitalize = false
  let skip_next = false
  const missing_letters: string[] = []

  ipa = ipa.replace(/\(.+?\)/, '')

  if (ipa && ipa[0] === '-') {
    console.debug(`${ipa} is just a rhyme. Not an actual pronunciation`)
    return { newspaper: undefined, missing_letters: missing_letters }
  }

  // for i, letter in enumerate(ipa):
  const newspaper_respell: string[] = ipa
    .split('')
    .reduce<string[]>((newspaper, letter, i) => {
      let newspaper_letter = ''

      // Skip after a double
      if (skip_next) {
        skip_next = false
        return newspaper
      }

      if (
        ['ˌ', ' ', 'ː', 'ʔ', '.', 'ˑ'].indexOf(letter) !== -1 &&
        newspaper &&
        newspaper[-1] !== '-'
      ) {
        capitalize = false
      } else if (['ˈ'].indexOf(letter) !== -1) {
        capitalize = true
      }

      if (
        letter in possible_doubles &&
        ipa.slice(i, i + 2).length > 1 &&
        ipa.slice(i, i + 2) in IPA_TO_NEWSPAPER_DICT
      ) {
        newspaper_letter = IPA_TO_NEWSPAPER_DICT[ipa.slice(i, i + 2)] || ''
        skip_next = true
      } else if (letter in IPA_TO_NEWSPAPER_DICT) {
        newspaper_letter = IPA_TO_NEWSPAPER_DICT[letter] || ''
      } else {
        missing_letters.push(letter)
        return newspaper
      }

      if (
        newspaper &&
        newspaper.slice(-1)[0] === '-' &&
        newspaper_letter === '-'
      )
        return newspaper
      if (newspaper.length === 0 && newspaper_letter === '-') return newspaper
      newspaper = newspaper.concat(
        capitalize ? newspaper_letter.toUpperCase() : newspaper_letter
      )
      return newspaper
      // Prep the IPA into phonemes, dashes, and spaces
      // Translate the phonemes to respellings and return
    }, [])
  return {
    newspaper: newspaper_respell.join(''),
    missing_letters: missing_letters,
  }
}

const OK_CHARS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz₁₂₃₄₅₆₇₈₉ʰʷ-()[]' "

const needs_transliteration = (word: string): boolean => {
  return word
    .normalize('NFD') // decomposition https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
    .split('') // turn string into array
    .filter(
      (
        char // combining diacritics are 768 to 879
      ) =>
        char.charCodeAt(0) < 768 || // https://en.wikipedia.org/wiki/Combining_Diacritical_Marks
        char.charCodeAt(0) > 879
    )
    .some((char) => OK_CHARS.indexOf(char) === -1)
}

const full_transliteration = (word: string): string => {
  return transliterate(
    word
      .normalize('NFD') // decomposition https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
      .split('')
      .filter(
        (
          char // combining diacritics are 768 to 879
        ) =>
          char.charCodeAt(0) < 768 || // https://en.wikipedia.org/wiki/Combining_Diacritical_Marks
          char.charCodeAt(0) > 879
      )
      // .map((char) =>
      //@ts-ignore
      // char in transliteration_dict ? transliteration_dict[char] : char
      // )
      .join('')
  )
}

export const transliterate_only_complex = (
  word: string
): string | undefined => {
  // pass in a word, and get back the transliterated word or undefined if none needed or unable
  if (needs_transliteration(word)) {
    return full_transliteration(word)
  } else {
    return undefined
  }
}

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export function extent<D>(
  allData: D[],
  value: (d: D) => number
): [number, number] {
  return [Math.min(...allData.map(value)), Math.max(...allData.map(value))]
}

/**
 * removes null and undefined params
 * @param {object} paramsDict
 */
export const getParamString = (paramsDict: ParamsDict) => {
  const reducedParamsDict: object = Object.keys(paramsDict)
    .filter((key) => paramsDict[key] !== null && paramsDict[key] !== undefined)
    .reduce((accu, key) => ({ ...accu, [key]: paramsDict[key] }), {})
  //https://github.com/sindresorhus/query-string#stringifyobject-options
  const options = {} //Doing encoding in pathManager now { encode: false }
  const reducedParamsString = queryString.stringify(reducedParamsDict, options)
  return reducedParamsString
}

export type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T

export const isTruthy = <T extends unknown>(value: T): value is Truthy<T> =>
  Boolean(value)

export const addDays = (date: Date, days: number) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const rotate = (
  cx: number,
  cy: number,
  x: number,
  y: number,
  radians: number
) => {
  // let radians = (Math.PI / 180) * angle
  const cos = Math.cos(radians)
  const sin = Math.sin(radians)
  const nx = cos * (x - cx) + sin * (y - cy) + cx
  const ny = cos * (y - cy) - sin * (x - cx) + cy
  return [nx, ny]
}

export const onlyUnique = (value: unknown, index: number, self: unknown[]) => {
  return self.indexOf(value) === index
}

export const arrowHead = ({
  endx,
  endy,
  startx,
  starty,
  arrowSize = 10,
  endSize = 0,
}: {
  startx: number
  starty: number
  endx: number
  endy: number
  arrowSize?: number
  endSize?: number
}) => {
  const radians =
    Math.atan((starty - endy) / (startx - endx)) + (endx > startx ? Math.PI : 0)

  const [rx, ry] = rotate(0, 0, endSize + arrowSize * 1.5, 0, -radians)
  const [p1x, p1y] = rotate(0, 0, 0, arrowSize / 2, -radians)
  const [p2x, p2y] = rotate(0, 0, -arrowSize, -arrowSize / 2, -radians)
  const [p3x, p3y] = rotate(0, 0, arrowSize, -arrowSize / 2, -radians)

  // Draw the line and arrow
  return `M${endx},${endy}
  m${rx},${ry}
  l ${p1x},${p1y} ${p2x},${p2y} ${p3x},${p3y} z
  L${startx},${starty}`
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

export function debounce<A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number
): [(args: A) => Promise<R>, () => void] {
  let timer: NodeJS.Timeout

  const debouncedFunc = (args: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        resolve(fn(args))
      }, ms)
    })

  const teardown = () => clearTimeout(timer)

  return [debouncedFunc, teardown]
}

export function objectToArray<T>(
  object: T
): Array<T[keyof T] & { id: string }> {
  const ret: Array<T[keyof T] & { id: string }> = []
  // Object.keys() returns `string[]` but we will assert as `Array<keyof T>`
  for (const key of Object.keys(object) as Array<keyof T>) {
    ret.push(Object.assign({ id: key.toString() }, object[key]))
  }
  return ret
}

/**
 * changes value at index in array and returns new array
 */
export const replaceAt = <T extends unknown>(
  array: T[],
  index: number,
  value: T
) => {
  const ret = array.slice()
  ret[index] = value
  return ret
}

// Helpful for autocomplete
export const convertPropertiesToStrings = <T extends { [key: string]: any }>(
  object: T
): RecursiveStringProperties<T> =>
  Object.entries(object).reduce(
    (accu, [key, val]) => ({
      ...accu,
      [key]:
        typeof val === 'object'
          ? convertPropertiesToStrings(object)
          : !val
          ? ''
          : val.toString(),
    }),
    {} as RecursiveStringProperties<T>
  )

export const isEmail = (email: string) => {
  const newLocal =
    // eslint-disable-next-line no-control-regex
    /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$/
  return newLocal.test(email)
}

export function dragSim<U extends Element, T extends SimulationNodeDatum>(
  simulation: Simulation<T, undefined>
) {
  function dragstarted(event: any, d: any) {
    d.fx = d.x
    d.fy = d.y
  }

  function dragged(event: any, d: any) {
    if (simulation.alphaTarget() < 0.3) {
      simulation.alphaTarget(0.3).restart()
    }
    d.fx = event.x
    d.fy = event.y
  }

  function dragended(event: any, d: any) {
    if (!event.active) simulation.alphaTarget(0)
    d.fx = null
    d.fy = null
  }

  return drag<U, T>()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended)
}

export function titleCase(str: string, type: 'word' | 'sentence' = 'sentence') {
  return str
    .toLowerCase()
    .split(' ')
    .map((word, idx) =>
      type === 'word' || (type === 'sentence' && idx === 0)
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word
    )
    .join(' ')
}

export function snakeCaseToTitleCase(
  snakeCase: string,
  type: 'word' | 'sentence' = 'sentence'
) {
  return titleCase(snakeCase.replaceAll('_', ' ').replaceAll('-', ' '), type)
}

export const dateToFirebaseDate = (d: Date) => {
  const month = `${d.getMonth() + 1}`
  return `${d.getFullYear()}-${month.padStart(2, '0')}-${d
    .getDate()
    .toString()
    .padStart(2, '0')}`
}

export const numberToOrdinal = (i: number): string => {
  const j = i % 10
  const k = i % 100
  if (j == 1 && k != 11) {
    return i + 'st'
  }
  if (j == 2 && k != 12) {
    return i + 'nd'
  }
  if (j == 3 && k != 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export const langToTitle = (lang: string): string => {
  if (lang === DEFAULT_LANGUAGE) return ''
  if (lang === 'Proto-Indo-European') return 'PIE '
  return lang + ' '
}

export const randomItem = <T extends unknown>(array: T[]): T =>
  array[Math.floor(Math.random() * array.length)]

export const bound = (variable: number, min: number, max: number) =>
  Math.max(min, Math.min(variable, max))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmpty = <T extends { [key: string]: any }>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== null && v !== undefined)
  ) as NonNullableProperties<T>

export const splitTextAtPos = (text: string, pos: number) => {
  // const before = text.lastIndexOf(' ', pos)
  const after = text.indexOf(' ', pos + 1)

  // if (pos - before < after - pos) {
  //   pos = before
  // } else {
  pos = after
  // }

  if (text.length <= pos || pos <= 0) return [text]

  const s1 = text.slice(0, pos)
  const s2 = text.slice(pos + 1)
  return [s1, s2]
}
