import { EtymologyObject, EtymologyObjectDict } from 'componentData/types'
import { createContext, Dispatch, ReactNode, useReducer } from 'react'

export type AppContextState = {
  connectionDetailRoot: string | undefined
  connectionDetailDescendant: string | undefined
  etymologyObjects: EtymologyObjectDict
}

export type AppContextAction = {
  type: 'setConnectionDetails'
  payload: {
    connectionDetailRoot: string | undefined
    connectionDetailDescendant: string | undefined
  }
}

export type AppReducer = (
  state: AppContextState,
  action: AppContextAction
) => AppContextState

export type AppValue = [AppContextState, Dispatch<AppContextAction>]

const initialState: AppContextState = {
  connectionDetailRoot: undefined,
  connectionDetailDescendant: undefined,
  etymologyObjects: {},
}

// const initialContext: AppValue = [initialState, () => {}]
export const AppStoreContext = createContext(initialState)
export const DetailObjectContext = createContext(
  undefined as EtymologyObject | undefined
)
export const AppDispatchContext = createContext<Dispatch<AppContextAction>>(
  () => undefined
)

const appReducer: AppReducer = (
  state: AppContextState,
  action: AppContextAction
) => {
  switch (action.type) {
    case 'setConnectionDetails':
      return {
        ...state,
        ...action.payload,
      }

    default:
      throw new Error('unrecognized settings action')
  }
}

export const AppController = ({ children }: { children: ReactNode }) => {
  const [appData, reduceAppData]: AppValue = useReducer(
    appReducer,
    initialState
  )

  return (
    <AppDispatchContext.Provider value={reduceAppData}>
      <AppStoreContext.Provider value={appData}>
        {children}
      </AppStoreContext.Provider>
    </AppDispatchContext.Provider>
  )
}
