import { useCallback, useContext } from 'react'
import { DispatchContext } from './constants'
import { AlertDialogState } from './types'

export const useDispatchDialog = () => useContext(DispatchContext)

export const useThemeDialog = () => {
  const dispatch = useContext(DispatchContext)
  const openThemeDialog = useCallback(
    () => dispatch({ type: 'open', payload: { type: 'theme' } }),
    [dispatch]
  )
  return openThemeDialog
}

export const useLanguageDialog = () => {
  const dispatch = useContext(DispatchContext)
  const openThemeDialog = useCallback(
    () => dispatch({ type: 'open', payload: { type: 'language' } }),
    [dispatch]
  )
  return openThemeDialog
}

export const useAlertDialog = () => {
  const dispatch = useContext(DispatchContext)
  const openAlert = useCallback(
    ({
      title,
      subtitle,
      onConfirm,
      onCancel,
      confirmText,
      cancelText,
      blur,
    }: Omit<AlertDialogState, 'open'>) =>
      dispatch({
        type: 'open',
        payload: {
          type: 'alert',
          state: {
            title,
            subtitle,
            blur,
            onConfirm,
            onCancel,
            confirmText,
            cancelText,
          },
        },
      }),
    [dispatch]
  )

  const closeAlert = useCallback(
    () =>
      dispatch({
        type: 'close',
        payload: 'alert',
      }),
    [dispatch]
  )
  return { openAlert, closeAlert }
}
