import { Component, ComponentType } from 'react'
import CSS from 'csstype'
import { styled } from '@mui/material/styles'
import { PAGE_WRAPPER_ID } from 'utils/constants'
import { isMobile } from 'react-device-detect'
import Div100vh from 'react-div-100vh'

type WrapperOptions = {
  width?: 'max-width' | 'full' // page is max width
  verticalCentering?: 'flex-start' | 'center' // page is max width
  maxWidth?: number // max width in px
}

// https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
export const withPageWrapper = <P extends Record<string, never>>(
  Comp: ComponentType<P>,
  options?: WrapperOptions,
  wrapperStyleAugment?: CSS.Properties
): ComponentType<P> =>
  class WithScreen extends Component<P> {
    render() {
      const Wrapper =
        options?.width === 'full' ? FullWidthPageWrapper : MaxWidthPageWrapper
      const verticalCenteringStyle = {
        justifyContent: options?.verticalCentering ?? 'flex-start',
      }
      const maxWidth =
        options?.width === 'full' ? undefined : options?.maxWidth ?? 960
      const maxWidthStyle =
        maxWidth === undefined
          ? {}
          : {
              maxWidth,
              // Use padding and content-box to ensure scrollbar is at rightmost side
              padding: `0 max(50vw - ${maxWidth / 2}px, 1rem)`,
            }

      return (
        <Wrapper
          id={PAGE_WRAPPER_ID}
          style={{
            ...verticalCenteringStyle,
            ...wrapperStyleAugment,
            ...maxWidthStyle,
          }}
        >
          <Comp {...this.props} />
        </Wrapper>
      )
    }
  }

const MaxWidthPageWrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 320px;
  box-sizing: content-box;
  overflow-y: auto;
`

const FullWidthPageWrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 320px;
  // Use padding and content-box to ensure scrollbar is at rightmost side
  box-sizing: border-box;
  overflow-y: auto;
`
