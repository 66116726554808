/* eslint-disable @typescript-eslint/no-explicit-any */
import { useWindowSize } from '@react-hook/window-size'
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  Dispatch,
} from 'react'
import { isMobile } from 'react-device-detect'
import { useQueries, UseQueryOptions, UseQueryResult } from 'react-query'
import { useLocation } from 'react-router-dom'
import {
  INVITATION_COUNT_DOWN,
  INVITATION_MIN_PAGE_COUNT,
} from 'utils/constants'
import { usePathParams } from 'pathManager/hooks'
import { getStorageItem, setStorageItem, StorageItems } from './storage'
import { Action } from 'dialog/types'

export const useKeyPress = (targetKey: string) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState<boolean>(false)
  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === targetKey) {
        setKeyPressed(true)
      }
    },
    [targetKey]
  )

  // If released key is our target key then set to false
  const upHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === targetKey) {
        setKeyPressed(false)
      }
    },
    [targetKey]
  )

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [downHandler, upHandler]) // Empty array ensures that effect is only run on mount and unmount
  return keyPressed
}

export const useForceUpdate = () => {
  const [, setValue] = useState<number>(0)
  return () => setValue((value) => value + 1) // update state to force render
}

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

export const useQueriesTyped = <TQueries extends readonly UseQueryOptions[]>(
  queries: [...TQueries]
): {
  [ArrayElement in keyof TQueries]: UseQueryResult<
    TQueries[ArrayElement] extends { select: infer TSelect }
      ? TSelect extends (data: any) => any
        ? ReturnType<TSelect>
        : never
      : Awaited<
          ReturnType<
            NonNullable<
              Extract<TQueries[ArrayElement], UseQueryOptions>['queryFn']
            >
          >
        >
  >
} => {
  return useQueries(
    queries as UseQueryOptions<unknown, unknown, unknown>[]
  ) as any
}

export const useClickOutside = (
  elRef: MutableRefObject<HTMLDivElement | null>,
  callback: (e: MouseEvent) => void
) => {
  const callbackRef = useRef<(e: MouseEvent) => void>()
  callbackRef.current = callback
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!elRef.current?.contains(e.target as Node) && callbackRef.current) {
        callbackRef.current(e)
      }
    }
    document.addEventListener('click', handleClickOutside)
  }, [callbackRef, elRef])
}

export const useRootPath = () => {
  const location = useLocation()
  const rootPath = location.pathname.split('/')[1]
  return rootPath
}

/**
 * Scrolls to the top of the page on load
 */
export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])
}

export const useAudio = (url: string) => {
  const [audio] = useState(new Audio(url))
  const [isPlaying, setPlaying] = useState(false)

  const toggle = () => setPlaying(!isPlaying)

  useEffect(() => {
    isPlaying ? audio.play() : audio.pause()
  }, [audio, isPlaying])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [audio])

  return { isPlaying, toggle }
}

export const usePrevious = <T,>(value: T | undefined) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useBrowserType = () => {
  // // Opera 8.0+
  // const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // // Firefox 1.0+
  // const isFirefox = typeof InstallTrigger !== 'undefined';

  // // Safari 3.0+ "[object HTMLElementConstructor]"
  // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

  // // Internet Explorer 6-11
  // const isIE = /*@cc_on!@*/false || !!document.documentMode;

  // // Edge 20+
  // const isEdge = !isIE && !!window.StyleMedia;

  // // Chrome 1 - 71
  // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // // Blink engine detection
  // const isBlink = (isChrome || isOpera) && !!window.CSS;
  navigator.userAgent
}

export const useIsLandscape = () => {
  const [width, height] = useWindowSize()
  const [isLandscape, setIsLandscape] = useState<boolean>()

  useEffect(() => {
    setIsLandscape(isMobile && width > height)
  }, [height, width])

  return { isLandscape }
}

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      savedCallback.current && savedCallback.current()
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

export const useCountdown = (seconds: number) => {
  const [countdown, setCountdown] = useState<number>(seconds)

  const handleCountdown = () => {
    if (countdown === 0) return
    setCountdown((prevState) => prevState - 1)
  }

  useInterval(handleCountdown, 1000)

  return { countdown }
}

// TODO Move this to the Dialog controller
export function usePageInvitationWatcher(reduceData: Dispatch<Action>) {
  const { word, language, component } = usePathParams()
  const [pageCount, setPageCount] = useState<number>(1)
  const { countdown } = useCountdown(INVITATION_COUNT_DOWN)
  const [prevWordLangCompHash, setPrevWordLangCompHash] = useState(
    getWordLangCompHash({ word, language, component })
  )

  const openInvitationDialog = useCallback(() => {
    if (!getStorageItem(StorageItems.VIEWED_JOIN_MAIL_LISTS)) {
      setStorageItem(StorageItems.VIEWED_JOIN_MAIL_LISTS, true)
      reduceData({ type: 'open', payload: { type: 'invitation' } })
    }
  }, [reduceData])

  //https://beta.reactjs.org/learn/you-might-not-need-an-effect?utm_campaign=This%20Week%20In%20React&utm_medium=email&utm_source=Revue%20newsletter#resetting-all-state-when-a-prop-changes
  const currentWordLangCompHash = getWordLangCompHash({
    word,
    language,
    component,
  })
  if (
    currentWordLangCompHash &&
    currentWordLangCompHash !== prevWordLangCompHash
  ) {
    // console.log('pageCount', word, prevWord)
    setPrevWordLangCompHash(currentWordLangCompHash)
    setPageCount((p) => p + 1)
  }

  // useEffect(() => {
  //   console.log('pageCount', pageCount)
  // }, [pageCount])

  useEffect(() => {
    if (pageCount > INVITATION_MIN_PAGE_COUNT && countdown === 0) {
      openInvitationDialog()
    }
  }, [pageCount, openInvitationDialog, countdown])
}

const getWordLangCompHash = ({
  word,
  language,
  component,
}: {
  word?: string
  language?: string
  component?: string
}) => `${word}-${language}-${component}`
