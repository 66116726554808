import { useTheme } from '@mui/material/styles'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { colorTypeToThemeColor } from 'theme/constants'

export const ChildrenTableIcon = (
  props: SvgIconProps & { strokeColor?: string }
) => {
  const theme = useTheme()
  const { strokeColor: strokeColorProp, ...rest } = props
  const strokeColor =
    strokeColorProp ?? colorTypeToThemeColor(props.color, theme)

  return (
    <SvgIcon viewBox="-50 40 800 450" {...rest}>
      <circle cx="62.5" cy="62.5" r="62.5" />
      <circle cx="62.5" cy="255.5" r="62.5" />
      <circle cx="62.5" cy="450.5" r="62.5" />
      <line
        x1="243.5"
        y1="62.5"
        x2="618.5"
        y2="62.5"
        stroke={strokeColor}
        strokeWidth="125"
        strokeLinecap="round"
      />
      <line
        x1="243.5"
        y1="255.5"
        x2="618.5"
        y2="255.5"
        stroke={strokeColor}
        strokeWidth="125"
        strokeLinecap="round"
      />
      <line
        x1="243.5"
        y1="450.5"
        x2="618.5"
        y2="450.5"
        stroke={strokeColor}
        strokeWidth="125"
        strokeLinecap="round"
      />
    </SvgIcon>
  )
}
