/* eslint-disable @typescript-eslint/no-namespace */
export enum Path {
  LOGIN = 'login',
  LOGOUT = 'logout',
  VANGUARD = 'vanguard',
  REGISTER = 'register',
  CONN = 'connections',
  DETAIL = 'detail',
  WOTD_LIST = 'wotd-list',
  SEE_WOTD = 'wotd',
  NODE = 'nodes',
  NODE_CHILD = 'descendant-nodes',
  PROGENY_TREE = 'descendant-bubble',
  PROGENY_TABLE = 'descendant-table',
  ACCOUNT = 'account',
  PLANS = 'plans',
  TREE = 'tree',
  INFO = 'info',
  REPORT = 'report',
}

export namespace PathHelper {
  export const AUTH_COMPONENTS = [
    Path.LOGIN,
    Path.LOGOUT,
    Path.REGISTER,
  ] as const

  export const WORD_COMPONENTS = [
    Path.CONN,
    Path.DETAIL,
    Path.NODE,
    Path.NODE_CHILD,
    Path.PROGENY_TREE,
    Path.PROGENY_TABLE,
    Path.TREE,
  ] as const

  export const FREE_COMPONENTS = [Path.DETAIL, Path.TREE] as const

  export const CHILD_FOCUSED_COMPONENTS = [
    Path.NODE,
    Path.TREE,
    // Path.MAP,
  ] as const
  export const ROOT_FOCUSED_COMPONENTS = [
    Path.NODE_CHILD,
    Path.PROGENY_TABLE,
    Path.PROGENY_TREE,
  ] as const

  export const UTILITIES = [
    Path.VANGUARD,
    Path.INFO,
    Path.LOGIN,
    Path.LOGOUT,
    Path.REGISTER,
    Path.ACCOUNT,
    Path.PLANS,
    Path.WOTD_LIST,
    Path.SEE_WOTD,
    Path.REPORT,
  ] as const

  export const CONNS = [
    `/${Path.CONN}`,
    `/${Path.CONN}/id/:id`,
    `/${Path.CONN}/:language/:word`,
    `/${Path.CONN}/:language/:word/:direction`,
    `/${Path.CONN}/:word`,
  ]
  export const DETAILS = [
    `/${Path.DETAIL}`,
    `/${Path.DETAIL}/id/:id`,
    `/${Path.DETAIL}/:language/:word`,
    `/${Path.DETAIL}/:word`,
  ] as const
  export const NODES = [
    `/${Path.NODE}`,
    `/${Path.NODE}/id/:id`,
    `/${Path.NODE}/:language/:word`,
    `/${Path.NODE}/:word`,
  ] as const

  export const NODE_CHILDS = [
    `/${Path.NODE_CHILD}`,
    `/${Path.NODE_CHILD}/id/:id`,
    `/${Path.NODE_CHILD}/:language/:word`,
    `/${Path.NODE_CHILD}/:word`,
  ] as const

  export const PROGENY_TABLES = [
    `/${Path.PROGENY_TABLE}`,
    `/${Path.PROGENY_TABLE}/id/:id`,
    `/${Path.PROGENY_TABLE}/:language/:word`,
    `/${Path.PROGENY_TABLE}/:word`,
  ] as const

  export const PROGENY_TREES = [
    `/${Path.PROGENY_TREE}`,
    `/${Path.PROGENY_TREE}/id/:id`,
    `/${Path.PROGENY_TREE}/:language/:word`,
    `/${Path.PROGENY_TREE}/:word`,
  ] as const

  export const TREES = [
    `/${Path.TREE}`,
    `/${Path.TREE}/id/:id`,
    `/${Path.TREE}/:language/:word`,
    `/${Path.TREE}/:word`,
  ] as const
}

if (
  Object.values(Path).length !==
  PathHelper.WORD_COMPONENTS.length + PathHelper.UTILITIES.length
) {
  throw new Error("Path aren't all assigned to Word Components or Utilities")
}

export const DEFAULT_CHILD_PATH = Path.TREE
export const DEFAULT_PATH = DEFAULT_CHILD_PATH
