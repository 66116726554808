import { Reducer } from '../utils/types'
import { Action, State } from './types'

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action
) => {
  switch (action.type) {
    case 'open':
      switch (action.payload.type) {
        case 'alert':
          return {
            ...state,
            alert: {
              open: true,
              ...action.payload.state,
            },
          }
        case 'language':
          return {
            ...state,
            language: { open: true },
          }
        case 'theme':
          return {
            ...state,
            theme: { open: true },
          }
        case 'invitation':
          return {
            ...state,
            invitation: { open: true },
          }
      }
      break
    case 'close':
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          blur: false,
          open: false, // default value
        },
      }
    default:
      throw new Error('unrecognized settings action in snackbar reducer')
  }
}
