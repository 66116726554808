import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { ThemeSwitch } from 'theme/ThemeSwitch'
import { useDispatchDialog } from './hooks'

export interface ThemeDialogProps {
  open: boolean
}

const ThemeDialog = ({ open }: ThemeDialogProps) => {
  const dispatch = useDispatchDialog()

  const handleCancel = () => dispatch({ type: 'close', payload: 'theme' })

  return (
    <Dialog onClose={handleCancel} open={open}>
      <DialogTitle>Choose a theme</DialogTitle>
      <DialogContent>
        <ThemeSwitch />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ThemeDialog
