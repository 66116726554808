import useTheme from '@mui/material/styles/useTheme'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { colorTypeToThemeColor } from 'theme/constants'

export const NodeChildrenIcon = (
  props: SvgIconProps & { strokeColor?: string }
) => {
  const theme = useTheme()
  const { strokeColor: strokeColorProp, ...rest } = props
  const strokeColor =
    strokeColorProp ?? colorTypeToThemeColor(props.color, theme)

  return (
    <SvgIcon viewBox="110 110 804 804" {...rest}>
      <circle cx="512" cy="512" r="98" />
      <circle cx="612" cy="339" r="80" />
      <circle cx="757" cy="300" r="48" />
      <circle cx="573" cy="194" r="48" />
      <circle cx="687" cy="209" r="48" />
      <circle cx="851" cy="569" r="48" />
      <circle cx="851" cy="454" r="48" />
      <circle cx="392" cy="834" r="48" />
      <circle cx="293" cy="779" r="48" />
      <circle cx="262" cy="665" r="48" />
      <circle cx="503" cy="804" r="48" />
      <circle cx="320" cy="512" r="48" />
      <circle cx="412" cy="685" r="80" />
      <circle cx="412" cy="339" r="80" />
      <circle cx="612" cy="685" r="80" />
      <circle cx="712" cy="512" r="80" />
      <path d="M712 512L512 512" stroke={strokeColor} strokeWidth="33" />
      <path
        d="M512.165 511.83L412.165 338.625"
        stroke={strokeColor}
        strokeWidth="33"
      />
      <path d="M612 685.205L512 512" stroke={strokeColor} strokeWidth="33" />
      <path
        d="M512 512.252L612 339.047"
        stroke={strokeColor}
        strokeWidth="33"
      />
      <path
        d="M612.129 338.638L573.306 193.75"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path
        d="M612 338.851L687 208.947"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path
        d="M612 339.129L756.889 300.306"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path
        d="M712 512L850.582 569.403"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path
        d="M293 776.314L412.003 685"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path
        d="M263 665L411.717 684.579"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path
        d="M392 833.717L411.579 685"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path
        d="M503.314 804.003L412 685"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path
        d="M712 511.883L850.582 454.481"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <circle cx="389" cy="191" r="48" transform="rotate(-180 389 191)" />
      <circle cx="296" cy="244" r="48" transform="rotate(-180 296 244)" />
      <path
        d="M411.744 339.412L296 244"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path
        d="M413.757 338.943L389 191"
        stroke={strokeColor}
        strokeWidth="15"
      />
      <path d="M412 685.205L512 512" stroke={strokeColor} strokeWidth="33" />
      <path d="M320 512L512 512" stroke={strokeColor} strokeWidth="15" />
    </SvgIcon>
  )
}
