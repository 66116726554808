import { createContext } from 'react'
import { State } from './types'
export const INITIAL_STATE: State = {
  loading: true,
  loggedIn: false,
  canUpdateConnections: false,
  user: undefined,
}

export const INITIAL_STRINGS = { email: '', password: '', password_confirm: '' }
export const INITIAL_BOOLEANS = {
  email: false,
  password: false,
  password_confirm: false,
}

export const AuthStoreContext = createContext(INITIAL_STATE)
