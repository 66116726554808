import Fab from '@mui/material/Fab'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useWindowSize } from '@react-hook/window-size'
import { NavLinkIconButton } from 'components/NavLinkIconButton'
import {
  useActionPointDispatch,
  useActionPointStore,
} from 'contexts/ActionPointController'
import { SVGProps } from 'react'
import { Textfit } from 'react-textfit'
import { Path } from 'router/constants'
import { useActivePlan } from 'subscriptions/hooks'
import { getLanguageColor } from 'utils/Colors'
import { bound } from 'utils/functions'

const ARROW_STROKE_WIDTH = 3
const ICON_SPACE_RADIUS = 100
const DEFAULT_ICON_RADIUS = 40

const ACTION_ELEMENT_RADIUS = ICON_SPACE_RADIUS + DEFAULT_ICON_RADIUS / 2 + 10 //padding
const CENTER_RADIUS = 80

export const ActionRing = () => {
  const { availablePaths } = useActivePlan()
  const [windowWidth, windowHeight] = useWindowSize()
  const actionPoint = useActionPointStore()
  const setActionPoint = useActionPointDispatch()
  const {
    x: clickX = 0,
    y: clickY = 0,
    word = '',
    language = '',
  } = actionPoint ?? {}
  const theme = useTheme()
  const languageColor = getLanguageColor(language)
  const displayX = bound(
    clickX,
    ACTION_ELEMENT_RADIUS,
    windowWidth - ACTION_ELEMENT_RADIUS
  )
  const displayY = bound(
    clickY,
    ACTION_ELEMENT_RADIUS,
    windowHeight - ACTION_ELEMENT_RADIUS
  )

  const actionPaths = [...availablePaths, Path.REPORT]

  return !actionPoint ? null : (
    <svg
      onClick={() => setActionPoint(undefined)}
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: '#00000088',
        pointerEvents: actionPoint ? 'all' : 'none',
        backdropFilter: 'blur(2px)',
        // display: actionPoint ? 'block' : 'none',
        visibility: actionPoint ? 'visible' : 'hidden',
      }}
    >
      <defs>
        <radialGradient id="action" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={languageColor} stopOpacity="100%" />
          <stop offset="100%" stopColor={languageColor} stopOpacity="0%" />
        </radialGradient>
      </defs>

      <circle
        r={CENTER_RADIUS + 10}
        fill={`url('#action')`}
        cx={displayX}
        cy={displayY}
      />
      {actionPoint &&
        actionPaths.map((path, idx, arr) => {
          const angle = ((Math.PI * 2) / arr.length) * -1 * idx + Math.PI
          const circleX = Math.sin(angle) * ICON_SPACE_RADIUS
          const circleY = Math.cos(angle) * ICON_SPACE_RADIUS
          const lineEndX = Math.sin(angle) * (ICON_SPACE_RADIUS - 24)
          const lineEndY = Math.cos(angle) * (ICON_SPACE_RADIUS - 24)

          return (
            <g key={path}>
              <path
                d={`M${displayX},${displayY} l${lineEndX},${lineEndY}  `}
                strokeWidth={ARROW_STROKE_WIDTH}
                stroke={theme.palette.arrowColor.action}
              />
              <SvgViewIcon
                x={displayX + circleX}
                y={displayY + circleY}
                // radius={radius}
              >
                <NavLinkIconButton
                  word={word}
                  language={language}
                  path={path}
                  style={{
                    pointerEvents: 'all',
                  }}
                  onClick={() => {
                    // Removed stop propagataion due to white screen issue
                    setActionPoint(undefined)
                  }}
                />
              </SvgViewIcon>
            </g>
          )
        })}

      <SvgViewIcon
        x={displayX}
        y={displayY}
        radius={CENTER_RADIUS}
        color={languageColor}
      >
        <Fab
          style={{
            width: CENTER_RADIUS,
            height: CENTER_RADIUS,
            pointerEvents: 'all',
            backgroundColor: languageColor,
          }}
          onClick={(e) => {
            e.stopPropagation()
            setActionPoint(undefined)
          }}
        >
          <div
            style={{
              width: '100%',
              textTransform: 'none',
            }}
          >
            <Typography style={{ fontSize: 10 }}>{language}</Typography>
            <Textfit
              mode="single"
              style={{ marginLeft: 3, marginRight: 3, fontWeight: '400' }}
              min={8}
              max={15}
            >
              {word}
            </Textfit>
          </div>
        </Fab>
      </SvgViewIcon>
    </svg>
  )
}

type SvgViewIconProps = SVGProps<SVGForeignObjectElement> & {
  x: number
  y: number
}
const SvgViewIcon = ({ x, y, children, ...svgProps }: SvgViewIconProps) => {
  const { radius = DEFAULT_ICON_RADIUS, ...restSvgProps } = svgProps
  const radiusNum = parseInt(radius.toString())

  return (
    <foreignObject
      x={x - radiusNum}
      y={y - radiusNum}
      className="test-foreign-object"
      height={radiusNum * 2}
      width={radiusNum * 2}
      {...restSvgProps}
      style={{
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
    </foreignObject>
  )
}
