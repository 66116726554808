import { isWordComponent } from 'pathManager/functions'
import { usePathParams } from 'pathManager/hooks'
import { ReactNode } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Path } from 'router/constants'
import { pathToInfo } from 'router/functions'
import { langToTitle, titleCase } from 'utils/functions'

const pathToTitle = (word: string, language: string, component: Path): string =>
  `${langToTitle(language)}${word} | ${
    pathToInfo(component)?.title ?? titleCase(component)
  }`

export const HelmetController = ({ children }: { children: ReactNode }) => {
  const { word, language, component } = usePathParams()

  const titleText =
    !component || !word || !language
      ? 'EtymologyExplorer' // If no info yet
      : isWordComponent(component)
      ? pathToTitle(word, language, component)
      : pathToInfo(component)?.title ?? titleCase(component)
  const descriptionText = !component
    ? 'Explore the history of words in a unique, visually-driven style'
    : pathToInfo(component)?.description ?? ''

  return (
    <HelmetProvider>
      <Helmet>
        <title>{titleText}</title>
        <meta name="description" content={descriptionText} />
      </Helmet>
      {children}
    </HelmetProvider>
  )
}
