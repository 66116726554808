import { styled } from '@mui/material/styles'
import LinkIcon from '@mui/icons-material/Link'
import { Li } from 'components/Li'
import { Link } from 'components/Link'
import { P } from 'components/P'
import { Subtitle, Ul, Ol, A } from 'components'
import { Title } from 'components/Title'
import { HTMLProps, ReactNode } from 'react'
import { Path } from 'router/constants'
import { Spacer } from 'components/Spacer'
import MuiIconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'
import { useShowSnackbar } from 'contexts/SnackbarController'
import { withPageWrapper } from 'components/withPageWrapper'

// WOTD = 'wotd',
// NODE = 'node',
// NODE_CHILD = 'descendant-nodes',
// PROGENY_TREE = 'descendant-bubble',
// PROGENY_TABLE = 'descendant-table',

// DATA_CREATION = 'data_generation', // Google Colab
// WORD_FREQUENCY = 'word_frequency',
// ROOT_IMPACT = 'root_impact',
// ROOT_DETERMINATION = 'root_determination',
// PRONUNCIATION = 'pronunciation',
// HIDDEN_AFFIXES = 'hidden_affixes',

const ChildrenAndRootPagesInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.CHILDREN_AND_ROOT_PAGES}>
      Children and Root Pages
    </SubtitleLink>
    <P>
      In EtymologyExplorer, some pages are considered to be {'"'}Root Pages{'"'}{' '}
      while others are considered {'"'}Children Pages{'"'}. On{' '}
      <L to={Path.DETAIL}>the detail page</L>, the {'"'}Children Pages{'"'} are
      highlighted for words that are considered {'"'}children{'"'}, and {'"'}
      Root Pages{'"'} are highlighted for words that are considered to be the{' '}
      {'"'}roots{'"'}. For the free plan, this includes only{' '}
      <L to={Path.TREE}>the tree explorer</L> and{' '}
      <L to={Path.NODE_CHILD}>the descendant nodes explorer</L> You can learn
      more about how words are{' '}
      <L to={Other.ROOT_DETERMINATION}>
        determined to be roots or children here.
      </L>
    </P>
    <P>
      In general, {'"'}
      Children Pages{'"'} are better for exploring modern day words, or words
      that don{"'"}t have many descendants. These views include{' '}
      <L to={Path.TREE}>the tree page</L> and the{' '}
      <L to={Path.NODE}>the tree node page</L>.
    </P>
    <P>
      {'"'}Root Pages{'"'}, on the other hand, are better for exploring root
      words that have many descendants. These views include{' '}
      <L to={Path.NODE_CHILD}>the node children page</L>,{' '}
      <L to={Path.PROGENY_TREE}>the children pack page</L>, and{' '}
      <L to={Path.PROGENY_TABLE}>the children table page</L>.
    </P>
  </InfoGroup>
)

const ConnectionExplorerInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Path.CONN}>Connection Explorer</SubtitleLink>
    <P>
      The Connection Explorer lists the etymological connections between words.
    </P>
    <P>
      Each connection is listed as a row in a table, showing the descendant, the
      root, the number of sources, and the last time the connection was editted.
      The sources represent either manual entries or entries in Wiktionary that
      support this connection. A planned feature is to allow users to see the
      individual sources for each connection.
    </P>
    <P>
      This feature is included in premium. Users on the free plan can preview
      the Connection Explorer for the <L to={Path.WOTD_LIST}>Word of the Day</L>
      .
    </P>
    <Link to={`/${Path.CONN}`}>Check out the Connection Explorer here</Link>
  </InfoGroup>
)

const DataGenerationInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.DATA_CREATION}>Data Generation</SubtitleLink>
    <P>
      The data for EtymologyExplorer is based on Natural Language Processing
      (NLP) based transformations of etymologies sourced from Wiktionary.org.
    </P>
    <P>
      The etymologies on Wiktionary.org are written in natural language (i.e.
      they are paragraphs and sentences) so to convert them into usable data in
      this web application, the relationships between the words had to be
      extracted. This was done using a Deep Learning algorithm for sentiment
      analysis that was tweaked to compare relations between words. The output
      of this algorithm is a list of connections. To construct the etymology
      trees, this list of connections is queried recursively until the most
      ancient roots are pulled.
    </P>
  </InfoGroup>
)

const DescendantGroupingInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.GROUPING}>Descendant Grouping</SubtitleLink>
    <P>
      In several parts of the mobile app, when showing the descendants of a root
      word, there is a concept called a Group. More specifically it is a
      definition group. This feature allows users to understand the descendants
      of a root word by grouping them by similar definition.
    </P>
    <P>
      These groups are determined by using natural language processing. More
      specifically sentence vectors convert definitions into multidimensional
      space and then k-means clustering groups them by similarity. One of the
      most challenging aspects of grouping is selecting a key word of phrase to
      call each group. This was done by taking the definition closest to the
      {'"'}center{'"'} of a group.
    </P>
  </InfoGroup>
)

const DescendantNodeInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Path.NODE_CHILD}>
      Descendant Node Explorer
    </SubtitleLink>
    <P>
      The Descendant Node Explorer shows a word{"'"}s descendants and
      connections. It attempts to capture the different meanings that have
      evolved from ancestor words. It automatically generates a layout that
      shows how descendant words branched off of parent words to make the modern
      words we use today.
    </P>
    <P>
      The Descendant Node Explorer is similar to the{' '}
      <L to={Path.NODE}>Node Explorer</L> except for the connections and
      connected nodes shown. In the <L to={Path.NODE}>Node Explorer</L> and{' '}
      <L to={Path.TREE}>Tree Explorer</L>, root connections (i.e. connections
      from a word to it{"'"}s root) and roots are shown. In the{' '}
      <L to={Path.NODE_CHILD}>Descendant Node Explorer</L>, descendant
      connections (i.e. connections from a root to it{"'"}s descendants) and
      descendants are shown.
    </P>
    <P>
      This feature is included in premium. Users on the free plan can preview
      the Node Explorer for the{' '}
      <L to={Path.WOTD_LIST}>Word of the Day and it{"'"} roots</L>.
    </P>
    <Link to={`/${Path.NODE_CHILD}`}>
      Check out the Descendant Node Explorer here
    </Link>
  </InfoGroup>
)

const DescendantBubbleInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Path.PROGENY_TREE}>
      Descendant Bubble Explorer
    </SubtitleLink>
    <P>
      The Descendant Bubble Explorer shows a word{"'"}s descendants as nested
      circles. The outermost circle represents the target word (i.e. the
      searched for word), and each interior circle represents a descendant word.
      This continues for each of those descendants, until words are reached that
      have no descendants.
    </P>
    <P>
      This feature is included in premium. Users on the free plan can preview
      the Node Explorer for the{' '}
      <L to={Path.WOTD_LIST}>Word of the Day and it{"'"}s roots</L>.
    </P>
    <Link to={`/${Path.PROGENY_TREE}`}>
      Check out the Descendant Bubble Explorer here
    </Link>
  </InfoGroup>
)

const DescendantTableInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Path.PROGENY_TABLE}>
      Descendant Table Explorer
    </SubtitleLink>
    <P>
      The Descendant Table Explorer shows the entire list of descendants for a
      word. Each row of the table is a descendant, at any connection distance
      from the target word.
    </P>
    <P>
      This feature is included in premium. Users on the free plan can preview
      the Node Explorer for the{' '}
      <L to={Path.WOTD_LIST}>Word of the Day and it{"'"}s roots</L>.
    </P>
    <Link to={`/${Path.PROGENY_TABLE}`}>
      Check out the Descendant Table Explorer here
    </Link>
  </InfoGroup>
)

const DetailExplorerInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Path.DETAIL}>Detail Explorer</SubtitleLink>
    <P>
      The Detail Explorer shows the dictionary entry for each word. This is the
      default location users are taken to when they click on a word.
    </P>
    <P>
      The first section of this page shows the word and links to each of the
      other explorer pages. On the other hand, If the word is a root, the{' '}
      <L to={Path.NODE_CHILD}>Descendant Nodes</L>,{' '}
      <L to={Path.PROGENY_TREE}>Descendant Bubble</L>, and{' '}
      <L to={Path.PROGENY_TABLE}>Descendant Table</L> links will be highlighted,
      since they usually have more to offer for these types of words. If the
      word is more of a descendant than a root, then the{' '}
      <L to={Path.NODE}>Tree</L> and <L to={Path.NODE}>Node</L> links will be
      highlighted.
    </P>
    <P>
      After that, a dictionary entry for the word will be shown, listing as much
      of the following information as possible:
    </P>
    <Ul>
      <Li>A written etymology with links to each mentioned word</Li>
      <Li>A brief list of this word{"'"}s descendants</Li>
      <Li>
        A list of <L to={Other.PRONUNCIATION}>pronunciations</L> of the word,
        shown as the IPA, newspaper pronunciation, and an audio link
      </Li>
      <Li>The parts of speech of the word along with definitions for each</Li>
      <Li>
        Information on the frequency of use of the word. If the word is a root,
        then this will be the <L to={Other.ROOT_IMPACT}>root impact</L>.
        Otherwise it will be the <L to={Other.WORD_FREQUENCY}>word frequency</L>
      </Li>
    </Ul>
    <P>This is a free feature available to all users.</P>
    <Link to={`/${Path.DETAIL}`}>Check out the Detail Explorer here</Link>
  </InfoGroup>
)

const HiddenAffixesInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.HIDDEN_AFFIXES}>Hidden Affixes</SubtitleLink>
    <P>
      Several EtymologyExplorer pages make an attempt to hide affixes. This
      means that if the word has a suffix, prefix, etc. it will be excluded from
      the visualization.
    </P>
    <P>
      In the <L to={Path.TREE}>Tree Page</L> this can be seen for the word{' '}
      <A href="/tree/English/atypical">atypical</A>. The prefix {'"'}a-{'"'} is
      shown, but all ancestors of that prefix are hidden. To see those
      ancestors, go directly to <A href="/tree/English/a-">it{"'"}s tree</A>.
    </P>
    <P>
      The faded arrow above the word hints that there are more ancestors that
      are hidden. Affixes may not be hidden for two reasons:
    </P>
    <Ol>
      <Li>
        In some cases, an affix is shown because it is uncommon, such as for{' '}
        <A href="/tree/English/phonology">phono- in phonology</A>
      </Li>{' '}
      <Li>
        Often an affix is shown because it has not yet been added to the
        database of affixes. If that is the case, please notify me at{' '}
        <A href="mailto:dev@etymologyexplorer.com">dev@etymologyexplorer.com</A>
      </Li>
    </Ol>
  </InfoGroup>
)

const LanguagesInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.LANGUAGES}>Languages</SubtitleLink>
    <P>
      EtymologyExplorer contains words from over 8,000 languages. Sometimes
      these languages have different names and when possible, the languages are
      combined into one single name.
    </P>
    <P>
      Languages are color coded to help with visualization. The color hue (or
      combination of red, green, and blue) associated with a language is
      somewhat random, although some effort was made to group similar languages.
      The color saturation (or how white it is) is based on how old the language
      is as can be seen from the progression from English to proto Germanic.
    </P>
  </InfoGroup>
)

const NodeExplorerInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Path.NODE}>Node Explorer</SubtitleLink>
    <P>
      The Node Explorer shows a word{"'"}s roots. It automatically generates a
      layout that tries to space words to show the branching of the etymology.
    </P>
    <P>
      The Node Explorer is similar to the <L to={Path.TREE}>Tree Explorer</L>.
      This allows it to better display the branched nature of the etymology.
      Whereas the <L to={Path.TREE}>Tree Explorer</L> shows the etymology in one
      direction, the Node Explorer better shows how disparate root words have
      come together to form our modern words.
    </P>
    <P>
      The Node explorer is able to better depict these divergent branches by
      using the physics simulator from{' '}
      <A href="https://observablehq.com/collection/@d3/d3-force">d3-force</A>{' '}
      xcept that it uses a physics simulator to lay out the nodes.
    </P>
    <P>
      This feature is included in premium. Users on the free plan can preview
      the Node Explorer for the <L to={Path.WOTD_LIST}>Word of the Day</L>.
    </P>
    <Link to={`/${Path.NODE}`}>Check out the Node Explorer here</Link>
  </InfoGroup>
)

const OperatingCostsInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.COSTS}>Operating Costs</SubtitleLink>
    <P>
      Operation of EtymologyExplorer incurs costs for the database, API,
      authentication, Word of the Day server, and payments system.
    </P>
    <P>
      The database is hosted on Amazon Web Services (AWS) Relational Database
      System. The database is sized to handle peak operation and this determines
      the monthly cost. The API that connects this webpage and the mobile app to
      the database uses AWS API Gateway and Lambda, and incurs costs for each
      query that a user makes. Some actions users take spawn multiple queries.
      For instance, searching for the word {'"'}magnanimous{'"'} and then
      viewing its tree incurs 12 queries—one autocomplete query for each letter
      of the word, and one query for the tree data. There is also a continuously
      running server that handles the Word of the Day notifications for the
      mobile app.
    </P>
  </InfoGroup>
)

const OtherQuestionsInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.OTHER}>Other Questions?</SubtitleLink>
    <P>
      Have a questions about something that isn{"'"}t covered above? Please
      reach out to{' '}
      <A href="mailto:info@etymologyexplorer.com">info@etymologyexplorer.com</A>{' '}
      so we can help you out!
    </P>
    <P>
      The goal of Etymology Explorer is to be the best source for learning more
      about words by learning about all the interconnectedness of our languages.
      If you have any ideas about how to improve
    </P>
  </InfoGroup>
)

const PremiumInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.PREMIUM}>Premium</SubtitleLink>
    <P>
      Etymology Explorer Premium includes access to additional features of the
      web app, and removal of all advertisements
    </P>
    <P>
      Premium includes access to the following features for any word (not just
      the WOTD):
    </P>
    <Ul>
      <Li>
        <L to={Path.NODE}>Node Explorer</L>
      </Li>
      <Li>
        <L to={Path.NODE_CHILD}>Node Child Explorer</L>
      </Li>
      <Li>
        <L to={Path.PROGENY_TABLE}>Descendant Table Explorer</L>
      </Li>
      <Li>
        <L to={Path.PROGENY_TREE}>Progeny Explorer</L>
      </Li>
      <Li>
        <L to={Path.CONN}>Connections Explorer</L>
      </Li>
      <Li>
        Premium access to the <L to={Other.MOBILE_APP}>Mobile App</L>
      </Li>
    </Ul>
  </InfoGroup>
)

const RandomWordInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.RANDOM_WORD}>Random Word</SubtitleLink>
    <P>
      In the search bar, there is a button that allows you to go to a randomly
      generated word. The options it chooses from change depending on your
      search language and the page you are currently viewing. If you are viewing
      a <L to={Other.CHILDREN_AND_ROOT_PAGES}>Root Page</L> it will choose from
      a list of root words, and if you are viewing a{' '}
      <L to={Other.CHILDREN_AND_ROOT_PAGES}>Child Page</L> it will choose from a
      list of non-root words based on your current search langauge.
    </P>
  </InfoGroup>
)

const SourcesInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.SOURCES}>Sources</SubtitleLink>
    <P>
      Dictionary information, including etymologies, definitions, and parts of
      speech are sourced from wiktionary. The connections between words are
      generated through a custom-made machine learning model that analyzes
      written-word etymologies to extract the connections between words.
      Frequency data is taken from Google n-grams for English, French, Italian,
      Russian, Spanish, and German. Much thanks goes to Flaminia Rosato for her
      contributions there.
    </P>
    <P>
      Languages are color coded to help with visualization. The color hue (or
      combination of red, green, and blue) associated with a language is
      somewhat random, although some effort was made to group similar languages.
      The color saturation (or how white it is) is based on how old the language
      is as can be seen from the progression from English to proto Germanic.
    </P>
  </InfoGroup>
)

const TransliterationInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.TRANSLITERATION}>Transliteration</SubtitleLink>
    <P>
      Transliteration (literally {'"'}writing across{'"'}) is the process of
      converting words written in one script to a different script while
      maintaining as much of the essence of the word as possible. Examples are
      the <A href="https://en.wikipedia.org/wiki/Latin_script">Latin script</A>,{' '}
      <A href="https://en.wikipedia.org/wiki/Cyrillic_script">
        Cyrillic Script
      </A>
      , and <A href="https://en.wikipedia.org/wiki/Thai_script">Thai script</A>
    </P>
    <P>
      One peculiarity of EtymologyExplorer{"'"}s transliteration process is how{' '}
      <A href="https://en.wikipedia.org/wiki/Schwa">
        {'"'}ə{'"'} (Schwa)
      </A>{' '}
      is handled. To allow for more accessible search queries for words, this
      letter is transliterated to the Latin script {'"'}a{'"'}.
    </P>
    <P>
      Words in etymologyexplorer are transliterated using wiktionary{"'"}s data,
      with some help from{' '}
      <A href="https://pypi.org/project/Unidecode/">unidecode</A> to allow for
      easier searching and understanding. This causes some accent marks to be
      removed, when the word would be better represented with them included. If
      you see any errors in transliterations, please report them here.
    </P>
  </InfoGroup>
)

const TreeExplorerInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Path.TREE}>Tree Explorer</SubtitleLink>
    <P>
      The Tree Explorer visually depicts the etymology of a word as a chart of
      etymological connections.
    </P>
    <P>
      The selected word is shown at the bottom of the screen. Each word above it
      is an etymological ancestor. Arrows are drawn between words that have a
      direct connection.{' '}
      <L to={Other.HIDDEN_AFFIXES}>Ancestors of affixes are hidden</L>, but they
      can be seen by viewing the Tree Explorer for that word directly
    </P>
    <P>
      Each word is shown as a card. The language is shown at the top, and also{' '}
      <L to={Other.LANGUAGES}>determines the color of the card</L>.
    </P>
    <P>This is a free feature available to all users.</P>
    <Link to={`/${Path.TREE}`}>Check out the Tree Explorer here</Link>
  </InfoGroup>
)

const VanguardInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Other.VANGUARD_INFO}>Vanguard</SubtitleLink>
    <P>
      Vanguard is the highest subscription tier for EtymologyExplorer. It is for
      word fanatics and those that want to take full advantage of the
      EtymologyExplorer platform
    </P>
    <P>Vanguard includes access to the following features:</P>
    <Ul>
      <Li>
        The Etymology Explorer API which includes 11 different endpoitns for
        querying and summarizing etymological data
      </Li>
      <Li>
        A private slack channel for insight on upcoming features releases and
        etymological discussions
      </Li>
      <Li>
        Beta access to see the latest features for both the web app and mobile
        app
      </Li>
      <Li>
        All <L to={Other.PREMIUM}>Premium features</L>
      </Li>
    </Ul>
  </InfoGroup>
)

const WordOfTheDayInfo = () => (
  <InfoGroup>
    <SubtitleLink anchor={Path.WOTD_LIST}>Word of the Day</SubtitleLink>
    <P>
      The Word of the Day Explorer highlights new interesting words each day.
      Words are chosen and grouped based on their roots. About each week, a new
      Proto-Indo-European root is chosen, and each word of the day for that week
      is a descendant of that root. This allows a peak at the variety of words
      and meanings that have developed from a single Proto-Indo-European root.
    </P>
    <Link to={`/${Path.WOTD_LIST}`}>
      Check out the Word of the Day Explorer here
    </Link>
  </InfoGroup>
)

function InfoContent() {
  return (
    <PageWrapper>
      <Title>EtymologyExplorer Info</Title>
      <P style={{ fontSize: '1.125rem' }}>
        Information about Etymology Explorer{"'"} features
      </P>
      <Subtitle id={'table-of-contents'}>Table of Contents</Subtitle>
      <L to={Other.CHILDREN_AND_ROOT_PAGES}>Children and Root Pages</L>
      <L to={Path.CONN}>Connection Explorer</L>
      <L to={Other.DATA_CREATION}>Data Generation</L>
      <L to={Other.GROUPING}>Descendant Grouping</L>
      <L to={Path.NODE_CHILD}>Descendant Node Explorer</L>
      <L to={Path.PROGENY_TREE}>Descendant Bubble Explorer</L>
      <L to={Path.PROGENY_TABLE}>Descendant Table Explorer</L>
      <L to={Path.DETAIL}>Detail Explorer</L>
      <L to={Other.HIDDEN_AFFIXES}>Hidden Affixes</L>
      <L to={Other.LANGUAGES}>Languages</L>
      <L to={Path.NODE}>Node Explorer</L>
      <L to={Other.COSTS}>Operating Costs</L>
      <L to={Other.OTHER}>Other Questions?</L>
      <L to={Other.PREMIUM}>Premium</L>
      <L to={Other.RANDOM_WORD}>Random Word</L>
      <L to={Other.SOURCES}>Sources</L>
      <L to={Other.TRANSLITERATION}>Transliteration</L>
      <L to={Path.TREE}>Tree Explorer</L>
      <L to={Other.VANGUARD_INFO}>Vanguard</L>
      <L to={Path.WOTD_LIST}>Word of the Day</L>
      <ChildrenAndRootPagesInfo />
      <ConnectionExplorerInfo />
      <DataGenerationInfo />
      <DescendantGroupingInfo />
      <DescendantNodeInfo />
      <DescendantBubbleInfo />
      <DescendantTableInfo />
      <DetailExplorerInfo />
      <HiddenAffixesInfo />
      <LanguagesInfo />
      <NodeExplorerInfo />
      <OperatingCostsInfo />
      <OtherQuestionsInfo />
      <PremiumInfo />
      <RandomWordInfo />
      <SourcesInfo />
      <TransliterationInfo />
      <TreeExplorerInfo />
      <VanguardInfo />
      <WordOfTheDayInfo />
    </PageWrapper>
  )
}

export enum Other {
  COSTS = 'costs',
  DATA_CREATION = 'data_generation', // Google Colab
  GROUPING = 'grouping',
  HIDDEN_AFFIXES = 'hidden_affixes',
  LANGUAGES = 'languages',
  MOBILE_APP = 'mobile_app',
  OTHER = 'other',
  PREMIUM = 'premium',
  PRONUNCIATION = 'pronunciation',
  ROOT_DETERMINATION = 'root_determination',
  ROOT_IMPACT = 'root_impact',
  SOURCES = 'sources', // wiktionary
  TRANSLITERATION = 'transliteration',
  WORD_FREQUENCY = 'word_frequency',
  RANDOM_WORD = 'random_word',
  CHILDREN_AND_ROOT_PAGES = 'root_and_children_pages',
  VANGUARD_INFO = 'vanguard-info',
}

const PageWrapper = styled('div')({
  marginTop: '10rem',
  marginBottom: '10rem',
  display: 'flex',
  alignItem: 'flex-start',
  flexDirection: 'column',
  textAlign: 'left',
})

const SubtitleLink = ({
  children,
  anchor,
}: HTMLProps<HTMLParagraphElement> & { anchor: Path | Other }) => {
  const navigate = useNavigate()
  const showSnackbar = useShowSnackbar()

  return (
    <Wrapper>
      <Subtitle id={anchor}>{children}</Subtitle>
      <Spacer />
      <IconButton
        onClick={() => {
          const { origin, pathname } = window.location
          navigator.clipboard.writeText(`${origin}${pathname}#${anchor}`)
          navigate(`#${anchor}`, { replace: true })
          showSnackbar({ message: 'Copied link to clipboard!' })
        }}
      >
        <LinkIcon color="action" />
      </IconButton>
    </Wrapper>
  )
}

const L = ({ to, children }: { to: Path | Other; children: ReactNode }) => (
  <Link to={`#${to}`}>{children}</Link>
)

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const InfoGroup = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
}))

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  ':hover .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    // visibility: 'hidden',
  },
}))

export default withPageWrapper(InfoContent, { width: 'max-width' })
