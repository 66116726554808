import styled from '@mui/material/styles/styled'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { getLanguageColor } from 'utils/Colors'

const LanguageTag = ({ language }: { language: string }) => {
  return (
    <Wrapper
      style={{
        backgroundColor: getLanguageColor(language),
      }}
    >
      <LanguageTagTypography>{language}</LanguageTagTypography>
    </Wrapper>
  )
}

const Wrapper = styled('div')({
  borderRadius: 8,
  paddingLeft: 10,
  paddingRight: 10,
})

const LanguageTagTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: 13, // 0.6rem,
    fontWeight: 'bold',
    color: theme.palette.common.black,
  })
)

export default LanguageTag
