import Button from '@mui/material/Button'
import MuiDialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useCallback } from 'react'
import { useDispatchDialog } from './hooks'
import { AlertDialogState } from './types'

const AlertDialog = ({ data }: { data: AlertDialogState }) => {
  const dispatch = useDispatchDialog()
  const {
    open,
    blur = false,
    title,
    subtitle,
    onConfirm,
    onCancel,
    confirmText: option1Text = 'Ok',
    cancelText: option2Text = 'Cancel',
  } = data

  const handleClick = useCallback(
    (option: 'confirm' | 'cancel') => () => {
      if (option === 'confirm') {
        onConfirm && onConfirm()
      } else if (option === 'cancel') {
        onCancel && onCancel()
      }
      dispatch({ type: 'close', payload: 'alert' })
    },
    [dispatch, onConfirm, onCancel]
  )

  return (
    <Dialog
      blur={blur}
      open={open}
      onKeyDown={(e) =>
        e.key === 'Enter'
          ? handleClick('confirm')()
          : e.key === 'Escape'
          ? handleClick('cancel')()
          : null
      }
      onBackdropClick={handleClick('cancel')}
    >
      <DialogContent dividers>
        <Typography variant="h6" marginBottom={1}>
          {title}
        </Typography>
        {typeof subtitle === 'string' ? (
          <Typography gutterBottom>{subtitle}</Typography>
        ) : (
          subtitle
        )}
      </DialogContent>
      <DialogActions>
        {option2Text === null || option2Text === false ? null : (
          <Button onClick={handleClick('cancel')}>{option2Text}</Button>
        )}
        <Button
          onClick={handleClick('confirm')}
          color="primary"
          variant="outlined"
        >
          {option1Text}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Dialog = styled(MuiDialog, { shouldForwardProp: (p) => p !== 'blur' })<
  DialogProps & { blur: boolean }
>(({ blur }) => ({
  '.MuiDialog-container': {
    backdropFilter: blur ? `blur(3px)` : undefined,
  },
}))

export default AlertDialog
