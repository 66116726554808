import { useAuthStore } from 'auth/hooks'
import { useChannel } from 'channel'
import {
  addDoc,
  collection,
  DocumentData,
  DocumentReference,
  onSnapshot,
} from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Path } from 'router/constants'
import {
  PromotionDataType,
  PromotionTimestamps,
  SubscriptionTier,
  SubscriptionType,
} from 'types/backend-types'
import {
  Payment,
  Subscription,
  SubscriptionProductOptionDict,
} from 'types/stripe-types'
import { firestore } from 'utils/firebase'
import { useSettings } from 'utils/settings'
import { getStorageItem, setStorageItem, StorageItems } from 'utils/storage'
import { useWordsOfTheDay } from 'wotd/hooks'
import {
  ACTIVE_FIREBASE_ONE_TIME_PAYMENT_QUERY_KEY,
  ACTIVE_FIREBASE_SUBSCRIPTION_QUERY_KEY,
  FirebaseSubscriptionPriceId,
  FIREBASE_SUBSCRIPTION_INFO_DICT,
  FIREBASE_SUBSCRIPTION_PRODUCT_OPTIONS_QUERY_KEY,
  FIRESTORE_CHECKOUT_SESSIONS_PATH,
  FIRESTORE_USER_PATH,
  LOCAL_PROMOTIONS,
} from './constants'
import {
  getActiveFirestoreSubscriptions,
  getPriceIdFromDetails,
  getSubscriptionProducts,
  getSuccessfulFirestoreOneTimePayments,
  isOneTimePaymentPlan,
} from './functions'

type UID = string | undefined
export type ActiveFirebaseSubscriptionQueryKey = [
  typeof ACTIVE_FIREBASE_SUBSCRIPTION_QUERY_KEY,
  UID
]

export type ActiveFirebaseOneTimePaymentQueryKey = [
  typeof ACTIVE_FIREBASE_ONE_TIME_PAYMENT_QUERY_KEY,
  UID
]

export type FirebaseSubscriptionProductOptionsQueryKey =
  typeof FIREBASE_SUBSCRIPTION_PRODUCT_OPTIONS_QUERY_KEY

export type AccountQueryKey = [
  typeof FIREBASE_SUBSCRIPTION_PRODUCT_OPTIONS_QUERY_KEY,
  UID
]

export const useSubscriptionProducts = () => {
  const { data: subscriptionProducts, ...rest } = useQuery<
    SubscriptionProductOptionDict | undefined,
    Error,
    SubscriptionProductOptionDict | undefined,
    FirebaseSubscriptionProductOptionsQueryKey
  >(
    FIREBASE_SUBSCRIPTION_PRODUCT_OPTIONS_QUERY_KEY,
    () => getSubscriptionProducts(),
    {}
  )

  return { subscriptionProducts, ...rest }
}

export const useActiveFirestoreSubscriptionInfo = () => {
  const { user } = useAuthStore()
  const { data: activeFirestoreSubscriptions, ...rest } = useQuery<
    Subscription[] | undefined,
    Error,
    Subscription[] | undefined,
    ActiveFirebaseSubscriptionQueryKey
  >(
    [ACTIVE_FIREBASE_SUBSCRIPTION_QUERY_KEY, user?.uid],
    () => (user?.uid ? getActiveFirestoreSubscriptions(user.uid) : undefined),
    {
      enabled: !!user?.uid,
    }
  )

  const activeSubscription = activeFirestoreSubscriptions?.length
    ? activeFirestoreSubscriptions[0]
    : undefined
  const activeSubscriptionId: FirebaseSubscriptionPriceId | undefined =
    activeSubscription?.items.length
      ? (activeSubscription?.items[0].price.id as FirebaseSubscriptionPriceId)
      : undefined
  const activeFirestoreSubscriptionInfo = activeSubscriptionId
    ? FIREBASE_SUBSCRIPTION_INFO_DICT[activeSubscriptionId]
    : undefined

  return { activeFirestoreSubscriptionInfo, ...rest }
}

export const useSuccessfulFirestoreOneTimePaymentInfo = () => {
  const { user } = useAuthStore()
  const { data: activeFirestoreOneTimePayments, ...rest } = useQuery<
    Payment[] | undefined,
    Error,
    Payment[] | undefined,
    ActiveFirebaseOneTimePaymentQueryKey
  >(
    [ACTIVE_FIREBASE_ONE_TIME_PAYMENT_QUERY_KEY, user?.uid],
    () =>
      user?.uid ? getSuccessfulFirestoreOneTimePayments(user.uid) : undefined,
    {
      enabled: !!user?.uid,
    }
  )

  const activeOneTimePayment = activeFirestoreOneTimePayments?.length
    ? activeFirestoreOneTimePayments[0]
    : undefined
  const activeOneTimePaymentId: FirebaseSubscriptionPriceId | undefined =
    activeOneTimePayment?.items?.length
      ? (activeOneTimePayment?.items?.[0].price
          ?.id as FirebaseSubscriptionPriceId)
      : undefined
  const activeFirestoreOneTimePaymentInfo = activeOneTimePaymentId
    ? FIREBASE_SUBSCRIPTION_INFO_DICT[activeOneTimePaymentId]
    : undefined

  return { activeFirestoreOneTimePaymentInfo, ...rest }
}

export const useActivePlan = () => {
  const { settings = {}, isLoading: isLoadingSettings } = useSettings()
  const {
    activeFirestoreSubscriptionInfo,
    isLoading: isLoadingFirestoreSubscriptions,
  } = useActiveFirestoreSubscriptionInfo()
  const {
    activeFirestoreOneTimePaymentInfo,
    isLoading: isLoadingFirestoreOneTimePayments,
  } = useSuccessfulFirestoreOneTimePaymentInfo()

  const activeWebPlan =
    activeFirestoreSubscriptionInfo?.plan ??
    activeFirestoreOneTimePaymentInfo?.plan

  const isWebPlan = activeWebPlan !== undefined

  const plan = isWebPlan ? activeWebPlan : settings.plan ?? 'free'

  const isVanguardPlan = plan.toLowerCase().includes('vanguard')

  const isOneTimePayment =
    !!activeFirestoreOneTimePaymentInfo?.plan ||
    isOneTimePaymentPlan(settings.plan)

  const isLoading =
    isLoadingSettings ||
    isLoadingFirestoreSubscriptions ||
    isLoadingFirestoreOneTimePayments
  const hasPremium = !isLoading && plan !== 'free'

  const planManagement = !hasPremium
    ? 'none'
    : !isWebPlan
    ? 'mobile'
    : isOneTimePayment
    ? 'none'
    : 'web'

  // console.log('useActivePlan', {
  //   plan,
  //   hasPremium,
  //   isWebPlan,
  //   isLoading,
  //   isVanguardPlan,
  //   isOneTimePayment,
  //   planManagement,
  // })

  const availablePaths: Path[] = hasPremium
    ? [
        Path.TREE,
        Path.DETAIL,
        Path.NODE,
        Path.NODE_CHILD,
        Path.PROGENY_TREE,
        Path.PROGENY_TABLE,
      ]
    : [Path.TREE, Path.DETAIL, Path.NODE_CHILD]

  const defaultComponent = Path.TREE

  return {
    plan,
    hasPremium,
    isWebPlan,
    isLoading,
    isVanguardPlan,
    isOneTimePayment,
    planManagement,
    defaultComponent,
    availablePaths,
  }
}

export const useCanSeeContent = () => {
  const { hasPremium, isLoading: planLoading } = useActivePlan()
  const {
    isWotd,
    isWotdRoot,
    todaysWotd,
    todaysWotdRoot,
    isLoading: freeWotdLoading,
  } = useWordsOfTheDay(1)

  const loading = freeWotdLoading || planLoading
  const canSeeContent = hasPremium || isWotd || isWotdRoot

  // console.log('useCanSeeContent', {
  //   canSeeContent,
  //   hasPremium,
  //   isWotd,
  //   todaysWotd,
  // })
  return {
    canSeeContent,
    hasPremium,
    isWotd,
    isWotdRoot,
    todaysWotd,
    todaysWotdRoot,
    loading,
  }
}

export const usePromotion = (tier: SubscriptionTier) => {
  const { user } = useAuthStore()
  const { channel } = useChannel()
  const [promotions] = useState<PromotionDataType[]>(LOCAL_PROMOTIONS)
  const currentTime = new Date().getTime()
  const appUse = user?.metadata.creationTime
    ? Math.max(currentTime - new Date(user.metadata.creationTime).getTime(), 1)
    : new Date().getTime()
  const promotionTimestamps: PromotionTimestamps =
    getStorageItem(StorageItems.PROMOTIONS) ?? {}

  // useEffect(() => {
  //   if (tier === 'free') return
  //   getFirebasePromotions().then((p) => setPromotions((f) => [...f, ...p]))
  // }, [tier])

  const availablePromotions: PromotionDataType[] =
    tier === 'free'
      ? []
      : promotions
          .filter((promotion) => {
            // Filter down to just those that are appropriate
            // start time is from the asyncStorage timestamp OR current time (hypethetically)
            const promotionStartTimestamp =
              (getStorageItem(StorageItems.PROMOTIONS) ?? {})[
                promotion.promotion
              ] ?? currentTime
            // Update the real promotion end time will be that start time + duration
            const promotionExpireTime =
              (promotion?.duration ?? Number.MAX_SAFE_INTEGER) +
              (promotionStartTimestamp || 0)
            const shouldInclude =
              (promotion?.minAppUse ?? 0) < appUse && // meets min app use (e.g. one week)
              (promotion?.startTime ?? 0) <= currentTime && // has started
              (promotion?.endTime ?? Number.MAX_SAFE_INTEGER) > currentTime && // hasn't ended
              promotionExpireTime > currentTime && // hasn't ended for user
              (!promotion.test || channel !== 'prod') &&
              promotion.enabled !== false
            // console.log( 'promotion eval', promotion.promotion, 'shouldInclude', shouldInclude, currentTime, 'promotionStartTimestamp', promotionStartTimestamp, 'promotionEndTimeUpdated', promotionEndTimeUpdated, appUse, promotion)
            return shouldInclude
            // Sort so that the highest priority is first
          })
          .sort((a, b) =>
            (a.priority ?? Number.MAX_SAFE_INTEGER) >
            (b.priority ?? Number.MAX_SAFE_INTEGER)
              ? -1
              : 1
          )

  // console.log('getPromotion summary:', { availablePromotions })

  let [highestPriorityPromotion] = availablePromotions

  // console.log('promotionTimestamps:', promotionTimestamps)
  // console.log(`first app open timestamp: ${firstAppOpenTimestamp}, appUse: ${appUse} `)
  // console.log('highestPriorityPromotion', highestPriorityPromotion)

  if (!highestPriorityPromotion?.promotion) return undefined // avoid issue with timestamp

  if (highestPriorityPromotion?.duration !== undefined) {
    let promotionStartTime =
      promotionTimestamps[highestPriorityPromotion.promotion]
    if (!promotionStartTime) {
      // This promotion needs a start timestamp but it doesn't exist, so we need to make one
      // if normal getPromotion: then use reduceSettings
      // if initial load call, set via local settings; "await" to ensure loading into settings
      promotionStartTime = new Date().getTime()
      setStorageItem(StorageItems.PROMOTIONS, {
        ...promotionTimestamps,
        [highestPriorityPromotion.promotion]: promotionStartTime,
      })
    }

    // Update via deconstruction to avoid immutable issue
    // The end time will be the lesser of:
    // * The endTime (or max_safe_int if that DNE)
    // * The duration + the promotionStartTime (or currentTime if promotionStartTime DNE)
    highestPriorityPromotion = {
      ...highestPriorityPromotion,
      endTime: Math.min(
        highestPriorityPromotion.duration + promotionStartTime,
        highestPriorityPromotion.endTime ?? Number.MAX_SAFE_INTEGER
      ),
    }
  }

  // console.log('getPromotion summary:', 'currentTime', new Date().getTime(), 'promStart:', promotionStartTime, 'appUse', appUse, 'endTime', firebasePromotion.endTime)

  return highestPriorityPromotion
}

export const useCheckoutSession = (
  tier: SubscriptionTier,
  interval: Omit<SubscriptionType, 'free'>,
  stripeCode: string | undefined
) => {
  const { user } = useAuthStore()
  const [redirectUrl, setRedirectUrl] = useState<string>()
  const [docRef, setDocRef] = useState<DocumentReference<DocumentData>>()
  const [redirectWhenPossible, setRedirectWhenPossible] = useState(false)

  const priceId = getPriceIdFromDetails(tier, interval)
  const priceData = priceId
    ? FIREBASE_SUBSCRIPTION_INFO_DICT[priceId]
    : undefined

  if (!priceId || !priceData) {
    console.error('Could not find price for', tier, interval, stripeCode)
  }

  useEffect(() => {
    if (user?.isAnonymous || !user?.uid || tier === 'free') return
    const params = {
      price: priceId,
      success_url: window.location.origin + `/${Path.ACCOUNT}`,
      cancel_url: window.location.href,
      ...(stripeCode ? { promotion_code: stripeCode } : {}),
      ...(interval === 'lifetime' ? { mode: 'payment' } : {}),
    }

    addDoc(
      collection(
        firestore,
        FIRESTORE_USER_PATH,
        user.uid,
        FIRESTORE_CHECKOUT_SESSIONS_PATH
      ),
      params
    ).then((newDocRef) => setDocRef(newDocRef))
  }, [interval, priceId, stripeCode, tier, user?.isAnonymous, user?.uid])

  // Wait for the CheckoutSession to get attached by the extension
  useEffect(() => {
    if (!docRef) return
    const unsub = onSnapshot(docRef, (snap) => {
      // console.debug('snapshot for useCheckoutSession')
      const data = snap.data() ?? {}
      const { error, url } = data
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console
        window.alert(`An error occured: ${error.message}`)
        console.error(error.message)
      } else {
        if (url) {
          // We have a Stripe Checkout URL, let's set redirect.
          setRedirectUrl(url)
        }
      }
    })
    return () => unsub()
  }, [docRef])

  useEffect(() => {
    if (redirectWhenPossible && redirectUrl) {
      console.log('redirectWhenPossible!')
      window.location.assign(redirectUrl)
      setRedirectWhenPossible(false)
    }
  }, [redirectUrl, redirectWhenPossible])

  const loadCheckoutSession = useCallback(() => {
    if (redirectUrl) {
      window.location.assign(redirectUrl)
    } else {
      setRedirectWhenPossible(true)
    }
  }, [redirectUrl])
  return { loadCheckoutSession }
}
