import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'
import { A } from 'components'
import { useState } from 'react'
import { ThemeConstants } from 'theme/constants'
import { APP_LINK } from 'utils/env'

const MobileAppBanner = () => {
  const [hide, setHide] = useState(false)

  return hide ? null : (
    <Wrapper>
      <IconButton onClick={() => setHide(true)}>
        <CloseIcon />
      </IconButton>
      <Typography color="textPrimary" lineHeight={1.2}>
        This is the <b>Desktop</b> web app. For this device, try the mobile app
      </Typography>
      <A href={APP_LINK}>
        <Button variant="contained" color="inherit" style={{ color: 'black' }}>
          Open
        </Button>
      </A>
    </Wrapper>
  )
}

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  pointerEvents: 'all',
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: ThemeConstants.Z_INDEX_MOBILE_APP_BANNER,
  color: 'black',
  height: 60,
  padding: 10,
  paddingRight: 15,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export default MobileAppBanner
