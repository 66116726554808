import {
  endBefore,
  get,
  limitToLast,
  orderByKey,
  query,
  ref,
} from 'firebase/database'
import { WordOfTheDayType, WotdGroupDict } from 'types/backend-types'
import { database } from 'utils/firebase'
import { addDays, dateToFirebaseDate } from 'utils/functions'

export const getWordsOfTheDay = async (
  // uid: string | undefined,
  amount: number,
  last_key?: string
): Promise<WordOfTheDayType[]> => {
  // permission are disabled

  const endBeforeDate = last_key ?? dateToFirebaseDate(addDays(new Date(), 1))
  const queryConstraints = [
    orderByKey(),
    limitToLast(amount),
    endBefore(endBeforeDate),
  ]
  const results = await get(
    query(ref(database, '/wotd/'), ...queryConstraints)
  ).then(
    (snapshot) =>
      Object.keys(snapshot.val())
        .filter((key) => {
          try {
            const [year, month, day] = key.split('-')
            const wotd_date = new Date(
              parseInt(year || String(new Date().getFullYear())),
              parseInt(month || String(new Date().getMonth())) - 1,
              parseInt(day || String(new Date().getDay()))
            )
            return new Date() >= wotd_date // today is on or after
          } catch (err) {
            return true
          }
        }) // key = "2020-12-20" YYYY-MM-DD
        .map((key) => snapshot.val()[key] as WordOfTheDayType)
        .slice()
        .reverse() //nonmutating reverse
  )
  // console.debug('getWordsOfTheDay results', results)
  return results
}

export const getWotdGroups = async (): Promise<WotdGroupDict> => {
  // No permissions so just ignore

  const results = await get(
    query(ref(database, '/wotd_groups/'), orderByKey())
  ).then(
    (snapshot) => snapshot.val() as WotdGroupDict //nonmutating reverse
  )
  return results
}
