import { useTheme } from '@mui/material/styles'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { colorTypeToThemeColor } from 'theme/constants'

export const ChildrenPackIcon = (
  props: SvgIconProps & { strokeColor?: string }
) => {
  const theme = useTheme()
  const { strokeColor: strokeColorProp, ...rest } = props
  const strokeColor =
    strokeColorProp ?? colorTypeToThemeColor(props.color, theme)

  return (
    <SvgIcon viewBox="0 0 800 800" {...rest}>
      <circle
        cx="400"
        cy="400"
        r="375"
        stroke={strokeColor}
        strokeWidth="50"
        fill="transparent"
      />
      <circle
        cx="550.5"
        cy="387.5"
        r="201.5"
        stroke={strokeColor}
        fill="transparent"
        strokeWidth="50"
      />
      <circle
        cx="189.5"
        cy="399.5"
        r="134.5"
        stroke={strokeColor}
        fill="transparent"
        strokeWidth="50"
      />
      <circle
        cx="307.5"
        cy="188.5"
        r="80.5"
        stroke={strokeColor}
        fill="transparent"
        strokeWidth="50"
      />
      <circle
        cx="323.5"
        cy="628.5"
        r="112.5"
        stroke={strokeColor}
        fill="transparent"
        strokeWidth="50"
      />
      <circle
        cx="460.5"
        cy="113.5"
        r="56.5"
        stroke={strokeColor}
        fill="transparent"
        strokeWidth="50"
      />
    </SvgIcon>
  )
}
