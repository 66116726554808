import { recurringTokenRefresh } from 'auth/functions'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  AutoCompleteAPIWordItemType,
  AutoCompleteListType,
  BaseEtymology,
} from 'types/backend-types'
import {
  ALL_LANGUAGES_STRING,
  ROOT_FREQUENCY_PERCENTILE_LOOKUP,
  WORD_FREQUENCY_PERCENTILE_LOOKUP,
} from 'utils/constants'
import { getEnv } from 'utils/env'
import { getLayers, getParamString, isTruthy } from 'utils/functions'
import { getStorageItem, StorageItems } from 'utils/storage'
import {
  APIIdentifier,
  EnvironmentKey,
  isIdIdentifier,
  isIdsIdentifier,
  isWordIdentifier,
  ParamsDict,
} from 'utils/types'
import {
  Resource,
  ResourceResponseTypes,
  UNKNOWN_ETYMOLOGY_OBJECT,
} from './constants'
import {
  ApiConnectedProgenyTreeParams,
  ApiConnectionsParams,
  ApiHelperParams,
  ApiParams,
  ApiTreeParams,
  ConnectionDetailsDataType,
  ConnectionsDataType,
  DetailDataType,
  EtymologyObject,
  EtymologyObjectDict,
  isAutoCompleteAPIWordItemType,
  KinProgenyDataType,
  KinTreeDataType,
  ModifyConnectionReturnType,
  MultiDetailDataType,
  NestedTreeType,
  ProgenyTableDataType,
  ProgenyTreeDataType,
  RandomEtymologyType,
  TreeDataType,
  VisxProgenyPackNode,
  VisxRootTreeNode,
} from './types'

export const getTree = async ({
  identifier,
  channel,
  all_entry_numbers,
  compression,
  allow_duplicates,
  entry_number,
}: ApiTreeParams) => {
  const params = {
    ...identifier,
    all_entry_numbers,
    compression,
    allow_duplicates,
    entry_number,
    common_descendant_count: 0, // makes query faster
  }
  const { data } = await secureHttp(Resource.GET_TREES, params, channel)

  const trunkId = Object.keys(data[2]).pop() ?? '0'
  const trunkObject = data[1].words[trunkId]
  if (!trunkObject) {
    throw new Error('No trunk object')
  }
  let affixes = (data[1].affixes ?? []).map((a: number) => String(a))
  // don't worry about affixes if the root is an affix
  if (affixes.includes(trunkId)) {
    affixes = []
  }
  const treeData: TreeDataType = {
    trunkObject,
    nestedTree: data[2],
    tree: getLayers(data[2], affixes), //.slice().reverse(), using flex column-reverse instead
    connections: data[3].map(([start, end]: [number, number]) => ({
      descendant: String(start),
      root: String(end),
    })),
    etymologyObjects: data[1]['words'],
    affixes: affixes,
    type: 'tree',
  }
  return treeData
}

export const getConnections = async ({
  identifier,
  channel,
  direction,
}: ApiConnectionsParams): Promise<ConnectionsDataType> => {
  const params = {
    ...identifier,
    direction,
  }
  const { data } = await secureHttp(Resource.GET_CONNECTIONS, params, channel)

  const { trunkId, words: etymologyObjects, connection_items } = data

  if (!Array.isArray(connection_items) || typeof trunkId !== 'string') {
    throw new Error('Data from get_connections was bad')
  }

  const trunkObject = etymologyObjects[trunkId] ?? UNKNOWN_ETYMOLOGY_OBJECT
  const connectionData: ConnectionsDataType = {
    trunkObject,
    etymologyObjects,
    connection_items,
    type: 'connections',
  }
  return connectionData
}

export const getKinTree = async ({
  identifier,
  all_entry_numbers,
  channel,
  compression,
  allow_duplicates,
  entry_number,
}: ApiTreeParams): Promise<KinTreeDataType> => {
  const params = {
    ...identifier,
    all_entry_numbers,
    compression,
    allow_duplicates,
    entry_number,
  }
  const { data } = await secureHttp(Resource.GET_TREES, params, channel)
  const trunkId = Object.keys(data[2]).pop() ?? '0'
  const trunkObject = data[1].words[trunkId]
  if (!trunkObject) {
    throw new Error('No trunkObject!')
  }
  const { words: etymologyObjects, affixes: affixNumbers } = data[1]
  const affixes = affixNumbers.map((a) => a.toString())
  const connections: [number, number][] = data[3]

  const descendants = connections.map((c) => c[0].toString())

  const finalRoots = Object.keys(etymologyObjects).filter(
    (c) => !descendants.includes(c) && !affixes.includes(c)
  )
  const nestedTree = {
    [trunkId]: finalRoots.reduce(
      (accu, root) =>
        root === trunkId
          ? accu
          : {
              ...accu,
              [root]: {},
            },
      {}
    ),
  }

  return {
    trunkObject,
    nestedTree,
    treeLayers: getLayers(nestedTree, affixes), //.slice().reverse(), using flex column-reverse instead
    etymologyObjects,
    progeny: {},
    type: 'kin',
  }
}

export const getProgenyTree = async ({
  identifier,
  all_entry_numbers,
  compression,
  allow_duplicates,
  target_language,
  channel,
}: ApiConnectedProgenyTreeParams): Promise<ProgenyTreeDataType> => {
  const params = {
    ...identifier,
    all_entry_numbers,
    compression,
    allow_duplicates,
    amount: 250,
    target_language,
    include_details: true, // FALSE CHANGES THE API FORMAT
  }

  const { data } = await secureHttp(
    Resource.GET_CONNECTED_PROGENY,
    params,
    channel
  )

  const trunkObject = data.root.words[parseInt(data.id)]
  if (!trunkObject) {
    throw new Error('No trunk object!')
  }

  const returnData: ProgenyTreeDataType = {
    nestedTree: data.tree,
    trunkObject,
    connections: data.connections,
    etymologyObjects: {
      ...data.progeny.words,
      ...data.root.words,
      // This is used when the data comes in a different format (when amount or include details changes)
      // ...data.progeny.reduce(
      //   (accu: EtymologyObjectDict, etymology: EtymologyObject) => ({
      //     ...accu,
      //     [etymology._id]: etymology,
      //   }),
      //   {}
      // ),
    },
    groupData: data.groups,
    type: 'progeny-tree',
  }
  return returnData
}

export const getProgenyTable = async ({
  identifier,
  channel,
}: ApiParams): Promise<ProgenyTableDataType> => {
  if (!identifier) throw new Error('No identifier in getTree!')
  const params = {
    ...identifier,
  }
  const { data } = await secureHttp(Resource.GET_PROGENY, params, channel)

  const trunkObject = data.root.words[parseInt(data.id)]
  if (!trunkObject) {
    throw new Error('No trunk object!')
  }

  const returnData: ProgenyTableDataType = {
    trunkObject,
    etymologyObjects: {
      ...data.progeny.words,
      ...data.root.words,
    },
    groupData: data.groups,
    type: 'progeny-table',
  }
  return returnData
}

export const getDetails = async ({
  identifier,
  channel,
}: // channel,
ApiParams): Promise<DetailDataType> => {
  if ('id' in identifier) {
    throw new Error(
      'identifier error for getDetails, cannot provide id, only ids'
    )
  }
  const params = {
    ...identifier,
    linked_etymology: true,
    etymology: true,
    common_descendant_count: 10,
  }

  const { data } = await secureHttp(Resource.GET_DETAILS, params, channel)

  const returnData: DetailDataType = {
    etymologyObject: Object.values(data.words).filter(isTruthy)[0],
    type: 'detail',
  }
  return returnData
}

export const getMultiDetails = async ({
  ids,
  channel,
}: // channel,
ApiHelperParams & { ids: string[] }): Promise<MultiDetailDataType> => {
  const params = {
    ids,
    linked_etymology: true,
    etymology: true,
    common_descendant_count: 10,
  }

  const { data } = await secureHttp(Resource.GET_DETAILS, params, channel)

  const returnData: MultiDetailDataType = {
    etymologyObjects: Object.values(data.words).filter(isTruthy),
    type: 'multi-detail',
  }
  return returnData
}

type PostConnectionParams = {
  root_id: string
  desc_id: string
  dry_run: boolean
  executingChannel?: EnvironmentKey
  resourceChannel: EnvironmentKey
}

export const postAddConnection = async ({
  root_id,
  desc_id,
  dry_run = true,
  executingChannel,
  resourceChannel,
}: PostConnectionParams): Promise<ModifyConnectionReturnType> => {
  const params = { root_id, desc_id, dry_run, channel: resourceChannel }
  const { data } = await secureHttp(
    Resource.POST_ADD_CONNECTION,
    params,
    executingChannel
  )
  return data
}

export const postDeleteConnection = async ({
  root_id,
  desc_id,
  dry_run = true,
  executingChannel,
  resourceChannel,
}: PostConnectionParams): Promise<ModifyConnectionReturnType> => {
  const params = { root_id, desc_id, dry_run, channel: resourceChannel }
  const { data } = await secureHttp(
    Resource.POST_DELETE_CONNECTION,
    params,
    executingChannel
  )
  return data
}

type getRandomEtymologyProps = {
  language: string
  type?: string
  channel?: EnvironmentKey
}
export const getRandomEtymology = async ({
  language = 'English',
  type = 'child',
  channel,
}: getRandomEtymologyProps): Promise<RandomEtymologyType> => {
  const params = { language, type }
  const { data } = await secureHttp(
    Resource.GET_RANDOM_ETYMOLOGY,
    params,
    channel,
    false // not secure. Needed to resolve no access token issues
  )
  return data
}

type AutocompleteParams = {
  word: string
  searchLanguage: string
}
export const getAutoCompleteWords = async ({
  word,
  searchLanguage,
  channel,
}: ApiHelperParams & AutocompleteParams): Promise<AutoCompleteListType> => {
  const params = {
    word,
    order: new Date().getTime(),
    language: searchLanguage === ALL_LANGUAGES_STRING ? 'any' : searchLanguage,
  }

  const { data } = await secureHttp(Resource.GET_AUTOCOMPLETE, params, channel)
  const result: AutoCompleteListType = {
    data: data.auto_complete_data
      .filter((item) => isAutoCompleteAPIWordItemType(item))
      .map((item) => ({
        ...(item as AutoCompleteAPIWordItemType),
        icon: 'Search',
        type: 'word',
      }))
      .filter((item) => item._id),
    order: data.order,
    search_term: data.search_term,
  }
  return result
}

type ConnectionDetailsParams = {
  descendant: string
  root: string
}
export const getConnectionDetails = async ({
  descendant,
  root,
  channel,
}: ApiHelperParams & ConnectionDetailsParams): Promise<
  ConnectionDetailsDataType | undefined
> => {
  const params = { root, descendant }
  const { data } = await secureHttp(
    Resource.GET_CONNECTION_DETAILS,
    params,
    channel
  )

  return data
}

export const getPronunciation = async (
  ipa: string,
  language: string,
  channel?: EnvironmentKey
) => {
  const params = { ipa, speaker_language: language }

  const { data: audioBlob } = await secureHttp(
    Resource.GET_PRONUNCIATION,
    params,
    channel,
    true,
    { responseType: 'blob' }
  )
  return URL.createObjectURL(audioBlob)
}

export const getIdentifier = ({
  word,
  language,
  id,
}: Partial<BaseEtymology>) => {
  let identifier: APIIdentifier | undefined = undefined

  if (id !== undefined) {
    identifier = { ids: id }
  } else if (word !== undefined) {
    identifier = { word, language }
  }

  return {
    identifier,
    cacheIdentifier: getIdentifierCache(identifier),
  }
}

// For ensuring the data is cached
export const getIdentifierCache = (
  identifier: APIIdentifier | undefined
): string => {
  let cacheIdentifier: string
  if (identifier === undefined) {
    return '@random'
  }

  if (isIdsIdentifier(identifier)) {
    cacheIdentifier = identifier.ids
  } else if (isIdIdentifier(identifier)) {
    cacheIdentifier = identifier.id
  } else if (isWordIdentifier(identifier)) {
    cacheIdentifier = identifier.word + '-' + identifier.language
  } else {
    cacheIdentifier = '@random'
  }
  return cacheIdentifier
}

export const visxRootTree = (
  rootTree: NestedTreeType,
  kinProgenyData: KinProgenyDataType,
  treeEtymologyObjects: EtymologyObjectDict
): VisxRootTreeNode[] =>
  Object.entries(rootTree)
    .map(([key, value]) => {
      const etymologyObject = treeEtymologyObjects[key]
      if (!etymologyObject) return undefined

      const node: VisxRootTreeNode = {
        name: key,
        frequency: etymologyObject.frequencies,
        definition: getSimpleDefinition(etymologyObject),
        children: visxRootTree(value, kinProgenyData, treeEtymologyObjects),
        progeny: kinProgenyData[key]?.nestedTree,
        progenyEtymologyObjects: kinProgenyData[key]?.etymologyObjects,
        word: etymologyObject.word,
        language: etymologyObject.language_name,
        id: key,
      }
      return node
    })
    .filter(isTruthy)

export const visxProgenyTree = (
  progenyTree: NestedTreeType,
  progenyEtymologyObjects: EtymologyObjectDict,
  parentFrequency = 0
): VisxProgenyPackNode[] => {
  return (
    Object.entries(progenyTree)
      // .filter(([key, value]) => {
      //   const frequencies = progenyEtymologyObjects[key]?.frequencies ?? 1
      //   return !parentFrequency || frequencies >= parentFrequency
      // })
      .map(([key, value]) => {
        const word = progenyEtymologyObjects[key]?.word ?? 'Unknown'
        const frequencies = progenyEtymologyObjects[key]?.frequencies ?? 0
        const isLessFrequentChild = frequencies < parentFrequency
        const children = visxProgenyTree(
          value,
          progenyEtymologyObjects,
          Math.max(frequencies, parentFrequency)
        )
        const childrenIds = getNestedTreeKeys(value)
        const maxChildFrequencies = Math.max(
          ...childrenIds.map(
            (child) => progenyEtymologyObjects[child]?.frequencies ?? 0
          )
        )
        const higherFrequencyThanProgeny = frequencies > maxChildFrequencies
        const node: VisxProgenyPackNode = {
          word,
          language: progenyEtymologyObjects[key]?.language_name ?? 'Unknown',
          isLessFrequentChild,
          showText: higherFrequencyThanProgeny && !isLessFrequentChild,
          id: progenyEtymologyObjects[key]?._id?.toString() ?? '-1',
          children,
          frequencies,
          // progeny: (kinProgenyData[key] ?? {})['etymologyObjects'],
          // parents: visxNestTree(value),
        }
        return node
      })
  )
}

export const getNestedTreeKeys = (dict: NestedTreeType): string[] => {
  let layer_arrays: string[] = []
  for (const key in dict) {
    layer_arrays.push(key)
    layer_arrays = layer_arrays.concat(getNestedTreeKeys(dict[key] || {}))
  }
  return layer_arrays
}

export const getSimpleDefinition = (etymologyObject: EtymologyObject) => {
  const { entries } = etymologyObject
  const [sentence] = Object.values(entries ?? {}).reduce<string[]>(
    (entry_accu, entry) => [
      ...entry_accu,
      ...Object.values(entry.pos ?? {}).reduce<string[]>(
        (pos_accu, pos) => [...pos_accu, ...(pos?.definitions ?? [])],
        []
      ),
    ],
    []
  )

  return sentence
}

export const getWordFrequencyPercentile = (frequencies: number): number => {
  const wordFrequencyPercentileEntry = Object.entries(
    WORD_FREQUENCY_PERCENTILE_LOOKUP
  )
    .reverse()
    .find(([k]) => parseInt(k) < frequencies)
  return wordFrequencyPercentileEntry ? wordFrequencyPercentileEntry[1] : 0
}

export const getRootFrequencyPercentile = (frequencies_log: number): number => {
  const rootFrequencyPercentileEntry = Object.entries(
    ROOT_FREQUENCY_PERCENTILE_LOOKUP
  )
    .reverse()
    .find(([k]) => parseInt(k) < frequencies_log)
  return rootFrequencyPercentileEntry ? rootFrequencyPercentileEntry[1] : 0
}

export const secureHttp = async <T extends Resource>(
  resource: T,
  params: ParamsDict,
  channel: EnvironmentKey | undefined,
  secure = true,
  config = {}
): Promise<AxiosResponse<ResourceResponseTypes[T]>> => {
  const isPost =
    resource === Resource.POST_DELETE_CONNECTION ||
    resource === Resource.POST_ADD_CONNECTION
  const paramString = getParamString(params)
  const url = `${getEnv(channel).apiUrl}/${resource}?${paramString}`
  const token = getStorageItem(StorageItems.FIREBASE_TOKEN)
  if (secure && !token) {
    throw new Error(`No access token while trying to access ${url}!`)
  }

  // If we fail because te token is expired, then we should try to refresh
  const axiosConfig: AxiosRequestConfig = {
    url,
    method: isPost ? 'POST' : 'GET',
    ...(secure
      ? {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}),
    ...config,
  }

  const response = await axios
    .request<ResourceResponseTypes[T]>(axiosConfig)
    .catch((error) => {
      if (error.response.status !== 401) {
        return {} as AxiosResponse<ResourceResponseTypes[T]>
      }

      console.log('request failed due to 401. Attempting to reauthenticate')
      return recurringTokenRefresh().then((token) =>
        axios.request<ResourceResponseTypes[T]>({
          ...axiosConfig,
          headers: { Authorization: `Bearer ${token}` },
        })
      )
    })

  return response
}

// :authority: api.etymologyexplorer.com
// :method: GET
// :path: /dev/get_details?common_descendant_count=10&etymology=true&ids=150522&linked_etymology=true
// :scheme: https
// accept: application/json, text/plain, */*
// accept-encoding: gzip, deflate, br
// accept-language: en-US,en;q=0.9
// origin: http://localhost:3000
// referer: http://localhost:3000/
// sec-ch-ua: " Not A;Brand";v="99", "Chromium";v="98", "Google Chrome";v="98"
// sec-ch-ua-mobile: ?0
// sec-ch-ua-platform: "macOS"
// sec-fetch-dest: empty
// sec-fetch-mode: cors
// sec-fetch-site: cross-site
// user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.109 Safari/537.36V

// :authority: api.etymologyexplorer.com
// :method: OPTIONS
// :path: /dev/get_trees?common_descendant_count=0&language=Ancient%20Greek&word=%CE%B4%CE%B9%CE%B1%CF%83%CE%BA%CE%B5%CF%85%CE%B1%CF%83%CF%84%CE%AE%CF%82
// :scheme: https
// accept: */*
// accept-encoding: gzip, deflate, br
// accept-language: en-US,en;q=0.9
// access-control-request-headers: access-control-allow-origin,authorization
// access-control-request-method: GET
// origin: http://localhost:3000
// referer: http://localhost:3000/
// sec-fetch-dest: empty
// sec-fetch-mode: cors
// sec-fetch-site: cross-site
// user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.109 Safari/537.36

// Accept: application/json, text/plain, */*
// Access-Control-Allow-Origin: *
// Authorization: Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkYzBlNmRmOTgyN2EwMjA2MWU4MmY0NWI0ODQwMGQwZDViMjgyYzAiLCJ0eXAiOiJKV1QifQ.eyJzdHJpcGVSb2xlIjpudWxsLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd29yZGtpbi1kZXYiLCJhdWQiOiJ3b3Jka2luLWRldiIsImF1dGhfdGltZSI6MTY0NjI2OTY0OCwidXNlcl9pZCI6Ik9kTTlzaWRvMzdnTXZtSjdKVFgyU3paQlhXNTIiLCJzdWIiOiJPZE05c2lkbzM3Z012bUo3SlRYMlN6WkJYVzUyIiwiaWF0IjoxNjQ2MjgyODc4LCJleHAiOjE2NDYyODY0NzgsImVtYWlsIjoidGVzdCsxQG1vdW50YWluZGV2LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJ0ZXN0KzFAbW91bnRhaW5kZXYuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.DKKcGP2nQwxFzfSq6c6WuMvfdlwK6WZG5tX-8Log2E0Qb7Tj1sobzQN3J4x9KrdmgsFNq48Kvm3lNAGq55k88RHiElaprcqgnoI2cMvtCSnvE3u4bQIquSSCmqnZiWvQk5iHkb95EnX0LIg_0rhgH7VuvjG4GgidEjpWvGfArVqRW2ZdauhxKaaCSiuRabeTFvgFgy1hUSVpRncNpVYtVrn0FR1KoPtd5KlLVKNlPYCC9iW32PdIxUwy0P6XA-fD4pYSQyat8qpQEvHiIcdR9R-Y2w50Ls1MVbCCsNrfVmvruebiVuh2gLsaq_e4144APiuJLnYSb74ygLyLM6zVlw
// Referer: http://localhost:3000/
// sec-ch-ua: " Not A;Brand";v="99", "Chromium";v="98", "Google Chrome";v="98"
// sec-ch-ua-mobile: ?0
// sec-ch-ua-platform: "macOS"
// User-Agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.109 Safari/537.36
//
