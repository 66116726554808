import { ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react'
import { useQueryClient } from 'react-query'
import {
  ACTIVE_FIREBASE_ONE_TIME_PAYMENT_QUERY_KEY,
  ACTIVE_FIREBASE_SUBSCRIPTION_QUERY_KEY,
} from 'subscriptions/constants'
import {
  getActiveFirestoreSubscriptions,
  getSuccessfulFirestoreOneTimePayments,
} from 'subscriptions/functions'
import { getSettings, SETTINGS_QUERY_KEY } from 'utils/settings'
import { setStorageItem, StorageItems } from 'utils/storage'
import { AuthStoreContext, INITIAL_STATE } from './constants'
import { useFirebaseAuthListener } from './hooks'
import { reducer } from './reducer'
import { State } from './types'
import { recurringTokenRefresh } from './functions'
import { LoadingPage } from 'pages/LoadingPage'

export const AuthController = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient()
  const [userState, reduceUserState] = useReducer(reducer, INITIAL_STATE)
  const user = useFirebaseAuthListener()

  const resetUser = useCallback(
    () =>
      reduceUserState({
        type: 'update',
        payload: {
          ...INITIAL_STATE,
          loading: false,
        },
      }),
    []
  )

  // When user changes set a timeout to update the token
  useEffect(() => {
    if (user) {
      recurringTokenRefresh()
    }
  }, [user])

  useEffect(() => {
    if (!user) {
      resetUser()
      return
    }
    user
      .getIdTokenResult()
      .then((idTokenResult) => {
        // Store to session storage the token
        // console.log('TODO PUBLISH REMOVE THIS log', idTokenResult.token)
        setStorageItem(StorageItems.FIREBASE_TOKEN, idTokenResult.token)

        // Confirm the user is an Admin.
        reduceUserState({
          type: 'update',
          payload: {
            user,
            loading: false, // was causing problems (due to race)
            loggedIn: true,
            canUpdateConnections: Boolean(
              idTokenResult.claims.updateConnections
            ),
          },
        })
      })
      .catch(resetUser)
  }, [resetUser, user])

  useEffect(() => {
    if (!user?.uid) return
    queryClient.prefetchQuery(ACTIVE_FIREBASE_SUBSCRIPTION_QUERY_KEY, () =>
      getActiveFirestoreSubscriptions(user.uid)
    )
    queryClient.prefetchQuery(ACTIVE_FIREBASE_ONE_TIME_PAYMENT_QUERY_KEY, () =>
      getSuccessfulFirestoreOneTimePayments(user.uid)
    )
    queryClient.prefetchQuery(SETTINGS_QUERY_KEY, () => getSettings(user.uid))
  }, [queryClient, user?.uid])

  const value: State = useMemo(
    () => ({ ...userState, user }),
    [userState, user]
  )

  return (
    <AuthStoreContext.Provider value={value}>
      {user ? children : <LoadingPage />}
    </AuthStoreContext.Provider>
  )
}
