import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import styled from '@mui/material/styles/styled'
import { ThemeKey } from './constants'
import { useColorModeDispatch, useThemeKey } from './Controller'

export function ThemeSwitch() {
  const { changeColorMode } = useColorModeDispatch()
  const { chosenMode } = useThemeKey()

  return (
    <Wrapper>
      <FormLabel>Theme</FormLabel>
      <RadioGroup
        value={chosenMode}
        onChange={(event, value) => changeColorMode(value as ThemeKey)}
      >
        <FormControlLabel value="light" control={<Radio />} label="Light" />
        <FormControlLabel value="dark" control={<Radio />} label="Dark" />
        <FormControlLabel value="system" control={<Radio />} label="System" />
      </RadioGroup>
    </Wrapper>
  )
}

const Wrapper = styled(FormControl)({
  padding: 20,
})
