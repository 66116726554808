import { useTheme } from '@mui/material/styles'
import { useChannel } from 'channel/Controller'
import { isAndroid, isIOS } from 'react-device-detect'
import { PlanProductIdOption } from 'types/backend-types'
import { EnvironmentKey } from 'utils/types'
import packageJson from '../../package.json'
import { ENV_CHANNEL } from './constants'

// import Sentry from './sentry';

//https://medium.com/@peterpme/environment-variables-in-expo-using-release-channels-4934594c5307

export const EXPERIENCE_ID = '@dnishiyama/etymologyexplorer'

type EnvironmentDictType = {
  [environment in EnvironmentKey]: EnvironmentType
}

type EnvironmentType = {
  env: EnvironmentKey
  version: string
  // build: string
  apiUrl: string
  // adBottomBannerId: string
  // interstitialAdId: string
  productSKUsAndroid: PlanProductIdOption[]
  productSKUs: PlanProductIdOption[]
  subscriptionsSKUs: PlanProductIdOption[]
  isTestEnvironment: boolean
}

const productSKUs: PlanProductIdOption[] = [
  'etymology_explorer_monthly',
  'etymology_explorer_monthly_special',
  'etymology_explorer_annual',
  'etymology_explorer_annual_special',
  'etymology_explorer_lifetime_non_consumable',
  'etymology_explorer_lifetime_non_consumable_special',
  'etymology_explorer_remove_ads_v0',
  'etymology_explorer_gold',
  'etymology_explorer_vanguard_monthly',
  'etymology_explorer_vanguard_annual',
  'etymology_explorer_vanguard_lifetime',
]

const productSKUsAndroid: PlanProductIdOption[] = [
  'etymology_explorer_lifetime_',
  'etymology_explorer_lifetime_special_',
]

const subscriptionsSKUs: PlanProductIdOption[] = [
  'etymology_explorer_monthly',
  'etymology_explorer_annual_',
  'etymology_explorer_annual_special',
]

const environmentConstants = {
  version: packageJson.version,
  productSKUs,
  productSKUsAndroid,
  subscriptionsSKUs,
}

const baseUrl = 'https://api.etymos.ai'
// const baseUrl = 'https://api.etymologyexplorer.com'
// const baseUrl = 'https://nsll9sgujl.execute-api.us-east-1.amazonaws.com' // HTTP API
// const baseUrl = 'https://kbfn3kmk9c.execute-api.us-east-1.amazonaws.com' // REST API
const environments: EnvironmentDictType = {
  test: {
    ...environmentConstants,
    env: 'test',
    apiUrl: `${baseUrl}/test`,
    isTestEnvironment: true,
    // adBottomBannerId: TestIds.BANNER,
    // interstitialAdId: TestIds.INTERSTITIAL,
  },
  dev: {
    ...environmentConstants,
    env: 'dev',
    apiUrl: `${baseUrl}/dev`,
    isTestEnvironment: true,
    // adBottomBannerId: TestIds.BANNER,
    // interstitialAdId: TestIds.INTERSTITIAL,
  },
  staging: {
    ...environmentConstants,
    env: 'staging',
    apiUrl: `${baseUrl}/staging`,
    isTestEnvironment: true,
    // adBottomBannerId: TestIds.BANNER,
    // interstitialAdId: TestIds.INTERSTITIAL,
  },
  prod: {
    ...environmentConstants,
    env: 'prod',
    isTestEnvironment: false,
    apiUrl: `${baseUrl}/prod`,
    // adBottomBannerId: Platform.select({
    //   android:'ca-app-pub-2659227158030688/7969639652',
    //   ios: 'ca-app-pub-2659227158030688/9434701073',
    // }) ?? 'ca-app-pub-2659227158030688/9434701073', // default option
    // interstitialAdId: Platform.select({
    //   android: 'ca-app-pub-2659227158030688/4318169255',
    //   ios: 'ca-app-pub-2659227158030688/2570569934',
    // }) ?? 'ca-app-pub-2659227158030688/2570569934' // default option
  },
}

if (environments[ENV_CHANNEL] === undefined) {
  throw new Error(`No environment for channel ${ENV_CHANNEL}`)
}

export const testActiveSubscriptionToRestore = {
  expires_date: '2020-06-22 19:37:54 Etc/GMT',
  expires_date_ms: '1692854674000',
  expires_date_pst: '2020-06-22 12:37:54 America/Los_Angeles',
  is_in_intro_offer_period: 'false',
  is_trial_period: 'false',
  original_purchase_date: '2020-06-22 19:27:55 Etc/GMT',
  original_purchase_date_ms: '1592854075000',
  original_purchase_date_pst: '2020-06-22 12:27:55 America/Los_Angeles',
  original_transaction_id: '1000000682944029',
  product_id: 'etymology_explorer_remove_ads_v0',
  purchase_date: '2020-06-22 19:32:54 Etc/GMT',
  purchase_date_ms: '1592854374000',
  purchase_date_pst: '2020-06-22 12:32:54 America/Los_Angeles',
  quantity: '1',
  subscription_group_identifier: '20650310',
  transaction_id: '1000000682945989',
  web_order_line_item_id: '1000000053487631',
}

export const testInactiveSubscription = {
  expires_date: '2020-06-22 19:32:54 Etc/GMT',
  expires_date_ms: '1592854374000',
  expires_date_pst: '2020-06-22 12:32:54 America/Los_Angeles',
  is_in_intro_offer_period: 'false',
  is_trial_period: 'false',
  original_purchase_date: '2020-06-22 19:27:55 Etc/GMT',
  original_purchase_date_ms: '1592854075000',
  original_purchase_date_pst: '2020-06-22 12:27:55 America/Los_Angeles',
  original_transaction_id: '1000000682944029',
  product_id: 'etymology_explorer_remove_ads_v0',
  purchase_date: '2020-06-22 19:27:54 Etc/GMT',
  purchase_date_ms: '1592854074000',
  purchase_date_pst: '2020-06-22 12:27:54 America/Los_Angeles',
  quantity: '1',
  subscription_group_identifier: '20650310',
  transaction_id: '1000000682944029',
  web_order_line_item_id: '1000000053487630',
}

export const GOOGLE_WEB_CLIENT_ID =
  '213214433531-4g5u5blm2l49pf9h1516gfk6blrudq69.apps.googleusercontent.com'

export const getEnv = (local_channel: EnvironmentKey | undefined) =>
  local_channel ? environments[local_channel] : environments[ENV_CHANNEL]

export const Version = () => {
  const theme = useTheme()
  const { channel } = useChannel()
  return (
    <p style={{ padding: 0, margin: 0, color: theme.palette.text.disabled }}>
      {packageJson.version}:{process.env.REACT_APP_CHANNEL}
      {process.env.REACT_APP_CHANNEL === channel ? '' : ` (${channel})`}
    </p>
  )
}

const APPLE_APP_ID = 1189423897
const GOOGLE_PACKAGE_NAME = 'com.nishiyama.etymologyexplorer'
const androidLink = `https://play.google.com/store/apps/details?id=${GOOGLE_PACKAGE_NAME}`
const iosLink = `https://apps.apple.com/us/app/etymologyexplorer/id${APPLE_APP_ID}`
export const APP_LINK = isIOS
  ? iosLink
  : isAndroid
  ? androidLink
  : `https://etymologyexplorer.com`
