import Stack from '@mui/material/Stack'
import styled from '@mui/material/styles/styled'
import { LinkIcons } from 'components/LinkIcons'
import { Spacer } from 'components/Spacer'
import { ThemeConstants } from 'theme/constants'

const NavIcons = () => {
  return (
    <Wrapper>
      <Stack direction="row" gap={1}>
        <LinkIcons />
      </Stack>
      <Spacer size={5} />
    </Wrapper>
  )
}

const Wrapper = styled('div')(() => ({
  pointerEvents: 'all',
  position: 'absolute',
  bottom: 12,
  left: 0,
  zIndex: ThemeConstants.Z_INDEX_NAV_ICONS,
  color: 'white',
  fontSize: 12,
  display: 'flex',
  flexDirection: 'row',
}))

export default NavIcons
