import { LoadingPage } from 'pages/LoadingPage'
import ConnectionsPage from 'pages/ConnectionsPage/Loadable'
import DetailPage from 'pages/DetailPage/Loadable'
import InfoPage from 'pages/InfoPage/Loadable'
import LoginPage from 'pages/LoginPage/Loadable'
import LogoutPage from 'pages/LogoutPage/Loadable'
import NodeTreePage from 'pages/NodeTreePage/Loadable'
import { NotFoundPage } from 'pages/NotFoundPage/Loadable'
import PricingPage from 'pages/PricingPage/Loadable'
import ProgenyNodeTreePage from 'pages/ProgenyNodeTreePage/Loadable'
import ProgenyPackPage from 'pages/ProgenyPackPage/Loadable'
import ProgenyTablePage from 'pages/ProgenyTablePage/Loadable'
import RegisterPage from 'pages/RegisterPage/Loadable'
import ReportPage from 'pages/ReportPage/Loadable'
import SettingsPage from 'pages/SettingsPage/Loadable'
import StructuredTreePage from 'pages/TreePage/Loadable'
import VanguardPage from 'pages/VanguardPage/Loadable'
import WotdPage from 'pages/WotdPage/Loadable'
import { WotdRedirect } from 'pages/WotdPage/WotdRedirect'
import { Route, Routes } from 'react-router-dom'
import { Path, PathHelper } from 'router/constants'
import { AuthRoute } from './AuthRoute'
import { PreviewAccess } from './PreviewAccess'

const Router = () => {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path={`/${Path.LOGIN}`} element={<LoginPage />} />
        <Route path={`/${Path.REGISTER}`} element={<RegisterPage />} />
      </Route>
      {PathHelper.TREES.map((p) => (
        <Route key={p} path={p} element={<StructuredTreePage />} />
      ))}
      {PathHelper.DETAILS.map((p) => (
        <Route key={p} path={p} element={<DetailPage />} />
      ))}
      <Route element={<PreviewAccess />}>
        {PathHelper.NODES.map((p) => (
          <Route key={p} path={p} element={<NodeTreePage />} />
        ))}
        {PathHelper.NODE_CHILDS.map((p) => (
          <Route key={p} path={p} element={<ProgenyNodeTreePage />} />
        ))}
        {PathHelper.PROGENY_TABLES.map((p) => (
          <Route key={p} path={p} element={<ProgenyTablePage />} />
        ))}
        {PathHelper.PROGENY_TREES.map((p) => (
          <Route key={p} path={p} element={<ProgenyPackPage />} />
        ))}
        {PathHelper.CONNS.map((p) => (
          <Route key={p} path={p} element={<ConnectionsPage />} />
        ))}
      </Route>
      <Route path={`/${Path.PLANS}/`} element={<PricingPage />} />
      <Route path={`/${Path.WOTD_LIST}/`} element={<WotdPage />} />
      <Route path={`/${Path.INFO}/`} element={<InfoPage />} />
      <Route path={`/${Path.REPORT}/*`} element={<ReportPage />} />
      <Route path={`/${Path.ACCOUNT}/`} element={<SettingsPage />} />
      <Route path={`/${Path.VANGUARD}/`} element={<VanguardPage />} />
      <Route path={`/${Path.LOGOUT}`} element={<LogoutPage />} />
      <Route path={'/'} element={<LoadingPage />} />
      <Route path={`/${Path.SEE_WOTD}`} element={<WotdRedirect />} />
      <Route path={'*'} element={<NotFoundPage />} />
    </Routes>
  )
}

export default Router

// This is more for redirecting after login, which I'd like to handle in the page instead of in the route
// const RequireAuth = ({children, redirectTo}: {children: JSX.Element, redirectTo: string}) => {
//   const location = useLocation()
//   const { loggedIn } = useAuth()
//   const state: LocationState | undefined = location?.state as LocationState
//   const redirectUrl = state?.from?.pathname ?? '/'
//   return loggedIn ? children : <Navigate to={redirectTo} />
// }
