import { useEffect } from 'react'
import {
  NavigateOptions,
  useLocation,
  useNavigate as useNavigateReactRouter,
} from 'react-router-dom'
import { ThemeConstants } from 'theme/constants'
import { PAGE_WRAPPER_ID } from 'utils/constants'

type NavigateToFunction = (to: string, options?: NavigateOptions) => void

export const useNavigate = () => {
  const navigate = useNavigateReactRouter()
  const location = useLocation()
  const navigateWithQueryParams: NavigateToFunction = (to, options) =>
    navigate({ pathname: to, search: location.search }, options)

  return navigateWithQueryParams
}

export const useAnchorScroll = () => {
  const location = useLocation()
  useEffect(() => {
    console.debug('useAnchorScroll location.hash', location.hash)
    scrollToAnchor(location.hash)
  }, [location.hash])
}

const scrollToAnchor = (locationHash: string, attempt = 1) => {
  console.debug('scrollToAnchor attempt:', attempt)
  const MAX_ATTEMPTS = 10
  const hashParts = locationHash.split('#')
  if (hashParts.length > 1) {
    const hash = hashParts.slice(-1)[0]
    const element = document.querySelector(`#${hash}`)
    const pageWrapper = document.querySelector(`#${PAGE_WRAPPER_ID}`)
    if (element && pageWrapper) {
      const position =
        element.getBoundingClientRect().top - ThemeConstants.NAV_BAR_HEIGHT
      pageWrapper.scrollBy({ top: position })
    } else if (attempt <= MAX_ATTEMPTS) {
      setTimeout(() => scrollToAnchor(locationHash, attempt + 1), 500)
    }
  }
}
