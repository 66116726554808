/**
 * This is a controller with storage in localstorage
 */
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react'
import { getStorageItem, StorageItems, useStoreOnChange } from 'utils/storage'

type State = {
  searchLanguage: string
}

type Action = { type: 'update'; payload: Partial<State> }

const initialState: State = {
  searchLanguage: getStorageItem(StorageItems.SEARCH_LANGUAGE) ?? 'English',
}

const StoreContext = createContext(initialState)
const DispatchContext = createContext<Dispatch<Action>>(() => undefined)

export const useSearchLanguage = () => useContext(StoreContext)
export const useSetSearchLanguage = () => {
  const reduceData = useContext(DispatchContext)
  const setSearchLanguage = (searchLanguage: string) => {
    reduceData({ type: 'update', payload: { searchLanguage } })
  }
  return setSearchLanguage
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      }

    default:
      throw new Error('unrecognized settings action')
  }
}

const Controller = ({ children }: { children: ReactNode }) => {
  console.debug('SearchLanguageController refreshing')
  const [data, reduceData] = useReducer(reducer, initialState)

  useStoreOnChange(StorageItems.SEARCH_LANGUAGE, data.searchLanguage)

  return (
    <DispatchContext.Provider value={reduceData}>
      <StoreContext.Provider value={data}>{children}</StoreContext.Provider>
    </DispatchContext.Provider>
  )
}

export { Controller as SearchLanguageController }
