import Box, { BoxProps } from '@mui/material/Box'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { forwardRef } from 'react'

const LinearProgressWithLabel = forwardRef<
  HTMLDivElement,
  LinearProgressWithLabelProps
>((props, ref) => {
  const { hideLabel, value, ...rest } = props
  return (
    <Wrapper
      style={{ display: 'flex', alignItems: 'center', flex: 1, width: '100%' }}
    >
      <LinearProgressWrapper>
        <LinearProgress
          variant="determinate"
          {...rest}
          value={value}
          ref={ref}
        />
      </LinearProgressWrapper>
      {!hideLabel && (
        <Box style={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      )}
    </Wrapper>
  )
})

LinearProgressWithLabel.displayName = 'LinearProgressWithLabel'

export const LinearBar = styled(LinearProgressWithLabel, {
  shouldForwardProp: (prop) => prop !== 'height',
})<LinearProgressProps & { height?: number }>(({ height }) => ({
  height,
}))

type LinearProgressWithLabelProps = LinearProgressProps & {
  value: number
  hideLabel?: boolean
}

const Wrapper = styled(Box)<BoxProps>(() => ({
  height: 10,
  borderRadius: 5,
}))

const LinearProgressWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(2),
}))
