import { styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'

export const Link = styled(RouterLink, {
  shouldForwardProp: (p) => p !== 'noStyle',
})<{ noStyle?: boolean }>(({ theme, noStyle }) => ({
  color: noStyle ? theme.palette.text.primary : theme.palette.primary.main,
  textDecoration: 'none',

  '&:hover': {
    textDecoration: noStyle ? 'none' : 'underline',
    opacity: '0.8',
  },

  '&:active': {
    opacity: '0.4',
  },
}))
