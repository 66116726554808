import Popper, { PopperProps } from '@mui/material/Popper'
import { ThemeConstants } from 'theme/constants'
import { SEARCH_BAR_WIDTH } from 'utils/constants'

// https://github.com/mui-org/material-ui/issues/19376
const PopperWithTopper = ({ children, style, ...rest }: PopperProps) => {
  return (
    <Popper
      {...rest}
      style={{
        ...style,
        width: SEARCH_BAR_WIDTH,
        paddingTop: 5,
        paddingLeft: ThemeConstants.OVERLAY_INSET,
      }}
      placement="bottom"
    >
      {children}
    </Popper>
  )
}

export default PopperWithTopper

// element.style {V
//   position: absolute;
//   inset: 0px 50px 25px 0px;
//   width: 392px;
//   margin: 24px;
//   transform: translate3d(0px, 60px, 0px);V
