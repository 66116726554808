import grey from '@mui/material/colors/grey'
import { SimplePaletteColorOptions, Theme } from '@mui/material/styles'
import { ThemeOptions } from '@mui/material/styles/createTheme'
import { Shadows } from '@mui/material/styles/shadows'
import { createContext, Dispatch, SetStateAction } from 'react'

const themePurple: SimplePaletteColorOptions = {
  light: '#a233a6',
  main: '#8b0090',
  dark: '#610064',
  contrastText: '#FFF',
}

const themeLightPurple: SimplePaletteColorOptions = {
  light: '#b45bb7',
  main: '#a233a6',
  dark: '#712374',
  contrastText: '#FFF',
}

const themeOrange: SimplePaletteColorOptions = {
  light: '#fbac53',
  main: '#fa9828',
  dark: '#af6a1c',
  contrastText: '#000',
}

// const themeOrange: ColorPartial = {
//   50: '#fef3e2',
//   100: '#fedfb6',
//   200: '#fdcb87',
//   300: '#fcb659',
//   400: '#fba63a',
//   500: '#fa9828',
//   600: '#f68d26',
//   700: '#ef7d23',
//   800: '#e86f21',
//   900: '#dd571e',
// }

const LIGHT_BACKGROUND_COLOR = '#FFFFFF'
const DARK_BACKGROUND_COLOR = '#121212'

//https://mui.com/customization/dark-mode/
export const getDesignTokens = (mode: 'light' | 'dark'): ThemeOptions => ({
  // https://mui.com/customization/theme-components/#global-style-overrides
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // To hide scrollbars
        '::-webkit-scrollbar': {
          display: 'none',
        },
        // To hide scrollbars
        '*': {
          msOverflowStyle: 'none',
        },
        body: {
          fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
          backgroundColor:
            mode === 'dark' ? DARK_BACKGROUND_COLOR : LIGHT_BACKGROUND_COLOR,
        },
        'body.fontLoaded': {
          fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
        },
        p: {
          lineHeight: '1.5em',
        },
        label: {
          lineHeight: '1.5em',
        },
        input: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
        },
        select: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
        },
        button: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
        },
        '.icon': {
          width: '1.5rem',
          height: '1.5rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.75rem',
        },
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  whiteShadows: WHITE_SHADOWS,
  focusShadow: `0px 0px 10px 3px ${mode === 'light' ? '#0288d1' : '#29b6f6'}`,
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: themePurple,
          secondary: themeOrange,
          text: {
            primary: grey[900],
            secondary: grey[700],
            disabled: grey[500],
          },
          background: {
            default: '#ffffff', //'#fef3e2',
            paper: '#ffffff',
          },
          icon: {
            default: '#121212',
          },
          arrowColor: {
            default: '#555',
            action: '#777',
          },
        }
      : {
          // palette values for dark mode
          primary: themeOrange,
          secondary: themeLightPurple,
          background: {
            default: '#121212',
            paper: '#121212',
          },
          text: {
            primary: grey[50],
            secondary: grey[400],
            disabled: grey[600],
          },
          icon: {
            default: '#ededed',
          },
          arrowColor: {
            default: '#777',
            action: '#aaa',
          },
        }),
  },
})

export const DEFAULT_THEME_KEY = 'dark'
export type ThemeKey = 'light' | 'dark' | 'system'

type ThemeDispatchContextType = Dispatch<SetStateAction<ThemeKey>>

export const ThemeDispatchContext = createContext<ThemeDispatchContextType>(
  () => undefined
)
export const ThemeKeyContext = createContext(DEFAULT_THEME_KEY as ThemeKey)

declare module '@mui/material/styles' {
  interface Theme {
    status?: {
      danger: string
    }
    whiteShadows: string[]
    focusShadow: string
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger: string
    }
    whiteShadows: string[]
    focusShadow: string
  }
  interface Palette {
    icon: {
      default: string
    }
    arrowColor: {
      default: string
      action: string
    }
  }
  interface PaletteOptions {
    icon: {
      default: string
    }
    arrowColor: {
      default: string
      action: string
    }
  }
}

const W1 = 'rgba(255,255,255,0.20)'
// const W2 = 'rgba(255,255,255,0.14)'
// const W3 = 'rgba(255,255,255,0.12)'

/* offset-x | offset-y | blur-radius | spread-radius | color */
const WHITE_SHADOWS: Shadows = [
  `none`,
  `0px 0px 6px 1px ${W1}`,
  `0px 0px 7px 2px ${W1}`,
  `0px 0px 8px 3px ${W1}`,
  `0px 0px 9px 4px ${W1}`,
  `0px 0px 10px 5px ${W1}`,
  `0px 0px 11px 5px ${W1}`,
  `0px 0px 12px 5px ${W1}`,
  `0px 0px 13px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
  `0px 0px 6px 5px ${W1}`,
]

export enum ThemeConstants {
  NAV_BAR_HEIGHT = 48 + 32 + 16 * 2, // 16 for padding on top and bottom
  DRAWER_WIDTH = 240,
  SCREEN_MAX_WIDTH = 960,
  FOOTER_NAV_ICON_HEIGHT = 72,
  FOOTER_HEIGHT = 24,
  OVERLAY_INSET = 10,
  Z_INDEX_AUTOCOMPLETE = 10,
  Z_INDEX_CONNECTION_DETAILS = 11,
  Z_INDEX_SIDEBAR = 11,
  Z_INDEX_MOBILE_APP_BANNER = 11,
  Z_INDEX_OVERLAY_WRAPPER = 2000,
  Z_INDEX_FOOTER_WRAPPER = 11,
  Z_INDEX_NAV_ICONS = 11,
  Z_INDEX_FULL_HINT = 1000,
  Z_INDEX_SHORTCUTS = 1001,
}

export const colorTypeToThemeColor = (
  color:
    | 'primary'
    | 'secondary'
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined,
  theme: Theme
): string | undefined => {
  const returnColor =
    color === 'action'
      ? theme.palette.action.active
      : color === 'error'
      ? theme.palette.error.main
      : color === 'disabled'
      ? theme.palette.action.disabled
      : color === 'info'
      ? theme.palette.info.main
      : color === 'inherit'
      ? theme.palette.text.primary
      : color === 'primary'
      ? theme.palette.primary.main
      : color === 'secondary'
      ? theme.palette.secondary.main
      : color === 'success'
      ? theme.palette.success.main
      : color === 'warning'
      ? theme.palette.warning.main
      : theme.palette.action.active
  // console.log('colorTypeToThemeColor', { color, returnColor })
  return returnColor
}

export enum ElementIds {
  D3_TREE_NODE = 'd3-tree-node',
  D3_TREE_NODE_GROUP = 'd3-tree-node-group',
  D3_TREE_NODE_GRADIENT = 'd3-tree-node-gradient',
  D3_TREE_NODE_HOVER_EXPAND = 'd3-tree-node-hover-expand',
  D3_TREE_NODE_DEFINITION_TEXT = 'd3-tree-node-definition-text',
  D3_TREE_NODE_DEFINITION_TEXT_2 = 'd3-tree-node-definition-text-2',
  D3_TREE_NODE_LANGUAGE_TEXT = 'd3-tree-node-language-text',
  D3_TREE_NODE_TEXT = 'd3-tree-node-text',
  D3_TREE_LINK = 'd3-tree-link',
  D3_HOVER_RADIAL_GRADIENT_ROOT = 'hover-radial-gradient-root',
  D3_HOVER_RADIAL_GRADIENT = 'hover-radial-gradient',
  D3_TREE_FRONT_HOVER_USE = 'd3-tree-front-hover-use',
  D3_TREE_FRONT_HOVER_EXPAND = 'd3-tree-front-hover-expand',
  D3_PROGENY_PACK_NODE = 'd3-progeny-pack-node',
}
