import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import { useSearchLanguage } from 'contexts/SearchLanguageController'
import { useDialogDispatch } from 'dialog'
import { ALL_LANGUAGES_STRING, DEFAULT_LANGUAGE } from 'utils/constants'
import { useTheme } from '@mui/material/styles'
import { isMobile } from 'react-device-detect'

export const SearchLanguage = () => {
  const dialogDispatch = useDialogDispatch()
  const theme = useTheme()
  const { searchLanguage } = useSearchLanguage()

  const defaultAppearance =
    searchLanguage === DEFAULT_LANGUAGE ||
    searchLanguage === ALL_LANGUAGES_STRING

  return (
    <Tooltip title={'Language used in the search bar and for random words'}>
      <Button
        variant={
          isMobile ? 'text' : defaultAppearance ? 'outlined' : 'contained'
        }
        style={{
          paddingLeft: isMobile ? 0 : 20,
          paddingRight: isMobile ? 5 : 20,
          marginLeft: isMobile ? 0 : theme.spacing(2),
          pointerEvents: 'all',
          backgroundColor:
            defaultAppearance && !isMobile
              ? theme.palette.background.default
              : undefined,
        }}
        onMouseDown={() =>
          dialogDispatch({ type: 'open', payload: { type: 'language' } })
        }
      >
        {searchLanguage}
      </Button>
    </Tooltip>
  )
}
