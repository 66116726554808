// import * as Notifications from 'expo-notifications';
// import { ExpoPushToken } from 'expo-notifications'; // need this separate to export

// import { SearchBar } from 'react-native-elements';
// import FirebaseAuthTypes from '@firebase/auth-types'
// import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
// import { StackNavigationProp } from '@react-navigation/stack';
// import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';

import { ReactNode } from 'react'

export const ChannelOptions = ['staging', 'dev', 'prod', 'test'] as const

export type EnvironmentKey = typeof ChannelOptions[number]

export const isEnvironmentKey = (
  possibleEnvironmentKey: string | undefined
): possibleEnvironmentKey is EnvironmentKey =>
  possibleEnvironmentKey !== undefined &&
  ChannelOptions.includes(possibleEnvironmentKey as EnvironmentKey)

// STYLE GUIDE

// indices - numbers (to compare)
// ids - strings (to be compatible with lambda output formatting and simpler)
// FC<xProps> for memo items otherwise only provide PropTypes

export type QueryDict = {
  allow_duplicates?: string
  compression?: number
  all_entry_numbers?: boolean
  channel?: EnvironmentKey
  entry_number?: number
  // [key: string]: string | number | boolean | (string | number | boolean)[] ;
}

// Navigation Types

// https://github.com/react-navigation/react-navigation/issues/9037

// MainNavigator (createMainStackNavigator)
// Intro
// Notifications
// Login
// Privacy Policy
// Terms And Conditions
// Update
// Plans
// Language Chooser
// EtymologyExplorer (EtymologyNavigator, createEtyStackNavigator)
// Word (TabNavigator, createBottomTabNavigator)
// Kin
// Tree
// Detail
// Menu
// 10+ options

// Route Params
export type TabRouteParams = {
  tabId: string
  type?: ComponentOption
  group?: string
  notificationId?: number // only if navigation is from a notification
}

type StackEtymologyType = {
  stackWord: string
  stackLanguage: string
  stackId: string
}

export type ComponentOption = 'Kin' | 'Detail' | 'Tree' | 'Progeny'
export type MainStackParamList = {
  EtymologyExplorer:
    | undefined
    | (StackEtymologyType & {
        screen: ComponentOption
        params: TabRouteParams
      })
  Intro: undefined
  Login: undefined | { firstLoad: boolean }
  'Language Chooser': undefined
  Menu: undefined
  Notifications: undefined
  Plans: undefined
  'Privacy Policy': undefined
  'Terms And Conditions': undefined
  'Version Notes': undefined
}

export type MenuStackParamList = {
  Account: undefined
  About: undefined
  'Developer Options': undefined
  Feedback: undefined
  'Language Chooser': undefined
  Legal: undefined
  Likes: undefined
  Menu: undefined
  Notifications: undefined
  Plans: undefined
  'Privacy Policy': undefined
  'Refund Policy': undefined
  'Version Notes': undefined
  Settings: undefined
  'Terms And Conditions': undefined
  'License And Attribution': undefined
  'Update Email': undefined
  'Words of the Day': undefined
}

export type EtymologyStackParamList = {
  Word: StackEtymologyType & {
    screen?: ComponentOption
    params?: TabRouteParams
  }
}

export type WordTabParamList = {
  Tree: undefined | TabRouteParams
  Progeny: undefined | TabRouteParams
  Detail: undefined | TabRouteParams
  Kin: undefined | TabRouteParams
}

// Layout Context
export type ScrollDictType = { [index: number]: number }

export type LeafIndicesType = { [rowIndex: string]: { [_id: string]: number } }

export type ArrowInfo = {
  rootX: number
  descX: number
  rootY: number
  descY: number
  rootId: string
  descId: string
  isAffix: boolean
}

export type ArrowPointPositions = { [rowIndex: string]: ArrowInfo[] }

// Miscellaneous
export type WhyDidYouRenderType = { whyDidYouRender?: boolean }

export type ParentProps = { children: ReactNode }

export type LoginOption = 'facebook' | 'google'

export type LeafSizeType = 'small' | 'medium' | 'large'

export type LeafTypeOption = 'main' | 'definition'

//https://stackoverflow.com/questions/60141960/typescript-key-value-relation-preserving-object-entries-type
//When you want to pair each key with something dependent on that key's type, use a mapped type:
export type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

// export type EmailErrorMsgReducerType = (
//     state?: string,
//     error: { message: string}
// )

// Newspaper pronunciation
export type NewspaperRespellingType = {
  newspaper?: string
  missing_letters: string[]
}

export type FeedbackType = 'bug' | 'feedback' | 'feature request' | 'question'

export type FeedbackItemType = {
  email?: string
  feedback?: string
  comments?: { [comment_id: string]: FeedbackCommentType }
  creation?: number
  lastUpdate?: number
  type: FeedbackType
  uid?: string
  response?: string
  feedback_id: string
  resolved?: boolean
  createdByAdmin?: boolean
}
export type FeedbackCommentType = {
  commentor?: string // admin || $uid
  comment?: string
  timestamp?: number
  comment_id?: string
}

// Type Guards
// export const isKinBranchOption = (node: string | KinGroupOption): node is KinBranchOption => {
//     return KIN_BRANCH_OPTIONS.includes(node as KinBranchOption)
// }
// export const isKinGroupOption = (node: string | KinGroupOption): node is KinGroupOption => {
//     return KIN_GROUP_OPTIONS.includes(node as KinGroupOption)
// }

// export type VisxProgenyTreeNode = {
//   name: string
//   children?: VisxProgenyTreeNode[]
//   // progeny?: EtymologyObjectDict
// }

export type Bounds = {
  xMin: number
  xMax: number
  yMin: number
  yMax: number
}

export type LanguageDictType = {
  [languageCode: string]: string
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>> //  OnlyNameIsMandatory = PartialBy<Person, 'nickname'|'hometown'>

// react-router-dom helpers
export type LocationState = { from: { pathname: string }; search: string }
export type HistoryState = object

//https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
export type PartialNull<T> = {
  [Property in keyof T]: T[Property] | null
}

//https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
// Turns all properties into strings (objects handled recursively), Helpful for autocomplete
export type RecursiveStringProperties<T> = {
  [P in keyof T]-?: T[P] extends boolean | string | number
    ? string
    : RecursiveStringProperties<T[P]>
}

export type StringKeys<T> = Extract<keyof T, string>

export type Reducer<S, A> = (state: S, action: A) => S

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

export type IdsApiIdentifier = { ids: string }
export type WordApiIdentifier = { word: string; language?: string }
export type IdApiIdentifier = { id: string }
export type APIIdentifier =
  | IdsApiIdentifier
  | WordApiIdentifier
  | IdApiIdentifier

export const isIdsIdentifier = (i: APIIdentifier): i is IdsApiIdentifier =>
  'ids' in i
export const isWordIdentifier = (i: APIIdentifier): i is WordApiIdentifier =>
  'word' in i && 'language' in i
export const isIdIdentifier = (i: APIIdentifier): i is IdApiIdentifier =>
  'id' in i

export type ParamsDict = { [key: string]: unknown }

export type NonNullableProperties<T> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

export type Dict<T, K extends string = string> = { [id in K]: T | undefined }
