import { query as databaseQuery, ref, get } from 'firebase/database'
import {
  collection,
  getDocs,
  query as firestoreQuery,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import {
  PlanProductIdOption,
  PromotionDataType,
  SubscriptionTier,
  SubscriptionType,
} from 'types/backend-types'
import {
  Payment,
  ProdPriceDict,
  Subscription,
  SubscriptionProductOptionDict,
} from 'types/stripe-types'
import { database, firestore, functions } from 'utils/firebase'
import {
  FirebaseSubscriptionPriceId,
  FIREBASE_SUBSCRIPTION_INFO_DICT,
  FIRESTORE_PAYMENTS_PATH,
  FIRESTORE_SUBSCRIPTIONS_PATH,
  FIRESTORE_USER_PATH,
} from './constants'

export const getSubscriptionProducts =
  async (): Promise<SubscriptionProductOptionDict> => {
    // get highest level snapshot, of all products
    const q = firestoreQuery(
      collection(firestore, 'products'),
      where('active', '==', true)
    )
    const productsSnapshot = await getDocs(q)

    // get next level snapshots, of all prices under a product
    const priceSnapshots = await Promise.all(
      productsSnapshot.docs.map((doc) =>
        getDocs(
          firestoreQuery(
            collection(doc.ref, 'prices'),
            where('active', '==', true)
          )
        )
      )
    )

    // combine prices and products into lookup dict
    const priceDocDict: ProdPriceDict = priceSnapshots.reduce(
      (prodAccu, snapshot) => ({
        ...prodAccu,
        // This gets the product, since it is the parent(price)'s parent ({prod_id}) : snapshot.docs[0].ref.parent.parent?.id
        [snapshot.docs[0].ref.parent.parent?.id ?? 'unknown']:
          snapshot.docs.reduce(
            (priceAccu, doc) => ({ ...priceAccu, [doc.id]: doc.data() }),
            {}
          ),
      }),
      {}
    )

    // Combine into the subscription price
    const subscriptionProducts: SubscriptionProductOptionDict =
      productsSnapshot.docs.reduce(
        (accu, productDoc) => ({
          ...accu,
          [productDoc.id]: {
            ...productDoc.data,
            prices: priceDocDict[productDoc.id],
          },
        }),
        {}
      )

    return subscriptionProducts
  }

export const getActiveFirestoreSubscriptions = async (
  uid: string
): Promise<Subscription[]> => {
  const subscriptionsRef = collection(
    firestore,
    FIRESTORE_USER_PATH,
    uid,
    FIRESTORE_SUBSCRIPTIONS_PATH
  )
  const activeSubscriptionQuery = firestoreQuery(
    subscriptionsRef,
    where('status', 'in', ['trialing', 'active'])
  )
  const activeSubscriptions: Subscription[] = await getDocs(
    activeSubscriptionQuery
  ).then((snapshot) => snapshot.docs.map((doc) => doc.data() as Subscription))

  return activeSubscriptions
  //   // In this implementation we only expect one active or trialing subscription to exist.
  // .onSnapshot((snapshot) => {
  //   const doc = snapshot.docs[0];
  //   console.log(doc.id, ' => ', doc.data());
  // });
}

export const getSuccessfulFirestoreOneTimePayments = async (
  uid: string
): Promise<Payment[]> => {
  const paymentsRef = collection(
    firestore,
    FIRESTORE_USER_PATH,
    uid,
    FIRESTORE_PAYMENTS_PATH
  )
  const activeOneTimePaymentQuery = firestoreQuery(
    paymentsRef,
    where('status', '==', 'succeeded')
  )
  const activeOneTimePayments: Payment[] = await getDocs(
    activeOneTimePaymentQuery
  ).then((snapshot) => snapshot.docs.map((doc) => doc.data() as Payment))

  return activeOneTimePayments
}

export const redirectToCustomerPortal = async () => {
  type RequestData = {
    returnUrl: string
    locale?: string
  }
  type ResponseData = { url: string }
  const functionRef = httpsCallable<RequestData, ResponseData>(
    functions,
    'ext-firestore-stripe-payments-createPortalLink'
  )
  const { data } = await functionRef({
    returnUrl: window.location.href,
    locale: 'auto', // Optional, defaults to "auto"
    // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
  })
  console.log('data', data)
  window.location.assign(data.url)
}

export const getPriceIdFromDetails = (
  tier: SubscriptionTier,
  interval: Omit<SubscriptionType, 'free'>
): FirebaseSubscriptionPriceId => {
  const [priceId] =
    Object.entries(FIREBASE_SUBSCRIPTION_INFO_DICT).find(
      ([, v]) => v.tier === tier && v.interval === interval
    ) ?? []
  if (!priceId)
    throw new Error(`Didn't find a priceId for ${tier}, ${interval}`)
  return priceId as FirebaseSubscriptionPriceId
}

export const areSamePlanTypes = (
  plan1?: PlanProductIdOption,
  plan2?: PlanProductIdOption
) => {
  const bothFree =
    (!plan1 || plan1.includes('free')) && (!plan2 || plan2.includes('free'))
  if (bothFree) return true

  if (!plan1 || !plan2) return false
  const bothVanguard = plan1.includes('vanguard') && plan2.includes('vanguard')
  // Avoid free
  const bothPremium =
    plan1 !== 'free' &&
    !plan1.includes('vanguard') &&
    plan2 !== 'free' &&
    !plan2.includes('vanguard')
  const sameTier = bothVanguard || bothPremium

  // const sameInterval = plan1.includes('lifetime') && plan2.includes('lifetime') ||
  //     ( plan1.includes('annual') &&  plan2.includes('annual')) ||
  //     ( plan1.includes('monthly') && plan2.includes('monthly'))
  return sameTier
}

export const getFirebasePromotions = async (): Promise<PromotionDataType[]> => {
  const results = await get(databaseQuery(ref(database, '/promotions/'))).then(
    (snapshot) => Object.values(snapshot.val()) as PromotionDataType[]
  )
  return results
}

export const isOneTimePaymentPlan = (plan: PlanProductIdOption | undefined) =>
  plan?.includes('lifetime') ?? false
