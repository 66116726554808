import { useAlertDialog } from 'dialog/hooks'
import { onAuthStateChanged, signInAnonymously, User } from 'firebase/auth'
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useQueryClient } from 'react-query'
import { auth } from 'utils/firebase'
import {
  AuthStoreContext,
  INITIAL_BOOLEANS,
  INITIAL_STRINGS,
} from './constants'
import {
  handleFormErrors,
  handleHttpErrors,
  login,
  register,
  resetPassword,
  storeIdToken,
} from './functions'
import { FormValues } from './types'

export const useAuthStore = () => useContext(AuthStoreContext)

export const useLogout = () => {
  const queryClient = useQueryClient()
  const logout = () => {
    auth.signOut()
    queryClient.clear()
    queryClient.invalidateQueries()
  }
  return logout
}

export const useLoginForm = () => {
  // const showSnackbar = useShowSnackbar()
  const { openAlert } = useAlertDialog()
  const [values, setValues] = useState<FormValues<string>>({
    ...INITIAL_STRINGS,
  })
  const [touched, setTouched] = useState<FormValues<boolean>>({
    ...INITIAL_BOOLEANS,
  })
  const [show, setShow] = useState<FormValues<boolean>>({ ...INITIAL_BOOLEANS })
  const [wasSubmitted, setWasSubmitted] = useState(false)

  const [errors, setErrors] = useState<FormValues<string>>({
    ...INITIAL_STRINGS,
  })

  const displayErrorMessage = {
    email: (wasSubmitted || touched.email) && errors.email,
    password: (wasSubmitted || touched.password) && errors.password,
    password_confirm:
      (wasSubmitted || touched.password_confirm) && errors.password_confirm,
  }

  useEffect(() => {
    setErrors(handleFormErrors(values))
  }, [values])

  const onSubmitForm = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setWasSubmitted(true)
      const formData = new FormData(event.currentTarget)
      const fieldValues = Object.fromEntries(formData.entries())
      const formIsValid = Object.keys(fieldValues).every(
        (name) => errors[name as keyof FormValues<unknown>] === ''
      )
      if (!formIsValid) {
        openAlert({
          title: 'Authorization Error',
          subtitle: 'Please correct issues to continue',
          cancelText: null,
        })
      } else {
        login(
          fieldValues.email as string,
          fieldValues.password as string
        ).catch((err) => {
          const error = handleHttpErrors(err)
          setErrors((e) => ({ ...e, ...error }))
        })
      }
    },
    [errors, openAlert]
  )

  const handleChange =
    (key: keyof FormValues<unknown>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValues((v) => ({ ...v, [key]: event.target.value }))
    }

  const handleTouch = (key: keyof FormValues<unknown>) => () => {
    setTouched((v) => ({ ...v, [key]: true }))
  }

  const handleShow = (key: keyof FormValues<unknown>) => () => {
    setShow((v) => ({ ...v, [key]: !v[key] }))
  }

  const handleForgotPassword = useCallback(
    () =>
      resetPassword(values.email)
        .then(() =>
          openAlert({
            title: 'Email sent',
            subtitle:
              'An email has been sent to this email address with instructions for resetting your password.',
          })
        )
        .catch((err) => {
          const error = handleHttpErrors(err)
          setErrors((e) => ({ ...e, ...error }))
        }),
    [openAlert, values.email]
  )

  return {
    values,
    setValues,
    touched,
    setTouched,
    show,
    setShow,
    errors,
    setErrors,
    displayErrorMessage,
    onSubmitForm,
    handleChange,
    handleTouch,
    handleShow,
    handleForgotPassword,
  }
}

export const useRegisterForm = () => {
  const { openAlert } = useAlertDialog()
  const [values, setValues] = useState<FormValues<string>>({
    ...INITIAL_STRINGS,
  })
  const [touched, setTouched] = useState<FormValues<boolean>>({
    ...INITIAL_BOOLEANS,
  })
  const [show, setShow] = useState<FormValues<boolean>>({ ...INITIAL_BOOLEANS })
  const [wasSubmitted, setWasSubmitted] = useState(false)

  const [errors, setErrors] = useState<FormValues<string>>({
    ...INITIAL_STRINGS,
  })

  const displayErrorMessage = {
    email: (wasSubmitted || touched.email) && errors.email,
    password: (wasSubmitted || touched.password) && errors.password,
    password_confirm:
      (wasSubmitted || touched.password_confirm) && errors.password_confirm,
  }

  useEffect(() => {
    setErrors(handleFormErrors(values))
  }, [values])

  const onSubmitForm = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setWasSubmitted(true)
      const formData = new FormData(event.currentTarget)
      const fieldValues = Object.fromEntries(formData.entries())
      const formIsValid = Object.keys(fieldValues).every(
        (name) => errors[name as keyof FormValues<unknown>] === ''
      )
      if (!formIsValid) {
        openAlert({
          title: 'Authorization Error',
          subtitle: 'Please correct issues to continue',
          cancelText: null,
        })
      } else {
        register(
          fieldValues.email as string,
          fieldValues.password as string
        ).catch((err) => {
          // console.log('err', err.message, err.code, err)
          if (
            err.message === 'User already exists' ||
            err.code === 'auth/email-already-in-use'
          ) {
            setErrors((err) => ({
              ...err,
              email: 'Account already exists. Try logging in.',
            }))
          }
        })
      }
    },
    [errors, openAlert]
  )

  const handleChange =
    (key: keyof FormValues<unknown>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValues((v) => ({ ...v, [key]: event.target.value }))
    }

  const handleTouch = (key: keyof FormValues<unknown>) => () => {
    setTouched((v) => ({ ...v, [key]: true }))
  }

  const handleShow = (key: keyof FormValues<unknown>) => () => {
    setShow((v) => ({ ...v, [key]: !v[key] }))
  }

  return {
    values,
    setValues,
    touched,
    setTouched,
    show,
    setShow,
    errors,
    setErrors,
    displayErrorMessage,
    onSubmitForm,
    handleChange,
    handleTouch,
    handleShow,
  }
}

export const useFirebaseAuthListener = () => {
  const [user, setUser] = useState<User | null>()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser === null) {
        signInAnonymously(auth).then((cred) => {
          setUser(cred.user)
          storeIdToken(cred.user) // for as fast as possible access
          console.log('anonymous sign in', cred)
        })
      } else {
        setUser(firebaseUser)
        storeIdToken(firebaseUser) // for as fast as possible acces
      }
    })
    return unsubscribe
  }, [])

  return user
}
