import {
  AutocompleteResponseType,
  ConnectionDetailsResponseType,
  ConnectionResponseType,
  DetailResponseType,
  EtymologyObject,
  ModifyConnectionReturnType,
  ProgenyTableResponseType,
  ProgenyTreeResponseType,
  RandomEtymologyResponseType,
  TreeResponseType,
} from './types'

export enum QueryKey {
  CONNECTION_DETAILS = 'connection-details',
  TREE = 'tree',
  PROGENY_TREE = 'progeny-tree',
  PROGENY_TABLE = 'progeny-table',
  KIN_TREE = 'kin-tree',
  KIN_PROGENY = 'kin-progeny',
  CONNECTIONS = 'connections',
  DETAIL_PAGE = 'detail-page',
  DETAIL_PREVIEW = 'detail-preview',
  PRONUNCIATION = 'pronunciation',
}

export const UNKNOWN_ID = '4085'

export const UNKNOWN_ETYMOLOGY_OBJECT: EtymologyObject = {
  _id: UNKNOWN_ID,
  word: 'Unknown',
  language_name: 'Unknown',
}

export enum Resource {
  GET_TREES = 'get_trees',
  GET_CONNECTIONS = 'get_connections',
  GET_CONNECTED_PROGENY = 'get_connected_progeny',
  GET_PROGENY = 'get_progeny',
  GET_DETAILS = 'get_details',
  POST_ADD_CONNECTION = 'post_add_connection',
  POST_DELETE_CONNECTION = 'post_delete_connection',
  GET_RANDOM_ETYMOLOGY = 'random_etymology',
  GET_AUTOCOMPLETE = 'autocomplete',
  GET_CONNECTION_DETAILS = 'get_connection_details',
  GET_PRONUNCIATION = 'get_pronunciation',
}

//TODO: Add a resource request type
export type ResourceResponseTypes = {
  [Resource.GET_TREES]: TreeResponseType
  [Resource.GET_CONNECTIONS]: ConnectionResponseType
  [Resource.GET_CONNECTED_PROGENY]: ProgenyTreeResponseType
  [Resource.GET_PROGENY]: ProgenyTableResponseType
  [Resource.GET_DETAILS]: DetailResponseType
  [Resource.POST_ADD_CONNECTION]: ModifyConnectionReturnType
  [Resource.POST_DELETE_CONNECTION]: ModifyConnectionReturnType
  [Resource.GET_RANDOM_ETYMOLOGY]: RandomEtymologyResponseType
  [Resource.GET_AUTOCOMPLETE]: AutocompleteResponseType
  [Resource.GET_CONNECTION_DETAILS]: ConnectionDetailsResponseType
  [Resource.GET_PRONUNCIATION]: Blob
}
