import { HierarchyNode } from 'd3-hierarchy'
import { EnvironmentKey, APIIdentifier } from 'utils/types'
import { QueryKey } from './constants'
import { ConnectionDirection } from 'pathManager/types'
import {
  AutoCompleteAPILanguageItemType,
  AutoCompleteAPIType,
  AutoCompleteAPIWordItemType,
  BaseEtymology,
} from 'types/backend-types'

export type LoadingStatus = 'not_initialized' | 'loading' | 'success' | 'error'

// Query types

export type CacheKey = string | undefined
export type LanguageKey = string

export type ConnectionDetailsKeyType = [
  typeof QueryKey.CONNECTION_DETAILS,
  string?,
  string?
]
export type ProgenyTableQueryKey = [typeof QueryKey.PROGENY_TABLE, CacheKey]
export type ProgenyTreeQueryKey = [
  typeof QueryKey.PROGENY_TREE,
  CacheKey,
  LanguageKey
]
export type TreeQueryKey = [typeof QueryKey.TREE, CacheKey]
export type KinTreeQueryKey = [typeof QueryKey.KIN_TREE, CacheKey]
export type KinProgenyQueryKey = [typeof QueryKey.KIN_PROGENY, CacheKey]
export type ProgenyQueryKey = [typeof QueryKey.PROGENY_TREE, CacheKey]
export type DetailPageQueryKey = [typeof QueryKey.DETAIL_PAGE, CacheKey]
export type ConnectionsKeyType = [
  typeof QueryKey.CONNECTIONS,
  CacheKey,
  ConnectionDirection
]
export type PronunciationKeyType = [
  typeof QueryKey.PRONUNCIATION,
  string,
  string
]

// EtymologyObject
export type EtymologyObject = {
  _id: number | string
  word: string
  language_name: string
  frequencies?: number
  common_descendants?: CommonDescendantDictType
  entries?: EtymologyEntryDictType
  // definition?: string
  relations?: RelationsType
  progeny_count?: number
  /**The frequency as a log with all descendants included */
  frequencies_log?: number
}

export type EtymologyObjectDict = { [id: string]: EtymologyObject | undefined }
export type EtymologyEntryDictType = { [entry_id: string]: EtymologyEntryType }
export type EtymologyEntryType = {
  entry_id?: number
  entry_number?: number
  etymology?: string
  linked_etymology?: string
  pronunciation?: string
  pronunciations?: string[]
  pos?: POSDictType
}
export type POSDictType = { [key: string]: POSType }
export type POSType = {
  pos_id?: number
  pos_name?: string
  definitions?: string[]
}
export type CommonDescendantDictType = { [key: string]: CommonDescendant }
export type CommonDescendant = {
  progeny: number
  progeny_word: string
  progeny_language: string
  quality: number
}

export type RelationsType = {
  [group: string]: number // group to similarity
}

export type ApiHelperParams = { channel?: EnvironmentKey }

export type ApiParams = ApiHelperParams & {
  identifier: APIIdentifier
}

export type ApiTreeParams = ApiParams & {
  all_entry_numbers?: boolean
  compression?: number
  allow_duplicates?: string
  entry_number?: number
}

export type ApiConnectedProgenyTreeParams = ApiTreeParams & {
  target_language: string
}

export type ApiConnectionsParams = ApiParams & {
  direction: ConnectionDirection
}

// Component Data Content
export type ComponentId2ProgenyGroupType = { [componentId: string]: string }

export type ComponentData =
  | DetailDataType
  | TreeDataType
  | KinTreeDataType
  | ProgenyTreeDataType
  | ProgenyTreeDataType
  | ConnectionsDataType
// | ProgenyPackData

// Real values are defined in MainNavigator. This is like the type
// Similar but different from ComponentData
export type APIReturnType =
  | TreeDataType
  | DetailDataType
  | ProgenyPackData
  | ProgenyTreeDataType
  | KinProgenyDataType
  | KinTreeDataType

export type NestedTreeType = { [node: string]: NestedTreeType }
export type TreeType = string[][]

export type MultiDetailDataType = {
  etymologyObjects: EtymologyObject[]
  type: 'multi-detail'
}

export type DetailDataType = {
  etymologyObject: EtymologyObject
  type: 'detail'
}
export type ConnectionType = { descendant: string; root: string }
export type ConnectionSimple = [number, number]
export type ConnectionList = ConnectionSimple[]
export type ConnectionDict = { [desc: string]: string[] } // desc to root

export type TreeDataType = {
  trunkObject: EtymologyObject
  tree: TreeType
  nestedTree: NestedTreeType
  connections: ConnectionType[]
  etymologyObjects: EtymologyObjectDict
  affixes: string[]
  type: 'tree'
}

export type ProgenyPackData = {
  trunkObject: EtymologyObject
  etymologyObjects: EtymologyObjectDict
  nestedTree: NestedTreeType
  type: 'progeny-pack'
}

export type KinProgenyDataType = {
  [rootId: string]: {
    etymologyObjects: EtymologyObjectDict
    nestedTree: NestedTreeType
  }
}

export type ProgenyTreeDataType = {
  trunkObject: EtymologyObject
  etymologyObjects: EtymologyObjectDict
  nestedTree: NestedTreeType
  connections: ConnectionSimple[]
  groupData: GroupDataType
  type: 'progeny-tree'
}

export type ConnectionsDataType = {
  trunkObject: EtymologyObject
  etymologyObjects: EtymologyObjectDict
  connection_items: ConnectionItem[]
  type: 'connections'
}

export type KinTreeDataType = {
  trunkObject: EtymologyObject
  nestedTree: NestedTreeType
  treeLayers: TreeType
  progeny: KinProgenyDataType
  etymologyObjects: EtymologyObjectDict
  type: 'kin'
}

export type KinItemType = {
  branch_number: string
  branch: KinBranchLetterOption
  groups: string[]
  progeny_count: number
}

export type PositionType = { x?: number; y?: number }
export type KinBranchLetterOption = 'A' | 'B' | 'C'

export type KinPositionDataType = {
  height: number
  initialHeight: number
  groups: { [node: string]: PositionType }
  leafs: { [node: string]: PositionType }
}

export type KinItemDictType = { [id: string]: KinItemType }

export type KinGroupType = {
  ancestor: string
  definition: string
  ancestor_group: string
  progeny_count: number
}
export type KinGroupDictType = { [group: string]: KinGroupType }

export type KinSimilaritiesType = { [node: string]: number[] }

export type KinDataType = {
  mainId: string
  r_2_nodes: { [id: string]: string[] }
  kinList: KinItemDictType
  groups: KinGroupDictType
  etymologyObjects: EtymologyObjectDict
}

export type ConnectionSourceType = 'etymology' | 'definition' | 'manual'

export type ConnectionSourceDetails = {
  table_source: number
  entry_number: number
  confidence: number
  type: ConnectionSourceType
}

export type ConnectionOtherDetails = {
  desc_word: string
  desc_lang: string
  desc_id: string
  root_word: string
  root_lang: string
  root_id: string
  deployed_to_dev: boolean
  deployed_to_staging: boolean
  deployed_to_prod: boolean
  last_edit_days_since_1_1_21: number
  desc_affix_id: number | null
}
export type ConnectionItem = ConnectionSourceDetails & ConnectionOtherDetails

export type AggregatedConnectionItem = ConnectionOtherDetails & {
  sources: ConnectionSourceDetails[]
}

export type GroupDataType = { [group_id: string]: string }
export type EnhancedGroupDataType = {
  [group_id: string]: { definition: string; color: string }
}

export type ProgenyTableDataType = {
  connections?: ConnectionType[]
  trunkObject: EtymologyObject
  groupData: GroupDataType
  etymologyObjects: EtymologyObjectDict
  type: 'progeny-table'
}

export type ProgenyFilterOption = 'group' | 'language' | 'none'

export type KinProgenyDataQueryKey = ['kinProgeny', string | undefined]

export type ProgenyTableResponseType = {
  id: string
  groups: GroupDataType
  progeny: {
    words: EtymologyObjectDict
  }
  root: {
    words: EtymologyObjectDict
  }
}

export type ProgenyTreeResponseType = {
  id: string
  groups: GroupDataType
  progeny: {
    words: EtymologyObjectDict
  }
  root: {
    words: EtymologyObjectDict
  }
  tree: NestedTreeType
  connections: ConnectionList
}

export type RandomEtymologyTypeType = 'child' | 'root' | 'any' // for get_random_etymology
export type RandomEtymologyType = BaseEtymology & {
  type: number // 0, 1 ,2 ?
  quality: number // 0 or 1
}

export type TreeResponseType = [
  'tree',
  { words: EtymologyObjectDict; affixes: number[] },
  NestedTreeType,
  ConnectionList
]

export type ConnectionItemType =
  | EtymologyConnectionItemType
  | DefinitionConnectionItemType

export type EtymologyConnectionItemType = {
  type: 'etymology'
  descendant: string
  root: string
  etymology: string
  source_word: string
  source_language_name: string
  source_id: string
}
export type DefinitionConnectionItemType = {
  type: 'definition'
  descendant: string
  root: string
  definitions: string[]
}

export type ConnectionDetailsDataType = {
  descendant: EtymologyObject
  root: EtymologyObject
  connection_items: ConnectionItemType[]
}

export type DetailResponseType = { words: EtymologyObjectDict }

export type AutocompleteResponseType = {
  auto_complete_data: AutoCompleteAPIType[]
  order: number
  search_term: string
}

export const isAutoCompleteAPIWordItemType = (
  type: AutoCompleteAPIType
): type is AutoCompleteAPIWordItemType => 'word' in type
export const isAutoCompleteAPILanguageItemType = (
  type: AutoCompleteAPIType
): type is AutoCompleteAPILanguageItemType => 'searchName' in type

export type RandomEtymologyResponseType = BaseEtymology & {
  type: number
  quality: number
}

export type ConnectionDetailsResponseType = {
  descendant: EtymologyObject
  root: EtymologyObject
  connection_items: ConnectionItemType[]
}

export type ConnectionResponseType = {
  trunkId: string
  words: EtymologyObjectDict
  connection_items: ConnectionItem[]
}

export type ModifyConnectionReturnType = {
  delete: {
    [key: string]: {
      connection_sources: ConnectionSourceItem[]
      connections: { root: number; descendant: number }[]
      progeny: { id: number; progeny: number; quality: number }[]
    }
  }
  add: {
    [key: string]: {
      connection_sources: ConnectionSourceItem[]
      connections: { root: number; descendant: number }[]
      progeny: { id: number; progeny: number; quality: number }[]
    }
  }
  modify: {
    [key: string]: {
      connection_sources: ConnectionSourceItem[]
      connections: { root: number; descendant: number }[]
      progeny: { id: number; progeny: number; quality: number }[]
    }
  }
}

export type ConnectionSourceItem = {
  root: number
  descendant: number
  table_source: number | null
  entry_number: number
  type: ConnectionSourceType
  confidence: number
}

export type ConnForDetails = {
  root: string
  descendant: string
}

export type VisxProgenyPackNode = BaseEtymology & {
  frequencies: number
  showText: boolean
  isLessFrequentChild: boolean // if any of its parents are more frequent than the word
  children: VisxProgenyPackNode[]
  isRoot?: boolean // helper for a circle at the center, need thhis to be dead center
}

export type VisxRootTreeNode = BaseEtymology & {
  name: string
  definition?: string
  isExpanded?: boolean
  frequency?: number
  children?: VisxRootTreeNode[]
  progeny?: NestedTreeType
  progenyEtymologyObjects?: EtymologyObjectDict
}

// D3 Extension Types
export type TreeHierarchyLink<T> = {
  source: TreeHierarchyNode<T>
  target: TreeHierarchyNode<T>
}

export type TreeHierarchyNode<T> = HierarchyNode<T> & {
  index?: number | undefined
  /**
   * Node’s current x-position
   */
  x?: number | undefined
  /**
   * Node’s current y-position
   */
  y?: number | undefined
  /**
   * Node’s current x-velocity
   */
  vx?: number | undefined
  /**
   * Node’s current y-velocity
   */
  vy?: number | undefined
  /**
   * Node’s fixed x-position (if position was fixed)
   */
  fx?: number | null | undefined
  /**
   * Node’s fixed y-position (if position was fixed)
   */
  fy?: number | null | undefined
  /**
   * The node's radius
   */
  radius?: number | null | undefined
  /**
   * Node's language matches selected language and more logic
   */
  isHighlightedLanguagedNode?: boolean
}

export type Node = VisxRootTreeNode & {
  x?: number
  y?: number
}

export enum WordStrata {
  CHILD = 'child',
  ROOT = 'root',
  ANY = 'any',
}
