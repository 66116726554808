import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useDispatchDialog } from './hooks'
import { useCallback, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { useAuthStore } from 'auth/hooks'
import Checkbox from '@mui/material/Checkbox'
import { useUpdateSettings } from 'utils/settings'
import { useTheme } from '@mui/material/styles'
import { Spacer } from 'components/Spacer'

export interface ThemeDialogProps {
  open: boolean
}

const InvitationDialog = ({ open }: ThemeDialogProps) => {
  const dispatch = useDispatchDialog()
  const theme = useTheme()
  const [wotdSelected, setWotdSelected] = useState(true)
  const [pieSelected, setPieSelected] = useState(true)
  const [email, setEmail] = useState<string>('')
  const { mutateAsync: mutateSettingsAsync } = useUpdateSettings()

  const { user } = useAuthStore()

  const handleCancel = () => {
    dispatch({ type: 'close', payload: 'invitation' })
  }

  const handleSubscribe = useCallback(() => {
    // if (user){
    mutateSettingsAsync({
      weeklyEmailsEnabled: pieSelected,
      wotdEmailsEnabled: wotdSelected,
      email,
    })
    dispatch({ type: 'close', payload: 'invitation' })
  }, [dispatch, email, mutateSettingsAsync, pieSelected, wotdSelected])

  useEffect(() => {
    setEmail(user?.email ?? '')
  }, [user])

  return (
    <Dialog onClose={handleCancel} open={open}>
      <DialogTitle color={theme.palette.primary.main}>
        Don{"'"}t miss an etymology!
      </DialogTitle>
      <DialogContent>
        <Typography marginBottom={2}>
          Join our WOTD email list to discover new etymologies each day. Or join
          our weekly dive deep into Proto-Indo-European roots and their modern
          descendants
        </Typography>
        {/* <Typography>Discover etymologies each day</Typography> */}
        <div
          onClick={() => setWotdSelected((w) => !w)}
          style={{ cursor: 'pointer' }}
        >
          <Stack direction="row" alignItems="center">
            <Checkbox
              checked={wotdSelected}
              onChange={(e, checked) => setWotdSelected(checked)}
            />
            <Typography>
              Join the <b>Word of the Day</b> email
            </Typography>
          </Stack>
        </div>
        {/* <Typography>
          Learn Proto-Indo-European roots, and their modern descendants
        </Typography> */}

        <div
          onClick={() => setPieSelected((w) => !w)}
          style={{ cursor: 'pointer' }}
        >
          <Stack direction="row" alignItems="center">
            <Checkbox
              checked={pieSelected}
              onChange={(e, checked) => setPieSelected(checked)}
            />
            <Typography>
              Join the <b>Weekly Slice of PIE</b> email (coming soon)
            </Typography>
          </Stack>
        </div>
        <Spacer vertical size={30} />
        <TextField
          label="Enter your email address"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          style={{ width: '100%' }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubscribe}
          variant="contained"
          disabled={(!wotdSelected && !pieSelected) || !email}
        >
          Subscribe
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InvitationDialog
