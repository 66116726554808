import { ref, update } from 'firebase/database'
import { deleteDoc, doc } from 'firebase/firestore'
import { auth, database, firestore } from 'utils/firebase'
import { isEmail } from 'utils/functions'
import { INITIAL_STRINGS } from './constants'
import { FormValues } from './types'
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInAnonymously,
  sendPasswordResetEmail,
  User,
} from 'firebase/auth'
import { setStorageItem, StorageItems } from 'utils/storage'
import { DateTime, Duration } from 'luxon'

export const register = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password)
}

export const login = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password)
}

export const resetPassword = (email: string) => {
  return sendPasswordResetEmail(auth, email)
}

export const anonymousLogin = () => {
  return signInAnonymously(auth)
}

export const deleteAllData = async (
  deleteUser = true
): Promise<void | null> => {
  const { currentUser } = auth
  const { uid } = currentUser ?? {}
  if (!uid) {
    console.warn('deleteAllData, No uid!')
    return
  }

  return update(ref(database), {
    [`/users/${uid}`]: {},
    [`/settings/${uid}`]: {},
    [`/likes/${uid}`]: {},
    [`/notifications/${uid}`]: {},
    [`/word_histories/${uid}`]: {},
  })
    .then(() => {
      deleteDoc(doc(firestore, 'users', uid))
    })
    .then(() => {
      if (deleteUser && currentUser) {
        currentUser.delete().catch((err) => {
          if (err.code === 'auth/requires-recent-login') {
            window.alert(
              'For your security, completing this action requires you to reauthenticate. Please log out and then log back in and then try again. Some of your data has already been deleted.'
            )
          }
        })
      }
    })
}

export const handleFormErrors = (
  values: FormValues<string>
): FormValues<string> => {
  const errors = { ...INITIAL_STRINGS }
  if (!isEmail(values.email)) {
    errors.email = 'Please enter a properly formatted email'
  }
  if (values.password !== values.password_confirm) {
    errors.password_confirm = 'Those passwords didn’t match. Try again.'
  }
  if (values.password.length < 8) {
    errors.password = 'Use 8 characters or more for your password'
  }
  return errors
}

export const handleHttpErrors = ({
  code,
  message,
}: {
  code: string
  message: string
}): Partial<FormValues<string>> =>
  message === 'Unauthorized'
    ? {
        email:
          'Unauthorized. Incorrect password or this account does not exist.',
      }
    : code === 'auth/wrong-password'
    ? {
        password: 'Incorrect password',
      }
    : code === 'auth/too-many-requests'
    ? {
        email:
          'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later',
      }
    : code === 'auth/user-not-found'
    ? {
        email: 'Could not find this user. Try creating it',
      }
    : code === 'auth/invalid-email'
    ? {
        email: 'This is not a valid email address. Please correct it',
      }
    : code === 'auth/missing-email'
    ? {
        email: 'Please enter a valid email address to reset the password',
      }
    : { email: `${code}: ${message}` }

export const signInWithGoogle = async () =>
  await signInWithPopup(auth, new GoogleAuthProvider())

export const signInWithFacebook = async () =>
  await signInWithPopup(auth, new FacebookAuthProvider())

export const signInWithApple = async () => {
  const provider = new OAuthProvider('apple.com')
  provider.addScope('email')
  provider.addScope('name')
  await signInWithPopup(auth, provider)
}

export const storeIdToken = (user: User) =>
  user
    .getIdTokenResult()
    .then(({ token }) => setStorageItem(StorageItems.FIREBASE_TOKEN, token))

export const recurringTokenRefresh = async () => {
  if (!auth.currentUser) {
    console.warn('no current user while attempting recurringTokenRefresh')
    return
  }

  const { token, expirationTime } = await auth.currentUser.getIdTokenResult()
  setStorageItem(StorageItems.FIREBASE_TOKEN, token)
  const DELAY = 4 * 60 * 1000
  const renewalTime =
    new Date(expirationTime).getTime() - new Date().getTime() - DELAY
  console.log(
    'recurringTokenRefresh expiration',
    DateTime.fromHTTP(expirationTime).toISO(),
    'renew in',
    Duration.fromMillis(renewalTime).as('minutes'),
    'minutes'
  )
  setTimeout(() => recurringTokenRefresh(), renewalTime)
  return token
}
