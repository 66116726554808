import CssBaseline from '@mui/material/CssBaseline'
import createTheme from '@mui/material/styles/createTheme'
import MuiThemeProvider from '@mui/system/ThemeProvider' // Must use this and not from @mui/material/styles
import {
  Children,
  createContext,
  ReactChild,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { getStorageItem, StorageItems, useStoreOnChange } from 'utils/storage'
import { DEFAULT_THEME_KEY, getDesignTokens, ThemeKey } from './constants'
import { useIsSystemDark } from './hooks'
import FontFaceObserver from 'fontfaceobserver'

type KeyStore = {
  chosenMode: ThemeKey
  mode: 'light' | 'dark'
}

type ColorModeDispatchType = {
  changeColorMode: (mode: ThemeKey) => void
}
const ColorModeDispatchContext = createContext<ColorModeDispatchType>({
  changeColorMode: () => undefined,
})
const ThemeKeyStore = createContext<KeyStore>({
  chosenMode: DEFAULT_THEME_KEY,
  mode: 'dark',
})

export const useColorModeDispatch = () => useContext(ColorModeDispatchContext)
export const useThemeKey = () => useContext(ThemeKeyStore)

const Controller = (props: { children: ReactChild }) => {
  const isSystemDark = useIsSystemDark()
  const [chosenMode, setChosenMode] = useState<ThemeKey>(
    getStorageItem(StorageItems.THEME) ?? 'system'
  )
  const mode: 'light' | 'dark' =
    chosenMode !== 'system' ? chosenMode : isSystemDark ? 'dark' : 'light'

  const changeColorMode = useCallback(
    (mode: ThemeKey) => setChosenMode(mode),
    []
  )

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  useStoreOnChange(StorageItems.THEME, chosenMode)

  return (
    <ColorModeDispatchContext.Provider value={{ changeColorMode }}>
      <ThemeKeyStore.Provider value={{ chosenMode, mode }}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <FontLoader>{Children.only(props.children)}</FontLoader>
        </MuiThemeProvider>
      </ThemeKeyStore.Provider>
    </ColorModeDispatchContext.Provider>
  )
}

const FontLoader = (props: { children: ReactChild }): JSX.Element => {
  const fontObserver = new FontFaceObserver('Roboto')
  fontObserver.load().then(() => {
    console.debug('done loading Roboto')
    document.body.classList.add('fontLoaded')
  })
  return <>{props.children}</>
}

export default Controller
