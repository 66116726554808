import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useOverlayDispatch } from 'overlay/Controller'

export const MenuButton = () => {
  const overlayDispatch = useOverlayDispatch()
  return (
    <Tooltip title="Menu">
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          overlayDispatch('main')
        }}
        style={{ padding: 0 }}
      >
        <MenuIcon />
      </IconButton>
    </Tooltip>
  )
}
