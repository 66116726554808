import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { GridColumns, GridValueGetterParams } from '@mui/x-data-grid'
import { useAuthStore } from 'auth/hooks'
import { useChannel } from 'channel'
import { getSimpleDefinition } from 'componentData/functions'
import {
  ConnectionItem,
  EtymologyObject,
  EtymologyObjectDict,
  PronunciationKeyType,
  WordStrata,
} from 'componentData/types'
import LanguageTag from 'components/LanguageTag'
import { LinearBar } from 'components/LinearBar'
import { Link } from 'components/Link'
import { useSearchLanguage } from 'contexts/SearchLanguageController'
import { useShowSnackbar } from 'contexts/SnackbarController'
import { Other } from 'pages/InfoPage'
import { getPathWordStrata, isWordComponent } from 'pathManager/functions'
import { usePathIdentifier, usePathParams } from 'pathManager/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DEFAULT_PATH, Path } from 'router/constants'
import { ElementIds } from 'theme/constants'
import Colors, { getLanguageColor } from 'utils/Colors'
import { ALL_LANGUAGES_STRING, MAX_DETAIL_HINT_PREFETCH } from 'utils/constants'
import {
  isTruthy,
  numberToOrdinal,
  onlyUnique,
  randomItem,
} from 'utils/functions'
import { common_lang_dict } from 'utils/lang_dict'
import { getStorageItem, StorageItems } from 'utils/storage'
import { APIIdentifier } from 'utils/types'
import { QueryKey } from './constants'
import {
  getConnectionDetails,
  getConnections,
  getDetails,
  getIdentifier,
  getIdentifierCache,
  getMultiDetails,
  getProgenyTable,
  getProgenyTree,
  getPronunciation,
  getRandomEtymology,
  getRootFrequencyPercentile,
  getTree,
  getWordFrequencyPercentile,
} from './functions'
import {
  ConnectionDetailsDataType,
  ConnectionDetailsKeyType,
  ConnectionsDataType,
  ConnectionsKeyType,
  DetailDataType,
  DetailPageQueryKey,
  ProgenyTableDataType,
  ProgenyTableQueryKey,
  ProgenyTreeDataType,
  ProgenyTreeQueryKey,
  TreeDataType,
  TreeQueryKey,
} from './types'
type TableRow = GridValueGetterParams<number, EtymologyObject>

export const useDetailHintTree = (
  id: string | undefined,
  rootId: string | undefined
) => {
  const { loggedIn } = useAuthStore()
  const { identifier, cacheIdentifier } = getIdentifier({ id })
  const { data: treeData, ...rest } = useQuery<
    TreeDataType | undefined,
    Error,
    TreeDataType | undefined,
    TreeQueryKey
  >(
    [QueryKey.TREE, cacheIdentifier],
    () => (identifier ? getTree({ identifier }) : undefined),
    {
      enabled: !!id && !!rootId && !!identifier && loggedIn,
    }
  )

  const treePath: string[] = useMemo(() => (rootId ? [rootId] : []), [rootId])
  let moreItems = false
  do {
    const mostRecentItem = treePath[0]
    const newItem = treeData?.connections.find(
      (c) => c.root === mostRecentItem
    )?.descendant
    newItem && treePath.unshift(newItem)
    moreItems = !!newItem
  } while (treePath.length && moreItems)

  const treeHint = useMemo(
    () =>
      treePath
        .map((p) => treeData?.etymologyObjects[p])
        .filter(isTruthy)
        .map((e) => ({
          id: e._id.toString(),
          word: e.word,
          language: e.language_name,
        })),
    [treeData?.etymologyObjects, treePath]
  )

  return { treeHint, ...rest }
}

export const useTree = () => {
  const queryClient = useQueryClient()
  const { loggedIn } = useAuthStore()
  const { identifier, cacheIdentifier } = usePathIdentifier()
  const {
    data: treeData,
    isLoading,
    isSuccess,
    isError,
  } = useQuery<
    TreeDataType | undefined,
    Error,
    TreeDataType | undefined,
    TreeQueryKey
  >(
    [QueryKey.TREE, cacheIdentifier],
    () => (identifier ? getTree({ identifier }) : undefined),
    {
      enabled: !!identifier && loggedIn,
      //Prefetch the details for detail hints
      onSuccess: (treeData) =>
        getMultiDetails({
          ids: Object.keys(treeData?.etymologyObjects ?? {}),
        }).then((results) =>
          results?.etymologyObjects?.forEach((etymologyObject) =>
            queryClient.setQueryData(
              [
                QueryKey.DETAIL_PREVIEW,
                getIdentifierCache({ ids: etymologyObject._id.toString() }),
              ],
              {
                etymologyObject,
                type: 'detail',
              }
            )
          )
        ),
    }
  )

  const returnData = useMemo(() => {
    // console.log('recalc returnData useTree')
    return { ...(treeData ?? {}), isLoading, isSuccess, isError }
  }, [isError, isLoading, isSuccess, treeData])

  return returnData
}

export const useDetailPage = (paramIdentifier?: APIIdentifier | null) => {
  const { loggedIn } = useAuthStore()
  const { identifier: pathIdentifier, cacheIdentifier: pathCacheIdentifier } =
    usePathIdentifier()
  const cacheIdentifier =
    paramIdentifier === null
      ? undefined
      : paramIdentifier === undefined
      ? pathCacheIdentifier
      : getIdentifierCache(paramIdentifier)
  const identifier =
    paramIdentifier === null
      ? undefined
      : paramIdentifier === undefined
      ? pathIdentifier
      : paramIdentifier

  const { data, ...rest } = useQuery<
    DetailDataType | undefined,
    Error,
    DetailDataType | undefined,
    DetailPageQueryKey
  >(
    [QueryKey.DETAIL_PAGE, cacheIdentifier],
    () => (identifier ? getDetails({ identifier }) : undefined),
    { enabled: !!identifier && loggedIn }
  )
  return {
    etymologyObject: data?.etymologyObject,
    ...(data?.etymologyObject ?? {}),
    ...rest,
  }
}

export const useProgenyTable = () => {
  const queryClient = useQueryClient()
  const { loggedIn } = useAuthStore()
  const showSnackbar = useShowSnackbar()
  const { identifier, cacheIdentifier } = usePathIdentifier()
  const {
    data: progenyTableData,
    isSuccess,
    ...rest
  } = useQuery<
    ProgenyTableDataType | undefined,
    Error,
    ProgenyTableDataType | undefined,
    ProgenyTableQueryKey
  >(
    [QueryKey.PROGENY_TABLE, cacheIdentifier],
    () => (identifier ? getProgenyTable({ identifier }) : undefined),
    {
      enabled: !!identifier && loggedIn,
      onSuccess: (progenyData) => {
        const partialDescendants = Object.values(
          progenyData?.etymologyObjects ?? {}
        )
          .filter(isTruthy)
          .sort((eoa, eob) => {
            const aIsEnglish = eoa.language_name === 'English'
            const bIsEnglish = eob.language_name === 'English'
            return aIsEnglish && !bIsEnglish
              ? 1
              : !aIsEnglish && bIsEnglish
              ? -1
              : eoa.word.localeCompare(eob.word)
          })
          .slice(0, MAX_DETAIL_HINT_PREFETCH)
        getMultiDetails({
          ids: partialDescendants.map((eo) => eo._id.toString()),
        }).then((results) =>
          results?.etymologyObjects?.forEach((etymologyObject) =>
            queryClient.setQueryData(
              [
                QueryKey.DETAIL_PREVIEW,
                getIdentifierCache({ ids: etymologyObject._id.toString() }),
              ],
              {
                etymologyObject,
                type: 'detail',
              }
            )
          )
        )
      },
    }
  )

  useEffect(() => {
    const loadedEtymologyObjects = Object.keys(
      progenyTableData?.etymologyObjects ?? {}
    ).length
    if (isSuccess && loadedEtymologyObjects > 0) {
      showSnackbar({
        message: `Loaded ${loadedEtymologyObjects} progeny`,
      })
    }
  }, [progenyTableData, isSuccess, showSnackbar])

  const { etymologyObjects, trunkObject, groupData } = progenyTableData ?? {}

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'language_name',
        headerName: 'Language',
        description: 'The language of the descendant',
        minWidth: 120,
        renderCell: (params: TableRow) => (
          <LanguageTag language={params.row.language_name} />
        ),
      },
      {
        field: 'word',
        headerName: 'Word',
        description: 'The word that is a descendant',
        minWidth: 150,
      },
      {
        field: 'definition',
        headerName: 'Definition',
        description: 'The definition of the descendant',
        width: 400,
        valueGetter: (params: TableRow) => getSimpleDefinition(params.row),
      },
      {
        field: 'word_frequency',
        headerName: 'Word frequency',
        width: 200,
        valueGetter: (params: TableRow) => params?.row?.frequencies ?? 0,
        renderHeader: () => (
          <Tooltip title="The frequency of use of this word">
            <div>
              Word frequency
              <Link to={`/${Path.INFO}#${Other.WORD_FREQUENCY}`}>
                <IconButton>
                  <InfoOutlinedIcon color="disabled" />
                </IconButton>
              </Link>
            </div>
          </Tooltip>
        ),
        renderCell: (params: TableRow) => {
          const wordFrequencyPercentile = getWordFrequencyPercentile(
            params.value
          )
          return (
            <Tooltip
              title={`"${params.row.word}" is ${numberToOrdinal(
                wordFrequencyPercentile
              )} percentile for frequency of use`}
            >
              <LinearBar
                variant="determinate"
                value={wordFrequencyPercentile}
                height={20}
                hideLabel
              />
            </Tooltip>
          )
        },
      },
      {
        field: 'root_frequency',
        headerName: 'Root impact',
        width: 200,
        renderHeader: () => (
          <Tooltip title="The frequency of use of this word's descendants">
            <div>
              Root impact
              <Link to={`/${Path.INFO}#${Other.ROOT_IMPACT}`}>
                <IconButton>
                  <InfoOutlinedIcon color="disabled" />
                </IconButton>
              </Link>
            </div>
          </Tooltip>
        ),
        valueGetter: (params: TableRow) => params.row.frequencies_log ?? 0,
        renderCell: (params: TableRow) => {
          const rootFrequencyPercentile = getRootFrequencyPercentile(
            params.value
          )
          return (
            <Tooltip
              title={`"${params.row.word}" is ${numberToOrdinal(
                rootFrequencyPercentile
              )} percentile for frequency of use of its descendants`}
            >
              <LinearBar
                variant="determinate"
                color="secondary"
                value={rootFrequencyPercentile}
                height={20}
                hideLabel
              />
            </Tooltip>
          )
        },
      },
      {
        field: 'group',
        width: 200,
        renderHeader: () => (
          <Tooltip title="The group of definitions this word fits in">
            <div>
              Group
              <Link to={`/${Path.INFO}#${Other.GROUPING}`}>
                <IconButton>
                  <InfoOutlinedIcon color="disabled" />
                </IconButton>
              </Link>
            </div>
          </Tooltip>
        ),
        valueGetter: (params: TableRow) => {
          const groupNumber = Object.keys(params.row.relations ?? {}).pop()
          const group = groupData?.[groupNumber ?? '']
          return group
        },
        renderCell: (params: TableRow) => {
          const groupNumber = Object.keys(params.row.relations ?? {}).pop()
          const group = groupData?.[groupNumber ?? '']
          return !group ? (
            <></>
          ) : (
            <Tooltip title={group}>
              <div>
                <LanguageTag language={group} />
              </div>
            </Tooltip>
          )
        },
      },
    ],
    [groupData]
  )

  const rows = useMemo(
    () =>
      Object.values(etymologyObjects ?? {})
        .filter(isTruthy)
        .map((e) => ({
          ...e,
          id: e._id,
        })),
    [etymologyObjects]
  )

  return { etymologyObjects, trunkObject, columns, rows, ...rest }
}

export const useProgenyTree = () => {
  const queryClient = useQueryClient()
  const { searchLanguage } = useSearchLanguage()
  const { loggedIn } = useAuthStore()
  const { identifier, cacheIdentifier } = usePathIdentifier()
  const { data: progenyTreeData, ...rest } = useQuery<
    ProgenyTreeDataType | undefined,
    Error,
    ProgenyTreeDataType | undefined,
    ProgenyTreeQueryKey
  >(
    [QueryKey.PROGENY_TREE, cacheIdentifier, searchLanguage],
    () =>
      identifier
        ? getProgenyTree({ identifier, target_language: searchLanguage })
        : undefined,
    {
      enabled: !!identifier && loggedIn,
      onSuccess: (progenyData) => {
        const partialDescendants = Object.values(
          progenyData?.etymologyObjects ?? {}
        )
          .filter(isTruthy)
          .sort((eoa, eob) => eoa.word.localeCompare(eob.word))
          .slice(0, MAX_DETAIL_HINT_PREFETCH)
        getMultiDetails({
          ids: partialDescendants.map((eo) => eo._id.toString()),
        }).then((results) =>
          results?.etymologyObjects?.forEach((etymologyObject) =>
            queryClient.setQueryData(
              [
                QueryKey.DETAIL_PREVIEW,
                getIdentifierCache({ ids: etymologyObject._id.toString() }),
              ],
              {
                etymologyObject,
                type: 'detail',
              }
            )
          )
        )
      },
    }
  )

  const { nestedTree, etymologyObjects } = progenyTreeData ?? {}

  return { nestedTree, etymologyObjects, ...rest }
}

export const useConnections = () => {
  const { loggedIn } = useAuthStore()
  const { identifier, cacheIdentifier, direction } = usePathIdentifier()
  const { data: connectionsData, ...rest } = useQuery<
    ConnectionsDataType | undefined,
    Error,
    ConnectionsDataType | undefined,
    ConnectionsKeyType
  >(
    [QueryKey.CONNECTIONS, cacheIdentifier, direction],
    () =>
      identifier
        ? getConnections({ identifier, direction: direction ?? 'root' })
        : undefined,
    { enabled: !!identifier && loggedIn }
  )

  const { connection_items, trunkObject } =
    connectionsData ??
    ({ connection_items: [] as ConnectionItem[] } as ConnectionsDataType)

  return { connection_items, trunkObject, ...rest }
}

type useConnectionDetailsProps = {
  root: string | undefined
  descendant: string | undefined
}
export const useConnectionDetails = ({
  root,
  descendant,
}: useConnectionDetailsProps) => {
  const useConnectionDetailsKey: ConnectionDetailsKeyType = [
    QueryKey.CONNECTION_DETAILS,
    root,
    descendant,
  ]
  const { data: connectionDetailData, ...rest } = useQuery<
    ConnectionDetailsDataType | undefined,
    Error,
    ConnectionDetailsDataType | undefined,
    ConnectionDetailsKeyType
  >(
    useConnectionDetailsKey,
    () =>
      root && descendant
        ? getConnectionDetails({
            root,
            descendant,
          })
        : undefined,
    {
      // onSuccess: (data) => reduceCompany({ type: "load", payload: data }),
      enabled: !!root && !!descendant,
    }
  )
  const { connection_items } = connectionDetailData ?? {}
  return {
    connection_items,
    ...rest,
  }
}

/**
 * Redirects the browser to the correct path based on path variables and random etymologies
 */

export const useNavigateToRandomEtymology = (replaceInHistory: boolean) => {
  const navigate = useNavigate() // dont use usePathNavigate due to infinite loop
  const { channel } = useChannel()
  const searchLanguage = getStorageItem(StorageItems.SEARCH_LANGUAGE)
  const { component } = usePathParams()
  // Update type dependent on the path component

  const commonLanguages = Object.values(common_lang_dict)
  const wordStrata = getPathWordStrata(component)

  const navigateToRandomEtymology = useCallback(
    (type?: WordStrata, path?: Path, language?: string) => {
      const providedType = type ?? wordStrata
      const providedLanguage = language
        ? language // use provided language if available
        : searchLanguage !== ALL_LANGUAGES_STRING
        ? searchLanguage ?? 'English' // use searchLaguage if not ALL Languages
        : providedType === WordStrata.ROOT
        ? 'English' // use English to enable random root selection
        : randomItem(commonLanguages) // use a common language

      // console.log('navigateToRandomEtymology', {
      //   path,
      //   component,
      //   isWordComponent: isWordComponent(component),
      //   type,
      //   providedType,
      // })

      return getRandomEtymology({
        language: providedLanguage,
        type: providedType,
        channel,
      }).then((etymology) => {
        const url = `/${
          path ?? (isWordComponent(component) ? component : DEFAULT_PATH)
        }/${etymology.language}/${etymology.word}`
        // console.log('getRandomEtymology url', url)
        navigate(url, { replace: replaceInHistory })
      })
    },
    [
      wordStrata,
      searchLanguage,
      commonLanguages,
      channel,
      navigate,
      component,
      replaceInHistory,
    ]
  )

  return navigateToRandomEtymology
}

export const useRadialGradients = (
  etymologyObjects: EtymologyObjectDict | undefined
) => {
  const [uniqueLangs, setUniqueLangs] = useState<string[]>([])
  useEffect(() => {
    setUniqueLangs(
      Object.values(etymologyObjects ?? {})
        .filter(isTruthy)
        .map((eo) => eo.language_name)
        .filter(onlyUnique)
    )
  }, [etymologyObjects])

  return (
    <defs>
      <radialGradient
        key={'root'}
        id={ElementIds.D3_HOVER_RADIAL_GRADIENT_ROOT}
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stopColor={Colors.eeSelectionBlue}
          stopOpacity="100%"
        />
        <stop
          offset="100%"
          stopColor={Colors.eeSelectionBlue}
          stopOpacity="0%"
        />
      </radialGradient>
      {
        //  https://stackoverflow.com/questions/37962071/react-js-use-svg-linear-gradient-not-working
        // Make gradients for all the languages of the etymologyObjects
        uniqueLangs.map((lang) => {
          const languageColor = getLanguageColor(lang, 1)

          return (
            <radialGradient
              key={lang}
              id={`hover-radial-gradient-${lang}`}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop offset="0%" stopColor={languageColor} stopOpacity="100%" />
              <stop offset="100%" stopColor={languageColor} stopOpacity="0%" />
            </radialGradient>
          )
        })
      }
    </defs>
  )
}

export const useFrequencyType = (identifier?: APIIdentifier | null) => {
  const { frequencies, frequencies_log } = useDetailPage(identifier)
  const [wordStrata, setWordStrata] = useState<WordStrata>(WordStrata.ANY)
  const wordFrequencyPercentile = frequencies
    ? getWordFrequencyPercentile(frequencies)
    : undefined
  const rootFrequencyPercentile = frequencies_log
    ? getRootFrequencyPercentile(frequencies_log)
    : undefined

  useEffect(() => {
    if (!rootFrequencyPercentile && !wordFrequencyPercentile) {
      setWordStrata(WordStrata.ANY)
    } else if (!wordFrequencyPercentile && rootFrequencyPercentile) {
      setWordStrata(WordStrata.ROOT)
    } else if (wordFrequencyPercentile && !rootFrequencyPercentile) {
      setWordStrata(WordStrata.CHILD)
    } else if (
      wordFrequencyPercentile === undefined ||
      rootFrequencyPercentile === undefined ||
      wordFrequencyPercentile > rootFrequencyPercentile
    ) {
      setWordStrata(WordStrata.CHILD)
    }
  }, [rootFrequencyPercentile, wordFrequencyPercentile])

  return { wordStrata, rootFrequencyPercentile, wordFrequencyPercentile }
}

export const usePronunciation = (ipa: string, language: string) => {
  const queryClient = useQueryClient()
  const [audio, setAudio] = useState<HTMLAudioElement>()
  const [hasPlayed, setHasPlayed] = useState(false)
  const [isPlaying, setPlaying] = useState(false)
  const { channel } = useChannel()
  const { data: audioUrl, ...rest } = useQuery<
    string | undefined,
    Error,
    string | undefined,
    PronunciationKeyType
  >(
    [QueryKey.PRONUNCIATION, ipa, language],
    () => getPronunciation(ipa, language, channel),
    { enabled: hasPlayed }
  )

  const play = () => {
    setHasPlayed(true)
    setPlaying(true)
  }

  const prefetch = () =>
    queryClient.prefetchQuery({
      queryKey: [QueryKey.PRONUNCIATION, ipa, language],
    })

  useEffect(() => {
    setAudio(new Audio(audioUrl))
  }, [audioUrl])

  useEffect(() => {
    isPlaying && audio?.play()
  }, [isPlaying, audio])

  useEffect(() => {
    audio?.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio?.removeEventListener('ended', () => setPlaying(false))
    }
  }, [audio])

  return { play, isPlaying, prefetch, ...rest }
}
